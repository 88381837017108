import React from "react";
import { StyleSheet, View } from "react-native";

import { Scale, VerticalScale } from "../constants";
import type { RecipeMacrosItemType } from "../types";
import MacroProgressWidget from "./RecipeMacrosItem";

const styles = StyleSheet.create({
  horizontalMode: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingHorizontal: Scale(10),
    paddingVertical: VerticalScale(12),
  },
  verticalMode: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingHorizontal: Scale(10),
    paddingVertical: VerticalScale(12),
    // borderColor: Colors.grayBorder,
    borderWidth: 1,
    borderRadius: 8,
    borderStyle: "solid",
  },
  transformStyle: {
    transform: [{ rotateZ: "-3deg" }],
  },
});

type RecipeMacrosProps = {
  RecipeMacrosItems: RecipeMacrosItemType[];
  isTransformed?: boolean;
  verticalMode?: boolean;
  showLabel?: boolean;
  showUnits?: boolean;
};

const RecipeMacros = ({
  RecipeMacrosItems,
  verticalMode,
  isTransformed,
  showLabel = true,
  showUnits = true,
}: RecipeMacrosProps): JSX.Element => (
  <View
    style={[verticalMode ? styles.verticalMode : styles.horizontalMode, isTransformed ? styles.transformStyle : {}]}
  >
    {RecipeMacrosItems.map((statusItem, index) => (
      <MacroProgressWidget
        key={index}
        macroDetail={{
          macroName: statusItem.macroName,
          unit: statusItem.unit,
          total: statusItem.total,
          progress: statusItem.progress,
        }}
        showLabel={showLabel}
        showUnit={showUnits}
        verticalMode={verticalMode}
      />
    ))}
  </View>
);
export default RecipeMacros;
