import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import type { RootState } from "../../../store";
import type { Food, GeneratedRecipeMeal, RecipeTemplate } from "../services/backendTypes";
// import logger from "../services/logger";

export type FoodById = { [id: string]: Food };

export interface FoodState {
  food: {
    byId: FoodById;
  };
  createOwnRecipeTemplate?: RecipeTemplate; // This is the own recipe template that is currently being created
}

// TODO: Consider using createEntityAdapter (https://redux-toolkit.js.org/api/createEntityAdapter)
const initialState: FoodState = {
  food: { byId: {} },
  createOwnRecipeTemplate: undefined,
};

export const foodSlice = createSlice({
  name: "food",
  initialState,
  reducers: {
    storeFood(state, action: { payload: Food }) {
      const food = action.payload;

      if (food.id) {
        // eslint-disable-next-line no-param-reassign
        state.food.byId[food.id] = food;
      }
    },
    setCreateOwnRecipeTemplate(state, action: { payload: RecipeTemplate | undefined }) {
      // eslint-disable-next-line no-param-reassign
      state.createOwnRecipeTemplate = action.payload;
    },
  },
});

const selectSelf = (state: RootState): FoodState => state[foodSlice.name];

export const foodSelector = createSelector(selectSelf, (state: FoodState): FoodById => state?.food?.byId ?? {});

export const createOwnRecipeTemplateSelector = createSelector(
  selectSelf,
  (state: FoodState): RecipeTemplate | undefined => state?.createOwnRecipeTemplate
);
