// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Divider, Flex, ScrollView } from "native-base";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Image, Text, View } from "react-native";

import { CommonButton } from "../commons";
import PlanRowItem from "../components/PlanRowItem";
import { Images, Routes } from "../constants";
import styles from "./PlanScreenStyle";

const SportsDataList = [
  {
    id: 1,
    label: "30 minuten fietsen naar werk",
    value: "3x per week",
    image: Images.BicycleIcon,
  },
  {
    id: 2,
    label: "60 minuten workout met coach",
    value: "2x per week",
    image: Images.LiftIcon,
  },
];

type ParamList = {
  PlanScreen: undefined;
  NutritionPlanScreen: undefined;
};
type PlanScreenProps = {
  navigation: NativeStackNavigationProp<ParamList, "PlanScreen">;
};

const PlanScreen = ({ navigation }: PlanScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const coachName = "Kevin Matisse";
  const age = "35 jaar";
  const weight = "70 kilogram";
  const fatPercent = "15%";
  const lifeStyle = "Licht actief";

  const AvatarHeader = (): JSX.Element => (
    <View style={styles.rowContainer}>
      <Image style={styles.avatarImg} source={Images.Avatar}></Image>
      <View>
        <Text style={styles.roleText}>{"Jouw coach"}</Text>
        <Text style={styles.nameText}>{coachName}</Text>
      </View>
    </View>
  );

  const SportListView = (): JSX.Element => (
    <View>
      <Flex direction="row" justifyContent={"space-between"} mt="3">
        <Text style={styles.sectionTitle}>{"Sport"}</Text>
        <Image source={Images.PencilIconWhite} />
      </Flex>
      {SportsDataList.map((item, index) => (
        <View key={index} style={styles.itemRowContainer}>
          <Image source={item.image} style={styles.sportImg} />
          <View>
            <Text style={styles.labelText}>{item.label}</Text>
            <Text style={styles.valueText}>{item.value}</Text>
          </View>
        </View>
      ))}
    </View>
  );

  const UserDataView = (): JSX.Element => (
    <View>
      <Flex direction="row" justifyContent={"space-between"} mt="6">
        <Text style={styles.sectionTitle}>{"Jouw gegevens"}</Text>
        <Image source={Images.PencilIconWhite} />
      </Flex>
      <PlanRowItem label={"Leeftijd"} value={age} />
      <PlanRowItem label={"Gewicht"} value={weight} />
      <PlanRowItem label={"Vetpercentage"} value={fatPercent} />
      <PlanRowItem label={"Levenstijl"} value={lifeStyle} />
    </View>
  );

  const MainGoalView = (): JSX.Element => (
    <View>
      <Flex direction="row" justifyContent={"space-between"} mt="3">
        <Text style={styles.sectionTitle}>{"Belangrijkste doel"}</Text>
        <Image source={Images.PencilIconWhite} />
      </Flex>
      <View style={styles.itemRowContainer}>
        <Text style={styles.labelText}>{"10 kilogram afvallen"}</Text>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={styles.avatarHeader}>
          <AvatarHeader />
        </View>

        <Divider my="2" />

        <View style={styles.mainContainer}>
          <View style={styles.container}>
            <Flex direction="row" justifyContent={"space-between"} mt="6">
              <Text style={styles.planTitle}>{"Juow plan"}</Text>
            </Flex>
            <UserDataView />
            <SportListView />
            <MainGoalView />
          </View>
        </View>
      </ScrollView>

      <View style={styles.bottomContainer}>
        <CommonButton
          title={"Naar voedingsplan"}
          onPress={() => navigation.push(Routes.NutritionPlanScreen as keyof ParamList)}
        />
      </View>
    </View>
  );
};
export default PlanScreen;
