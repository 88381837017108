import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { DietPreferences, onboardingDataSelector, onboardingSlice } from "../../slices/onboardingSlice";
import { userSelector } from "../../slices/userSlice";
import { MealMomentEnum } from "../../types";
import SelectionScreen, { Option } from "./SelectionScreen";

interface MealTimesScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const MealTimesScreen: React.FC<MealTimesScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const onboardingData = useSelector(onboardingDataSelector);

  if (!user) {
    throw new Error("User is not defined");
  }

  const mealOptions: Option[] = [
    {
      label: t("onboarding.onboarding_8_meal_times_screen.breakfast"),
      description: t("onboarding.onboarding_8_meal_times_screen.breakfast_description"),
      value: MealMomentEnum.BREAKFAST,
      icon: "🍳", // Eggs in frying pan
      testID: `meal-option-${MealMomentEnum.BREAKFAST}`,
    },
    {
      label: t("onboarding.onboarding_8_meal_times_screen.morning_snack"),
      description: t("onboarding.onboarding_8_meal_times_screen.morning_snack_description"),
      value: MealMomentEnum.MORNING_SNACK,
      icon: "🍏", // Green apple
      testID: `meal-option-${MealMomentEnum.MORNING_SNACK}`,
    },
    {
      label: t("onboarding.onboarding_8_meal_times_screen.lunch"),
      description: t("onboarding.onboarding_8_meal_times_screen.lunch_description"),
      value: MealMomentEnum.LUNCH,
      icon: "🍽️", // Fork and knife
      testID: `meal-option-${MealMomentEnum.LUNCH}`,
    },
    {
      label: t("onboarding.onboarding_8_meal_times_screen.afternoon_snack"),
      description: t("onboarding.onboarding_8_meal_times_screen.afternoon_snack_description"),
      value: MealMomentEnum.AFTERNOON_SNACK,
      icon: "🍏", // Green apple
      testID: `meal-option-${MealMomentEnum.AFTERNOON_SNACK}`,
    },
    {
      label: t("onboarding.onboarding_8_meal_times_screen.dinner"),
      description: t("onboarding.onboarding_8_meal_times_screen.dinner_description"),
      value: MealMomentEnum.DINNER,
      icon: "🍛", // Curry with rice
      testID: `meal-option-${MealMomentEnum.DINNER}`,
    },
    {
      label: t("onboarding.onboarding_8_meal_times_screen.snack"),
      description: t("onboarding.onboarding_8_meal_times_screen.snack_description"),
      value: MealMomentEnum.SNACK,
      icon: "🍪", // Cookie
      testID: `meal-option-${MealMomentEnum.SNACK}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedMeals: Yup.array()
      .of(Yup.mixed().oneOf(Object.values(MealMomentEnum)))
      .test("required-meals", t("onboarding.onboarding_8_meal_times_screen.required_meals_error"), (selectedMeals) =>
        Boolean(selectedMeals?.includes(MealMomentEnum.LUNCH) && selectedMeals?.includes(MealMomentEnum.DINNER))
      )
      .min(2, t("onboarding.onboarding_8_meal_times_screen.min_meals_error"))
      .required(t("onboarding.onboarding_8_meal_times_screen.required_meals_error")),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    // Update the state with the new diet preferences
    const updatedDietPreferences: DietPreferences = {
      ...onboardingData.dietPreferences,
      mealTimes: values.selectedMeals,
    };
    dispatch(onboardingSlice.actions.setDietPreferences(updatedDietPreferences));

    navigation.navigate(Routes.DietSelectionScreen);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({
        selectedMeals: [MealMomentEnum.BREAKFAST, MealMomentEnum.LUNCH, MealMomentEnum.DINNER],
      })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue, errors, touched }) => {
        const handleToggleSelection = (selectedOptions: string[]): void => {
          setFieldValue("selectedMeals", selectedOptions);
        };

        return (
          <SelectionScreen
            title={t("onboarding.onboarding_8_meal_times_screen.title")}
            subtitle={t("onboarding.onboarding_8_meal_times_screen.subtitle")}
            options={mealOptions}
            selectedOptions={values.selectedMeals}
            setSelectedOptions={handleToggleSelection}
            onPressNext={handleSubmit}
            multipleSelection={true}
            onPressBack={() => navigation.goBack()}
            errorMessage={typeof errors.selectedMeals === "string" && touched.selectedMeals ? errors.selectedMeals : ""}
          />
        );
      }}
    </Formik>
  );
};

export default MealTimesScreen;
