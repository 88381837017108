/**
 * This is taken from the legacy codebase (`src/components/calculator/macros.js` in the `weekmeals.co` repo)
 *
 * It has been converted from flow to typescript.
 *
 * I have tried to make minimal changes but we use some of our types (from backendTypes)
 * which are the same as the legacy but with only minor differences (ie, uppercase vs lowercase).
 * This was done to help with the conversion.
 */

import type {
  ActivityEnum as Activity,
  DietEnum as DietName,
  GenderEnum as Gender,
  SustainabilityEnum as Sustainability,
} from "./backendTypes.js";

export type WeightliftingSession = {
  inactivityFactor: number; // as a percentage (50% => 0.5).
  duration: number; // in minutes.
  weeklyFrequency: number; // in times per week.
};

export type CardioSession = {
  id: number;
  activity: string;
  burnRate: number; // in kcal/kg/min.
};

export type CardioOption = {
  id: number;
  name: string;
  session: CardioSession;
  netEnergyExpenditure: number; // in kcal/30 min.
};

export type CardioInput = {
  session: CardioSession;
  option: CardioOption; // selected cardio option.
  duration: number; // in minutes.
  weeklyFrequency: number; // times per week.
};

export type EnergyInput = {
  gender: Gender;
  weight: number; // in kilos.
  bodyFatPercentage: number; // as a percentage (16% => 0.16).

  // Thermic effect of diet (0.1 - 0.25). Higher when lean and when diet's rich
  // in whole foods, unsat fats, MCTs, high volume foods, and lots of fiber.
  // For average western diet low in protein, it's 10%.
  thermicEffectOfDiet: number;

  lifestyle: Activity;

  cardio?: CardioInput[];

  weightlifting?: WeightliftingSession;
};

export type EnergyExpenditure = {
  leanBodyMass: number;

  daily: {
    // Basal energy expenditure, also known as basal metabolic rate (BMR), is
    // the energy used to fuel all other internal processes, such as the
    // maintenance of respiration, heart rate, and kidney function. It is
    // affected by such factors as environmental temperature, as the body will
    // work to maintain its internal temperature of 98.6° F (about 37° C), and
    // muscle mass, as larger muscles have increased energy requirements even
    // when at rest.
    bmr: number;

    ree: number;

    // Lifestyle physical activities.
    lifestyle: number;

    // Planned cardio (and sports).
    cardio: number;

    // Planned weightlifting
    weightlifting: number;

    // Food digestion and processing.
    foodDigestion: number;

    // Total Daily Energy Expenditure (TDEE).
    total: number;
  };
};

export type EnergyOptions = {
  energyBalance: number | undefined;
  diet: DietName;
  optimize: Sustainability;
};

export function computeLeanBodyMass(input: EnergyInput): number {
  // B13 = B11 * ((100-B12)/100)
  const { weight, bodyFatPercentage } = input;
  return weight * (1 - bodyFatPercentage);
}

export function computeBMR(leanBodyMass: number): number {
  // B22 = 370 + 21.6*(if(B21=1,B13,B18))
  // True BF%=1, Estimated BF%=0
  // const {useEstimatedBodyFat, estimatedLeanBodyMass, leanBodyMass} = energy;
  // return (
  //   370 + 21.6 * (useEstimatedBodyFat ? estimatedLeanBodyMass : leanBodyMass)
  // );
  return 370 + 21.6 * leanBodyMass;
}

// Lifestyle physical activities
//                  Men  Women  Bescription
// Sedentary        1    1      Office job.
// Somewhat active  1.11 1.12   Walks the dog several times a day, travels by
//                              bicycle.
// Active           1.25 1.27   Full-time waiter or PT, on your feet whole day.
// Very active      1.48 1.45   Manual labor, like a construction worker.
const lifestyleFactors: { [G in Gender]: { [A in Activity]: number } } = {
  MALE: {
    SEDENTARY: 1.0,
    MILDLY_ACTIVE: 1.11,
    ACTIVE: 1.25,
    VERY_ACTIVE: 1.48,
  },
  FEMALE: {
    SEDENTARY: 1.0,
    MILDLY_ACTIVE: 1.12,
    ACTIVE: 1.27,
    VERY_ACTIVE: 1.45,
  },
};

export function computeLifestyle(input: EnergyInput, bmr: number): number {
  // B36 = Lifestyle physical activity factor
  // B78 = BMR
  // B79 = (B36 - 1) * B78
  const { gender, lifestyle } = input;
  return (lifestyleFactors[gender][lifestyle] - 1) * bmr;
}

export function computeRestingEnergyExpenditure(input: EnergyInput, bmr: number): number {
  // B36 = Lifestyle physical activity factor
  const { gender, lifestyle, thermicEffectOfDiet } = input;
  const lifestyleFactor = lifestyleFactors[gender][lifestyle];
  // B40 = Thermic effect of diet (1.1 - 1.25)
  // B41 = B36* BMR *B40
  return lifestyleFactor * bmr * (thermicEffectOfDiet + 1);
}

export function computeNetEnergyExpenditureForCardio(input: CardioInput, ree: number, lbm: number): number {
  // weekly duration = D61 * E61
  const weeklyDuration = input.duration * input.weeklyFrequency;

  // F61 = Total energy exp
  // F61 = IFERROR(B$13*C61*D61*E61,0)
  const total = lbm * input.session.burnRate * weeklyDuration;

  // G61 = Basal energy exp
  // G61 = B41/24/60 * D61 * E61
  const basal = (ree / 24 / 60) * weeklyDuration;

  // H61 = Net energy exp (-BMR)
  // H61 = F61-G61
  return total - basal;
}

export function initializeCardioOption(session: CardioSession, netEnergyExpenditure: number): CardioOption {
  return {
    id: session.id,
    name: session.activity,
    session,
    netEnergyExpenditure,
  };
}

export function personalizeCardioOptions(sessions: CardioSession[], ree: number, lbm: number): CardioOption[] {
  return sessions.map((session) =>
    initializeCardioOption(
      session,
      // Compute net energy expenditure (in kcal/30 min).
      Math.round(session.burnRate * lbm * 30 - (ree / 24 / 60) * 30)
    )
  );
}

export function computeCardio(input: EnergyInput, ree: number, lbm: number, bmr: number): number {
  if (!input.cardio) return 0.0;
  // Exercise physical activities
  // Total weekly planned cardio energy exp:
  // H62 = sum(H48:H61)
  const weekly = input.cardio
    .map((cardio) => (cardio ? computeNetEnergyExpenditureForCardio(cardio, ree, lbm) : 0))
    // eslint-disable-next-line camelcase
    .reduce((partial_sum, i) => partial_sum + i, 0);

  // Average daily planned cardio energy exp:
  // H63 = H62 / 7
  const daily = weekly / 7;

  // Planned cardio (and sports):
  // B80 = H63
  return daily;
}

export function computeWeightlifting(input: EnergyInput, bmr: number): number {
  // B11 = weight.
  const { weight, weightlifting } = input;

  if (!weightlifting) return 0.0;

  // TODO is this a constant or user adjustable?
  // C68 = kcal/kg/min
  const burnRate = 0.1;

  // Duration (in minutes)
  // E68 = weekly frequency (times per week)
  // inactivity factor as a percentage (where 0% means always active).
  const { duration, weeklyFrequency, inactivityFactor } = weightlifting;
  if (duration <= 0 || weeklyFrequency <= 0 || inactivityFactor < 0) return 0.0;

  // Net calories per session (-BMR):
  // F68 = (C68*D68*$B$11)-(($B$22/24/60)*E68)
  const netCaloriesPerSessionMinusBMR =
    burnRate * (1 - inactivityFactor) * duration * weight - (bmr / 24 / 60) * weeklyFrequency;

  // Average daily planned weight training energy exp:
  // H70 = F68*(E68/7)
  return netCaloriesPerSessionMinusBMR * (weeklyFrequency / 7);
}

export function computeFoodDigestion(input: EnergyInput, energy: EnergyExpenditure): number {
  const { bmr, lifestyle, cardio, weightlifting } = energy.daily;
  return (bmr + lifestyle + cardio + weightlifting) * input.thermicEffectOfDiet;
}

export function computeTDEE(energy: EnergyExpenditure): number {
  const { bmr, lifestyle, cardio, weightlifting, foodDigestion } = energy.daily;
  return bmr + lifestyle + cardio + weightlifting + foodDigestion;
}

export function computeEnergyExpenditure(input: EnergyInput): EnergyExpenditure | undefined {
  if (!input.gender) return undefined;

  const energy: EnergyExpenditure = {
    leanBodyMass: 0.0,

    daily: {
      bmr: 0.0,
      ree: 0.0,
      lifestyle: 0.0,
      cardio: 0.0,
      weightlifting: 0.0,
      foodDigestion: 0.0,
      total: 0.0,
    },
  };

  const lbm = computeLeanBodyMass(input);
  const bmr = computeBMR(lbm);

  // REE (Resting Energy Expenditure):
  // Energy you expend when you do no planned activities.
  // B41 = B36*(if(B38=1,B22,B25))*B40
  const ree = computeRestingEnergyExpenditure(input, bmr);

  energy.leanBodyMass = lbm;
  // energy.estimatedLeanBodyMass = computeEstimatedLeanBodyMass(energy);

  energy.daily.bmr = bmr;
  energy.daily.ree = ree;
  energy.daily.lifestyle = computeLifestyle(input, bmr);
  energy.daily.cardio = computeCardio(input, ree, lbm, bmr);
  energy.daily.weightlifting = computeWeightlifting(input, bmr);
  energy.daily.foodDigestion = computeFoodDigestion(input, energy);
  energy.daily.total = computeTDEE(energy);

  return energy;
}

function getTotalCalorieIntake(energy: EnergyExpenditure, energyBalance: number | undefined): number {
  return energy.daily.total * (energyBalance || 0);
}

export const HighTotalCalorieIntakeThreshold = 2000;

export function hasHighTotalCalorieIntake(energy: EnergyExpenditure, energyBalance: number | undefined): boolean {
  return getTotalCalorieIntake(energy, energyBalance) >= HighTotalCalorieIntakeThreshold;
}

export function pickOptimizationGoal(
  energy: EnergyExpenditure | undefined,
  goal: Sustainability,
  energyBalance: number | undefined
): Sustainability {
  if (!energy) return "SUSTAINABLE";
  // Force optimization goal to 'results' when total calorie intake is high.
  // This makes the meals more tasteful by distributing the calories more
  // evenly across protein, fat and carbs. The goal 'lifestyle' will favor
  // more carbs instead of fat, resulting in carb-heavy meals.
  if (hasHighTotalCalorieIntake(energy, energyBalance)) return "OPTIMAL";

  return goal;
}

export type RelativeMacros = { protein: number; fat: number; carbs: number };

export type LegacyMealType = "breakfast" | "lunch" | "snack" | "dinner" | "pre-bed";
export type Macros = {
  calories: number;
  protein: number;
  carbs: number;
  fat: number;
  fiber: number;
};
export type MealTypeTotalMacros = { [M in LegacyMealType | "total"]: Macros };
export type MacroDistribution = {
  meals: MealTypeTotalMacros;
};

export type MealPreferences = { [M in LegacyMealType]: boolean };

type PossibleRatioKey =
  | ""
  | "b"
  | "l"
  | "s"
  | "d"
  | "p"
  | "b_l"
  | "b_s"
  | "b_d"
  | "b_p"
  | "l_s"
  | "l_d"
  | "l_p"
  | "s_d"
  | "s_p"
  | "d_p"
  | "b_l_s"
  | "b_l_d"
  | "b_l_p"
  | "b_s_d"
  | "b_s_p"
  | "b_d_p"
  | "l_s_d"
  | "l_s_p"
  | "l_d_p"
  | "s_d_p"
  | "b_l_s_d"
  | "b_l_s_p"
  | "b_l_d_p"
  | "b_s_d_p"
  | "l_s_d_p"
  | "b_l_s_d_p";
export type RatioValue = { [MT in LegacyMealType]?: number };
type PossibleRatioValue = RatioValue | undefined;

function computeOptimalProtein(input: EnergyInput, energy: EnergyExpenditure, options: EnergyOptions): number {
  const { bodyFatPercentage, weight, gender } = input;
  const lbm = energy.leanBodyMass;

  let factor = 1.8;
  if (options.optimize === "SUSTAINABLE") {
    factor = {
      MALE: 1.6,
      FEMALE: 1.5,
    }[gender];
  }

  // D16 is 'yes' or 'no'.
  // B15 = IF(D16="yes",2*'CEE'!B13,1.8*'CEE'!B11)
  const constrain =
    bodyFatPercentage >
    {
      MALE: 0.2, // if male and bf% > 20%: constrain protein
      FEMALE: 0.3, // if female and bf% > 30%: constrain protein
    }[gender];

  let protein = factor * weight;

  if (constrain) protein = 2 * lbm;

  return protein;
}

function computeOptimalFat(input: EnergyInput, energy: EnergyExpenditure, options: EnergyOptions): number {
  const { gender } = input;
  const { bmr, ree } = energy.daily;

  // B17 = if(B16="Male",('CEE'!B39*0.4)/9,('CEE'!B41*0.4)/9)
  let factor;
  if (options.optimize === "SUSTAINABLE") factor = 0.25;
  else factor = 0.4;

  let fat;
  if (gender === "MALE") {
    fat = (bmr * factor) / 9;
  } else {
    fat = (ree * factor) / 9;
  }

  return fat;
}

export function computeOptimalMacros(
  input: EnergyInput,
  energy: EnergyExpenditure,
  options: EnergyOptions
): { protein: number; fat: number } {
  const protein = computeOptimalProtein(input, energy, options);
  const fat = computeOptimalFat(input, energy, options);

  // TODO
  // Alleen gebruiken bij mensen met overgewicht op een PSMF dieet, of bij hoge
  // uitzondering.
  // B18 = (0.2*B12)/9
  // const minimalFat = (0.2 * calorieIntake) / 9;

  return {
    protein,
    fat,
  };
}

function computeDailyMacros(
  optimal: { protein: number; fat: number },
  energy: EnergyExpenditure,
  options: EnergyOptions
): Macros {
  const calories = getTotalCalorieIntake(energy, options.energyBalance);

  const fatAdjustmentFactor = calories > 2800 ? calories / 2600 : 1;

  // B28 = if(D21="Vegan",B15*(2.2/1.8),if(D21="Vegetarian",B15*(2/1.8),B15))
  // TODO use 2.2 for vegan when lysine supplements are taken.
  const proteinFactor = {
    VEGAN: 2.4 / 1.8,
    VEGETARIAN: 2 / 1.8,
    OVO_VEGETARIAN: 2 / 1.8,
    LACTO_VEGETARIAN: 2 / 1.8,
    PESCATARIAN: 1,
    OMNIVORE: 1,
    HALAL: 1,
  }[options.diet];

  const protein = Math.round(optimal.protein * proteinFactor);

  // TODO Implement keto or obese adjustment?
  // B27 = if(B21=0,(B12-(4*B15)-(9*B17))/4,B22)*(1/B13)
  let carbs = Math.round((calories - 4 * protein - 9 * optimal.fat) / 4 / fatAdjustmentFactor);

  const keto = false;
  if (keto) carbs = 90;

  // TODO Implement fat adjustment?
  // B29 = if(B23=0,B17+((B12-(4*B27)-(4*B28))/9-B17),B24)
  const fat = Math.round((calories - 4 * carbs - 4 * protein) / 9);

  return {
    calories: Math.round(carbs * 4 + protein * 4 + fat * 9),
    protein,
    fat,
    carbs,
    fiber: NaN,
  };
}

export function computeRelativeMacros(total: Macros): RelativeMacros {
  return {
    protein: ((total.protein * 4) / total.calories) * 100,
    fat: ((total.fat * 9) / total.calories) * 100,
    carbs: ((total.carbs * 4) / total.calories) * 100,
  };
}

export function computeRecommendedMacros(
  input: EnergyInput,
  energy: EnergyExpenditure | undefined,
  options: EnergyOptions
): Macros {
  if (typeof options.energyBalance !== "number" || options.energyBalance < 0.01 || !energy) {
    throw new Error(`Invalid energy balance: ${String(options.energyBalance)}`);
  }

  const optimal = computeOptimalMacros(input, energy, options);
  return computeDailyMacros(optimal, energy, options);
}

function getMealPreferencesKey(meals: LegacyMealType[]): PossibleRatioKey {
  const getFirstLetter = (mealType: LegacyMealType): PossibleRatioKey => mealType[0] as PossibleRatioKey;

  return meals.map(getFirstLetter).join("_") as PossibleRatioKey;
}

type LegacyMacroType = "calories" | "protein" | "fat" | "carbs" | "fiber";
export const macroTypes: LegacyMacroType[] = ["calories", "protein", "fat", "carbs", "fiber"];
export const mealTypes: LegacyMealType[] = ["breakfast", "lunch", "snack", "dinner", "pre-bed"];

export function computeCalories(macros: Macros): number {
  const factors = {
    calories: NaN,
    protein: 4,
    fat: 9,
    carbs: 4,
    fiber: 2,
  };
  return macroTypes
    .filter((key) => key !== "calories")
    .map((type) => factors[type] * macros[type])
    .filter((x) => !Number.isNaN(x))
    .reduce((a, b) => a + b, 0);
}

function computeRemainingMacros(recommended: Macros, meals: MealTypeTotalMacros, last: LegacyMealType): Macros {
  const remaining = { ...recommended };

  mealTypes.forEach((mealType) => {
    if (mealType === last.toLowerCase()) return;

    macroTypes
      .filter((macro) => !Number.isNaN(meals[mealType][macro]))
      .forEach((macro) => {
        remaining[macro] -= meals[mealType][macro];
      });
  });

  remaining.calories = computeCalories(remaining);

  return remaining;
}

export function scaleMacrosOverMealType(recommended: Macros, ratios: PossibleRatioValue): MealTypeTotalMacros {
  const total = {
    calories: NaN,
    protein: Math.round(recommended.protein),
    fat: Math.round(recommended.fat),
    carbs: Math.round(recommended.carbs),
    fiber: NaN,
  };

  const meals: MealTypeTotalMacros = {
    total: { ...total, calories: computeCalories(total) },
    breakfast: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      fiber: 0,
    },
    lunch: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      fiber: 0,
    },
    snack: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      fiber: 0,
    },
    dinner: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      fiber: 0,
    },
    "pre-bed": {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      fiber: 0,
    },
  };

  mealTypes.forEach((mealType) => {
    meals[mealType] = {
      calories: 0,
      protein: NaN,
      fat: NaN,
      carbs: NaN,
      fiber: NaN,
    };
  });

  mealTypes.forEach((mealType) => {
    if (!ratios) throw new Error("Ratio value was undefined - this should never happen.");

    const ratio = ratios[mealType];
    if (!ratio) return;

    const macros = {
      calories: NaN,
      protein: Math.round(ratio * recommended.protein),
      fat: Math.round(ratio * recommended.fat),
      carbs: Math.round(ratio * recommended.carbs),
      fiber: NaN,
    };

    macros.calories = computeCalories(macros);
    meals[mealType] = macros;
  });

  const last = mealTypes
    .filter((mealType) => {
      if (!ratios) throw new Error("Ratio value was undefined - this should never happen.");

      return ratios[mealType];
    })
    .slice(-1)[0];

  if (!last) throw new Error("`last` was undefined - this should never happen.");

  meals[last] = computeRemainingMacros(recommended, meals, last);

  return meals;
}

const possibleRatios: { [K in PossibleRatioKey]: PossibleRatioValue } = {
  "": undefined,

  b: { breakfast: 1 },
  l: { lunch: 1 },
  s: { snack: 1 },
  d: { dinner: 1 },
  p: { "pre-bed": 1 },

  b_l: { breakfast: 0.5, lunch: 0.5 },
  b_s: { breakfast: 0.5, snack: 0.5 },
  b_d: { breakfast: 0.4, dinner: 0.6 },
  b_p: { breakfast: 0.5, "pre-bed": 0.5 },
  l_s: { lunch: 0.7, snack: 0.3 },
  l_d: { lunch: 0.4, dinner: 0.6 },
  l_p: { lunch: 0.6, "pre-bed": 0.4 },
  s_d: { snack: 0.2, dinner: 0.8 },
  s_p: { snack: 0.5, "pre-bed": 0.5 },
  d_p: { dinner: 0.6, "pre-bed": 0.4 },

  b_l_s: { breakfast: 0.35, lunch: 0.35, snack: 0.3 },
  b_l_d: { breakfast: 0.25, lunch: 0.25, dinner: 0.5 },
  b_l_p: { breakfast: 0.3, lunch: 0.35, "pre-bed": 0.35 },
  b_s_d: { breakfast: 0.35, snack: 0.15, dinner: 0.5 },
  b_s_p: { breakfast: 0.35, snack: 0.15, "pre-bed": 0.5 },
  b_d_p: { breakfast: 0.3, dinner: 0.5, "pre-bed": 0.2 },
  l_s_d: { lunch: 0.35, snack: 0.15, dinner: 0.5 },
  l_s_p: { lunch: 0.45, snack: 0.25, "pre-bed": 0.3 },
  l_d_p: { lunch: 0.25, dinner: 0.45, "pre-bed": 0.3 },
  s_d_p: { snack: 0.15, dinner: 0.55, "pre-bed": 0.3 },

  b_l_s_d: { breakfast: 0.25, lunch: 0.25, snack: 0.1, dinner: 0.4 },
  b_l_s_p: { breakfast: 0.3, lunch: 0.3, snack: 0.1, "pre-bed": 0.3 },
  b_l_d_p: { breakfast: 0.25, lunch: 0.25, dinner: 0.35, "pre-bed": 0.15 },
  b_s_d_p: { breakfast: 0.2, snack: 0.1, dinner: 0.5, "pre-bed": 0.2 },
  l_s_d_p: { lunch: 0.3, snack: 0.1, dinner: 0.4, "pre-bed": 0.2 },

  b_l_s_d_p: {
    breakfast: 0.2,
    lunch: 0.2,
    snack: 0.07,
    dinner: 0.35,
    "pre-bed": 0.18,
  },
};

export function computeMacroDistribution(recommended: Macros, preferences: MealPreferences): MacroDistribution {
  const meals = mealTypes.filter((mealType) => preferences[mealType]);
  const key = getMealPreferencesKey(meals);

  const ratios = possibleRatios[key];

  if (!ratios) throw new Error(`unknown combination of meal preferences: ${key}`);

  return {
    meals: scaleMacrosOverMealType(recommended, ratios),
  };
}

// NOTE: This function is not used anymore in the new codebase.
// It is commented because it has type errors from the original.
// export function serializeMealMacros(macros: MealTypeTotalMacros): string {
//   // Average day  Breakfast  Lunch  Snack  Dinner  Pre-bed meal  Total
//   // Calories           501    501      0    1001             0   2003
//   // Protein (g)         37     37             74                  148
//   // Fat (g)             21     21             41                   83
//   // Carbohydrate (g)    42     42             84                  167
//   const header = ["Macronutrient", "Breakfast", "Lunch", "Snack", "Dinner", "Pre-bed", "Total"];

//   const forAll = (fn: (s: string) => void): void => [...mealTypes, "total"].forEach(fn);

//   const calories = ["Calories"];
//   forAll((mealType) => calories.push(macros[mealType].calories));

//   const protein = ["Protein (g)"];
//   forAll((mealType) => protein.push(macros[mealType].protein));

//   const fat = ["Fat (g)"];
//   forAll((mealType) => fat.push(macros[mealType].fat));

//   const carbs = ["Carbohydrate (g)"];
//   forAll((mealType) => carbs.push(macros[mealType].carbs));

//   const output = [header, calories, protein, fat, carbs];

//   return output.map((row) => row.join("\t")).join("\n");
// }
