import { MaterialIcons } from "@expo/vector-icons";
import type { TFunction } from "i18next";
import _ from "lodash";
import { Icon, Text, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity } from "react-native";

import { VerticalScale } from "../constants";
import type { Food } from "../services/backendTypes";
import logger from "../services/logger";

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingVertical: VerticalScale(12),
    borderRadius: 8,
  },
});

type Props = {
  food: Food;
  onPress: (data: Food) => void;
  index: number;
};

function getFoodDescriptionForSearchResult(food: Food, t: TFunction): string {
  const suggestedServing100g = _.find(food.suggested_servings, { serving_description: "100 g" });
  const suggestedServing = suggestedServing100g ?? food.suggested_servings[0];

  if (!suggestedServing) {
    logger.error("Missing suggested serving", { food });
    return "";
  }

  return `${suggestedServing.kcal} ${t("general.kcal")} per ${suggestedServing.serving_description}`;
}

const FoodSearchResult = ({ food, onPress, index }: Props): JSX.Element => {
  const { t } = useTranslation();

  let searchResultText = food.name;

  if (food.brand_name) {
    searchResultText = `${searchResultText} (${food.brand_name})`;
  }

  return (
    <TouchableOpacity
      testID={`foodSearchResult-${food.id || "MISSING_ID"}`}
      style={styles.rowContainer}
      onPress={() => onPress(food)}
    >
      <View nativeID={`foodSearchResult-${index}`}>
        <Text fontSize={"md"} color={"primary.600"}>
          {searchResultText}
        </Text>
        <Text fontSize={"md"} color={"gray.300"}>
          {getFoodDescriptionForSearchResult(food, t)}
        </Text>
      </View>
      <View>
        <Icon as={MaterialIcons} m="2" ml="3" size="xl" color={"primary.600"} name="navigate-next" />
      </View>
    </TouchableOpacity>
  );
};
export default FoodSearchResult;
