import { Button, Text, useTheme } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text as NativeText, View } from "react-native";

import ProgressBar from "../commons/ProgressBar";
import { commonStyles, isDesktopScreen, Scale } from "../constants";
import { formatNumberAsPercentage, formatNumberAsWholeNumber } from "../helpers/generalHelpers";
import type { MacroName, RecipeMacrosItemType } from "../types";

const styles = StyleSheet.create({
  rowContainer: {
    flex: 1,
  },
  transformStyle: {
    transform: [{ rotateZ: "-3deg" }],
  },
});

type Props = {
  macroDetail: RecipeMacrosItemType;
  showLabel?: boolean;
  verticalMode?: boolean;
  excludeDetail?: MacroName[];
  showUnit?: boolean;
  allowedToGoOver?: boolean;
  optionalPercentage?: number;
};

const MacroProgressWidget = ({
  macroDetail,
  excludeDetail: exceptItems,
  verticalMode,
  showLabel,
  showUnit = true,
  allowedToGoOver = false,
  optionalPercentage = undefined,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isDesktop = isDesktopScreen();

  const macroForDisplay = isDesktop
    ? t(`general.${macroDetail.macroName}`)
    : t(`general.${macroDetail.macroName}_short`);

  let progressText = "";
  if (exceptItems?.includes(macroDetail.macroName)) {
    // progressText = ` ${macroForDisplay}`;
    progressText = macroDetail.macroName === "kcal" ? "" : "g";
  } else {
    progressText = ` /${macroDetail.total} ${macroForDisplay}`;
  }

  return (
    <View
      style={[!verticalMode && styles.rowContainer, { marginHorizontal: Scale(3) }]}
      nativeID={"MacroProgressWidget"}
    >
      {verticalMode ? (
        <>
          {showLabel ? (
            <NativeText style={{ ...commonStyles.bodyText14W500, color: theme.colors.gray["400"] }}>
              {macroForDisplay}
            </NativeText>
          ) : null}
          {/* NOTE: This parent Text component is required in order for things to display correctly */}
          <NativeText>
            <NativeText
              testID={`macroDetail-${macroDetail.unit}`}
              style={{ ...commonStyles.titleGreenText14, color: theme.colors.primary["600"] }}
            >
              {macroDetail.progress ? formatNumberAsWholeNumber(macroDetail.progress) : 0}
            </NativeText>
            <NativeText style={{ ...commonStyles.bodyText12, color: theme.colors.gray["400"] }}>
              {progressText}
            </NativeText>
            {optionalPercentage ? (
              <Button ml="1" disabled bgColor="amber.200" size="8">
                <Text color="gray.600">{formatNumberAsPercentage(optionalPercentage)}</Text>
              </Button>
            ) : null}
          </NativeText>
        </>
      ) : (
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {showLabel ? (
            <Text style={commonStyles.bodyText14W500} color="gray.400">
              {macroForDisplay}
            </Text>
          ) : null}
          <Text>
            <Text testID={`macroDetail-${macroDetail.unit}`} style={commonStyles.titleGreenText14} color="primary.600">
              {macroDetail.progress}
            </Text>
            <Text style={commonStyles.bodyText12} color="gray.400">{` /${macroDetail.total}`}</Text>
            {showUnit ? (
              <Text style={commonStyles.bodyText12} color="gray.400">
                {macroDetail.unit}
              </Text>
            ) : null}
          </Text>
        </View>
      )}

      <View>
        {exceptItems?.includes(macroDetail.macroName) ? null : (
          <ProgressBar
            value={(100 * (macroDetail.progress || 0)) / macroDetail.total}
            allowedToGoOver={allowedToGoOver}
            width={Scale(120)}
          />
        )}
      </View>
    </View>
  );
};

export default MacroProgressWidget;
