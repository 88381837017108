import React from "react";
import { StyleSheet, Text, TextStyle } from "react-native";

import { Scale, width } from "../constants";

type CommonSmallTextProps = {
  title: string;
  externalTextStyle?: TextStyle;
};
const styles = StyleSheet.create({
  textStyle: {
    // color: Colors.textColor,
    fontSize: Scale(14),
    lineHeight: Scale(20),
  },
});

const CommonSmallText: React.FC<CommonSmallTextProps> = ({ title, externalTextStyle }) => (
  <Text style={{ ...styles.textStyle, ...externalTextStyle }}>{title}</Text>
);

export default CommonSmallText;
