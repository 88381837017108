import { StyleSheet } from "react-native";

import { Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  pageHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: Scale(24),
    paddingVertical: VerticalScale(12),
  },
  createRecipe: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: VerticalScale(24),
  },
  titleText: {
    marginLeft: Scale(11),
  },
  tickIcon: {
    width: Scale(20),
    height: Scale(20),
  },
  descriptionText: {
    marginLeft: Scale(8),
  },
  recipesListContainer: {},
  statusViewContainer: {},
  statusTextContainer: {
    marginTop: VerticalScale(30),
  },
  modalButtonContainer: {
    marginTop: VerticalScale(48),
  },
});

export default styles;
