import { Entypo } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import _ from "lodash";
import { Center, Flex, IconButton, ScrollView, Text, useTheme, View } from "native-base";
import { useTranslation } from "react-i18next";
import React, { SafeAreaView } from "react-native";
import { useSelector } from "react-redux";

import EditUserProfileComponent, {
  ExerciseInstanceFormSchema,
  UserProfileFormSchema,
} from "../components/EditUserProfileComponent";
import { isDesktopScreen, Routes } from "../constants";
import { apiCallErrorHandler, isMobilePlatform } from "../helpers/generalHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { ExerciseInstanceListRetrieve as ExerciseInstance, SustainabilityEnum } from "../services/backendTypes";
import logger from "../services/logger";
import { clientsSelector } from "../slices/userSlice";
import styles from "./CoachModeClientInfoScreenStyle";

const {
  useUsersUserProfileUpdateMutation,
  useUsersUserProfileCreateMutation,
  useUsersExerciseInstanceCreateMutation,
  useUsersExerciseInstanceUpdateMutation,
  useUsersExerciseInstanceDestroyMutation,
} = backendApi;

type Props = NativeStackScreenProps<RootStackParamList, Routes.EditUserProfileScreen>;

const EditUserProfileScreen = ({
  navigation,
  route: {
    params: { client },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = isDesktopScreen();

  const [updateUserProfileOnBackend, { isLoading: isLoadingUpdateUserProfileOnBackend }] =
    useUsersUserProfileUpdateMutation();
  const [createExerciseInstanceBackendCall, { isLoading: isLoadingCreateExerciseInstance }] =
    useUsersExerciseInstanceCreateMutation();
  const [updateExerciseInstanceBackendCall, { isLoading: isLoadingUpdateExerciseInstance }] =
    useUsersExerciseInstanceUpdateMutation();
  const [createUserProfileOnBackend, { isLoading: isLoadingCreateUserProfileOnBackend }] =
    useUsersUserProfileCreateMutation();
  const [deleteExerciseInstanceBackendCall, { isLoading: isLoadingDeleteExerciseInstance }] =
    useUsersExerciseInstanceDestroyMutation();
  const showLoading =
    isLoadingUpdateUserProfileOnBackend ||
    isLoadingCreateExerciseInstance ||
    isLoadingUpdateExerciseInstance ||
    isLoadingDeleteExerciseInstance ||
    isLoadingCreateUserProfileOnBackend;

  const onSubmitUserProfile = async (values: UserProfileFormSchema): Promise<void> => {
    // NOTE: sustainability is currently not implemented so we add it
    const sustainability: SustainabilityEnum = "SUSTAINABLE";
    const userProfileRequest = { ...values, body_fat_percentage: values.body_fat_percentage / 100, sustainability };

    const updateOrCreatePromise = client.intake?.id
      ? updateUserProfileOnBackend({ id: client.intake.id, userProfileRequest })
      : createUserProfileOnBackend({
          userProfileRequest: {
            ...userProfileRequest,
            user_id: client.id,
          },
        });

    await updateOrCreatePromise
      .unwrap()
      .then((clientIntake) => {
        const clientIntakeId = clientIntake.id;

        /**
         * NOTE: If an exerciseInstance does not have an id it means that it is a new instance that needs to be created
         *
         * @param exerciseInstance
         * @returns
         */
        const doesExerciseInstanceHaveId = (exerciseInstance: ExerciseInstanceFormSchema): boolean =>
          exerciseInstance.id !== null;

        const exerciseInstancesThatAlreadyExist = values.exercise_instances?.filter(doesExerciseInstanceHaveId);
        const exerciseInstancesThatDoNotExist = values.exercise_instances?.filter(
          (ei) => !doesExerciseInstanceHaveId(ei)
        );

        const exerciseCreationPromises = exerciseInstancesThatDoNotExist?.map((exercise) =>
          createExerciseInstanceBackendCall({
            exerciseInstanceCreateUpdateRequest: {
              ...exercise,
              intake: clientIntakeId,
            },
          })
        );

        const exerciseUpdatePromises = exerciseInstancesThatAlreadyExist?.map((exercise) => {
          if (!exercise.id) {
            throw new Error("Exercise instance id is not set");
          }

          return updateExerciseInstanceBackendCall({
            id: exercise.id,
            exerciseInstanceCreateUpdateRequest: {
              ...exercise,
              intake: clientIntakeId,
            },
          });
        });

        const isExerciseToBeDeleted = (exerciseInstance: ExerciseInstance): boolean =>
          !_.some(
            exerciseInstancesThatAlreadyExist,
            (exerciseInstanceToBeUpdated) => exerciseInstanceToBeUpdated.id === exerciseInstance.id
          );

        const exerciseInstancesToBeDeleted = _.filter(client?.intake?.exercise_instances, isExerciseToBeDeleted);

        const exerciseDeletionPromises = exerciseInstancesToBeDeleted.map((exerciseInstance) => {
          if (typeof exerciseInstance === "number") {
            throw new Error("Exercise instance id is not set");
          }
          if (!exerciseInstance.id) {
            throw new Error("Exercise instance id is not set");
          }

          return deleteExerciseInstanceBackendCall({
            id: exerciseInstance.id,
          });
        });

        // The compiler does not like concatenating heterogeneous promises but there is no problem here
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return Promise.all(_.concat(exerciseCreationPromises, exerciseUpdatePromises, exerciseDeletionPromises));
      })
      .then(() => {
        // alert(t("general.updated_successfully"));
        // TODO: This is not working for some reason. This will cause a crash if it executes.
        // toast.show({
        //   description: t("general.updated_successfully"),
        //   placement: "top",
        //   bgColor: "green.400",
        // });
      })
      .catch(apiCallErrorHandler());

    navigation.goBack();
  };

  const headerComponent = (
    <Flex flexDirection={"row-reverse"} justifyContent="space-between">
      <View
        style={
          !isDesktop
            ? {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                backgroundColor: "white",
              }
            : null
        }
      >
        <IconButton
          size={"md"}
          mr="2"
          _icon={{
            as: Entypo,
            name: "cross",
            size: "2xl",
          }}
          testID={"editUserProfileScreen-back-button"}
          onPress={() => navigation.pop()}
        />
      </View>
    </Flex>
  );

  const commonContainerStyle = {
    backgroundColor: "white",
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
  };

  return (
    <SafeAreaView style={isMobilePlatform() ? mobileContainerStyle : commonContainerStyle}>
      {headerComponent}
      <Center>
        <ScrollView
          width={isDesktopScreen() ? "50%" : "80%"}
          style={[
            styles.routeViewContainer,
            !isDesktop && {
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.colors.muted["100"],
            },
          ]}
        >
          <EditUserProfileComponent
            client={client}
            onSubmitUserProfile={onSubmitUserProfile}
            showLoading={showLoading}
            submitButtonText={t("coach_mode_view_client.update_user_profile_button_text")}
          />
        </ScrollView>
      </Center>
    </SafeAreaView>
  );
};

export default EditUserProfileScreen;
