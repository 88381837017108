import { StyleSheet } from "react-native";

import { Scale, StatusBarHeight, VerticalScale } from "./helperFunction";

const commonStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  mobileContainer: {
    backgroundColor: "white",
  },
  mainContainer: {
    flex: 1,
    display: "flex",
    paddingHorizontal: Scale(24),
  },
  paddingContainer: {
    paddingHorizontal: Scale(24),
  },
  paddingVerticalContainer: {
    paddingVertical: VerticalScale(24),
  },
  safeAreaContainer: {
    paddingTop: StatusBarHeight,
  },
  bodyText12: {
    fontSize: 12,
    // color: Colors.textColor,
    fontWeight: "normal",
    lineHeight: VerticalScale(24),
  },
  bodyText14: {
    fontSize: 14,
    // color: Colors.textColor,
    fontWeight: "normal",
    lineHeight: VerticalScale(24),
  },
  bodyText14W500: {
    fontSize: 14,
    // color: Colors.textColor,
    fontWeight: "500",
    lineHeight: VerticalScale(22),
  },
  bodyText16: {
    fontSize: 16,
    // color: Colors.textColor,
    fontWeight: "normal",
    lineHeight: VerticalScale(22),
  },
  bodyText16W500: {
    fontSize: 16,
    // color: Colors.textColor,
    fontWeight: "500",
    lineHeight: VerticalScale(22),
  },
  bodyText20: {
    fontSize: 20,
    // color: Colors.textColor,
    lineHeight: VerticalScale(28),
  },
  titleText16: {
    fontSize: 16,
    // color: Colors.largeTextColor,
    fontWeight: "normal",
    lineHeight: VerticalScale(24),
  },
  titleText16Bold: {
    fontSize: 16,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
    lineHeight: VerticalScale(24),
  },
  titleText20Bold: {
    fontSize: 20,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
    lineHeight: VerticalScale(24),
  },
  titleText24: {
    fontSize: 24,
    fontWeight: "normal",
    // color: Colors.largeTextColor,
    lineHeight: VerticalScale(32),
  },
  titleText24Bold: {
    fontSize: 24,
    fontWeight: "bold",
    // color: Colors.largeTextColor,
    lineHeight: VerticalScale(32),
  },
  titleGreenText14: {
    fontSize: 14,
    // color: Colors.primaryGreenColor,
    lineHeight: VerticalScale(24),
  },
  titleGreenText16: {
    fontSize: 16,
    // color: Colors.primaryGreenColor,
    lineHeight: VerticalScale(24),
  },
  titleGreenText16Bold: {
    fontSize: 16,
    fontWeight: "bold",
    // color: Colors.primaryGreenColor,
    lineHeight: VerticalScale(24),
  },
  titleGreenText20: {
    fontSize: 20,
    fontWeight: "bold",
    // color: Colors.primaryGreenColor,
    lineHeight: VerticalScale(32),
  },
  titleGreenText24: {
    fontSize: 24,
    fontWeight: "bold",
    // color: Colors.primaryGreenColor,
    lineHeight: VerticalScale(28),
  },
  descriptionText14: {
    fontSize: 14,
    fontWeight: "normal",
    // color: Colors.descriptionTextColor,
    lineHeight: VerticalScale(14),
  },
  descriptionText16Bold: {
    fontSize: 16,
    fontWeight: "bold",
    // color: Colors.descriptionTextColor,
    lineHeight: VerticalScale(24),
  },
  descriptionText24Bold: {
    fontSize: 24,
    fontWeight: "700",
    // color: Colors.descriptionTextColor,
    lineHeight: VerticalScale(24),
  },
  descriptionText16: {
    fontSize: 16,
    fontWeight: "normal",
    // color: Colors.smallTextColor,
  },
  smallText14: {
    fontSize: 14,
    fontWeight: "normal",
    // color: Colors.smallTextColor,
    lineHeight: VerticalScale(18),
  },
  sectionText14Bold: {
    // color: Colors.sectionTextColor,
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 24,
  },
  sectionText16Bold: {
    // color: Colors.sectionTextColor,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
  },
  blueText14: {
    // color: Colors.blueTextColor,
    fontSize: 14,
    lineHeight: VerticalScale(22),
  },
  bottomContainer: {
    paddingHorizontal: Scale(24),
    bottom: Scale(40),
  },
  bottomFixedContainer: {
    paddingHorizontal: Scale(24),
    bottom: Scale(40),
  },
  bottomContainerFixed: {
    paddingHorizontal: Scale(24),
    bottom: 40,
    position: "absolute",
    width: "100%",
  },
  scrollContentContainer: {
    paddingBottom: Scale(40),
  },
});

export default commonStyles;
