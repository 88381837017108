// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Divider, Flex, ScrollView } from "native-base";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Image, Text, TouchableOpacity, View } from "react-native";
import type { Modalize } from "react-native-modalize";

import { CommonButton, CommonIconButton } from "../commons";
import CommonModalize from "../commons/CommonModalize";
import NutritionPlanRowItem from "../components/NutritionPlanRowItem";
import { commonStyles, height, Images, Routes } from "../constants";
import logger from "../services/logger";
import type { NutritionType } from "../types";
import styles from "./NutritionPlanScreenStyle";

const NutritionPlanRows = [
  {
    label: "Ontbijt",
    image: Images.FoodIcon1,
    nutrition: {
      calories: "716 kCal",
      fat: "27g",
      protein: "18g",
      carbohydrates: "33g",
    },
  },
  {
    label: "Lunch",
    image: Images.FoodIcon2,
    nutrition: {
      calories: "716 kCal",
      fat: "27g",
      protein: "18g",
      carbohydrates: "33g",
    },
  },
  {
    label: "Avondeten",
    image: Images.FoodIcon3,
    nutrition: {
      calories: "716 kCal",
      fat: "27g",
      protein: "18g",
      carbohydrates: "33g",
    },
  },
];

type FooterComponentProps = {
  modalizeRef: React.Ref<Modalize>;
};

const FooterComponent = ({ modalizeRef }: FooterComponentProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <View style={styles.bottomContainer}>
      <CommonButton
        title={t("general.ok")}
        onPress={() => {
          if (modalizeRef && typeof modalizeRef === "object" && modalizeRef.current) {
            modalizeRef.current.close();
          } else {
            logger.error("modalizeRef Error");
          }
        }}
      />
    </View>
  );
};

type ModalContentProps = {
  modalizeRef: React.Ref<Modalize>;
};

const ModalContent = ({ modalizeRef }: ModalContentProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={"column"} justifyContent={"space-around"} style={{ flex: 1 }}>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
        style={{
          ...commonStyles.mainContainer,
          paddingVertical: 24,
        }}
      >
        <Text style={commonStyles.titleText16}>{"Je dag doel"}</Text>
        <CommonIconButton
          source={Images.CloseIcon}
          onPress={() => {
            if (modalizeRef && typeof modalizeRef === "object" && modalizeRef.current) {
              modalizeRef.current.close();
            } else {
              logger.error("modalizeRef Error");
            }
          }}
          size={16}
        />
      </Flex>
      <Divider></Divider>
      <Flex
        justifyContent={"space-around"}
        style={{
          flex: 1,
          paddingHorizontal: 24,
        }}
      >
        <Text style={[styles.bodyText, { marginTop: 32 }]}>
          {
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus consectetur id tristique odio eget quam varius neque dui. Ipsum tellus est bibendum eu at. Urna et facilisi praesent sed."
          }
        </Text>
        <Text style={[styles.bodyText, { marginTop: 32 }]}>
          {
            "At quis turpis blandit tellus sit nulla. Cursus in mauris risus ipsum, tincidunt et, neque, pharetra, lacinia. Odio sit malesuada elementum donec eros sociis et at. Ornare dolor ornare amet viverra massa sed massa. Morbi adipiscing nulla aenean quam massa at. Bibendum est ipsum est tincidunt vitae eu sed blandit."
          }
        </Text>
        <Text style={[styles.bodyText, { marginTop: 32 }]}>
          {"Sed elit, interdum turpis netus. Tempus purus commodo tellus hac luctus pellentesque magna in ultricies."}
        </Text>
      </Flex>
    </Flex>
  );
};

type NutritionPlanScreenParamList = {
  NutritionPlanScreen: undefined;
  ProfileStartScreen: undefined;
};
type NutritionPlanScreenProps = {
  navigation: NativeStackNavigationProp<NutritionPlanScreenParamList, "NutritionPlanScreen">;
};

const NutritionPlanScreen = ({ navigation }: NutritionPlanScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const modalizeRef = useRef<Modalize>(null);
  const onOpen = (): void => {
    modalizeRef.current?.open();
  };

  const planData = {
    calories: "2150 kCal",
    protein: "56g",
    fat: "80g",
    carbohydrates: "99g",
  };
  const descriptionText =
    "Om jouw doel te behalen heeft je coach een persoonlijk voedingsplan voor je opgesteld met daarin per maaltijd je energie-behoefte. Wij houden dit allemaal voor je bij.";

  const AvatarHeader = (): JSX.Element => (
    <View style={{ marginTop: 50 }}>
      <View style={styles.rowContainer}>
        <Image source={Images.AvatarIcon1} style={styles.avatarImg} />
        <View>
          <Text style={styles.roleText}>{"Jouw coach"}</Text>
          <Text style={styles.nameText}>{"Kevin Matisse"}</Text>
        </View>
      </View>
    </View>
  );

  type DailyGoalRowProps = {
    // TODO: Use generated nutrition plan type instead
    data: NutritionType;
  };

  const DailyGoalRow = ({ data }: DailyGoalRowProps): JSX.Element => {
    const { calories, fat, protein, carbohydrates } = data;
    return (
      <Flex direction="row" justifyContent={"space-between"} mt="6">
        <View>
          <Text style={styles.valueText}>{t("general.calories")}</Text>
          <Text style={styles.labelTextBold}>{calories}</Text>
        </View>
        <View>
          <Text style={styles.valueText}>{t("general.protein")}</Text>
          <Text style={styles.labelTextBold}>{protein}</Text>
        </View>
        <View>
          <Text style={styles.valueText}>{t("general.fat")}</Text>
          <Text style={styles.labelTextBold}>{fat}</Text>
        </View>
        <View>
          <Text style={styles.valueText}>{t("general.carbohydrates")}</Text>
          <Text style={styles.labelTextBold}>{carbohydrates}</Text>
        </View>
      </Flex>
    );
  };

  const DailyGoalModalButton = (): JSX.Element => (
    <TouchableOpacity onPress={onOpen}>
      <Flex direction="row" justifyContent={"flex-start"} alignItems={"center"} mt="6">
        <Text style={[styles.greenTitle, { marginRight: 10 }]}>{"Je dag doel"}</Text>
        <Image source={Images.InfoIcon} style={{ width: 16, height: 16 }} />
      </Flex>
    </TouchableOpacity>
  );

  const Header = (): JSX.Element => (
    <>
      <Flex direction="row" justifyContent={"space-between"} mt="6">
        <Text style={styles.planTitle}>{"Voedingsplan"}</Text>
      </Flex>
      <Flex direction="row" justifyContent={"space-between"} mt="6">
        <Text style={styles.valueText}>{descriptionText}</Text>
      </Flex>
    </>
  );
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <AvatarHeader />
        <Divider my="2" />
        <View style={commonStyles.mainContainer}>
          <View style={styles.container}>
            <Header />
            <DailyGoalModalButton />
            <DailyGoalRow data={planData} />
            <View>
              {NutritionPlanRows.map((nutritionPlanRow) => (
                <NutritionPlanRowItem
                  key={nutritionPlanRow.label}
                  label={nutritionPlanRow.label}
                  imgSrc={nutritionPlanRow.image}
                  nutrition={nutritionPlanRow.nutrition}
                />
              ))}
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.bottomContainer}>
        <CommonButton
          title={"Volgende"}
          onPress={() => navigation.push(Routes.ProfileStartScreen as keyof NutritionPlanScreenParamList)}
        />
      </View>

      <CommonModalize
        modalizeRef={modalizeRef}
        ModalContent={ModalContent}
        // FooterComponent={FooterComponent}
        modalHeight={height - 150}
      />
    </View>
  );
};

export default NutritionPlanScreen;
