// TODO: Rename to helperFunctions.ts

import "@expo/match-media";

import React from "react";
import { Dimensions, EmitterSubscription, Keyboard, Platform } from "react-native";
import { getStatusBarHeight } from "react-native-status-bar-height";

const { width, height } = Dimensions.get("window");

const guidelineBaseWidth = 375;
const guidelineBaseHeight = 812;

const guidelineBaseWidthDesktop = 1440;
const guidelineBaseHeightDesktop = 1024;

const MIN_DESKTOP_WIDTH = 900; // min width to determine desktop or laptop

export const isDesktopScreen = (): boolean => Dimensions.get("window").width > MIN_DESKTOP_WIDTH;

export const IS_NOT_DESKTOP = width < MIN_DESKTOP_WIDTH;
export const Scale = (size: number): number =>
  (width / (IS_NOT_DESKTOP ? guidelineBaseWidth : guidelineBaseWidthDesktop)) * size;

export const VerticalScale = (size: number): number =>
  (height / (IS_NOT_DESKTOP ? guidelineBaseHeight : guidelineBaseHeightDesktop)) * size;

export const StatusBarHeight = getStatusBarHeight();

export { height, width };

/**
 * This function is used to achieve keyboard avoiding functionality with a NativeBase <ActionSheet/> component.
 * From https://github.com/GeekyAnts/NativeBase/issues/3939#issuecomment-1225669616
 * @returns {number} the number of pixels to offset the bottom by
 */
export const useKeyboardBottomInset = (): number => {
  const [bottom, setBottom] = React.useState(0);
  const subscriptions = React.useRef<EmitterSubscription[]>([]);

  React.useEffect(() => {
    subscriptions.current = [
      Keyboard.addListener("keyboardDidHide", () => setBottom(0)),
      Keyboard.addListener("keyboardDidShow", (e) => {
        if (Platform.OS === "android") {
          setBottom(e.endCoordinates.height);
        } else {
          setBottom(Math.max(e.startCoordinates?.height || 0, e.endCoordinates.height));
        }
      }),
    ];

    return () => {
      subscriptions.current.forEach((subscription) => {
        subscription.remove();
      });
    };
  }, [setBottom, subscriptions]);

  return bottom;
};
