import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";
import type { Brand } from "../services/backendTypes";

export interface BrandState {
  brandingWhileLoggedOut?: Brand;
  isLoadingBrand: boolean;
}

const initialState: BrandState = {
  brandingWhileLoggedOut: undefined,
  isLoadingBrand: false,
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    storeBrandWhileLoggedOut: (state, action: PayloadAction<Brand>) => {
      state.brandingWhileLoggedOut = action.payload;
    },
    setIsLoadingBrand: (state, action: PayloadAction<boolean>) => {
      state.isLoadingBrand = action.payload;
    },
  },
});

const selectSelf = (state: RootState): BrandState => state[brandSlice.name];

export const getIsLoadingBrandSelector = createSelector(
  selectSelf,
  (state: BrandState): boolean => state.isLoadingBrand
);

export const getBrandSelector = createSelector(
  selectSelf,
  (state: BrandState): Brand | undefined => state.brandingWhileLoggedOut
);
