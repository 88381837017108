import { MaterialIcons } from "@expo/vector-icons";
import { Formik } from "formik";
import _ from "lodash";
import moment, { Moment } from "moment";
import { Button, useTheme } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { Calendar, DateData } from "react-native-calendars";
import type { MarkingProps } from "react-native-calendars/src/calendar/day/marking";
import * as Yup from "yup";

import type { MarkedDatesType } from "../components/GroceryListDatePicker";
import { Scale } from "../constants";
import { FontFamily } from "../constants/fonts";
import { formatMomentAsDateForApi } from "../helpers/apiHelpers";
import backendApi from "../services/backendApi";
import type { CalendarDay, MealMomentEnum, User } from "../services/backendTypes";

const { usePlannerCopyMealsToMealMomentCreateMutation } = backendApi;

type Props = {
  user: User | null;
  onClose: () => void;
  currentCalendarDay: CalendarDay | undefined;
  mealMoment: MealMomentEnum;
};

// eslint-disable-next-line import/prefer-default-export
export const CopyMealsModal = ({ user, onClose, currentCalendarDay, mealMoment }: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [copyMealMomentToDate, { isLoading: isCopyingMealMomentToDate }] =
    usePlannerCopyMealsToMealMomentCreateMutation();

  interface CopyMealMomentToValues {
    toCalendarDays: string[];
  }

  const onSubmitWrapper = async ({ toCalendarDays }: CopyMealMomentToValues): Promise<void> => {
    if (!user) {
      throw new Error("User is not set");
    }
    if (!currentCalendarDay) {
      throw new Error("Current calendar day is not set");
    }
    if (!currentCalendarDay.id) {
      throw new Error("Current calendar day id is not set");
    }
    if (currentCalendarDay.id === undefined) {
      throw new Error("Current calendar day id is undefined");
    }

    // ts-ignore
    const copyMealMomentToPromises = _.map(toCalendarDays, (toCalendarDay: string) =>
      copyMealMomentToDate({
        copyMealsToMealMomentRequest: {
          user: user.id,
          // NOTE: the conditional checks above should prevent currentCalendarDay.id from being undefined
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          from_calendar_day_id: currentCalendarDay.id,
          to_calendar_day: toCalendarDay,
          meal_moment: mealMoment,
        },
      })
    );

    // NOTE: This array has different types and th typescript compiled complains.
    // However, this does not matter as they are all promises
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await Promise.all(copyMealMomentToPromises);
    onClose();
  };

  const copyMealMomentToSchema = Yup.object().shape({
    toCalendarDays: Yup.array(Yup.string()).required(t("general.required")),
  });

  const markedDateBaseProperties: MarkingProps = {
    color: theme.colors.primary["600"],
    textColor: "white",
  };

  const defaultSelectedDates: string[] = [];

  const getMarkedDates = (dates: string[]): MarkedDatesType => {
    const markedDates: MarkedDatesType = {};
    dates.forEach((dateString) => {
      const dateToMark = moment(dateString);
      markedDates[formatMomentAsDateForApi(dateToMark)] = markedDateBaseProperties;
    });
    return markedDates;
  };

  return (
    <>
      <Formik
        initialValues={{
          toCalendarDays: defaultSelectedDates,
        }}
        validationSchema={copyMealMomentToSchema}
        onSubmit={onSubmitWrapper}
      >
        {({ isSubmitting, handleChange, handleSubmit, values, isValid, setFieldValue }) => (
          <>
            <Calendar
              firstDay={1}
              markedDates={getMarkedDates([...values.toCalendarDays])}
              markingType="period"
              onDayPress={(toCalendarDay: DateData) => {
                const toCalendarDays = new Set(values.toCalendarDays);
                if (toCalendarDays.has(toCalendarDay.dateString)) {
                  toCalendarDays.delete(toCalendarDay.dateString);
                } else {
                  toCalendarDays.add(toCalendarDay.dateString);
                }

                setFieldValue("toCalendarDays", [...toCalendarDays]);
              }}
              renderArrow={(direction) => (
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                <MaterialIcons
                  name={direction === "left" ? "chevron-left" : "chevron-right"}
                  size={24}
                  color={theme.colors.primary["600"]}
                />
              )}
              theme={{
                textDayFontFamily: FontFamily.medium,
                textMonthFontFamily: FontFamily.bold,
                textDayHeaderFontFamily: FontFamily.medium,
                textDayFontSize: Scale(14),
                textMonthFontSize: Scale(16),
                textDayHeaderFontSize: Scale(16),
                arrowColor: theme.colors.primary["600"],
              }}
            />
            <Button
              mt={3}
              isLoading={isCopyingMealMomentToDate || isSubmitting}
              isDisabled={!isValid}
              onPress={() => handleSubmit()}
              testID="copyMealMomentFromSubmit"
            >
              {t("planner.copy_meals_to_modal.copy_meals")}
            </Button>
          </>
        )}
      </Formik>
    </>
  );
};
