import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { Locale } from "locale-enum";
import _ from "lodash";
import moment from "moment";

import type { RootState } from "../../../store";
import { formatMomentAsBackendFormatDateString } from "../helpers/generalHelpers";
import type { DiyCreateNutritionPlanFormInterface } from "../screens/New_DIY_form/onboardingHelpers";
import type {
  MealSlotSpecification,
  SlimClient,
  User,
  UserDistance,
  UserFavouriteRecipe,
  UserSleep,
  UserStress,
  UserWeight,
  WeeklyNutritionPlan,
} from "../services/backendTypes";
import type { LegacyInput } from "../services/legacyNutritionCalculations7";

export type UserFavouriteRecipeByRecipeTemplateId = {
  [id: number]: UserFavouriteRecipe;
};

export type UserDislikeRecipeByRecipeTemplateId = {
  [id: number]: UserFavouriteRecipe;
};

type RecipeTemplateIdPayload = {
  recipeTemplateId: number;
};

export type UserWeightsByDate = {
  [date: string]: UserWeight[];
};

export type UserStressByDate = {
  [date: string]: UserStress[];
};

export type UserSleepsByDate = {
  [date: string]: UserSleep[];
};

export type UserDistancesByDate = {
  [date: string]: UserDistance[];
};

type ClientNutritionPlansByClientId = {
  [clientId: number]: {
    // NOTE: id is optional because it may not exist in the DB yet (-1 is used if there is no id)
    [optionalId: number]: LegacyInput;
  };
};

/**
 * NOTE: The formValues of this object have been superseded by the onboardingState (these are now deprecated)
 */
export type DIYFormInterface = {
  formValues: DiyCreateNutritionPlanFormInterface;
  mss: MealSlotSpecification[];
};

export interface UserState {
  token: string;
  user: User | null;

  userFavouriteRecipes: {
    byRecipeTemplateId: UserFavouriteRecipeByRecipeTemplateId;
  };

  userDislikeRecipes:
    | {
        byRecipeTemplateId: UserDislikeRecipeByRecipeTemplateId;
      }
    | undefined; // Note: this was added later so we allow undefined in order to be defensive

  measurements: {
    weightsByDate: UserWeightsByDate;
    stressByDate: UserStressByDate;
    sleepsByDate: UserSleepsByDate;
    distancesByDate: UserDistancesByDate;
  };
  clients: SlimClient[];
  clientMode: boolean;

  // Coach functionality
  clientNutritionPlans: ClientNutritionPlansByClientId;

  appLastOpenedDateString?: string;

  locale?: Locale;

  // Coach functionality
  viewAsUser: User | null;

  diyForm: DIYFormInterface | undefined;

  pwa: {
    deferredPrompt: boolean;
  };
}

const initialState: UserState = {
  token: "",
  user: null,
  userFavouriteRecipes: { byRecipeTemplateId: {} },
  userDislikeRecipes: { byRecipeTemplateId: {} },

  measurements: {
    weightsByDate: {},
    stressByDate: {},
    sleepsByDate: {},
    distancesByDate: {},
  },
  clients: [],

  clientMode: false,
  viewAsUser: null,

  clientNutritionPlans: {},
  diyForm: undefined,
  pwa: {
    deferredPrompt: false,
  },
};

export const NUTRITION_PLAN_WITHOUT_ID = -1;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    storeAuthToken(state: UserState, action: { payload: string }) {
      // eslint-disable-next-line no-param-reassign
      state.token = action.payload;
    },
    storeUser(state: UserState, action: { payload: User }) {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
    },
    storeUserFavouriteRecipe(state: UserState, action: { payload: UserFavouriteRecipeByRecipeTemplateId }) {
      // eslint-disable-next-line no-param-reassign

      Object.keys(action.payload).forEach((recipeId) => {
        const userFavouriteRecipe = action.payload[Number(recipeId)];
        if (userFavouriteRecipe) {
          // eslint-disable-next-line no-param-reassign
          state.userFavouriteRecipes.byRecipeTemplateId[Number(recipeId)] = userFavouriteRecipe;
        }
      });
    },
    storeUserDislikeRecipe(state: UserState, action: { payload: UserDislikeRecipeByRecipeTemplateId }) {
      const updatedUserDislikeRecipes = { ...state.userDislikeRecipes?.byRecipeTemplateId, ...action.payload };
      state.userDislikeRecipes = { byRecipeTemplateId: updatedUserDislikeRecipes };
    },

    deleteUserFavouriteRecipe(state: UserState, action: { payload: RecipeTemplateIdPayload }) {
      // eslint-disable-next-line no-param-reassign
      delete state.userFavouriteRecipes.byRecipeTemplateId[action.payload.recipeTemplateId];
    },
    deleteUserDislikeRecipe(state: UserState, action: { payload: RecipeTemplateIdPayload }) {
      delete state.userDislikeRecipes?.byRecipeTemplateId[action.payload.recipeTemplateId];
    },
    storeUserWeightMeasurements(state: UserState, action: { payload: UserWeight[] }) {
      const newWeights = _.groupBy(action.payload, (weight: UserWeight) =>
        formatMomentAsBackendFormatDateString(moment(weight.created_at))
      );

      // eslint-disable-next-line no-param-reassign
      state.measurements = { ...state.measurements, weightsByDate: newWeights };
    },
    storeUserStressMeasurements(state: UserState, action: { payload: UserStress[] }) {
      const newStress = _.groupBy(action.payload, (stress: UserStress) =>
        formatMomentAsBackendFormatDateString(moment(stress.created_at))
      );

      // eslint-disable-next-line no-param-reassign
      state.measurements = { ...state.measurements, stressByDate: newStress };
    },
    storeUserSleepMeasurements(state: UserState, action: { payload: UserSleep[] }) {
      const newSleeps = _.groupBy(action.payload, (sleep: UserSleep) =>
        formatMomentAsBackendFormatDateString(moment(sleep.created_at))
      );

      // eslint-disable-next-line no-param-reassign
      state.measurements = { ...state.measurements, sleepsByDate: newSleeps };
    },
    storeUserDistanceMeasurements(state: UserState, action: { payload: UserDistance[] }) {
      const newDistances = _.groupBy(action.payload, (distance: UserDistance) =>
        formatMomentAsBackendFormatDateString(moment(distance.created_at))
      );

      // eslint-disable-next-line no-param-reassign
      state.measurements = { ...state.measurements, distancesByDate: newDistances };
    },
    storeClients(state: UserState, action: { payload: SlimClient[] }) {
      // eslint-disable-next-line no-param-reassign
      state.clients = action.payload;
    },
    toggleClientMode(state: UserState) {
      // eslint-disable-next-line no-param-reassign
      state.clientMode = !state.clientMode;
    },
    setViewAsUser(state: UserState, action: { payload: User | null }) {
      // eslint-disable-next-line no-param-reassign
      state.viewAsUser = action.payload;
    },

    storeClientNutritionPlan(state: UserState, action: { payload: { clientId: number; plan: LegacyInput } }) {
      if (!state.clientNutritionPlans) {
        state.clientNutritionPlans = {};
      }

      if (!state.clientNutritionPlans[action.payload.clientId]) {
        state.clientNutritionPlans[action.payload.clientId] = {};
      }

      state.clientNutritionPlans[action.payload.clientId] = {
        ...state.clientNutritionPlans[action.payload.clientId],
        [action.payload.plan.id || NUTRITION_PLAN_WITHOUT_ID]: action.payload.plan,
      };
    },

    storeAppLastOpened(state: UserState, action: { payload: string }) {
      // eslint-disable-next-line no-param-reassign
      state.appLastOpenedDateString = action.payload;
    },

    storeLocale(state: UserState, action: { payload: Locale }) {
      // eslint-disable-next-line no-param-reassign
      state.locale = action.payload;
    },

    storeDiyFormResults(
      state: UserState,
      action: { payload: { formValues: DiyCreateNutritionPlanFormInterface; mss: MealSlotSpecification[] } }
    ) {
      // eslint-disable-next-line no-param-reassign
      state.diyForm = action.payload;
    },
    clearDiyFormResults(state: UserState) {
      // eslint-disable-next-line no-param-reassign
      state.diyForm = undefined;
    },
    deferPwaInstallPrompt(state: UserState) {
      // eslint-disable-next-line no-param-reassign
      state.pwa = { deferredPrompt: true };
    },
  },
});

const selectSelf = (state: RootState): UserState => state[userSlice.name];

export const authTokenSelector = createSelector(selectSelf, (state: UserState): string => state.token);

export const userSelector = createSelector(selectSelf, (state: UserState): User | null => state.user);

export const viewAsUserSelector = createSelector(selectSelf, (state: UserState): User | null => state.viewAsUser);

export const weeklyNutritionPlanSelector = createSelector(
  selectSelf,
  (state: UserState): WeeklyNutritionPlan | undefined | null => state.user?.intake?.weekly_nutrition_plan
);

export const viewAsUserWeeklyNutritionPlanSelector = createSelector(
  selectSelf,
  (state: UserState): WeeklyNutritionPlan | undefined | null => state.viewAsUser?.intake?.weekly_nutrition_plan
);

export const userFavouriteRecipesSelector = createSelector(
  selectSelf,
  (state: UserState): UserFavouriteRecipeByRecipeTemplateId => state.userFavouriteRecipes.byRecipeTemplateId
);

export const userDislikeRecipesSelector = createSelector(
  selectSelf,
  (state: UserState): UserDislikeRecipeByRecipeTemplateId | undefined => state?.userDislikeRecipes?.byRecipeTemplateId
);

export const userWeightMeasurementsSelector = createSelector(
  selectSelf,
  (state: UserState): { [date: string]: UserWeight[] } => state.measurements?.weightsByDate || {}
);

export const userStressMeasurementsSelector = createSelector(
  selectSelf,
  (state: UserState): { [date: string]: UserStress[] } => state.measurements?.stressByDate || {}
);

export const userSleepMeasurementsSelector = createSelector(
  selectSelf,
  (state: UserState): { [date: string]: UserSleep[] } => state.measurements?.sleepsByDate || {}
);

export const userWaistCircumferenceMeasurementsSelector = createSelector(
  selectSelf,
  (state: UserState): { [date: string]: UserDistance[] } =>
    state.measurements?.distancesByDate
      ? _.pickBy(state.measurements?.distancesByDate, (distances) => _.filter(distances, { body_area: "WAIST" }))
      : {}
);

export const clientsSelector = createSelector(selectSelf, (state: UserState): SlimClient[] => state.clients);

export const clientModeSelector = createSelector(selectSelf, (state: UserState): boolean =>
  state.user?.is_coach ? state.clientMode : true
);

export const clientNutritionPlansByIdSelector = createSelector(
  selectSelf,
  (state: UserState): ClientNutritionPlansByClientId => state.clientNutritionPlans
);

export const getAppLastOpenedSelector = createSelector(
  selectSelf,
  (state: UserState): string | undefined => state.appLastOpenedDateString
);

export const localeSelector = createSelector(selectSelf, (state: UserState): Locale | undefined => state.locale);

export const diyFormSelector = createSelector(
  selectSelf,
  (state: UserState): DIYFormInterface | undefined => state.diyForm
);

export const hasUserDeferredPwaPrompt = createSelector(
  selectSelf,
  (state: UserState): boolean => state?.pwa?.deferredPrompt
);
