import { StyleSheet } from "react-native";

import { height, Scale, width } from "../constants";
import { FontFamily, FontSize } from "../constants/fonts";

const styles = StyleSheet.create({
  nameStyle: { color: "white", fontSize: Scale(25) },
  topTitleStyle: {
    fontWeight: "500",
    marginBottom: Scale(7),
  },
  headerStyle: {
    fontFamily: FontFamily.bold,
    fontSize: FontSize.h4,
    lineHeight: Scale(32),
  },
  separatorStyle: {
    width,
    // backgroundColor: Colors.disableButton,
    height: Scale(3),
    marginVertical: Scale(20),
  },
  profileCircle: {
    height: Scale(64),
    width: Scale(64),
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: Colors.blueColor,
    borderRadius: Scale(32),
    marginRight: Scale(20),
    marginVertical: Scale(20),
    marginLeft: Scale(20),
  },
  profileIconStyle: {
    height: Scale(22),
    width: Scale(22),
    resizeMode: "contain",
  },
  profileTitleStyle: {
    fontSize: Scale(16),
    fontWeight: "700",
  },
  titleStyle: {
    marginLeft: Scale(0),
    // width: 10,
  },
  listTopContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rightIconStyle: {
    height: Scale(15),
    width: Scale(15),
    resizeMode: "contain",
  },
  listContainer: {
    flexDirection: "row",
    marginVertical: Scale(7),
    alignItems: "center",
  },
  parentlistContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: Scale(5),
  },
  iconStyle: { height: Scale(50), width: Scale(50), resizeMode: "contain" },
  container: {
    // backgroundColor: Colors.white,
    flex: 1,
  },
  progressTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: Scale(10),
    marginHorizontal: Scale(20),
  },
  topContainer: {
    height: height / 4,
  },
  timeContainer: {
    marginRight: 0,
    width: Scale(200),
    fontSize: Scale(16),
    fontWeight: "500",
    // color: Colors.sectionTextColor,
  },
  plusIconContainer: {
    height: Scale(30),
    width: Scale(30),
  },
  wrapperStyle: { flexDirection: "row", alignItems: "center" },
  labelContainer: { flexDirection: "row", marginVertical: Scale(15) },
  timeLabelStyle: {
    marginRight: 0,
    width: Scale(150),
    fontWeight: "600",
    fontSize: Scale(17),
    // color: Colors.sectionTextColor,
  },
  innerTextStyle: {
    // color: Colors.textColor,
  },
  rightContainerStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: width / 3,
    marginVertical: Scale(7),
  },
  mealBoxText: {
    fontSize: Scale(16),
    lineHeight: Scale(24),
    // color: Colors.greyTextColor,
    marginLeft: Scale(13.34),
  },
  mealBox: {
    marginTop: Scale(20),
    height: Scale(112),
    // backgroundColor: Colors.grey,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginHorizontal: Scale(20),
  },
  plusIconStyle: {
    // tintColor: Colors.greyTextColor,
    width: Scale(13),
    height: Scale(13),
  },

  progressContainer: { flexDirection: "row", alignItems: "center" },
  spinnerExternalStyle: { width: Scale(130), marginRight: Scale(20) },
  textStyleForProgress: {
    // color: Colors.largeTextColor,
    fontWeight: "500",
    fontSize: Scale(16),
    marginRight: Scale(4),
  },
  texContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: Scale(15),
    marginTop: Scale(30),

    width: width - Scale(70),
    flexWrap: "wrap",
  },
  bottomContainer: {
    marginTop: Scale(40),
  },
  sideTextStyle: {
    // color: Colors.textColor,
    fontWeight: "500",
  },
  optionalStyle: {
    // color: Colors.textColor,
    fontSize: Scale(14),
    fontWeight: "500",
  },
});

export default styles;
