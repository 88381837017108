import { StyleSheet } from "react-native";

import { Scale, width } from "../constants";

const styles = StyleSheet.create({
  emailIconStyle: {
    height: Scale(100),
    width: Scale(100),
    resizeMode: "contain",
    marginVertical: Scale(40),
  },
  container: {
    justifyContent: "center",
    alignItems: "center",

    marginTop: Scale(200),
  },
  titleStyle: {
    textAlign: "center",
    marginVertical: Scale(30),
  },
  wrapper: {
    // backgroundColor: Colors.white,
    flex: 2,
  },
  middleTextStyle: {
    textAlign: "center",
    // color: Colors.textColor,
    fontSize: Scale(14),
    fontWeight: "500",
  },
  textStyle: { width: width - Scale(60) },
  emailStyle: {
    // color: Colors.textColor
  },
  lastTextStyle: {
    // color: Colors.textColor,
    fontWeight: "500",
  },
});

export default styles;
