import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as WebBrowser from "expo-web-browser";
import { Locale } from "locale-enum";
import { Box, Button, Center, Divider, Icon, Image, Select, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as URI from "uri-js";

import { ASYNC_STORAGE__LANGUAGE_KEY } from "../../../i18n";
import { USER_LOGOUT_ACTION } from "../../../store";
import CustomBrandingMobileScreenHeader from "../components/CustomBrandingMobileScreenHeader";
import { BUILD_NUMBER, commonStyles, Images, isDesktopScreen, Routes, width as deviceWidth } from "../constants";
import { Scale, VerticalScale, width } from "../constants/helperFunction";
import { isMobilePlatform } from "../helpers/generalHelpers";
import { logout } from "../helpers/logout";
import { ContactSupportLink, StripeCustomerPortalLink } from "../helpers/supportHelpers";
import {
  hasUserCompletedTheirDiyNutritionPlanningFormCompletely,
  isUserDiy,
  isUserInPaymentRequiredOrganisation,
  organisationIsDiyBillingForUsers,
} from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import logger from "../services/logger";
import { onboardingSlice } from "../slices/onboardingSlice";
import { clientModeSelector, userSelector, userSlice } from "../slices/userSlice";
import styles from "./MyProfileScreenStyles";

const { useUsersUserDestroyMutation, useCheckoutSubscriptionVerificationCreateMutation } = backendApi;

type SupportedLanguage = Locale.en | Locale.nl;
const LANGUAGE_OPTIONS: SupportedLanguage[] = [Locale.en, Locale.nl];

type Props = NativeStackScreenProps<RootStackParamList, Routes.MyProfileScreen>;
const MyProfileScreen = ({ navigation }: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isDesktop = isDesktopScreen();
  const isWeb = !isMobilePlatform();

  const user = useSelector(userSelector);
  const clientMode = useSelector(clientModeSelector);

  const [deleteUserOnBackend] = useUsersUserDestroyMutation();
  const [checkSubscription] = useCheckoutSubscriptionVerificationCreateMutation();
  const [subscriptionValid, setSubscriptionValid] = useState(true);

  useEffect(() => {
    const verifySubscription = async (): Promise<void> => {
      if (user && user.email) {
        if (!isUserInPaymentRequiredOrganisation(user)) {
          // Only do this check for payment required organisations
          return;
        }

        try {
          const result = await checkSubscription({
            subscriptionVerificationRequest: { email: user.email },
          }).unwrap();

          setSubscriptionValid(result.has_active_subscription === true);
        } catch (error) {
          // Log the error but don't block the user
          logger.warn("Error checking subscription:", error);
        }
      }
    };

    verifySubscription().catch((err) => console.error(err));
  }, [user, checkSubscription]);

  // const ProfileItem = ({
  //   title,
  //   leftIcon,
  //   onPress,
  //   testID,
  // }: {
  //   title: string;
  //   leftIcon: ImageSourcePropType;
  //   onPress: () => void;
  //   testID: string;
  // }): JSX.Element => (
  //   <Pressable onPress={onPress} testID={testID}>
  //     <View style={styles.parentlistContainer}>
  //       <View style={styles.listContainer}>
  //         <Image source={leftIcon} style={styles.profileIconStyle} />
  //         <Text
  //           // TODO: Replace this style with native native-base attributes
  //           style={{
  //             fontSize: Scale(16),
  //             fontWeight: "700",
  //             color: "grey.500",
  //             width: width - Scale(100),
  //             marginLeft: Scale(20),
  //           }}
  //         >
  //           {title}
  //         </Text>
  //       </View>
  //       <Image source={Images.RightIcon} style={styles.rightIconStyle} />
  //     </View>
  //   </Pressable>
  // );

  const deleteAccountButtonPress = async (): Promise<void> => {
    if (!user) {
      throw new Error("User is not defined");
    }

    const proceedToNextStep = window.confirm(t("delete_account.warning"));

    if (!proceedToNextStep) {
      return;
    }

    const confirmKeyword = t("delete_account.confirm_keyword");
    const returnValue = prompt(t("delete_account.input_confirmation", { confirmKeyword }));

    if (returnValue === confirmKeyword) {
      await deleteUserOnBackend({
        id: user.id,
      });

      logout(dispatch);
    } else {
      alert(t("delete_account.abandon", { confirmKeyword }));
    }
  };

  const swapMode = (): void => {
    dispatch(userSlice.actions.toggleClientMode());
  };

  const swapModeButton = (
    <Button
      leftIcon={<Icon as={MaterialIcons} name="swap-horiz" size="sm" />}
      mt={2}
      onPress={swapMode}
      testID={"swapMode-Button"}
      nativeID={"swapModeButton"}
    >
      {t("coach_mode_settings.swap_mode_button_text", {
        modeName: t(`coach_mode_settings.${clientMode ? "coach_mode_label_text" : "client_mode_label_text"}`),
      })}
    </Button>
  );

  // TODO: Implement this functionality later
  const goToPersonalInformation = (): void => {
    navigation.navigate(Routes.PersonalDetailsScreen);
  };

  const goToMyPreferences = (): void => {
    navigation.push(Routes.MyProfileDietPreferencesScreen);
  };

  const formatLanguage = (language: SupportedLanguage): string => t(`general.locales.${language}`);

  const switchLanguage = async (language: Locale): Promise<void> => {
    await i18n.changeLanguage(language);

    await AsyncStorage.setItem(ASYNC_STORAGE__LANGUAGE_KEY, language);
    dispatch(userSlice.actions.storeLocale(language));
  };

  const languageChooserComponent = (
    <View mt="4">
      <Text bold>{t("my_profile.language_chooser.section_title")}</Text>

      <Select
        defaultValue={i18n.language}
        placeholder={t("my_profile.language_chooser.language_select_placeholder")}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onValueChange={async (language) => switchLanguage(language as Locale)}
        testID="language-selector"
      >
        {LANGUAGE_OPTIONS?.map((language) => (
          <Select.Item key={language} label={formatLanguage(language)} value={language} />
        ))}
      </Select>
    </View>
  );

  const redoNutritionPlanningButton = (
    <Button
      leftIcon={<Icon as={MaterialIcons} name="refresh" size="sm" />}
      mt={2}
      onPress={() => {
        if (!user) {
          throw new Error("User is not defined");
        }
        if (!user.intake) {
          throw new Error("User intake is not defined");
        }
        // This ensures that the state used for the questions will be set appropriately
        dispatch(onboardingSlice.actions.setUserProfileData(user.intake));

        navigation.push(Routes.DIY1GoalScreen);
      }}
      testID={"redoNutritionPlanning-Button"}
      nativeID={"redoNutritionPlanningButton"}
    >
      {t("my_profile.redo_nutrition_planning_button_text")}
    </Button>
  );

  const shouldShowStripeCustomerPortalLink =
    (!clientMode && user && isWeb) || (user && organisationIsDiyBillingForUsers(user));

  const UserProfile = (): JSX.Element => (
    <Center mt="8">
      <Center mx="4">
        <Text mt="2">{user ? user.email : null}</Text>
      </Center>

      <Box mt="4">
        {languageChooserComponent}

        {shouldShowStripeCustomerPortalLink ? (
          <StripeCustomerPortalLink isDesktop={isDesktop} noCode={organisationIsDiyBillingForUsers(user)} />
        ) : null}

        <ContactSupportLink user={user} />
        <Button
          leftIcon={<Icon as={MaterialIcons} name="logout" />}
          onPress={() => logout(dispatch)}
          mt="10"
          testID={"logout-button"}
          nativeID={"logoutButton"}
        >
          {t("general.logout")}
        </Button>

        {user &&
        isUserDiy(user) &&
        clientMode &&
        hasUserCompletedTheirDiyNutritionPlanningFormCompletely(user) &&
        subscriptionValid
          ? redoNutritionPlanningButton
          : null}

        {user?.is_coach ? swapModeButton : null}
      </Box>

      <Center mx="4">
        <Text mt="15" fontSize={"xs"}>
          {BUILD_NUMBER}
        </Text>
      </Center>
    </Center>
  );

  return (
    <SafeAreaView style={commonStyles.container}>
      {!isDesktop && <CustomBrandingMobileScreenHeader />}

      {/* TODO: The below is note implemented yet */}
      {/* eslint-disable-next-line max-len */}
      {/* <ProfileList title={t("Personal information")} leftIcon={Images.HumanIcon} onPress={goToPersonalInformation} /> */}

      <UserProfile />

      <View style={{ flex: 1 }} />
      {/* NOTE: We only offer this functionality to accounts that are not coaches at the moment */}
      {/* NOTE: I have removed this functionality. It was only added to pass app store review.
      It causes problems for us when someone with a subscription deletes their account. */}
      {/* {!user?.is_coach ? (
        <Box>
          <Center>
            <Button
              leftIcon={<Icon as={MaterialIcons} name="warning" size="sm" />}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={deleteAccountButtonPress}
              bg="amber.400"
              testID={"delete-account-button"}
              style={{ marginBottom: 24 }}
            >
              {t("delete_account.button")}
            </Button>
          </Center>
        </Box>
      ) : null} */}
      {/* NOTE: We have decided to not surface this functionality to the client - only the coach can change these. */}
      {/* <ProfileItem
        title={t("My Preferences")}
        leftIcon={Images.RecipesIcon}
        onPress={goToMyPreferences}
        testID={"myProfileDietPreferences-button"}
      /> */}

      {/* <View style={styles.separatorStyle} /> */}
    </SafeAreaView>
  );
};

export default MyProfileScreen;
