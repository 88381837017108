import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { Icon, useTheme } from "native-base";
import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { Scale, VerticalScale } from "../constants";
import { getRecipeMealImage } from "../helpers/generalHelpers";
import type { GeneratedRecipeMeal, MealSlotSpecification } from "../services/backendTypes";
import MacroProgressWidget from "./RecipeMacrosItem";

export const styles = StyleSheet.create({
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: VerticalScale(24),
    marginHorizontal: Scale(12),
  },
  contentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: Scale(16),
  },
  contentWrapper: {
    paddingTop: VerticalScale(8),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  cardImg: {
    width: Scale(120),
    height: VerticalScale(120),
    borderRadius: 4,
  },
  ownRecipeImg: {
    width: Scale(78),
    height: VerticalScale(78),
  },
  descriptionText: {
    fontWeight: "500",
  },
  clockIcon: {
    width: Scale(15),
    height: Scale(15),
    marginRight: Scale(6),
  },
  timeViewContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: VerticalScale(8),
  },
  nextBtn: {
    width: Scale(32),
    height: Scale(32),
  },
  nextBtnView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingBottom: Scale(1),
  },
  durationText: {
    // color: Colors.smallTextColor,
    fontSize: 14,
  },
  imageContainer: {
    // borderColor: Colors.borderColor,
    borderRadius: 8,
    borderWidth: 1,
    padding: Scale(20),
  },
  recipeMacrosContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  descriptionContainer: {
    flex: 1,
    marginLeft: Scale(12),
  },
  menuIcon: {
    width: Scale(20),
    height: Scale(20),
  },
  menuIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

type RecipeCardProps = {
  recipeMeal: GeneratedRecipeMeal;
  index: number;
  mealSlotSpecification: MealSlotSpecification;
  onButtonPress: () => void;
  onOpenAction?: () => void;
};

const RecipeMealCard = ({
  recipeMeal,
  index,
  onButtonPress,
  onOpenAction,
  mealSlotSpecification,
}: RecipeCardProps): JSX.Element => {
  const theme = useTheme();

  return (
    <TouchableOpacity onPress={onButtonPress}>
      <View style={styles.cardContainer} nativeID={`recipeMeal-${index}`}>
        <Image
          source={getRecipeMealImage(recipeMeal).source}
          resizeMode={getRecipeMealImage(recipeMeal).usedDefaultImage ? "contain" : undefined}
          style={styles.cardImg}
        />
        <View
          testID={`recipeMeal-${recipeMeal.recipe_template.source_provider}-${
            recipeMeal.recipe_template.id || "MISSING_ID"
          }`}
          style={styles.contentContainer}
        >
          <View style={styles.contentWrapper}>
            <Text testID="recipeMealName">{recipeMeal.recipe_template.name}</Text>

            {Boolean(recipeMeal?.recipe_template?.preparation_time_min) && (
              <View
                style={styles.timeViewContainer}
                testID={`recipeMeal-preparationTime-${
                  recipeMeal.recipe_template.preparation_time_min || "MISSING_PREP_TIME"
                }`}
              >
                <Icon as={MaterialIcons} size={"sm"} name="schedule" mr={"2"} color={"gray.400"} />
                <Text style={{ color: theme.colors.gray["300"] }}>
                  {recipeMeal.recipe_template.preparation_time_min}
                  {"m"}
                </Text>
              </View>
            )}

            {/* kcal and protein for this meal vs MealSlotSpecification */}
            <View style={{ width: "50%", marginTop: Scale(1) }}>
              <>
                <MacroProgressWidget
                  macroDetail={{
                    macroName: "kcal",
                    total: mealSlotSpecification.kcal || 300,
                    unit: "kcal",
                    progress: recipeMeal.kcal || recipeMeal.recipe_template.kcal,
                  }}
                  verticalMode={true}
                />
              </>
              <MacroProgressWidget
                macroDetail={{
                  macroName: "protein",
                  total: mealSlotSpecification.protein || 300,
                  unit: "g",
                  progress: recipeMeal.protein || recipeMeal.recipe_template.protein,
                }}
                verticalMode={true}
                allowedToGoOver={true}
              />
            </View>

            <View style={styles.nextBtnView}>
              <Icon as={AntDesign} name="rightcircle" size={"8"} color={"primary.600"} />
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default RecipeMealCard;
