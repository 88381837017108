enum ScreenNames {
  BottomNavigationStack = "BottomNavigationStack",
  BottomNavigationTab = "BottomNavigationTab",

  LoginStack = "LoginStack",
  LoginScreenOnCheckout = "LoginScreenOnCheckout",
  LoginScreen = "LoginScreen",
  MagicLinkSentScreen = "MagicLinkSentScreen",
  EnterOTPScreen = "EnterOTPScreen",

  NutritionPlanScreen = "NutritionPlanScreen",
  ProfilePictureScreen = "ProfilePictureScreen",
  PlanScreen = "PlanScreen",
  ProfileStartScreen = "ProfileStartScreen",
  GetStaredScreen = "GetStaredScreen",
  PersonalDetailsScreen = "PersonalDetailsScreen",
  EmailConfirmed = "EmailConfirmed",
  EmailConfirmationSent = "EmailConfirmationSent",
  RegisterScreen = "RegisterScreen",
  OnboardingScreen = "OnboardingScreen",
  AddGenderScreen = "AddGenderScreen",
  AddMainGoalScreen = "AddMainGoalScreen",
  AddMotivationGoalScreen = "AddMotivationGoalScreen",
  AddLifeStyleScreen = "AddLifeStyleScreen",
  RecipeSearchScreen = "RecipeSearchScreen",
  DiaryViewScreen = "DiaryViewScreen",
  ThreeDaysScreen = "ThreeDaysScreen",
  RecipesScreen = "RecipesScreen",
  GroceryListScreen = "GroceryListScreen",
  RecipeInspirationScreen = "RecipeInspirationScreen",
  RecipesBottomTabScreen = "RecipesBottomTabScreen",
  FoodSearchScreen = "FoodSearchScreen",
  AddIngredientScreen = "AddIngredientScreen",
  RecipeDetailScreen = "RecipeDetailScreen",
  RecipeEditScreen = "RecipeEditScreen",
  RecipeSaveScreen = "RecipeSaveScreen",
  RecipeAddOwnScreen = "RecipeAddOwnScreen",
  AddProductStack = "AddProductStack",

  // Checkout
  CheckoutScreen = "CheckoutScreen",
  CheckoutFailureScreen = "CheckoutFailureScreen",

  // Profile
  MyProfileScreen = "MyProfileScreen",
  MyProfileDietPreferencesScreen = "MyProfileDietPreferencesScreen",
  MyProgressScreen = "MyProgressScreen",

  AddProductByBarcodeStack = "AddProductByBarcodeStack",
  AddRecipeTemplateStack = "AddRecipeTemplateStack",
  RecipeAddOwnStack = "RecipeAddOwnStack",
  BarcodeScannerScreen = "BarcodeScannerScreen",
  FloatingButton = "FloatingButton",
  FinishPlanningScreen = "FinishPlanningScreen",

  /*
   * Screens available for coaches only
   */
  CoachModeAccountScreen = "CoachModeAccountScreen",
  CoachModeViewClientsScreen = "CoachModeViewClientsScreen",
  CoachModeClientInfoScreen = "CoachModeClientInfoScreen",
  EditUserProfileScreen = "EditUserProfileScreen",
  BillingIssueScreen = "BillingIssueScreen",

  EditNutritionDayPlanStack = "EditNutritionDayPlanStack",
  EnergyBalanceTab1 = "EnergyBalanceTab1",
  ChooseMealMomentsTab2 = "ChooseMealMomentsTab2",
  EditMealMomentsTab3 = "EditMealMomentsTab3",
  FineTuningTab4 = "FineTuningTab4",

  // TODO: Should this be deleted?
  MacroProfilesEditMealAmountScreen = "MacroProfilesEditMealAmountScreen",

  /*
   * DIY path screens
   */
  Diy1WelcomeScreen = "Diy1WelcomeScreen",
  DiyIntakeScreen = "DiyIntakeScreen",
  DiyAvoidIngredientsScreen = "DiyAvoidIngredientsScreen",
  DiyCreateNutritionPlanScreen = "DiyCreateNutritionPlanScreen",
  DiyFineTuningScreen = "DiyFineTuningScreen",

  // NOTE: This is a user facing name because the user will be installing the PWA from this screen
  PWASignupScreen = "Install the app",

  // Onboarding flow
  DIY1GoalScreen = "DIY1GoalScreen",
  ActivityLevelScreen = "ActivityLevelScreen",
  BiometricDataScreen = "BiometricDataScreen",

  PhysicalStatsScreen = "PhysicalStatsScreen",
  WeeklyGoalScreen = "WeeklyGoalScreen",
  NutritionalPlanScreen = "NutritionalPlanScreen",
  ModifyNutritionalPlanScreen = "ModifyNutritionalPlanScreen",
  MealTimesScreen = "MealTimesScreen",
  DietSelectionScreen = "DietSelectionScreen",
  IntoleranceScreen = "IntoleranceScreen",
  IngredientDislikesScreen = "IngredientDislikesScreen",
  PostOnboardingScreen = "PostOnboardingScreen",
  SignUpScreen = "SignUpScreen",
  Onboarding_RegistrationScreen = "Onboarding_RegistrationScreen",
}

export default ScreenNames;
