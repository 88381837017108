import { Text } from "native-base";
import React from "react";
import { StyleSheet, TextStyle } from "react-native";

import { Scale } from "../constants";

const styles = StyleSheet.create({
  textStyle: {
    // color: Colors.largeTextColor,
    fontSize: Scale(15),
    flexWrap: "wrap",
    overflow: "scroll",
  },
});

type CommonInnerTextProps = {
  title: string;
  externalTextStyle?: TextStyle;
};

const CommonInnerText: React.FC<CommonInnerTextProps> = ({ title, externalTextStyle, ...props }): JSX.Element => (
  <Text fontWeight={"700"} style={[styles.textStyle, externalTextStyle]} {...props}>
    {title}
  </Text>
);

export default CommonInnerText;
