import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { useTranslation } from "react-i18next";
import { Image, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-elements";

import { Images, Routes } from "../../constants";

interface SignUpScreenProps {
  navigation: NavigationProp<ParamListBase>;
  brand: { name: string; logo: string };
}

const SignUpScreen: React.FC<SignUpScreenProps> = ({ navigation, brand }) => {
  const { t } = useTranslation();
  // TODO: Abstract this into a helper function
  const brandName = brand?.name || "NutriAI";

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.topContainer}>
        <Image source={Images.LoginBackground} style={styles.image} resizeMode="cover" />
        <LinearGradient
          colors={["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 1)"]}
          locations={[0.6, 1]}
          style={styles.gradientOverlay}
        />
      </View>
      <View style={styles.bottomContainer}>
        <Text style={styles.brandName}>{brandName}</Text>
        <Text style={styles.title}>{t("onboarding.onboarding_1_sign_up_screen.reach_your_goals")}</Text>
        <Text style={styles.subtitle}>{t("onboarding.onboarding_1_sign_up_screen.build_healthy_eating_habits")}</Text>
        <Button
          title={t("onboarding.onboarding_1_sign_up_screen.start_today")}
          buttonStyle={styles.startButton}
          titleStyle={styles.startButtonText}
          onPress={() => navigation.navigate(Routes.DIY1GoalScreen)}
        />
        <Text style={styles.loginPrompt}>{t("onboarding.onboarding_1_sign_up_screen.already_have_account")}</Text>
        <Button
          title={t("onboarding.onboarding_1_sign_up_screen.log_in")}
          type="clear"
          buttonStyle={styles.loginButton}
          titleStyle={styles.loginButtonTitle}
          onPress={() => navigation.navigate(Routes.LoginScreen, { screen: Routes.LoginScreen })}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  topContainer: {
    flex: 1,
    position: "relative",
  },
  bottomContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  gradientOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  image: {
    flex: 1,
    width: "100%",
  },
  brandName: {
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 8,
  },
  subtitle: {
    fontSize: 16,
    color: "#666",
    textAlign: "center",
    marginBottom: 32,
  },
  startButton: {
    borderRadius: 30,
    paddingVertical: 16,
    paddingHorizontal: 32,
    marginBottom: 16,
  },
  startButtonText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#fff",
  },
  loginPrompt: {
    fontSize: 16,
    color: "#666",
    textAlign: "center",
    marginBottom: 8,
  },
  loginButton: {
    borderRadius: 30,
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  loginButtonTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
});

export default SignUpScreen;
