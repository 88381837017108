// NOTE: This file is not currently used

import { Box, Flex, HStack, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import Swiper from "react-native-web-swiper";

import { CommonText, CommonTextInput } from "../commons";
import HomeScreenCarousel from "../components/RecipeInspirationScreenCarousel";
import RecipeInspirationCarousel from "../components/RecipeInspirationScreenListItems";
import { homeCarouselData, homeData, Images } from "../constants";
import styles from "./RecipeInspirationScreenStyle";

const RecipeInspirationScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const handleSearchText = (val: string): void => {
    setSearchText(val);
  };

  const featuredRecipesComponent = (
    <View style={styles.topContainer}>
      <CommonText title={"Uitgelicht"} />
      <View style={styles.swiperContainer}>
        {/* <Swiper
          containerStyle={{ flex: 3 }}
          controlsProps={{
            prevTitle: "",
            nextTitle: "",
            dotsTouchable: true,
            dotsPos: "bottom-left",
            dotsWrapperStyle: styles.dotsWrapperStyle,
            dotActiveStyle: styles.activeDotStyle,
            dotProps: {
              badgeStyle: styles.badgeStyle,
            },
          }}
        >
          {homeCarouselData.map((i) => (
            <HomeScreenCarousel title={t(i.title)} time={i.time} key={i.id} image={i.image} />
          ))}
        </Swiper> */}
      </View>
    </View>
  );

  const recipesForDifferentMealTypes = (
    <VStack>
      <RecipeInspirationCarousel recipes={homeData} mealTitle={"Breakfast"} />
      <RecipeInspirationCarousel recipes={homeData} mealTitle={"Lunch"} />
      <RecipeInspirationCarousel recipes={homeData} mealTitle={"Dinner"} />
      <RecipeInspirationCarousel recipes={homeData} mealTitle={"Snacks"} />
    </VStack>
  );

  return (
    <Flex bg={"white"}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Box mt="39">
          <CommonTextInput
            title={""}
            placeholder={"Probeer “zalm”"}
            textBoxContainerExternalStyle={styles.textInputStyle}
            textWrapperExternalStyle={styles.externalTextInputStyle}
            // placeholderTextColor={Colors.primaryGreenColor}
            searchIcon={Images.SearchIcon}
            onChangeText={(val: string) => handleSearchText(val)}
          />
        </Box>

        {/* NOTE: This component is for a future release. */}
        {/* {featuredRecipesComponent} */}

        {recipesForDifferentMealTypes}
      </ScrollView>
    </Flex>
  );
};

export default RecipeInspirationScreen;
