import { Platform, StyleSheet } from "react-native";

import { Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  initialsCircle: {
    width: Scale(32),
    height: Scale(32),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#93556B",
    borderRadius: 100,
  },
  initialsText: {
    color: "white",
  },
  routeViewContainer: {
    marginVertical: VerticalScale(24),
    marginHorizontal: Scale(16),
    borderRadius: 8,
  },
  bottomContainer: {
    paddingHorizontal: 20,
    bottom: 40,
  },
  modalButtonContainer: {
    marginTop: VerticalScale(28),
  },
  fabBtnContainer: {
    position: "absolute",
    right: Scale(12),
    bottom: VerticalScale(46),
  },
  clientViewContainer: {
    width: "100%",
    backgroundColor: "white",
  },
});

export default styles;
