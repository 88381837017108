// NOTE: This file is not currently used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, TouchableOpacity, View } from "react-native";
import { CheckBox } from "react-native-elements";

import { CommonButton, CommonText, CommonTextInput } from "../commons";
import { Images, Routes, Scale } from "../constants";
import styles from "./RegisterStyles";

type RegisterParamList = {
  Register: {
    email: string;
    password: string;
    confirmPassword: string;
    checkBox: boolean;
  };

  LoginScreen: undefined;
};

interface RegisterProps {
  navigation: NativeStackNavigationProp<RegisterParamList, "Register">;
}

const RegisterScreen: React.FC<RegisterProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    checkBox: false,
  });

  const { email, password, confirmPassword, checkBox } = values;
  const handleNavigation = (): void => {
    navigation.navigate(Routes.LoginScreen as keyof RegisterParamList);
  };

  // Added
  const handleButtonNavigation = (): void => {
    // navigation.navigate("EmailConfirmationSent");
  };

  const handleChange = (name: string, value: string | boolean): void => {
    setValues({ ...values, [name]: value });
  };

  // TODO: Name this better
  const checkDisableCondition = (): boolean => !(password === confirmPassword && checkBox);
  const renderStrongPassword = (): JSX.Element => (
    <View style={styles.strongPasswordContainer}>
      <View
        style={[
          { ...styles.strongPasswordStyle },
          password.length >= 2 &&
            {
              // backgroundColor: Colors.primaryGreenColor,
            },
        ]}
      />
      <View
        style={[
          { ...styles.strongPasswordStyle },
          password.length >= 3 &&
            {
              // backgroundColor: Colors.primaryGreenColor,
            },
        ]}
      />
      <View
        style={[
          { ...styles.strongPasswordStyle },
          password.length >= 5 &&
            {
              // backgroundColor: Colors.primaryGreenColor,
            },
        ]}
      />
      <View
        style={[
          { ...styles.strongPasswordStyle },
          password.length >= 7 &&
            {
              // backgroundColor: Colors.primaryGreenColor,
            },
        ]}
      />
    </View>
  );

  const makeYourAccountButtonStyle = checkDisableCondition() ? styles.lightGrayColor : undefined;

  return (
    <View style={styles.wrapper}>
      <View style={styles.logginContainer}>
        <TouchableOpacity style={styles.loginTouchableContainer} onPress={handleNavigation}>
          <CommonText title={"InLoggen"} externalTextStyle={styles.externalTextStyle} />
        </TouchableOpacity>
      </View>
      <View style={styles.container}>
        <CommonText title={"Maak je WeekMeals account"} externalTextStyle={styles.titleStyle} />
        <CommonTextInput
          title={"E-mailadres"}
          placeholder={"Jouw e-mailadres"}
          value={email}
          onChangeText={(val: string) => handleChange("email", val)}
        />
        <View style={{ justifyContent: "space-around", marginBottom: Scale(20) }}>
          <CommonTextInput
            title={"Kies een sterk wachtwoord"}
            placeholder={"Jouw wachtwoord"}
            value={password}
            onChangeText={(val: string) => handleChange("password", val)}
            password={true}
            textBoxContainerExternalStyle={styles.textBoxContainerExternalStyle}
            showEyeIcon={true}
          />
          {renderStrongPassword()}
        </View>
        <CommonTextInput
          title={"Herhaal je wachtwoord"}
          placeholder={"Jouw wachtwoord"}
          value={confirmPassword}
          onChangeText={(val: string) => handleChange("confirmPassword", val)}
          password={true}
          showEyeIcon={true}
        />
        <View style={styles.checkBoxContainer}>
          <CheckBox
            containerStyle={styles.textBoxContainerStyle}
            checkedIcon={<Image source={Images.CheckIcon} style={styles.checkboxStyle} />}
            uncheckedIcon={<Image source={Images.UncheckedIcon} style={styles.checkboxStyle} />}
            checked={checkBox}
            onPress={() => handleChange("checkBox", !checkBox)}
          />
          <CommonText title={"Ik accepteer de Voorwaarden"} externalTextStyle={styles.checkboxTextStyle} />
        </View>
      </View>
      <View style={styles.bottomStyle}>
        <CommonButton
          title={"Maak je account"}
          onPress={handleButtonNavigation}
          disable={checkDisableCondition()}
          externalContainerStyle={makeYourAccountButtonStyle}
        />
      </View>
    </View>
  );
};

export default RegisterScreen;
