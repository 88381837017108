// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import SelectOptionScreen from "../components/SelectOptionScreen";
import { commonStyles, Routes } from "../constants";

type ParamList = {
  AddGenderScreen: undefined;
  ProfileStartScreen: undefined;
  AddMainGoalScreen: undefined;
};
type Props = {
  navigation: NativeStackNavigationProp<ParamList, "AddGenderScreen">;
};

const optionsData = [
  {
    id: 0,
    label: "Female",
  },
  {
    id: 1,
    label: "Male",
  },
];

const AddGenderScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <View style={commonStyles.container}>
      <SelectOptionScreen
        pageTitle={"Your gender"}
        optionsData={optionsData}
        buttonText={"Next"}
        onButtonPress={() => {
          navigation.push(Routes.AddMainGoalScreen as keyof ParamList);
        }}
        multipleSelectionsAllowed={false}
        // FIXME:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigation={navigation}
      />
    </View>
  );
};
export default AddGenderScreen;
