import type { Dispatch } from "@reduxjs/toolkit";
import type { PrefetchOptions } from "@reduxjs/toolkit/dist/query/core/module";
import _ from "lodash";
import type { Moment } from "moment";
// eslint-disable-next-line import/no-named-default
import { default as MomentLib } from "moment";
import { extendMoment } from "moment-range";
import { useDispatch, useSelector } from "react-redux";

import { USER_LOGOUT_ACTION } from "../../../store";
import backendApi from "../services/backendApi";
import type {
  CalendarDay,
  CalendarItem,
  CalendarItemContentTypeEnum,
  Food,
  IngredientPostRequest,
  Meal,
  MealMomentEnum,
  MealSlotSpecification,
  NutritionDayPlan,
  PaginatedCalendarDayList,
  PlannerCalendarDayListApiArg,
  SuggestedServing,
  User,
  WeeklyNutritionPlan,
} from "../services/backendTypes";
import logger from "../services/logger";
import { FoodById, foodSlice } from "../slices/foodSlice";
import { CalendarDayByDate, currentDayInPlannerSelector, plannerSlice } from "../slices/plannerSlice";
import { authTokenSelector, userSelector, viewAsUserSelector } from "../slices/userSlice";
import type {
  CreateSingleFoodMeal,
  DayOfWeekString,
  FoodIngredientCreate,
  MacroName,
  PlannerCalendarDayCreate,
  PlannerCalendarItemCreate,
  PlannerPlanSingleFoodMealCreateMutation,
} from "../types";
import { formatMomentAsBackendFormatDateString } from "./generalHelpers";
import { logout } from "./logout";
import { isUserLoggedIn } from "./userHelpers";

const { usePlannerCalendarDayCreateMutation, usePlannerCalendarDayListQuery, useUsersAuthUsersMeRetrieveQuery } =
  backendApi;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const moment = extendMoment(MomentLib);

const getPlannerArgs = (
  startDate: Moment
): {
  dateGte?: string | undefined;
  dateLt?: string | undefined;
} => ({
  dateGte: formatMomentAsBackendFormatDateString(startDate),
  dateLt: formatMomentAsBackendFormatDateString(moment(startDate).add(1, "days")),
});

function createBlankCalendarDay(date: Moment, user: User, weeklyNutritionPlan: WeeklyNutritionPlan): CalendarDay {
  return {
    user: user?.id,
    date: formatMomentAsBackendFormatDateString(date),
    calendar_items: [],
  };
}

// FIXME: Make this function take an object parameter, not individual parameters
export const createCalendarDay = async (
  date: Moment,
  createNewCalendarDay: PlannerCalendarDayCreate,
  dispatch: Dispatch,
  user: User,
  weeklyNutritionPlan: WeeklyNutritionPlan,
  calendarDaysInStore: CalendarDayByDate,
  isLoadingCreateNewCalendarDay: boolean,
  isLoadingCalendarDayList: boolean
): Promise<CalendarDay | undefined> => {
  logger.debug("Inside createCalendarDay");
  const calendarDayToBeCreated = createBlankCalendarDay(date, user, weeklyNutritionPlan);

  const isCalendarDayNotInStore = calendarDaysInStore[calendarDayToBeCreated.date] === undefined;
  logger.debug(
    `isCalendarDayNotInStore for ${moment(calendarDayToBeCreated.date).format("DD-MM-YYYY")}:`,
    isCalendarDayNotInStore
  );

  const isPayloadValid = calendarDayToBeCreated.user !== undefined;

  const notInFlight = !isLoadingCreateNewCalendarDay || !isLoadingCalendarDayList;

  logger.debug("Before usePlannerCalendarDayCreateMutation()");
  if (isPayloadValid && isCalendarDayNotInStore && notInFlight) {
    // logger.debug("Creating new calendarDay for date: ", calendarDayToBeCreated.date);
    logger.debug("Creating new calendar day:", calendarDayToBeCreated);
    const calendarDay = await createNewCalendarDay({
      calendarDayRequest: calendarDayToBeCreated,
    }).unwrap();
    logger.debug("Got calendarDay: ", calendarDay);
    dispatch(plannerSlice.actions.storeCalendarDay(calendarDay));
  }
  logger.debug("After usePlannerCalendarDayCreateMutation()");
  return undefined;
};

const CALENDAR_DAY_POLLING_INTERVAL_MS = 30 * 1000;
export function getPlannerData(): {
  isLoadingCalendarDayList: boolean;
  isLoadingCreateNewCalendarDay: boolean;
  refetchCalendarDays: () => void;
  calendarDayListResponse: PaginatedCalendarDayList | undefined;
  plannerArgs: PlannerCalendarDayListApiArg;
  createNewCalendarDay: PlannerCalendarDayCreate;
  user: User | null;
} {
  // TODO: This should be removed to avoid potential errors but it is working at the moment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const calendarFetchStartDate = useSelector(currentDayInPlannerSelector);

  // TODO: This should be removed to avoid potential errors but it is working at the moment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const viewAsUser = useSelector(viewAsUserSelector);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realUser = useSelector(userSelector);
  const user = viewAsUser || realUser;

  const plannerArgs = getPlannerArgs(calendarFetchStartDate);

  // TODO: To avoid awkward logic and race condition we should create a getOrCreate endpoint for the CalendarDay model
  const {
    isLoading: isLoadingCalendarDayList,
    data: calendarDayListResponse,
    error: errorRetrivingCalendarDayList,
    refetch: refetchCalendarDays,
    // TODO: This should be removed to avoid potential errors but it is working at the moment
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = usePlannerCalendarDayListQuery(
    { ...plannerArgs, user: user?.id },
    { skip: !isUserLoggedIn(user), pollingInterval: CALENDAR_DAY_POLLING_INTERVAL_MS }
  );

  // TODO: This should be removed to avoid potential errors but it is working at the moment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createNewCalendarDay, { isLoading: isLoadingCreateNewCalendarDay }] = usePlannerCalendarDayCreateMutation();

  return {
    isLoadingCalendarDayList,
    refetchCalendarDays,
    calendarDayListResponse,
    plannerArgs,
    createNewCalendarDay,
    user,
    isLoadingCreateNewCalendarDay,
  };
}

export async function createCalendarDays(
  calendarDayListResponse: PaginatedCalendarDayList,
  dispatch: Dispatch,
  plannerArgs: PlannerCalendarDayListApiArg,
  createNewCalendarDay: PlannerCalendarDayCreate,
  user: User,
  weeklyNutritionPlan: WeeklyNutritionPlan,
  isLoadingCreateNewCalendarDay: boolean,
  isLoadingCalendarDayList: boolean,
  calendarDaysInStore: CalendarDayByDate
): Promise<void> {
  if (isLoadingCalendarDayList) {
    return;
  }

  _.forEach(calendarDayListResponse?.results, (calendarDay) => {
    dispatch(plannerSlice.actions.storeCalendarDay(calendarDay));
  });
  // TODO: The calendar logic should be separated into a different function
  const { dateGte, dateLt } = plannerArgs;
  const rangeByDate = moment.range(moment(dateGte), moment(dateLt).subtract(1, "days")).by("days");
  const days = Array.from(rangeByDate);
  const dates = days.map((m) => moment(m.format("YYYY-MM-DD")));
  logger.trace("dates from planner args: ", dates);
  // TODO: Abstract this logic into a function
  const datesWithoutCalendarDays: Moment[] = _.filter(
    dates,
    (date: Moment) =>
      !_.find(
        calendarDayListResponse?.results,
        (calendarDay: CalendarDay) => calendarDay.date === formatMomentAsBackendFormatDateString(date)
      )
  );
  logger.trace("datesWithoutCalendarDays: ", datesWithoutCalendarDays);
  // TODO: Make sure the days are separate days and there is no overlap
  // eslint-disable-next-line no-restricted-syntax
  for (const date of datesWithoutCalendarDays) {
    logger.debug("Creating calendarDay for date: ", date.format("YYYY-MM-DD"));
    // eslint-disable-next-line no-await-in-loop
    await createCalendarDay(
      date,
      createNewCalendarDay,
      dispatch,
      user,
      weeklyNutritionPlan,
      // TODO: Just pass in useSelector so the function can fetch its own data
      calendarDaysInStore,
      isLoadingCreateNewCalendarDay,
      isLoadingCalendarDayList
    );
  }
}

// TODO: This should be in a userHelpers or generalHelpers file
export function getUserData(): { user: User | undefined; isLoadingUserQuery: boolean; refetchUser: () => void } {
  // TODO: This should be removed to avoid potential errors but it is working at the moment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authToken = useSelector(authTokenSelector);

  // TODO: This should be removed to avoid potential errors but it is working at the moment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const {
    isLoading: isLoadingUserQuery,
    error,
    data: returnedUser,
    refetch: refetchUser,
    // TODO: This should be removed to avoid potential errors but it is working at the moment
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useUsersAuthUsersMeRetrieveQuery(undefined, { skip: !authToken });

  const is401Error = error && "status" in error && error.status === 401;
  if (is401Error) {
    logger.debug("Error retrieving current user: ", error);
    logout(dispatch);
  }

  return { isLoadingUserQuery, user: returnedUser, refetchUser };
}

// TODO: moment.js uses capitalised day strings by default, should we follow this convention in our code?
export const getDayOfWeekAsString = (day: Moment): DayOfWeekString =>
  day.locale("en").format("dddd").toLowerCase() as DayOfWeekString;

export async function addMealToPlanner({
  currentCalendarDay,
  mealSlot,
  meal,
  mealContentType,
  createCalendarItem,
  refetchCalendarDays,
}: {
  currentCalendarDay: CalendarDay;
  meal: Meal;
  mealContentType: CalendarItemContentTypeEnum;
  mealSlot: MealSlotSpecification;
  createCalendarItem: PlannerCalendarItemCreate;
  refetchCalendarDays: () => void;
}): Promise<void> {
  if (!currentCalendarDay) {
    throw new Error("No calendarDay provided");
  }
  if (!mealSlot) {
    throw new Error("No mealSlot provided");
  }

  if (!meal.id) {
    throw new Error("No meal.id provided");
  }

  if (!currentCalendarDay.id) {
    throw new Error("No currentCalendarDay.id provided");
  }

  const calendarItemToCreate: CalendarItem = {
    meal_slot: mealSlot.id,
    meal_moment: mealSlot.meal_moment,
    meal,
    object_id: meal.id,
    content_type: mealContentType,
    calendar_day: currentCalendarDay.id,
    // NOTE: This is just to make the type compiler happy, the backend will auto-populate this field
    modified: "",
  };
  await createCalendarItem({ calendarItemRequest: calendarItemToCreate }).unwrap();
  refetchCalendarDays();
}

export async function addSingleFoodMealToPlanner({
  food,
  currentCalendarDay,
  suggestedServing,
  quantity,
  mealSlot,
  planSingleFoodMealBackendCall,
  dispatch,
}: {
  food: Food;
  suggestedServing: SuggestedServing;
  quantity: number;
  currentCalendarDay: CalendarDay;
  mealSlot: MealSlotSpecification;
  refetchCalendarDays: () => void;
  dispatch: Dispatch;
  planSingleFoodMealBackendCall: PlannerPlanSingleFoodMealCreateMutation;
}): Promise<void> {
  if (currentCalendarDay === undefined) {
    logger.error("calendarDay is undefined");
    // TODO: Surface error to user
  }

  dispatch(foodSlice.actions.storeFood(food));

  if (!currentCalendarDay.id) {
    return Promise.reject(new Error("No calendar day id"));
  }
  if (!suggestedServing.id) {
    return Promise.reject(new Error("No suggested serving id"));
  }

  await planSingleFoodMealBackendCall({
    planSingleFoodMealRequest: {
      calendar_day_id: currentCalendarDay.id,
      meal_slot_id: mealSlot.id,
      quantity,
      suggested_serving_id: suggestedServing.id,
    },
  });
  return Promise.resolve();
}

export const getActualValueForMacro = (calendarItems: CalendarItem[], macro: MacroName): number =>
  _.sumBy(calendarItems, (calendarItem) => _.get(calendarItem.meal, macro, 0));

export const getTargetValueForMacro = (nutritionPlan: NutritionDayPlan, macro: MacroName): number =>
  _.sumBy(nutritionPlan?.meal_slot_specifications, (mealSlotSpecification) => mealSlotSpecification[macro] || 0);

export const findMatchingSuggestedServing = (f: Food, suggestedServingId: number): SuggestedServing | undefined =>
  _.find(f?.suggested_servings, { id: suggestedServingId });

export const findFoodBySuggestedServingId = (suggestedServingId: number, foodsById: FoodById): Food | undefined =>
  _.find(foodsById, (f) => Boolean(findMatchingSuggestedServing(f, suggestedServingId)));

const RANKED_MEAL_MOMENTS: { [M in MealMomentEnum]: number } = {
  BREAKFAST: 1,
  MORNING_SNACK: 2,
  LUNCH: 3,
  AFTERNOON_SNACK: 4,
  DINNER: 5,
  SNACK: 6,
  LATE_SNACK: 7,
};

export const sortMealSlotSpecificationsByMealMoment = (
  mealSlotSpecifications: MealSlotSpecification[]
): MealSlotSpecification[] => _.sortBy(mealSlotSpecifications, (mss) => RANKED_MEAL_MOMENTS[mss.meal_moment]);

export const generateMealsPrefetchOptions: PrefetchOptions = {
  ifOlderThan: 60 * 60 * 1000, // 1 hour
};

/**
 * Returns a date string, format "YYYY-MM-DD",
 * of the last date there are meals planned with the same nutrition day plan id
 */
export const findLastDateThereAreMealsPlannedWithThisNutritionDayPlan = (
  nutritionPlanForCurrentDay: NutritionDayPlan | undefined,
  weeklyNutritionPlan: WeeklyNutritionPlan | null | undefined,
  currentDayInPlanner: Moment,
  calendarDaysInStore: CalendarDayByDate
): CalendarDay | undefined => {
  const maximumHowManyDaysToLookBack = 14;

  const todaysNutritionDayPlanId = nutritionPlanForCurrentDay?.id;
  if (!todaysNutritionDayPlanId) {
    return undefined;
  }

  if (!weeklyNutritionPlan) {
    return undefined;
  }

  // use a while loop to loop over the past maximumHowManyDaysToLookBack days
  // and check if there are any days with the same nutrition day plan id
  // if there are then return that day

  let i = 1;
  while (i < maximumHowManyDaysToLookBack) {
    const dateToCheck = moment(currentDayInPlanner).subtract(i, "days");
    const dateToCheckFormattedAsString = formatMomentAsBackendFormatDateString(dateToCheck);
    const calendarDayToCheck = calendarDaysInStore[dateToCheckFormattedAsString];

    const dayOfWeekForDate = getDayOfWeekAsString(moment(dateToCheck));

    const isThisDayTheSameNDPAsToday = weeklyNutritionPlan[dayOfWeekForDate]?.id === todaysNutritionDayPlanId;

    if (isThisDayTheSameNDPAsToday) {
      if (calendarDayToCheck?.calendar_items && calendarDayToCheck?.calendar_items.length > 0) {
        return calendarDayToCheck;
      }
    }

    i += 1;
  }

  return undefined;
};
