import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";

import { CommonInnerText, CommonText } from "../commons";
import { Images, Scale, width } from "../constants";

const styles = StyleSheet.create({
  emailsStyle: {
    color: "#4A5E68",
  },
  externalTextStyle: { alignItems: "center", textAlign: "center" },

  textStyle: {
    // color: Colors.greyTextColor,
    fontWeight: "bold",
  },
  mainContainerStyle: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  container: {
    flex: 1,
    // backgroundColor: Colors.whiteColor,
  },
  iconStyle: {
    height: Scale(100),
    width: Scale(100),
  },
  iconContainer: {
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: Colors.primaryGreenColor,
    height: Scale(90),
    width: Scale(90),
    borderRadius: Scale(100),
  },
  titleStyle: {
    marginTop: Scale(35),
  },
  discriptionContainer: {
    marginTop: Scale(35),
    justifyContent: "center",
    alignItems: "center",
    marginBottom: Scale(15),
    alignSelf: "center",
  },
  bottomContainer: {
    flex: 1 / 2,
    justifyContent: "center",
    alignItems: "center",
  },

  bottomTextComponetStyle: {
    marginLeft: 0,
    marginTop: Scale(30),
    textAlign: "center",
    fontSize: Scale(16),
  },
});

type Props = {
  // FIXME: This is to be fixed on another branch
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation?: any;
};

const EmailConfirmationSent: React.FC<Props> = ({ navigation }) => {
  const handleNavigation = (): void => {
    // navigation.navigate("EmailConfirmed");
  };

  return (
    <View style={styles.container}>
      <View style={styles.mainContainerStyle}>
        <Image source={Images.EmailConfirmedIcon} style={styles.iconStyle} />

        <CommonText title="Bevestig je e-mailadres" externalTextStyle={styles.titleStyle} />

        <View style={styles.discriptionContainer}>
          <CommonInnerText title="We hebben een e-mail verstuurd naar" />
          <CommonInnerText title="lisa.de.leeuw@gmail.com " externalTextStyle={styles.emailsStyle} />
        </View>

        <View
          style={{
            ...styles.discriptionContainer,
            width: width - Scale(100),
          }}
        >
          <CommonInnerText
            title="Klik op de link in de e-mail om je e-mailadres te bevestigen "
            externalTextStyle={styles.externalTextStyle}
          />
        </View>
      </View>

      <View style={styles.bottomContainer}>
        <CommonInnerText title="Stuur opnieuw" externalTextStyle={styles.textStyle} />

        <TouchableOpacity onPress={handleNavigation}>
          <CommonText title="Annuleren" externalTextStyle={styles.bottomTextComponetStyle} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default EmailConfirmationSent;
