import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { DietPreferences, onboardingDataSelector, onboardingSlice } from "../../slices/onboardingSlice";
import { userSelector } from "../../slices/userSlice";
import { DietaryPreferenceEnum } from "../../types";
import SelectionScreen, { Option } from "./SelectionScreen";

interface DietsScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const DietSelectionScreen: React.FC<DietsScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const onboardingData = useSelector(onboardingDataSelector);

  if (!user) {
    throw new Error("User is not defined");
  }

  const dietOptions: Option[] = [
    // NOTE: Omnivore is the "default" option
    {
      label: t("general.diets.VEGAN"),
      description: "",
      value: DietaryPreferenceEnum.VEGAN,
      icon: "🌱", // Seedling
      testID: `diet-option-${DietaryPreferenceEnum.VEGAN}`,
    },
    // NOTE: We do not show these because they are confusing to users and are not used
    // I also note that lacto-vegetarian can be selected by selecting vegetarian and specifying a lactose intolerance
    // {
    //   label: t("general.diets.LACTO_VEGETARIAN"),
    //   description: "",
    //   value: "LACTO_VEGETARIAN",
    //   icon: "🥛", // Milk
    // },
    // {
    //   label: t("general.diets.OVO_VEGETARIAN"),
    //   description: "",
    //   value: "OVO_VEGETARIAN",
    //   icon: "🥚", // Egg
    // },
    {
      label: t("general.diets.VEGETARIAN"),
      description: "",
      value: DietaryPreferenceEnum.VEGETARIAN,
      icon: "🥗", // Green salad
      testID: `diet-option-${DietaryPreferenceEnum.VEGETARIAN}`,
    },
    {
      label: t("general.diets.PESCATARIAN"),
      description: "",
      value: DietaryPreferenceEnum.PESCATARIAN,
      icon: "🐟", // Fish
      testID: `diet-option-${DietaryPreferenceEnum.PESCATARIAN}`,
    },
    {
      label: t("general.diets.HALAL"),
      description: "",
      value: DietaryPreferenceEnum.HALAL,
      icon: "🕌", // Mosque
      testID: `diet-option-${DietaryPreferenceEnum.HALAL}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedDiet: Yup.mixed()
      .oneOf([...dietOptions.map((option) => option.value)])
      .notRequired(),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    const updatedDietPreferences: DietPreferences = {
      ...onboardingData.dietPreferences,
      dietaryPreference: values.selectedDiet,
    };
    dispatch(onboardingSlice.actions.setDietPreferences(updatedDietPreferences));

    navigation.navigate(Routes.IntoleranceScreen);
  };

  return (
    <Formik
      initialValues={validationSchema.cast({ selectedDiet: undefined })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue, errors, touched }) => {
        const handleToggleSelection = (selectedOptions: string[]): void => {
          if (values.selectedDiet === selectedOptions[0]) {
            setFieldValue("selectedDiet", undefined);
          } else {
            setFieldValue("selectedDiet", selectedOptions[0] || null);
          }
        };

        return (
          <SelectionScreen
            title={t("onboarding.onboarding_8b_diets_screen.title")}
            subtitle={t("onboarding.onboarding_8b_diets_screen.subtitle")}
            options={dietOptions}
            selectedOptions={values.selectedDiet ? [values.selectedDiet] : []}
            setSelectedOptions={handleToggleSelection}
            onPressNext={handleSubmit}
            multipleSelection={false}
            onPressBack={() => navigation.goBack()}
            errorMessage={typeof errors.selectedDiet === "string" && touched.selectedDiet ? errors.selectedDiet : ""}
          />
        );
      }}
    </Formik>
  );
};

export default DietSelectionScreen;
