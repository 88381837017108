import { Text, View } from "native-base";
import React from "react";
import { StyleSheet, TextStyle, ViewStyle } from "react-native";

import { commonStyles, Scale, VerticalScale } from "../constants";

type CommonStepIndexProps = {
  title: string;
  externalStyle?: ViewStyle;
};

const styles = StyleSheet.create({
  stepIndexCircle: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 100,
    width: Scale(28),
    height: Scale(28),
    // backgroundColor: Colors.primaryGreenColor,
  },
});

const CommonStepIndex: React.FC<CommonStepIndexProps> = ({ title, externalStyle }) => (
  <View style={[styles.stepIndexCircle, externalStyle]} bgColor="primary.600">
    <Text style={commonStyles.bodyText16W500} color="white">
      {title}
    </Text>
  </View>
);

export default CommonStepIndex;
