/* eslint-disable */
// @ts-nocheck

import { ENVIRONMENT } from "../constants";

console.log(`Loading the Datadog snippet, env="${ENVIRONMENT}"`);

if (!("Cypress" in window)) {
  // prettier-ignore
  (function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js','DD_RUM')
  window.DD_RUM.onReady(function () {
    window.DD_RUM.init({
      clientToken: "pub374256038657c928b2b3cbcbac1fa41c",
      applicationId: "cfb1e442-3a78-40fc-854c-4b0583a764f5",
      site: "datadoghq.eu",
      service: "nutriai-(js)",
      env: ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "allow",
      allowedTracingUrls: ["*"],
    });
  });
} else {
  console.log("Cypress detected, skipping Datadog snippet");
}
