import React from "react";
import { Image, ImageBackground, ImageSourcePropType, StyleSheet, Text, View } from "react-native";

import { Images, Scale } from "../constants";
import CommonSmallText from "./CommonSmallText";

type CommonWhiteContainerProps = {
  title: string;
  image?: ImageSourcePropType;
  time: string;
};

const styles = StyleSheet.create({
  timerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: Scale(5),
  },
  timerStyle: {
    height: Scale(15),
    width: Scale(20),
    resizeMode: "contain",
    marginRight: Scale(3),
  },
  backgroundImageStyle: {
    width: Scale(160),
    height: Scale(223),
    overflow: "hidden",
  },
  containerStyle: {
    flex: 1,
    marginHorizontal: Scale(15),
    justifyContent: "space-between",
    paddingVertical: Scale(10),
  },
  container: {
    height: Scale(223),
    width: Scale(150),

    marginHorizontal: Scale(5),
    borderRadius: Scale(3),
    overflow: "hidden",
  },
  imageStyleContainer: {
    height: Scale(121),
    width: Scale(160),
    resizeMode: "contain",
  },
  wrapperComponent: { marginLeft: Scale(5), marginBottom: Scale(10) },
});

const CommonWhiteContainer: React.FC<CommonWhiteContainerProps> = ({ title, time, image }) => (
  <View style={styles.wrapperComponent}>
    <ImageBackground source={Images.WhiteBackground} style={styles.backgroundImageStyle} resizeMode="contain">
      <View>{image ? <Image source={image} style={styles.imageStyleContainer} /> : null}</View>
      <View style={styles.containerStyle}>
        <CommonSmallText title={title} />
        <View style={styles.timerContainer}>
          <Image source={Images.Timer} style={styles.timerStyle} />
          <CommonSmallText title={time} />
        </View>
      </View>
    </ImageBackground>
  </View>
);

export default CommonWhiteContainer;
