// NOTE: This file is not used

import { Box, HStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, ScrollView } from "react-native";

import { CommonText, CommonWhiteContainer } from "../commons";
import CommonSmallText from "../commons/CommonSmallText";
import styles from "../screens/RecipeInspirationScreenStyle";

type RecipeInspirationCarouselProps = {
  // TODO: Use the RecipeMeal type from the backend
  recipes: { id: number; title: string; time: string; image: ImageSourcePropType }[];
  mealTitle: string;
};
const RecipeInspirationCarousel = ({ recipes, mealTitle }: RecipeInspirationCarouselProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box mt="5">
      <HStack justifyContent="space-between" mb="5" mx="4">
        <HStack justifyContent="center" alignItems="center">
          <CommonSmallText title={mealTitle} externalTextStyle={styles.leftTextStyle} />
          <CommonSmallText title={"Own recipes"} externalTextStyle={styles.middleTextStyle} />
        </HStack>

        <CommonText title={"VIEW ALL >"} externalTextStyle={styles.rightTextStyle} />
      </HStack>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollViewStyle}
      >
        {recipes.map((i) => (
          <CommonWhiteContainer key={i.id} title={i.title} time={i.time} image={i.image} />
        ))}
      </ScrollView>
    </Box>
  );
};

export default RecipeInspirationCarousel;
