import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  headerImg: {
    width: "100%",
    height: "40%",
  },
  title: {
    fontSize: 24,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
  },
  greenTitle: {
    fontSize: 24,
    // color: Colors.primaryGreenColor,
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 24,
  },
  avatarImg: {
    width: 40,
    height: 40,
    marginHorizontal: 12,
  },
  rowContainer: {
    // backgroundColor: Colors.grey,
    flexDirection: "row",
    paddingVertical: 16,
    alignItems: "center",
  },
  roleText: {
    fontSize: 12,
    // color: Colors.textColor
  },
  nameText: {
    fontSize: 16,
    fontWeight: "600",
    // color: Colors.largeTextColor
  },
  descriptionText: {
    // color: Colors.textColor,
    fontSize: 20,
    fontWeight: "500",
  },
  bottomContainer: {
    bottom: 40,
  },
  startBtn: {
    // backgroundColor: Colors.primaryGreenColor,
    height: 48,
    borderRadius: 8,
  },
  startText: { fontSize: 18, fontWeight: "500", color: "white" },
});

export default styles;
