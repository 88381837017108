import { StyleSheet } from "react-native";

import { Scale, width } from "../constants";

const loginStyles = StyleSheet.create({
  titleStyle: {
    marginBottom: Scale(20),
  },
  externalTextStyle: {
    fontSize: Scale(16),
  },
  container: {
    flex: 3,
  },
  wrapper: {
    flex: 1,
    alignItems: "center",
  },
  loginContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  loginTouchableContainer: {
    position: "absolute",
    right: Scale(20),
    width: Scale(110),
  },
  bottomStyle: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: Scale(30),
  },
  imageStyle: {
    height: Scale(200),
    width: Scale(200),
    resizeMode: "contain",
  },
  externalBoldTextStyle: {
    width: width / 2,
    fontSize: Scale(24),
    textAlign: "center",
    marginBottom: Scale(10),
    fontWeight: "700",
  },
  boldContainerWrapper: {
    marginBottom: Scale(40),
    width: width / 2,
  },
  smallTextStyle: {
    textAlign: "center",
    width: width - Scale(60),
    // color: Colors.textColor,
    fontSize: Scale(14),
  },
});

export default loginStyles;
