import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Localization from "expo-localization";
import i18n, { InitOptions } from "i18next";
import ChainedBackend from "i18next-chained-backend";
import { Locale } from "locale-enum";
import _ from "lodash";
import { initReactI18next } from "react-i18next";

// NOTE: This does not work on web, we need to find a solution for web
// import Phrase from "react-native-phrase-sdk";
import { DEFAULT_LANGUAGE, PHRASE_DISTRIBUTION_ID, PHRASE_ENVIRONMENT_ID, PRODUCTION } from "./app/src/constants";
import en from "./locales/en/translation.json";
import nl from "./locales/nl/translation.json";

// TODO: https://www.i18next.com/translation-function/formatting
// - dates (can we use the in-built moment.js internationalisation?)

// NOTE: This is for later when we use a remote service
// const phrase = new Phrase(
//   PHRASE_DISTRIBUTION_ID,
//   PHRASE_ENVIRONMENT_ID,
//   APP_VERSION,
//   "i18next"
// );

export const ASYNC_STORAGE__LANGUAGE_KEY = "@AppIntl:language";

// TODO: Add typing
const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (callback: (language: string) => void) => {
    // Fetch the language from the async storage
    // NOTE: We do not use the redux store because it is not available in this file
    const storedLanguage = await AsyncStorage.getItem(ASYNC_STORAGE__LANGUAGE_KEY);
    if (storedLanguage) {
      return callback(storedLanguage);
    }

    // If no stored language then we use the device language
    const { locale } = Localization;
    if (locale.toLowerCase().startsWith("nl")) {
      return callback(Locale.nl);
    }
    return callback(Locale.en);
  },
  init: () => _.noop(),
  cacheUserLanguage: () => _.noop(),
};

const resources = {
  [Locale.en]: { translation: en },
  [Locale.nl]: { translation: nl },
};

const localResources: InitOptions = {
  resources,
  // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // // if you're using a language detector, do not define the lng option

  interpolation: {
    // react already safes from xss
    escapeValue: false,
  },
  fallbackLng: Locale.en,
  compatibilityJSON: "v3",
};

i18n
  .use(ChainedBackend)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  .use(languageDetector)
  .use(initReactI18next)
  .init(localResources)
  .catch((err) => {
    if (typeof err === "string") {
      throw new Error(err);
    }
    // Otherwise rethrow the error
    throw err;
  });

export default i18n;
