import { StyleSheet } from "react-native";

import { Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  addItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: VerticalScale(32),
  },
  textBoxContainerExternalStyle: {
    width: "50%",
  },
  titleContainer: {
    marginTop: VerticalScale(24),
  },
  amountContainer: {
    flex: 1,
  },
  unitContainer: {
    flex: 2,
    marginRight: Scale(10),
  },
  textInputContainer: {
    marginTop: 6,
  },
  RecipeMacros: {
    marginTop: VerticalScale(32),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default styles;
