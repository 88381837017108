// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { getLogger } from "loglevel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Text, TouchableOpacity, View } from "react-native";
import Swiper from "react-native-web-swiper";

import CommonButton from "../commons/CommonButtons";
import CommonText from "../commons/CommonText";
import { constants, Routes, walkThroughList, width } from "../constants";
import backendApi from "../services/backendApi";
import logger from "../services/logger";
import styles from "./OnBoardingScreenStyle";

const { useFoodFoodListQuery } = backendApi;

type ParamList = {
  LoginScreen: undefined;
  OnboardingScreen: undefined;
};

type OnboardingProps = {
  navigation: NativeStackNavigationProp<ParamList, "OnboardingScreen">;
};

const OnboardingScreen = ({ navigation }: OnboardingProps): JSX.Element => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNavigation = (): void => {
    navigation.navigate(Routes.LoginScreen as keyof ParamList);
  };

  // TODO: This is just here for testing purposes
  const { data, error, isLoading } = useFoodFoodListQuery({});

  if (isLoading) {
    logger.debug("Loading...");
  } else if (error) {
    logger.debug("FoodList error: ", error);
  } else {
    logger.debug("FoodList result: ", data);
  }

  return (
    <View style={styles.swiperAlign}>
      <View style={styles.loginContainer}>
        <TouchableOpacity style={styles.loginTouchableContainer} onPress={handleNavigation}>
          <CommonText title={"Login"} externalTextStyle={styles.externalTextStyle} />
        </TouchableOpacity>
      </View>
      <View
      // style={[styles.imageBackground, { backgroundColor: walkThroughList[activeIndex].bgColor }]}
      />
      {/* <Swiper
        containerStyle={{ flex: 3 }}
        loop={false}
        controlsProps={{
          prevTitle: "",
          nextTitle: "",
          dotsTouchable: true,
          dotsPos: "center",
          dotActiveStyle: styles.activeDotStyle,
          dotProps: {
            badgeStyle: styles.badgeStyle,
          },
        }}
        onIndexChanged={(index: number) => {
          setActiveIndex(index);
        }}
      >
        {walkThroughList.map((item) => (
          <View key={item.id} style={styles.walkThroughListContainer}>
            <Image
              source={item.title === "Welkom!" ? item.image : item.icon}
              style={[
                styles.swipeImages,
                item.title === "Welkom!" && {
                  width: width / 2,
                  marginBottom: 0,
                  height: width / 2,
                },
              ]}
            />
            <CommonText title={item?.title} externalTextStyle={styles.titleText} />
            <Text style={styles.discriptionText}>{item.description}</Text>
            {item?.button && (
              <CommonButton
                title={t("Volgende")}
                onPress={handleNavigation}
                externalContainerStyle={styles.buttonContainer}
              />
            )}
          </View>
        ))}
      </Swiper> */}
    </View>
  );
};

export default OnboardingScreen;
