export enum CheckoutParamStates {
  CHECKOUT = "checkout",
  CHECKOUT_SUCCESS = "success",
  CHECKOUT_FAILURE = "fail",
}

export enum CheckoutState {
  NotInCheckout = "NotInCheckout",
}

export type CheckoutFlowState = CheckoutState | CheckoutParamStates;

export const CHECKOUT_QUERY_PARAM_NAME = "checkout";
export const CHECKOUT_PRICE_ID_PARAM_NAME = "priceId";
