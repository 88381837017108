// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import Matercolor from "matercolors";
import type { ITheme } from "native-base";
import { extendTheme, theme as defaultTheme } from "native-base";

import { getOrganisation } from "../helpers/userHelpers";
import type { Brand, User } from "../services/backendTypes";

const APP_DEFAULT_PRIMARY_COLOUR = "#58CA8C";

export function createThemeFromColour(colour: string | undefined): ITheme {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const appDefaultPalette = new Matercolor(APP_DEFAULT_PRIMARY_COLOUR);

  let palette = appDefaultPalette;

  // If the user's coach (or the coach themselves) has custom branding, use that instead of the default
  if (colour) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      palette = new Matercolor(colour);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  // NOTE: We adjust it because this becomes primary.400, but NativeBase default is 600. So we lighten it a bit.
  // We move all values above 200 down by 2 places if the primary colour is not black

  let primaryColourPalette = {
    ...palette,
  };

  if (colour !== "#000000") {
    primaryColourPalette = {
      ...palette,
      200: palette[50],
      300: palette[100],
      400: palette[200],
      500: palette[300],
      600: palette[400],
      700: palette[500],
      800: palette[600],
      900: palette[700],
    };
  }

  const theme: ITheme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: primaryColourPalette,
      orange: {
        50: "#FFC910",
        100: "#FFC920",
        200: "#FFC930",
        300: "#FFC940",
        400: "#FFC950",
        500: "#FFC960",
        600: "#FFC920",
        700: "#FFC980",
        800: "#FFC990",
        900: "#FFC9A0",
      },
      amber: {
        50: "#fff2db",
        100: "#ffdab0",
        200: "#E9BB91",
        300: "#fbac50",
        400: "#f99420",
        500: "#df7a06",
        600: "#ae5f02",
        700: "#7d4400",
        800: "#4c2800",
        900: "#1e0c00",
      },
      // NOTE: Some of the old colours that are used frequently are commented here for reference
      muted: {
        50: "#e7f5fe",
        100: "#cddce3", // borderColor = "#CBD9E0",
        200: "#b1c3cd",
        300: "#94abb8", // darkgrey2 = "#A0B5C0",
        400: "#7794a2", // textColor: "#718A96" // greyTextColor = "#718A96",  // darkGreyTextColor = "#78909C",
        500: "#5e7a89",
        600: "#475f6b",
        700: "#32444e", // largeTextColor = "#28414D",
        800: "#1b2a31",
        900: "#001014",
      },
      red: {
        50: "#ffe7e9",
        100: "#f6bdc2",
        200: "#eb949b",
        300: "#e16b73",
        400: "#d7414a",
        500: "#be2832",
        600: "#b7272f",
        700: "#6b141b",
        800: "#420a0e",
        900: "#1c0002",
      },
      gray: {
        ...defaultTheme.colors.gray,
        // NOTE: We will need to consolidate when doing later issues
        50: "#F7FAFC", // grey = "#F7FAFC",
        100: "#E2E9F0", // greyBgColor = "#E2E9F0",
        200: "#a1a1aa",
        300: "#A0B5C0", // smallTextColor = "#A0B5C0",
        500: "#718A96", // sectionTextColor = "#4A5E68",
        600: "#4A5E68",
        700: "#CBD9E0",
        900: "#e5e5e5",
      },
      blue: {
        ...defaultTheme.colors.blue,
        100: "#092939",
        200: "#28414D", // blueTextColor = "#5896CA",
      },
    },
    fontConfig: {
      DMSans: {
        400: {
          normal: "DMSans_400Regular",
        },
        500: {
          normal: "DMSans_500Medium",
        },
        700: {
          normal: "DMSans_700Bold",
        },
      },
    },
    // TODO: Perhaps change this when we use extendTheme
    // fonts: {
    //   heading: "DMSans_700Bold",
    //   body: "DMSans_400Regular",
    //   mono: "DMSans_400Regular",
    // },
    config: {
      initialColorMode: "light",
    },
  };

  return extendTheme(theme);
}

export const getTheme = (user: User | null, brandIfNotLoggedIn: Brand | undefined): ITheme => {
  let primaryColour;
  if (user) {
    const organisation = getOrganisation(user);
    primaryColour = organisation?.primary_colour || undefined;
  } else {
    // Not logged in
    primaryColour = brandIfNotLoggedIn?.primary_colour || undefined;
  }

  return createThemeFromColour(primaryColour);
};

// Old colours for reference
// enum Colours {
//   primaryGreenColor = "#58CA8C",
//   secondaryGreenColor = "#8ADAAF",
//   whiteColor = "#FFFFFF",
//   largeTextColor = "#28414D",
//   disableButton = "#E2EBF0",
//   textColor = "#718A96",
//   bgColor = "#ffffff",
//   disableColor = "#A1B5C0",
//   grey = "#F7FAFC",
//   greyTextColor = "#718A96",
//   darkGreyTextColor = "#78909C",
//   sectionTextColor = "#4A5E68",
//   lightBrownColor = "#E1BD97",
//   darkgrey2 = "#A0B5C0",
//   white = "#FFF",
//   bgEmerald = "#8ADAAF",
//   bgPurple = "#9C9BDF",
//   bgBlizzardBlue = "#8AD1DA",
//   bgTangerine = "#E9BB91",
//   borderColor = "#CBD9E0",
//   descriptionTextColor = "#092939",
//   blueTextColor = "#5896CA",
//   smallTextColor = "#A0B5C0",
//   lightGreen = "#F3FBF7",
//   swiperColor = "#105A49",
//   grayBorder = "#EDF4F7",
//   tipColor = "#58ca8c1a",
//   blueColor = "#2D44A0",
//   pink = "#CD8DA5",
//   greyBgColor = "#E2E9F0",
// }
