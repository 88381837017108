import convert from "convert-units";
import _ from "lodash";
import type { ITheme } from "native-base";
import React from "react";
import { Text, View } from "react-native";

import type {
  GeneratedRecipeMealIngredient,
  GroceryListItemUpdate as GroceryListItem,
  Ingredient,
  SuggestedServing,
  User,
} from "../services/backendTypes";
import { formatNumberAsDecimal, formatNumberAsWholeNumberForGroceryList } from "./generalHelpers";

export function getIngredientInWeightStringForDisplay(
  ingredientInGrams: string,
  isServingInGrams: boolean,
  shouldWeUseImperial: boolean = false
): string {
  if (isServingInGrams) {
    // Serving already in grams so we don't want to display it again
    return "";
  }

  if (!ingredientInGrams) {
    return "";
  }

  if (shouldWeUseImperial) {
    return "";
  }

  return ingredientInGrams;
}

/**
 *
 * @param suggestedServing
 * @param ingredient
 * @returns `{
    servingDescription: '1 tbsp',
    servingInGrams: '17g',
    nonEditableDescription: '2 tbsp',
    ingredientInGrams: '34g'
  }`
 */
export function getServingDescriptionText(
  suggestedServing: SuggestedServing,
  ingredient?: Ingredient | GeneratedRecipeMealIngredient,
  shouldWeUseImperial: boolean = false
): {
  servingInGrams: string;
  nonEditableDescription: string;
  ingredientInGrams: string | undefined;
  isServingInGrams: boolean;
  unit: string;
} {
  let servingDescription = suggestedServing ? suggestedServing.serving_description : "";

  const isServingInGrams = servingDescription.endsWith(" g");

  if (ingredient && servingDescription === ingredient.name) {
    servingDescription = "";
  }

  const servingInGrams = suggestedServing.one_serving_in_metric_value
    ? `${suggestedServing.one_serving_in_metric_value}g`
    : "";

  // NOTE: This was previous code for a non-editable description. It is here if it is of use for other functionality
  const quantityOfServingDescription = parseFloat(servingDescription);
  const isQuantityOfServingDescriptionANumber = !Number.isNaN(quantityOfServingDescription);

  let unit = _(servingDescription).split(" ").tail().join(" ");

  // https://regex101.com/r/BStwwN/1
  const GRAMS_SERVING_DESCRIPTION_REGEX = /[0-9]+g$/;
  if (GRAMS_SERVING_DESCRIPTION_REGEX.test(servingDescription)) {
    unit = "g";
  }

  let nonEditableDescription = servingDescription;
  let ingredientInGrams;
  if (ingredient) {
    if (suggestedServing.one_serving_in_metric_value) {
      ingredientInGrams = `${formatNumberAsDecimal(
        ingredient.quantity * suggestedServing.one_serving_in_metric_value,
        2
      )}g`;
    }

    const quantityForDisplay = parseFloat(formatNumberAsDecimal(ingredient.quantity * quantityOfServingDescription, 2));

    nonEditableDescription = !isQuantityOfServingDescriptionANumber
      ? `${ingredient.quantity} ${_.truncate(servingDescription)}`
      : `${quantityForDisplay} ${unit}`;

    // NOTE: 0.66 is rounded to 0.67 so we add it here
    const normalNumberFactors = [1, 0.5, 0.25, 0.33, 0.66, 0.67];
    const quantityForDisplayIsNotAUsualNumber = !_.some(
      normalNumberFactors,
      (factor) => quantityForDisplay % factor !== 0
    );
    const quantityForDisplayIsNotAWholeNumber = quantityForDisplay % 1 !== 0;

    if (quantityForDisplayIsNotAWholeNumber && quantityForDisplayIsNotAUsualNumber && ingredientInGrams) {
      nonEditableDescription = ingredientInGrams;
    }

    const ingredientInWeightStringForDisplay = getIngredientInWeightStringForDisplay(
      ingredientInGrams || "",
      isServingInGrams,
      shouldWeUseImperial
    );

    if (ingredientInWeightStringForDisplay) {
      nonEditableDescription = `${nonEditableDescription} (${ingredientInWeightStringForDisplay})`;
    }
  }

  return { servingInGrams, nonEditableDescription, ingredientInGrams, isServingInGrams, unit };
}

export function shouldWeUseImperialForThisUser(user: User): boolean {
  if (user.uses_imperial_measurements || user.is_coach) {
    return Boolean(user.uses_imperial_measurements);
  }

  if (!user.coach) {
    return Boolean(user.uses_imperial_measurements);
  }

  return Boolean(user.coach.uses_imperial_measurements);
}

export function createIngredientDescriptionComponent(
  ingredient: Ingredient | GeneratedRecipeMealIngredient,
  theme: ITheme,
  user: User | null
): JSX.Element {
  if (!ingredient.suggested_serving) {
    console.warn(
      `Ingredient ${
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        ingredient.id || "GENERATED_RECIPE_MEAL_INGREDIENT"
      } does not have a suggested_serving. This is unexpected and should be investigated.`
    );

    return <></>;
  }

  const { unit, ingredientInGrams, isServingInGrams } = getServingDescriptionText(
    ingredient.suggested_serving,
    ingredient
  );
  const doNotShowIngredientInGrams = user ? shouldWeUseImperialForThisUser(user) : false;

  return (
    <View style={{ flexDirection: "row" }}>
      {/* NOTE: This empty view is required to make the two elements stack vertically */}
      <View>
        {!isServingInGrams ? (
          <Text style={{ color: theme.colors.gray["500"] }} testID="servingDescription">
            {_.truncate(unit)}{" "}
          </Text>
        ) : null}
        {ingredientInGrams && !doNotShowIngredientInGrams ? (
          <Text style={{ color: theme.colors.gray["500"] }} testID="ingredientInGrams">
            {` (${ingredientInGrams})`}
          </Text>
        ) : null}
      </View>
    </View>
  );
}

export function groceryListItemIsLiquid(suggestedServing: SuggestedServing): boolean {
  /**
   * NOTE: The backend converts all liquid measurements to ml (because most Foods don't have imperial SuggestedServings)
   */

  return suggestedServing.measurement_description === "ml";
}

export function formatGroceryListItemQuantity(quantity: number): string {
  const formatted = formatNumberAsWholeNumberForGroceryList(quantity);

  if (formatted === "0") {
    return "1";
  }

  return formatted;
}

export const getGroceryListItemInWeight = (item: GroceryListItem, shouldUseImperial = false): string => {
  // Eg, eggs have no "unit"
  const itemWithoutUnit = item.name === item.suggested_serving.measurement_description;
  if (itemWithoutUnit || !item.suggested_serving.one_serving_in_metric_value) return String(item.quantity);

  const itemTotalInMetric = item.quantity * item.suggested_serving.one_serving_in_metric_value;

  if (shouldUseImperial) {
    if (groceryListItemIsLiquid(item.suggested_serving)) {
      const itemTotalInMetricVolume = convert(itemTotalInMetric).from("ml").to("fl-oz");
      return `${formatGroceryListItemQuantity(itemTotalInMetricVolume)} fl oz`;
    }

    const itemTotalInMetricMass = convert(itemTotalInMetric).from("g").to("oz");
    return `${formatGroceryListItemQuantity(itemTotalInMetricMass)} oz`;
  }

  return `${formatNumberAsWholeNumberForGroceryList(itemTotalInMetric)} g`;
};

export function isInstagramExternalSource(url: string): boolean {
  return url.includes("instagram.com");
}
