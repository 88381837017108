import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  itemContainer: {
    marginVertical: 8,
  },
  labelText: {
    // color: Colors.textColor,
    fontSize: 16,
    fontWeight: "bold",
  },
  caloriesLabelText: {
    // color: Colors.textColor,
    fontSize: 12,
    fontWeight: "bold",
  },
  caloriesValueText: {
    // color: Colors.largeTextColor,
    fontSize: 14,
    textAlign: "center",
  },
  valueText: {
    // color: Colors.textColor,
    fontSize: 16,
    textAlign: "center",
  },
  imgContainer: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    // backgroundColor: Colors.grey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
});

export default styles;
