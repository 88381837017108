// NOTE: THIs file is not used

import type { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";

import { CommonButton, CommonText } from "../commons";
import { Images, Routes } from "../constants";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import styles from "./FinishPlanningStyles";

type Props = NativeStackScreenProps<RootStackParamList, Routes.FinishPlanningScreen>;

const FinishPlanningScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();

  const handleNavigation = (): void => {
    // navigation.navigate(Routes.GroceriesList);
  };
  return (
    <View style={styles.wrapper}>
      <View style={styles.titleContainer}>
        <CommonText title={"“Failing to plan is planning to fail” - Me"} externalTextStyle={styles.headingFirst} />
        <CommonText title={"Not what happened to you!"} externalTextStyle={styles.headingSecond} />
        <CommonText title={"Time to get the groceries in your home."} externalTextStyle={styles.headingThird} />
      </View>
      <Image source={Images.MeditationIcon} style={styles.meditationIcon} />

      <View style={styles.buttonContainer}>
        <CommonButton title={"Create a grocery list"} onPress={handleNavigation} />
      </View>
    </View>
  );
};

export default FinishPlanningScreen;
