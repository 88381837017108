import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { onboardingDataSelector, onboardingSlice } from "../../slices/onboardingSlice";
import { IntolerancesEnum, IntoleranceType } from "../../types";
import SelectionScreen, { Option } from "./SelectionScreen";

interface IntoleranceScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const IntoleranceScreen: React.FC<IntoleranceScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onboardingData = useSelector(onboardingDataSelector);

  const intoleranceOptions: Option[] = [
    {
      label: t("onboarding.onboarding_9_intolerance_screen.no_intolerance"),
      description: "",
      value: IntolerancesEnum.NO_INTOLERANCE,
      icon: "✅", // Check mark
      testID: `intolerance-option-${IntolerancesEnum.NO_INTOLERANCE}`,
    },
    {
      label: t("onboarding.onboarding_9_intolerance_screen.lactose"),
      description: "",
      value: IntolerancesEnum.LACTOSE,
      icon: "🚫🥛", // Milk
      testID: `intolerance-option-${IntolerancesEnum.LACTOSE}`,
    },
    {
      label: t("onboarding.onboarding_9_intolerance_screen.crustaceans_and_shellfish"),
      description: "",
      value: IntolerancesEnum.CRUSTACEANS_AND_SHELLFISH,
      icon: "🚫🦞", // Shrimp
      testID: `intolerance-option-${IntolerancesEnum.CRUSTACEANS_AND_SHELLFISH}`,
    },
    {
      label: t("onboarding.onboarding_9_intolerance_screen.gluten"),
      description: "",
      value: IntolerancesEnum.GLUTEN,
      icon: "🚫🍞", // Bread
      testID: `intolerance-option-${IntolerancesEnum.GLUTEN}`,
    },
    {
      label: t("onboarding.onboarding_9_intolerance_screen.nuts"),
      description: "",
      value: IntolerancesEnum.NUTS,
      icon: "🚫🥜", // Peanuts
      testID: `intolerance-option-${IntolerancesEnum.NUTS}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedIntolerances: Yup.array()
      .of(Yup.mixed<IntoleranceType>().oneOf(Object.values(IntolerancesEnum)).defined())
      .test(
        "no-intolerance-exclusive",
        t("onboarding.onboarding_9_intolerance_screen.no_intolerance_exclusive"),
        (value) => {
          if (!value) return true;
          if (value.includes(IntolerancesEnum.NO_INTOLERANCE) && value.length > 1) {
            return false;
          }
          return true;
        }
      )
      .required(t("onboarding.onboarding_9_intolerance_screen.select_intolerances")),
  });
  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    dispatch(
      onboardingSlice.actions.setDietPreferences({
        ...onboardingData.dietPreferences,
        intolerances: values.selectedIntolerances,
      })
    );

    navigation.navigate(Routes.IngredientDislikesScreen);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({ selectedIntolerances: [IntolerancesEnum.NO_INTOLERANCE] })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue, errors, touched }) => (
        <SelectionScreen
          title={t("onboarding.onboarding_9_intolerance_screen.any_intolerance")}
          subtitle={t("onboarding.onboarding_9_intolerance_screen.select_all_applicable")}
          options={intoleranceOptions}
          multipleSelection={true}
          selectedOptions={values.selectedIntolerances}
          setSelectedOptions={(selectedOptions) => {
            setFieldValue("selectedIntolerances", selectedOptions);
          }}
          onPressNext={handleSubmit}
          onPressBack={() => navigation.goBack()}
          errorMessage={errors.selectedIntolerances && touched.selectedIntolerances ? errors.selectedIntolerances : ""}
        />
      )}
    </Formik>
  );
};

export default IntoleranceScreen;
