import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button, Icon, Input, useTheme } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { calculateDailyTotalForMacro } from "../../helpers/coachHelpers";
import { onboardingDataSelector, onboardingSlice, OnboardingState } from "../../slices/onboardingSlice";
import { diyFormSelector } from "../../slices/userSlice";
import type { Macros } from "../../types";
import { getBalancedMacros, updateMacrosInState } from "./onboardingHelpers";

enum UnitEnum {
  GRAMS = "g",
  PERCENT = "%",
}
type Unit = "g" | "%";

interface ModifyNutritionalPlanScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const CALORIES_IN_ONE_GRAM_OF_PROTEIN = 4;
const CALORIES_IN_ONE_GRAM_OF_FAT = 9;
const CALORIES_IN_ONE_GRAM_OF_CARBOHYDRATE = 4;
/**
 *
 * @param percent eg "15", "20.5"
 * @param calories
 * @param macroType
 * @returns
 */
function convertMacroInPercentToGrams(
  percent: string,
  calories: number,
  macroType: "protein" | "fat" | "carbohydrates"
): number {
  const factor = macroType === "fat" ? CALORIES_IN_ONE_GRAM_OF_FAT : CALORIES_IN_ONE_GRAM_OF_PROTEIN;
  return (parseFloat(percent) * calories) / 100 / factor;
}

const ModifyNutritionalPlanScreen: React.FC<ModifyNutritionalPlanScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const [unit, setUnit] = useState<Unit>(UnitEnum.GRAMS);
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const onboardingData = useSelector(onboardingDataSelector);
  const diyFormValues = useSelector(diyFormSelector);

  const initialMacros = diyFormValues
    ? {
        calories: calculateDailyTotalForMacro("kcal", diyFormValues.mss),
        protein: calculateDailyTotalForMacro("protein", diyFormValues.mss),
        carbohydrates: calculateDailyTotalForMacro("carbohydrates", diyFormValues.mss),
        fat: calculateDailyTotalForMacro("fat", diyFormValues.mss),
      }
    : {
        calories: 0,
        protein: 0,
        carbohydrates: 0,
        fat: 0,
      };

  type MacrosForm = {
    calories: number;
    protein: number;
    carbohydrates: number;
    fat: number;
  };

  const [macros, setMacros] = useState<MacrosForm>({
    ...initialMacros,
  });

  const macrosAsPercent: {
    protein: number;
    carbohydrates: number;
    fat: number;
  } = {
    protein: parseFloat((((macros.protein * CALORIES_IN_ONE_GRAM_OF_PROTEIN) / macros.calories) * 100).toFixed(2)),
    carbohydrates: parseFloat(
      (((macros.carbohydrates * CALORIES_IN_ONE_GRAM_OF_CARBOHYDRATE) / macros.calories) * 100).toFixed(2)
    ),
    fat: parseFloat((((macros.fat * CALORIES_IN_ONE_GRAM_OF_FAT) / macros.calories) * 100).toFixed(2)),
  };

  const validationSchema = Yup.object().shape({
    calories: Yup.number()
      .required(t("onboarding.onboarding_7_modify_nutrition_plan.validation_error_calories"))
      .positive(t("onboarding.onboarding_7_modify_nutrition_plan.positive_calories"))
      .min(1250, t("onboarding.onboarding_7_modify_nutrition_plan.min_calories")),
    protein: Yup.number()
      .required(t("onboarding.onboarding_7_modify_nutrition_plan.validation_error_protein"))
      .positive(t("onboarding.onboarding_7_modify_nutrition_plan.positive_protein"))
      .min(50, t("onboarding.onboarding_7_modify_nutrition_plan.min_protein")),
    carbohydrates: Yup.number()
      .required(t("onboarding.onboarding_7_modify_nutrition_plan.validation_error_carbohydrates"))
      .positive(t("onboarding.onboarding_7_modify_nutrition_plan.positive_carbohydrates")),
    fat: Yup.number()
      .required(t("onboarding.onboarding_7_modify_nutrition_plan.validation_error_fats"))
      .positive(t("onboarding.onboarding_7_modify_nutrition_plan.positive_fats"))
      .min(30, t("onboarding.onboarding_7_modify_nutrition_plan.min_fats", { grams: 30 })),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const handleUnitChange = (newUnit: Unit): void => {
    if (newUnit === UnitEnum.PERCENT) {
      const totalCalories = macros.calories;
      const proteinPercent = Math.round(((macros.protein * CALORIES_IN_ONE_GRAM_OF_PROTEIN) / totalCalories) * 100);
      const fatPercent = Math.round(((macros.fat * CALORIES_IN_ONE_GRAM_OF_FAT) / totalCalories) * 100);
      const carbsPercent = 100 - proteinPercent - fatPercent;

      const newProtein = (proteinPercent / 100) * (totalCalories / CALORIES_IN_ONE_GRAM_OF_PROTEIN);
      const newFat = (fatPercent / 100) * (totalCalories / CALORIES_IN_ONE_GRAM_OF_FAT);
      const newCarbs = (carbsPercent / 100) * (totalCalories / CALORIES_IN_ONE_GRAM_OF_CARBOHYDRATE);

      setMacros({
        ...macros,
        protein: newProtein,
        fat: newFat,
        carbohydrates: newCarbs,
      });
    }
    setUnit(newUnit);
  };

  type MacroName = "protein" | "carbohydrates" | "fat";
  const handleMacroChange = (name: MacroName, value: string): void => {
    const newValue = parseFloat(value);
    if (Number.isNaN(newValue) || newValue < 0) {
      return;
    }

    let newProtein = macros.protein;
    let newCarbohydrates = macros.carbohydrates;
    let newFats = macros.fat;

    if (name === "protein") {
      newCarbohydrates = macros.carbohydrates - (newValue - macros.protein);
      newProtein = newValue;
    } else if (name === "fat") {
      newCarbohydrates = macros.carbohydrates - (newValue - macros.fat);
      newFats = newValue;
    } else if (name === "carbohydrates") {
      // Carbs are not allowed to be directly
      return;
    }

    setMacros({
      ...macros,
      protein: newProtein,
      carbohydrates: newCarbohydrates,
      fat: newFats,
    });
  };

  const handleCaloriesChange = (value: string): void => {
    if (value === "") {
      setMacros({
        ...macros,
        calories: 0,
        protein: 0,
        carbohydrates: 0,
        fat: 0,
      });
      return;
    }

    const newKcal = parseFloat(value);
    const oldKcal = macros.calories;

    let newProtein = (macros.protein * newKcal) / oldKcal;
    let newCarbohydrates = (macros.carbohydrates * newKcal) / oldKcal;
    let newFats = (macros.fat * newKcal) / oldKcal;

    if (
      Number.isNaN(newProtein) ||
      Number.isNaN(newCarbohydrates) ||
      Number.isNaN(newFats) ||
      newProtein < 0 ||
      newCarbohydrates < 0 ||
      newFats < 0
    ) {
      const balancedMacros = getBalancedMacros(newKcal, onboardingData);
      newProtein = balancedMacros.protein;
      newCarbohydrates = balancedMacros.carbs;
      newFats = balancedMacros.fat;
    }

    setMacros({
      ...macros,
      calories: newKcal,
      protein: Number(newProtein.toFixed(2)),
      carbohydrates: Number(newCarbohydrates.toFixed(2)),
      fat: Number(newFats.toFixed(2)),
    });
  };

  /**
   *
   * @param macroType Note that you cannot edit carbohydrates directly
   * @param value
   * @param calories
   * @returns
   */
  function handlePercentChange(macroType: "protein" | "fat", value: string, calories: number): void {
    const parsedValue = parseFloat(value);

    if (Number.isNaN(parsedValue)) {
      // Handle empty or invalid input by setting to zero
      if (macroType === "protein") handleMacroChange("protein", "0");
      if (macroType === "fat") handleMacroChange("fat", "0");
      return;
    }

    const newProteinPercent = macroType === "protein" ? parseFloat(value) : macrosAsPercent.protein;
    const newFatPercent = macroType === "fat" ? parseFloat(value) : macrosAsPercent.fat;

    if (newProteinPercent < 0 || newFatPercent < 0) return;

    // Calculate the remaining percentage for carbohydrates
    const remainingPercent = 100 - newProteinPercent - newFatPercent;

    // Convert percentages to grams
    const newProteinGrams = convertMacroInPercentToGrams(newProteinPercent.toString(), calories, "protein");
    const newFatGrams = convertMacroInPercentToGrams(newFatPercent.toString(), calories, "fat");
    const newCarbGrams = convertMacroInPercentToGrams(remainingPercent.toString(), calories, "carbohydrates");

    setMacros({
      ...macros,
      protein: newProteinGrams,
      fat: newFatGrams,
      carbohydrates: newCarbGrams,
    });
  }

  function convertMacrosToIntegers(values: FormSchema): {
    calories: number;
    protein: number;
    fat: number;
    carbohydrates: number;
  } {
    return {
      calories: parseInt(String(values.calories), 10),
      protein: parseInt(String(values.protein), 10),
      carbohydrates: parseInt(String(values.carbohydrates), 10),
      fat: parseInt(String(values.fat), 10),
    };
  }

  const onSubmit = (values: FormSchema): void => {
    const updatedOnboardingData: OnboardingState = {
      ...onboardingData,
      dietPreferences: {
        ...onboardingData.dietPreferences,
        dietType: "CHOOSE_YOUR_OWN",
        userProvidedMacros: convertMacrosToIntegers(values),
      },
    };
    dispatch(onboardingSlice.actions.setDietPreferences(updatedOnboardingData.dietPreferences));

    updateMacrosInState(dispatch, updatedOnboardingData);

    navigation.navigate(Routes.NutritionalPlanScreen);
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <Icon
          name="arrow-back"
          size={24}
          onPress={() => navigation.goBack()}
          tvParallaxProperties={undefined}
          testID="back-button"
        />
      </View>
      <ScrollView contentContainerStyle={[styles.content, { justifyContent: "center" }]}>
        <Text style={[styles.title, { color: theme.colors?.primary }]}>
          {t("onboarding.onboarding_7_modify_nutrition_plan.title")}
        </Text>
        <View style={styles.formContainer}>
          <Text style={styles.subtitle}>{t("onboarding.onboarding_7_modify_nutrition_plan.subtitle")}</Text>
          <Formik
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initialValues={macros}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
              <>
                <Input
                  placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_calories")}
                  value={values.calories === 0 ? "" : String(values.calories)}
                  onChangeText={(text) => {
                    setFieldValue("calories", text);
                    handleCaloriesChange(text);
                  }}
                  keyboardType="numeric"
                  inputStyle={styles.input}
                  errorMessage={errors.calories && touched.calories ? errors.calories : ""}
                  autoCompleteType={"off"}
                  inputContainerStyle={styles.inputContainer}
                  containerStyle={styles.containerStyle}
                  testID="calories-input"
                />
                {/* Grams/percent radio switch */}
                <>
                  <Text style={styles.radioTitle}>
                    {t("onboarding.onboarding_7_modify_nutrition_plan.set_macros_based_on")}
                  </Text>
                  <View style={styles.radioGroupContainer}>
                    <View style={styles.radioButtonContainer}>
                      <TouchableOpacity
                        style={[
                          styles.radioButton,
                          unit === UnitEnum.GRAMS && {
                            backgroundColor: theme.colors?.primary,
                          },
                        ]}
                        onPress={() => handleUnitChange(UnitEnum.GRAMS)}
                        testID="grams-radio-button"
                      >
                        <Text style={[styles.radioButtonText, unit === UnitEnum.GRAMS && styles.radioButtonTextActive]}>
                          {t("onboarding.onboarding_7_modify_nutrition_plan.gram")}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.radioButton,
                          unit === UnitEnum.PERCENT && {
                            backgroundColor: theme.colors?.primary,
                          },
                        ]}
                        onPress={() => handleUnitChange(UnitEnum.PERCENT)}
                        testID="percent-radio-button"
                      >
                        <Text
                          style={[styles.radioButtonText, unit === UnitEnum.PERCENT && styles.radioButtonTextActive]}
                        >
                          {t("onboarding.onboarding_7_modify_nutrition_plan.percentage")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </>
                {unit === UnitEnum.GRAMS ? (
                  <>
                    <Text style={styles.inputLabel}>{t("onboarding.onboarding_7_modify_nutrition_plan.protein")}</Text>
                    <Input
                      placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_protein")}
                      value={String(values.protein)}
                      onChangeText={(text) => {
                        setFieldValue("protein", text);
                        handleMacroChange("protein", text);
                      }}
                      keyboardType="numeric"
                      inputContainerStyle={styles.inputContainer}
                      containerStyle={styles.containerStyle}
                      inputStyle={styles.input}
                      errorMessage={errors.protein && touched.protein ? errors.protein : ""}
                      rightIcon={<Text style={styles.unitText}>{UnitEnum.GRAMS}</Text>}
                      autoCompleteType={"off"}
                      testID="protein-input"
                    />
                    <Text style={styles.inputLabel}>{t("onboarding.onboarding_7_modify_nutrition_plan.fats")}</Text>
                    <Input
                      placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_fats")}
                      value={String(values.fat)}
                      onChangeText={(text) => {
                        setFieldValue("fat", text);
                        handleMacroChange("fat", text);
                      }}
                      keyboardType="numeric"
                      inputContainerStyle={styles.inputContainer}
                      containerStyle={styles.containerStyle}
                      inputStyle={styles.input}
                      errorMessage={errors.fat && touched.fat ? errors.fat : ""}
                      rightIcon={<Text style={styles.unitText}>{UnitEnum.GRAMS}</Text>}
                      autoCompleteType={"off"}
                      testID="fat-input"
                    />
                    <Text style={styles.inputLabel}>
                      {t("onboarding.onboarding_7_modify_nutrition_plan.carbohydrates")}
                    </Text>
                    <Input
                      placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_carbohydrates")}
                      value={String(values.carbohydrates)}
                      keyboardType="numeric"
                      inputContainerStyle={styles.inputContainer}
                      containerStyle={styles.containerStyle}
                      inputStyle={styles.input}
                      errorMessage={errors.carbohydrates && touched.carbohydrates ? errors.carbohydrates : ""}
                      rightIcon={<Text style={styles.unitText}>{UnitEnum.GRAMS}</Text>}
                      autoCompleteType={"off"}
                      testID="carbohydrates-input"
                    />
                  </>
                ) : (
                  <>
                    <Text style={styles.inputLabel}>{t("onboarding.onboarding_7_modify_nutrition_plan.protein")}</Text>
                    <Input
                      placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_protein")}
                      value={String(macrosAsPercent.protein)}
                      onChangeText={(text) => handlePercentChange("protein", text, values.calories)}
                      keyboardType="numeric"
                      inputContainerStyle={styles.inputContainer}
                      containerStyle={styles.containerStyle}
                      inputStyle={styles.input}
                      errorMessage={errors.protein && touched.protein ? errors.protein : ""}
                      rightIcon={<Text style={styles.unitText}>{UnitEnum.PERCENT}</Text>}
                      autoCompleteType={"off"}
                      selectTextOnFocus
                      testID="protein-percent-input"
                    />
                    <Text style={styles.inputLabel}>{t("onboarding.onboarding_7_modify_nutrition_plan.fats")}</Text>
                    <Input
                      placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_fats")}
                      value={String(macrosAsPercent.fat)}
                      onChangeText={(text) => handlePercentChange("fat", text, values.calories)}
                      keyboardType="numeric"
                      inputContainerStyle={styles.inputContainer}
                      containerStyle={styles.containerStyle}
                      inputStyle={styles.input}
                      errorMessage={errors.fat && touched.fat ? errors.fat : ""}
                      rightIcon={<Text style={styles.unitText}>{UnitEnum.PERCENT}</Text>}
                      autoCompleteType={"off"}
                      selectTextOnFocus
                      testID="fat-percent-input"
                    />
                    <Text style={styles.inputLabel}>
                      {t("onboarding.onboarding_7_modify_nutrition_plan.carbohydrates")}
                    </Text>
                    <Input
                      placeholder={t("onboarding.onboarding_7_modify_nutrition_plan.enter_carbohydrates")}
                      value={String(macrosAsPercent.carbohydrates)}
                      // NOTE: You cannot edit carbohydrates directly
                      onChangeText={() => {}}
                      keyboardType="numeric"
                      inputContainerStyle={styles.inputContainer}
                      containerStyle={styles.containerStyle}
                      inputStyle={styles.input}
                      errorMessage={errors.carbohydrates && touched.carbohydrates ? errors.carbohydrates : ""}
                      rightIcon={<Text style={styles.unitText}>{UnitEnum.PERCENT}</Text>}
                      autoCompleteType={"off"}
                      testID="carbohydrates-percent-input"
                    />
                  </>
                )}
                <Button
                  title={t("onboarding.onboarding_7_modify_nutrition_plan.to_adjust")}
                  buttonStyle={[styles.updateButton, { backgroundColor: theme.colors?.primary }]}
                  onPress={() => handleSubmit()}
                  testID="update-button"
                />
              </>
            )}
          </Formik>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f0f0f0",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
    marginVertical: 32,
  },
  content: {
    paddingHorizontal: 24,
  },
  formContainer: {
    backgroundColor: "#F7F7F7",
    borderRadius: 12,
    padding: 24,
    marginHorizontal: 24,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 16,
  },
  inputLabel: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 24,
    textAlign: "left",
  },
  input: {
    textAlign: "left",
    color: "#333",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderBottomWidth: 0,
    fontSize: 16,
  },
  inputContainer: {
    marginVertical: 12,
    borderRadius: 8,
    backgroundColor: "#fff",
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
  },
  containerStyle: {
    borderBottomWidth: 0,
    borderBottomColor: "transparent",
  },
  unitText: {
    color: "#999",
    marginRight: 12,
  },
  updateButton: {
    paddingVertical: 16,
    marginTop: 32,
    borderRadius: 12,
  },
  radioTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 24,
    marginBottom: 8,
  },
  radioGroupContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 8,
    overflow: "hidden",
  },
  radioButtonContainer: {
    flexDirection: "row",
  },
  radioButton: {
    flex: 1,
    paddingVertical: 12,
    alignItems: "center",
    backgroundColor: "#f0f0f0",
  },
  radioButtonText: {
    fontSize: 14,
    color: "#333",
  },
  radioButtonTextActive: {
    color: "#fff",
  },
});

export default ModifyNutritionalPlanScreen;
