// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

import { CommonButton, CommonDateTextInput, CommonPageHeader, CommonTextInput } from "../commons";
import { commonStyles, Routes, VerticalScale } from "../constants";

type ParamList = {
  PersonalDetailsScreen: undefined;
  ProfileStartScreen: undefined;
  AddGenderScreen: undefined;
};
type Props = {
  navigation: NativeStackNavigationProp<ParamList, "PersonalDetailsScreen">;
};

const PersonalDetailsScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    weight: 70,
  });

  const handleChange = (name: string, value: string | boolean): void => {
    setValues({ ...values, [name]: value });
  };

  const { firstName, lastName, birthYear, birthMonth, birthDay, weight } = values;

  return (
    <View style={commonStyles.container}>
      <CommonPageHeader
        title={"Complete your profile"}
        onPressBack={() => {
          navigation.goBack();
        }}
        onPressClose={() => {
          navigation.navigate(Routes.ProfileStartScreen as keyof ParamList);
        }}
      />
      <View style={commonStyles.mainContainer}>
        <View style={{ marginVertical: VerticalScale(50) }}>
          <Text style={commonStyles.titleText24Bold}>{"Your personal details"}</Text>
        </View>

        <CommonTextInput
          title={"First Name"}
          placeholder=""
          value={firstName}
          onChangeText={(val: string) => handleChange("firstName", val)}
          textWrapperExternalStyle={{ marginHorizontal: 0 }}
        />

        <CommonTextInput
          title={"Last Name"}
          placeholder=""
          value={lastName}
          onChangeText={(val: string) => handleChange("firstName", val)}
          textWrapperExternalStyle={{ marginHorizontal: 0 }}
        />

        <View>
          <Text>{"Birthday"}</Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <CommonDateTextInput
              value={birthDay}
              placeholder={"DD"}
              maxLength={2}
              onChangeText={(val: string) => {
                handleChange("birthDay", val);
              }}
            />

            <CommonDateTextInput
              value={birthMonth}
              placeholder={"MM"}
              maxLength={2}
              onChangeText={(val: string) => {
                handleChange("birthMonth", val);
              }}
            />

            <CommonDateTextInput
              value={birthYear}
              placeholder={"YYYY"}
              maxLength={4}
              onChangeText={(val: string) => {
                handleChange("birthYear", val);
              }}
            />
          </View>
        </View>

        <CommonTextInput
          title={"Weight (in kg)"}
          placeholder={""}
          value={weight}
          onChangeText={(val: string) => handleChange("weight", val)}
          textWrapperExternalStyle={{ marginHorizontal: 0 }}
          textInputProps={{
            keyboardType: "number-pad",
            returnKeyType: "done",
          }}
        />
      </View>
      <View style={commonStyles.bottomContainer}>
        <CommonButton
          title={"Next"}
          onPress={() => {
            navigation.push(Routes.AddGenderScreen as keyof ParamList);
          }}
        />
      </View>
    </View>
  );
};

export default PersonalDetailsScreen;
