import type { NativeStackScreenProps } from "@react-navigation/native-stack";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import {
  AlertDialog,
  Button,
  Center,
  Column,
  Divider,
  Flex,
  FormControl,
  HStack,
  Image,
  Input,
  Modal,
  Row,
  ScrollView,
  Spinner,
  Switch,
  Text,
  theme,
  useDisclose,
  View,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, SafeAreaView, StyleProp, useWindowDimensions, ViewStyle } from "react-native";
import { NavigationState, Route, SceneMap, SceneRendererProps, TabBar, TabView } from "react-native-tab-view";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import type { RequiredArraySchema } from "yup/lib/array";
import type { MixedSchema } from "yup/lib/mixed";
import type { Assign, ObjectShape, TypeOfShape } from "yup/lib/object";
import type { RequiredStringSchema } from "yup/lib/string";
import type { AnyObject } from "yup/lib/types";

import { CommonIconButton } from "../commons";
import { commonStyles, Images, isDesktopScreen, KG_TO_LBS, Routes, Scale, VerticalScale } from "../constants";
import { calculateDailyTotalForMacro, getNutritionDayPlanOverviewComponent } from "../helpers/coachHelpers";
import { shouldWeUseImperialForThisUser } from "../helpers/foodHelpers";
import {
  formatNumberAsDecimal,
  formatNumberAsPercentage,
  formatNumberAsWholeNumber,
  formatUserForDisplay,
  isMobilePlatform,
} from "../helpers/generalHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { NutritionDayPlan, User, UserProfile } from "../services/backendTypes";
import type { DaysForNutritionPlan } from "../services/legacyNutritionCalculations7";
import logger from "../services/logger";
import { calculateEnergyExpenditure } from "../services/nutritionCalculations";
import {
  DEFAULT_NUTRITION_PLAN_NAME,
  getDefaultLegacyInput,
  getLegacyInputFromNutritionPlan,
} from "../services/nutritionCalculations7";
import { plannerSlice } from "../slices/plannerSlice";
import { clientsSelector, userSelector, userSlice } from "../slices/userSlice";
import type { DayOfWeekString } from "../types";
import styles from "./CoachModeClientInfoScreenStyle";
import MyProgressScreen from "./MyProgressScreen";

const {
  useUsersClientsRetrieveQuery,
  useUsersClientsPartialUpdateMutation,
  useUsersWeeklyNutritionPlanUpdateMutation,
} = backendApi;

// TODO: Use native-base instead
const nutritionCardStyle: StyleProp<ViewStyle> = {
  backgroundColor: "white",
  borderRadius: 8,
  borderWidth: 1,
  borderStyle: "solid",
  padding: Scale(12),
  marginTop: VerticalScale(24),
};

// TODO: This should be in a helpers/constants file
const ALL_DAYS: DayOfWeekString[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const daysSchema = Yup.object().shape({
  name: Yup.string().required(),
  daysInNutritionPlan: Yup.array()
    .of(Yup.mixed<DayOfWeekString>().oneOf(ALL_DAYS).default("monday"))
    .required()
    .min(1)
    .default([]),
});

// NOTE: Yup recommends using empty interfaces instead of types
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DaysSchema extends Yup.InferType<typeof daysSchema> {}

const EVERY_DAY: DaysForNutritionPlan = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

const convertDaysArrayToDaysForNutritionPlan = (days: DayOfWeekString[]): DaysForNutritionPlan => {
  const daysForNutritionPlan: DaysForNutritionPlan = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  days.forEach((day) => {
    daysForNutritionPlan[day] = true;
  });

  return daysForNutritionPlan;
};

// TODO: Put in helpers
const dayOfWeekStringToIndex = (dayOfWeekString: DayOfWeekString): number => {
  switch (dayOfWeekString) {
    case "monday":
      return 1;
    case "tuesday":
      return 2;
    case "wednesday":
      return 3;
    case "thursday":
      return 4;
    case "friday":
      return 5;
    case "saturday":
      return 6;
    case "sunday":
      return 0;
    default:
      throw new Error("This should never happen");
  }
};

const createUserProfileFieldComponent = (fieldName: string, fieldValue: string): JSX.Element => (
  <Row>
    <Text textAlign={"left"} bold>{`${fieldName}: `}</Text>
    <Text textAlign={"left"} fontSize={14}>
      {fieldValue}
    </Text>
  </Row>
);

const NutritionDayPlanInfoForm = ({
  initialValues,
  onSubmit,
  nutritionDayPlan,
  isCreateNewPlanForm,
  disableDaySwitches = true,
  isLoadingBackendUpdateCall = false,
}: {
  initialValues: TypeOfShape<
    Assign<
      ObjectShape,
      {
        name: RequiredStringSchema<string | undefined, AnyObject>;
        daysInNutritionPlan: RequiredArraySchema<
          MixedSchema<DayOfWeekString, AnyObject, DayOfWeekString>,
          AnyObject,
          DayOfWeekString[]
        >;
      }
    >
  >;
  onSubmit: (values: DaysSchema, nutritionDayPlan: NutritionDayPlan) => Promise<void> | void;
  nutritionDayPlan?: NutritionDayPlan;
  isCreateNewPlanForm: boolean;
  disableDaySwitches?: boolean;
  isLoadingBackendUpdateCall?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  if (isLoadingBackendUpdateCall) {
    return <Spinner />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={daysSchema}
      // NOTE: This Yup/Formik object is difficult to write a type for
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSubmit={(values) => onSubmit(values, nutritionDayPlan)}
    >
      {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, setFieldValue, errors, dirty, isValid }) => (
        <View mt="2">
          <Center>
            <FormControl isRequired isInvalid={!_.isEmpty(errors.name)}>
              <Input
                placeholder={t("nutrition_day_plan.update_nutrition_plan_form.name_input_field_placeholder")}
                onChangeText={handleChange("name")}
                onBlur={handleBlur("name")}
                value={values.name}
                minWidth="50%"
                testID={`nutritionplan-name-${nutritionDayPlan?.id || "NULL"}-input`}
              />
              {/* NOTE: This does not work. When the error message appears it causes a re-render
          of the form in its original state */}
              {/* <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage> */}
            </FormControl>
          </Center>

          <Row>
            {ALL_DAYS.map((day) => (
              <Column key={day}>
                <Text>{moment().day(dayOfWeekStringToIndex(day)).format("ddd")}</Text>
                <Switch
                  size="sm"
                  disabled={disableDaySwitches}
                  value={values.daysInNutritionPlan.includes(day)}
                  onToggle={() => {
                    const updatedDays = values.daysInNutritionPlan.includes(day)
                      ? values.daysInNutritionPlan.filter((d) => d !== day)
                      : [...values.daysInNutritionPlan, day];

                    setFieldValue("daysInNutritionPlan", updatedDays);
                  }}
                  mr="-1"
                  testID={`${day}-switch-${nutritionDayPlan?.id || "NULL"}`}
                />
              </Column>
            ))}
          </Row>

          <Center>
            <Button
              onPress={() => handleSubmit()}
              isDisabled={isSubmitting || !isValid || !dirty}
              isLoading={isLoadingBackendUpdateCall}
              mt="2"
              width="40%"
              testID={`updateDaysOnNutritionPlan-${nutritionDayPlan?.id || "NULL"}-button`}
            >
              <Text>
                {t(
                  isCreateNewPlanForm
                    ? "nutrition_day_plan.create_new_nutrition_plan_button_text"
                    : "nutrition_day_plan.update_nutrition_plan_button_text"
                )}
              </Text>
            </Button>
          </Center>
        </View>
      )}
    </Formik>
  );
};

const NutritionPlansComponent = ({
  client,
  onPressUpdateOrCreateNutritionDayPlan,
  onOpenAddAdditionalNDPDialog,
  onPressDeleteNutritionPlan,
  onSubmitUpdateNameAndDaysForm,
  isLoadingBackendUpdateCall,
}: {
  client: User;
  onPressUpdateOrCreateNutritionDayPlan: (
    daysForNutritionPlan: DaysForNutritionPlan,
    name: string,
    nutritionDayPlan?: NutritionDayPlan
  ) => void;
  onOpenAddAdditionalNDPDialog: () => void;
  onSubmitUpdateNameAndDaysForm: (values: DaysSchema, nutritionDayPlan: NutritionDayPlan) => Promise<void>;
  onPressDeleteNutritionPlan: (nutritionPlanId: number) => void;
  isLoadingBackendUpdateCall?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  if (!client.intake) return <></>;

  if (!client.intake?.weekly_nutrition_plan) return <></>;

  const mondayId = client.intake?.weekly_nutrition_plan?.monday.id;
  if (!mondayId) {
    throw new Error("client.intake?.weekly_nutrition_plan?.monday.id was undefined");
  }
  const getDayId = (day: DayOfWeekString, intake: UserProfile): number =>
    intake.weekly_nutrition_plan?.[day]?.id || mondayId;
  const nutritionPlans: { [D in DayOfWeekString]: number } = {
    monday: mondayId,
    tuesday: getDayId("tuesday", client.intake),
    wednesday: getDayId("wednesday", client.intake),
    thursday: getDayId("thursday", client.intake),
    friday: getDayId("friday", client.intake),
    saturday: getDayId("saturday", client.intake),
    sunday: getDayId("sunday", client.intake),
  };

  const nutritionPlanGroupsRaw = _.groupBy(Object.entries(nutritionPlans), ([, nutritionPlanId]) => nutritionPlanId);
  const nutritionPlanGroups = _.mapValues(nutritionPlanGroupsRaw, (array) =>
    _.map(array, ([day]) => day as DayOfWeekString)
  );
  const moreThanOneNutritionPlan = _.size(nutritionPlanGroups) > 1;

  const addAnAdditionalNutritionPlanComponent = (
    <Flex mt="5" mb="5">
      <Button
        onPress={onOpenAddAdditionalNDPDialog}
        testID={"createAdditionalNutritionPlan-button"}
        nativeID={"createAdditionalNutritionPlanButton"}
      >
        <Text color="white">{`+ ${t("nutrition_day_plan.add_an_additional_nutrition_plan")}`}</Text>
      </Button>
    </Flex>
  );

  const createNutritionPlanGroupComponent = (days: DayOfWeekString[], nutritionPlanId: string): JSX.Element => {
    const firstDay = days[0];

    if (!firstDay) {
      throw new Error(`No first day found in days array: ${_.toString(days)}`);
    }

    if (!client.intake) {
      return <></>;
    }

    const nutritionDayPlan = client.intake.weekly_nutrition_plan?.[firstDay];

    if (!nutritionDayPlan) {
      return <></>;
    }

    const initialValues = daysSchema.cast({
      name: nutritionDayPlan.name,
      daysInNutritionPlan: days,
    });

    return nutritionDayPlan ? (
      <Flex
        key={nutritionPlanId}
        style={nutritionCardStyle}
        borderColor="muted.100"
        testID={"nutritionPlanOverview-div"}
        nativeID="nutritionPlanOverviewDiv"
      >
        <Text nativeID="nutritionPlanTab">{t("coach_mode_top_tabs.nutrition_plan_tab_name")}</Text>
        <Center>
          <NutritionDayPlanInfoForm
            initialValues={initialValues}
            onSubmit={onSubmitUpdateNameAndDaysForm}
            nutritionDayPlan={nutritionDayPlan}
            disableDaySwitches={!moreThanOneNutritionPlan}
            isLoadingBackendUpdateCall={isLoadingBackendUpdateCall}
            isCreateNewPlanForm={false}
          />
        </Center>

        <Flex mt={"2"} style={styles.recipeMacrosContainer}>
          {getNutritionDayPlanOverviewComponent(nutritionDayPlan.meal_slot_specifications)}
        </Flex>

        <Button
          mt={"2"}
          onPress={() =>
            onPressUpdateOrCreateNutritionDayPlan(
              convertDaysArrayToDaysForNutritionPlan(days),
              nutritionDayPlan?.name || t("nutrition_day_plan.default_nutrition_plan_name"),
              nutritionDayPlan
            )
          }
          testID={"editNutritionPlan-button"}
        >
          {t("nutrition_day_plan.edit_button_text")}
        </Button>

        {moreThanOneNutritionPlan ? (
          <Button
            mt="2"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={() => onPressDeleteNutritionPlan(parseInt(nutritionPlanId, 10))}
            testID={`deleteNutritionPlan-${nutritionDayPlan.id || "THIS_SHOULD_NEVER_HAPPEN"}-button`}
          >
            {t("nutrition_day_plan.delete_nutrition_plan_button_text")}
          </Button>
        ) : null}
      </Flex>
    ) : (
      <></>
    );
  };

  const sortedKeys = _.sortBy(_.keys(nutritionPlanGroups));

  const calculateTotalEnergyOfNutritionPlan = (daysArray: DayOfWeekString[]): number => {
    if (daysArray.length === 0) {
      return 0;
    }
    const day = daysArray[0];

    if (!day) throw new Error("day was undefined, this should never happen");

    const nutritionPlan = client?.intake?.weekly_nutrition_plan?.[day];

    if (!nutritionPlan?.meal_slot_specifications) return 0;
    const totalEnergyOfNutritionPlan = calculateDailyTotalForMacro("kcal", nutritionPlan?.meal_slot_specifications);

    return totalEnergyOfNutritionPlan * _.size(daysArray);
  };
  const averageEnergyIntakeAcrossWeek = _.sum(_.map(nutritionPlanGroups, calculateTotalEnergyOfNutritionPlan)) / 7;

  const tdee = calculateEnergyExpenditure(client.intake)?.daily.total;

  const averageEnergyIntakeComponent = tdee ? (
    <Column>
      <Center>
        <Text>
          {t("nutrition_day_plan.average_energy_across_week_label", {
            weekly_average: formatNumberAsWholeNumber(averageEnergyIntakeAcrossWeek),
          })}
        </Text>
        <Text>
          {t("nutrition_day_plan.average_energy_balance_across_week_label", {
            energy_balance_percentage: formatNumberAsPercentage(averageEnergyIntakeAcrossWeek / tdee),
          })}
        </Text>
      </Center>
    </Column>
  ) : (
    <></>
  );

  return (
    <>
      {moreThanOneNutritionPlan ? averageEnergyIntakeComponent : null}
      {_.map(sortedKeys, (nutritionPlanId) => {
        const daysArray = nutritionPlanGroups[nutritionPlanId];

        if (!daysArray) {
          return <></>;
        }

        return createNutritionPlanGroupComponent(daysArray, nutritionPlanId);
      })}
      {_.size(nutritionPlanGroups) < 2 ? addAnAdditionalNutritionPlanComponent : null}
    </>
  );
};

type Props = NativeStackScreenProps<RootStackParamList, Routes.CoachModeClientInfoScreen>;
const CoachModeClientInfoScreen = ({
  navigation,
  route: {
    params: { clientId },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isOpen: isOpenDeactivateDialog,
    onOpen: onOpenDeactivateDialog,
    onClose: onCloseDeactivateDialog,
  } = useDisclose();

  const {
    isOpen: isOpenAddAdditionalNDPDialog,
    onOpen: onOpenAddAdditionalNDPDialog,
    onClose: onCloseAddAdditionalNDPDialog,
  } = useDisclose();

  const layout = useWindowDimensions();

  const coach = useSelector(userSelector);

  const [patchUserBackendCall, { isLoading: isLoadingUpdateUserOnBackend }] = useUsersClientsPartialUpdateMutation();
  const {
    isLoading: isLoadingClientRetrieveQuery,
    error: clientRetrieveQueryError,
    data: client,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useUsersClientsRetrieveQuery({ id: clientId });

  const [updateWeeklyNutritionPlanOnBackend, { isLoading: isLoadingUpdateWnpOnBackend }] =
    useUsersWeeklyNutritionPlanUpdateMutation();
  const isLoadingBackendUpdateCall = isLoadingUpdateUserOnBackend || isLoadingUpdateWnpOnBackend;

  const deactivateClientDialogRef = React.useRef(null);

  const [nutritionPlanDeletionId, setNutritionPlanDeletionId] = useState<number>();
  const {
    onOpen: onOpenNutritionPlanDeleteConfirmationModal,
    onClose: onCloseNutritionPlanDeleteConfirmationModal,
    isOpen: isNutritionPlanDeleteConfirmationModalOpen,
  } = useDisclose();
  const nutritionPlanDeleteRef = useRef(null);

  const isDesktop = isDesktopScreen();

  enum ClientViewTab {
    NUTRITION_PLAN = 0,
    ABOUT = 1,
    PROGRESS = 2,
    SETTINGS = 3,
  }
  const [index, setIndex] = React.useState<ClientViewTab>(ClientViewTab.NUTRITION_PLAN);
  const routes: Route[] = [
    {
      key: String(ClientViewTab.NUTRITION_PLAN),
      title: t("coach_mode_top_tabs.nutrition_plan_tab_name"),
      testID: "viewClientTab-nutritionPlan",
    },
    {
      key: String(ClientViewTab.PROGRESS),
      title: t("coach_mode_top_tabs.progress_tab_name"),
      testID: "viewClientTab-progress",
    },
    {
      key: String(ClientViewTab.SETTINGS),
      title: t("coach_mode_top_tabs.settings_tab_name"),
      testID: "viewClientTab-settings",
    },
  ];

  if (!client) {
    // NOTE: This should never happen - send an error to Sentry
    if (isLoadingClientRetrieveQuery && !clientRetrieveQueryError) {
      return <Spinner />;
    }
    return <Text>{"coach_mode_client_settings.client_not_found_error_text"}</Text>;
  }

  const DailyEnergyItem = ({
    label,
    value,
    imgSrc,
  }: {
    label: string;
    value: string;
    imgSrc?: ImageSourcePropType;
  }): JSX.Element => (
    <View>
      <Image alt={"TDEE-element"} source={imgSrc} style={{ width: Scale(35), height: Scale(35) }} />
      <Text>{label}</Text>
      <Text style={[commonStyles.sectionText16Bold, { marginTop: VerticalScale(2) }]}>{value}</Text>
    </View>
  );

  const onPressUpdateOrCreateNutritionDayPlan = (
    daysForNutritionPlan: DaysForNutritionPlan,
    name: string,
    nutritionDayPlan?: NutritionDayPlan
  ): void => {
    if (!client) {
      throw new Error("Client not found");
    }

    if (!client.intake) {
      throw new Error("Client intake not found");
    }

    const initialLegacyInput = nutritionDayPlan
      ? getLegacyInputFromNutritionPlan(client.intake, nutritionDayPlan, daysForNutritionPlan)
      : getDefaultLegacyInput(client.intake, daysForNutritionPlan, name);

    dispatch(userSlice.actions.storeClientNutritionPlan({ clientId, plan: initialLegacyInput }));

    navigation.navigate(Routes.EditNutritionDayPlanStack, {
      screen: Routes.EnergyBalanceTab1,
      params: { client, nutritionPlanId: initialLegacyInput.id },
    });
  };

  const onViewClientPlan = (): void => {
    dispatch(plannerSlice.actions.resetCalendarDays());
    dispatch(userSlice.actions.setViewAsUser(client));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.push(Routes.DiaryViewScreen);
  };

  let tdee = -1;
  let rmr = -1;
  if (client.intake) {
    // TODO: Add in exercise calories in later MR
    const energyExpenditure = calculateEnergyExpenditure(client.intake);

    if (!energyExpenditure) {
      throw new Error("No energy expenditure calculated");
    }

    tdee = energyExpenditure.daily.total;
    rmr = energyExpenditure.daily.bmr;
  }

  function onPressUpdateUserProfile(): void {
    if (!client) {
      throw new Error("Client not found");
    }

    navigation.navigate(Routes.EditUserProfileScreen, { client });
  }

  function convertAndFormatWeight(weight: number, usesImperialMeasurements: boolean): string {
    const convertedWeight = weight * (usesImperialMeasurements ? KG_TO_LBS : 1);
    return convertedWeight.toFixed(2);
  }

  const MacroProfileRoute = (): JSX.Element => {
    if (!client) {
      throw new Error("No client found");
    }

    if (!client.intake) {
      return (
        <Center mt="3">
          <Text bold>{"No intake completed"}</Text>

          <Button
            mt={"2"}
            variant={"outline"}
            onPress={onPressUpdateUserProfile}
            testID={"updateUserProfile-button"}
            nativeID={"createUserProfileButton"}
            disabled={!client}
          >
            {t("coach_mode_view_client.create_user_profile_button_text")}
          </Button>
        </Center>
      );
    }

    const userProfileInfoComponent = (
      <>
        <Text bold fontSize={16}>
          {t("coach_mode_view_client.user_profile_view_heading")}
        </Text>

        {createUserProfileFieldComponent(
          t("coach_mode_create_new_client.full_name_label_text"),
          `${client.first_name || ""} ${client.last_name || ""}`
        )}

        {createUserProfileFieldComponent(t("coach_mode_create_new_client.email_label_text"), client?.email || "")}

        {createUserProfileFieldComponent(
          t("coach_mode_create_new_client.gender_title_text"),
          t(`general.gender.${client.intake.gender}`)
        )}

        {createUserProfileFieldComponent(
          t("coach_mode_create_new_client.diet_title_text"),
          t(`general.diets.${client.intake.diet}`)
        )}

        {createUserProfileFieldComponent(
          t("coach_mode_create_new_client.activity_title_text"),
          t(`general.activity.${client.intake.activity}`)
        )}

        {createUserProfileFieldComponent(
          t("coach_mode_create_new_client.weight_title_text"),
          convertAndFormatWeight(client.intake.weight, coach?.uses_imperial_measurements || false)
        )}

        {createUserProfileFieldComponent(
          t("coach_mode_create_new_client.body_fat_percentage_title_text"),
          formatNumberAsPercentage(client.intake.body_fat_percentage)
        )}
      </>
    );

    const onSubmitUpdateNameAndDaysForm = async (
      values: DaysSchema,
      nutritionDayPlan: NutritionDayPlan
    ): Promise<void> => {
      const weeklyNutritionPlanId = client?.intake?.weekly_nutrition_plan?.id;

      if (!weeklyNutritionPlanId) {
        throw new Error("weeklyNutritionPlanId is not set");
      }

      if (!nutritionDayPlan) {
        throw new Error("nutritionDayPlan is not set");
      }

      await updateWeeklyNutritionPlanOnBackend({
        id: weeklyNutritionPlanId,
        weeklyNutritionPlanUpdateRequest: {
          name: values.name,
          nutrition_day_plan_id: nutritionDayPlan.id,
          days_to_update_to_nutrition_day_plan_id: values.daysInNutritionPlan,
        },
      });
    };

    const onPressDeleteNutritionPlan = (nutritionPlanId: number): void => {
      setNutritionPlanDeletionId(nutritionPlanId);
      onOpenNutritionPlanDeleteConfirmationModal();
    };

    const onPressDeleteNutritionPlanAfterConfirmation = async (nutritionPlanId: number): Promise<void> => {
      const weeklyNutritionPlanId = client?.intake?.weekly_nutrition_plan?.id;

      if (!weeklyNutritionPlanId) {
        throw new Error("weeklyNutritionPlanId is not set");
      }

      await updateWeeklyNutritionPlanOnBackend({
        id: weeklyNutritionPlanId,
        weeklyNutritionPlanUpdateRequest: {
          nutrition_day_plan_id: nutritionPlanId,
          days_to_update_to_nutrition_day_plan_id: [],
        },
      });
    };

    const nutritionPlanDeleteConfirmationModal = (
      <AlertDialog
        leastDestructiveRef={nutritionPlanDeleteRef}
        isOpen={isNutritionPlanDeleteConfirmationModalOpen}
        onClose={onCloseNutritionPlanDeleteConfirmationModal}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{t("general.dialog_confirmation_question")}</AlertDialog.Header>

          <AlertDialog.Footer>
            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={async (): Promise<void> => {
                onCloseNutritionPlanDeleteConfirmationModal();

                if (!nutritionPlanDeletionId) {
                  throw new Error("nutritionPlanDeletionId is not set");
                }

                setNutritionPlanDeletionId(undefined);
                try {
                  await onPressDeleteNutritionPlanAfterConfirmation(nutritionPlanDeletionId);
                } catch (error) {
                  Sentry.captureException(error);

                  alert(t("nutrition_day_plan.error_while_deleting_nutrition_plan_message"));
                }
              }}
              bg="red.600"
              testID={"nutritionPlanDeleteConfirmationModal-confirm-button"}
            >
              {t("nutrition_day_plan.delete_nutrition_plan_button_text")}
            </Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );

    const addAdditionalNutritionDayPlanDialog = (
      <Modal
        isOpen={isOpenAddAdditionalNDPDialog}
        onClose={onCloseAddAdditionalNDPDialog}
        size={isDesktop ? "md" : "full"}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>{t("nutrition_day_plan.add_an_additional_nutrition_plan")}</Modal.Header>

          <Modal.Body>
            <NutritionDayPlanInfoForm
              initialValues={daysSchema.cast({
                name: "",

                // TODO: Verify if we should default to the inverse of the other currently existing NDP
                daysInNutritionPlan: [],
              })}
              onSubmit={(values: DaysSchema) => {
                onCloseAddAdditionalNDPDialog();

                onPressUpdateOrCreateNutritionDayPlan(
                  convertDaysArrayToDaysForNutritionPlan(values.daysInNutritionPlan),
                  values.name
                );
              }}
              nutritionDayPlan={undefined}
              disableDaySwitches={false}
              isCreateNewPlanForm={true}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );

    const clientHasNoWeeklyNutritionPlan = !client?.intake?.weekly_nutrition_plan;
    const createFirstNutritionPlanComponent = (
      <Flex mt="5">
        <Button
          onPress={() => onPressUpdateOrCreateNutritionDayPlan(EVERY_DAY, DEFAULT_NUTRITION_PLAN_NAME)}
          testID={"createNutritionPlan-button"}
          nativeID={"createNutritionPlanButton"}
        >
          <Text fontSize="lg" color="white" bold>{`+ ${t("nutrition_day_plan.create_button_text")}`}</Text>
        </Button>
        <Text fontSize={16} color="gray.300" mt={4}>
          {t("nutrition_day_plan.precreation_comment_text")}
        </Text>
      </Flex>
    );

    const NutritionDayPlanOverviewComponent = (): JSX.Element => (
      <ScrollView style={commonStyles.paddingContainer}>
        <Flex
          style={{ ...nutritionCardStyle }}
          borderColor="muted.100"
          padding="2"
          nativeID={"nutritionDayPlanOverviewComponent"}
        >
          <Center>
            <Text bold fontSize={16}>
              {t("general.daily_energy_calculation.daily_energy_expenditure")}
            </Text>
            {/* Note: Not in scope */}
            {/* {!isDesktop && (
            <CommonIconButton onPress={onQuestionMarkPress} source={Images.QuestionMarkIcon} size={Scale(20)} />
          )} */}
          </Center>

          {/* TODO: We need to create the element (Nowak did not create it properly). Perhaps something less fancy? */}
          <Flex direction="row" justifyContent={"center"}>
            {/* eslint-disable-next-line max-len */}
            {/* <DailyEnergyItem imgSrc={Images.LifeStyle} label={t("general.daily_energy_calculation.rmr")} value={bmr} /> */}
            {/* NOTE: Is it really necessary to break this out? */}
            {/* <DailyEnergyItem imgSrc={Images.LifeStyle} label={t("Lifestyle")} value={"159"} />
          <DailyEnergyItem imgSrc={Images.Cardio} label={t("Cardio")} value={"136"} />
          <DailyEnergyItem imgSrc={Images.Digestion} label={t("Digestion")} value={"325"} /> */}
            <DailyEnergyItem
              label={t("general.daily_energy_calculation.total")}
              value={`${formatNumberAsWholeNumber(tdee)} ${t("general.kcal")}`}
            />
          </Flex>

          <Divider my="2" />

          {userProfileInfoComponent}

          <Center mt="3">
            <Button mt={"2"} variant={"outline"} onPress={onPressUpdateUserProfile} testID={"updateUserProfile-button"}>
              {t("coach_mode_view_client.update_user_profile_button_text")}
            </Button>
          </Center>
        </Flex>

        {!client.intake || clientHasNoWeeklyNutritionPlan ? (
          createFirstNutritionPlanComponent
        ) : (
          <NutritionPlansComponent
            client={client}
            onPressUpdateOrCreateNutritionDayPlan={onPressUpdateOrCreateNutritionDayPlan}
            onPressDeleteNutritionPlan={onPressDeleteNutritionPlan}
            onSubmitUpdateNameAndDaysForm={onSubmitUpdateNameAndDaysForm}
            onOpenAddAdditionalNDPDialog={onOpenAddAdditionalNDPDialog}
            isLoadingBackendUpdateCall={isLoadingBackendUpdateCall}
          />
        )}

        {addAdditionalNutritionDayPlanDialog}
      </ScrollView>
    );

    return isDesktop ? (
      <Flex
        flexDirection={"row"}
        justifyContent="center"
        style={{
          width: "50%",
          height: "100%",
          backgroundColor: "white",
          alignSelf: "center",
          marginTop: VerticalScale(33),
        }}
      >
        <Flex>
          <NutritionDayPlanOverviewComponent />
          {nutritionPlanDeleteConfirmationModal}
        </Flex>
      </Flex>
    ) : (
      <>
        <NutritionDayPlanOverviewComponent />
        {nutritionPlanDeleteConfirmationModal}
      </>
    );
  };
  const ProgressRoute = (): JSX.Element => (
    <View>
      <Center>
        <Text mt={"2"} bold>
          {/* This component is in NavigationStackParams as it is navigated to by the BottomNavigationStack */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <MyProgressScreen route={{ params: { viewAsUser: client } }} />
        </Text>
      </Center>
    </View>
  );

  const deactivateClientComponent = (
    <>
      <Button
        mt="3"
        // eslint-disable-next-line no-underscore-dangle
        onPress={onOpenDeactivateDialog}
        testID={"openDeactivateClientDialog-button"}
      >
        {t(
          client.account_enabled
            ? "coach_mode_client_settings.open_deactivate_client_modal_button_text"
            : "coach_mode_client_settings.open_activate_client_modal_button_text"
        )}
      </Button>
      {client.account_enabled ? (
        <Text fontSize={16} style={styles.marginVertical24}>
          {t("coach_mode_client_settings.deactivate_client_confirmation_message")}
        </Text>
      ) : null}
    </>
  );

  const isViewingSelf = coach && client.id === coach.id;

  const SettingViewMobile = (): JSX.Element => (
    <Flex style={commonStyles.paddingContainer}>
      {/* TODO: Decide what content is required in here and then delete commented code */}
      {/* <Image alt={"settings-icon"} source={Images.FilterIcon} style={styles.settingIcon} /> */}
      {/* <Text
        fontSize="16"
        color="gray.100"
        fontWeight="500"
        textAlign="center"
        style={{ marginTop: VerticalScale(24) }}
      >
        {t("Only the client can change their allergy and diet settings in the profile of their app.")}
      </Text>
      <Text fontSize={24} fontWeight={"700"} color="blue.100" style={styles.marginVertical24}>
        {t("Personal information")}
      </Text> */}
      <Center>
        <View mt="2">
          <Row>
            <Text textAlign={"left"} bold>{`${t("general.email_address")}: `}</Text>
            <Text textAlign={"left"} fontSize={14}>
              {client.email}
            </Text>
          </Row>

          <Row>
            <Text textAlign={"left"} bold>{`${t(
              "coach_mode_client_settings.client_active_status_modal.title_text"
            )}: `}</Text>
            <Text
              textAlign={"left"}
              fontSize={14}
              color={client.account_enabled ? theme.colors.primary["600"] : theme.colors.danger["600"]}
            >
              {`${client.account_enabled ? "Active" : "Inactive"}`}
            </Text>
          </Row>
          <Divider />
        </View>

        {isViewingSelf ? null : deactivateClientComponent}
      </Center>
    </Flex>
  );

  // TODO: Decide what settings are required and then delete commented code. Ideally reuse the mobile component
  // const SettingViewDesktop = (): JSX.Element => (
  //   <Flex
  //     flexDirection={"row"}
  //     justifyContent="center"
  //     style={{
  //       width: "50%",
  //       backgroundColor: "white",
  //       alignSelf: "center",
  //       marginVertical: VerticalScale(33),
  //       paddingVertical: VerticalScale(33),
  //     }}
  //   >
  //     <Flex style={{ flex: 1, paddingHorizontal: Scale(30) }}>
  //       <Text fontSize={24} fontWeight="Bold" color={"blue.100"} style={styles.marginVertical24}>
  //         {t("Personal information")}
  //       </Text>
  //       <Text fontSize={14} fontWeight="normal" color={"blue.100"}>
  //         {t("E-mail address")}
  //       </Text>
  //       <CommonPureTextInput
  //         placeholder={""}
  //         onChangeText={function (text: string): void {
  //           logger.debug("email", text);
  //         }}
  //       />
  //       <Text style={[commonStyles.bodyText16W500, styles.marginVertical24]}>
  //         {t("This button will archive the client and de-activate their macro profile:")}
  //       </Text>
  //       <CommonButton
  //         title={t("De-active client")}
  //         onPress={onOpenDeactivateDialog}
  //         externalContainerStyle={styles.deactiveButton}
  //         externalTextStyle={commonStyles.sectionText16Bold}
  //       />
  //     </Flex>
  //     <Flex style={{ flex: 1, paddingHorizontal: Scale(30) }}>
  //       <Text fontSize={16} fontWeight="Bold" color="blue.100" style={styles.marginVertical24}>
  //         {t("Dietary settings")}
  //       </Text>
  //       <Text
  //         fontSize="16"
  //         color="gray.100"
  //         fontWeight="500"
  //         textAlign="center"
  //         style={{ marginTop: VerticalScale(24) }}
  //       >
  //         {t("Only the client can change their allergy and diet settings in the profile of their app.")}
  //       </Text>
  //     </Flex>
  //   </Flex>
  // );

  const renderScene = SceneMap({
    [ClientViewTab.SETTINGS]: SettingViewMobile,
    [ClientViewTab.NUTRITION_PLAN]: MacroProfileRoute,
    [ClientViewTab.PROGRESS]: ProgressRoute,
  });

  // TODO: Not currently in scope. If in scope then replace this with native-base action sheet from the bottom
  // const ModalContent = (): JSX.Element => (
  //   <Flex>
  //     <View style={[commonStyles.paddingContainer, commonStyles.paddingVerticalContainer]}>
  //       <Flex flexDirection={"row"} justifyContent="space-between" alignItems={"center"}>
  //         <Text style={commonStyles.titleText16Bold}>{t("Calculation method")}</Text>
  //         <TouchableOpacity
  //           onPress={() => {
  //             console.log("Inside ModalContent onPress");

  //             if (modalizeRef && typeof modalizeRef === "object" && modalizeRef.current) {
  //               modalizeRef.current.close();
  //             } else {
  //               logger.error("modalizeRef Error");
  //             }
  //           }}
  //         >
  //           <Image alt={"close-icon"} source={Images.CloseIcon} style={{ width: Scale(12), height: Scale(12) }} />
  //         </TouchableOpacity>
  //       </Flex>
  //     </View>
  //     <Divider />
  //     <View style={[commonStyles.paddingContainer, commonStyles.paddingVerticalContainer]}>
  //       <Text
  //         fontSize={16}
  //         color="gray.100"
  //         fontWeight={"500"}
  //         lineHeight="22"
  //         textAlign={"center"}
  //         marginTop="5"
  //       >
  //         {t(
  // eslint-disable-next-line max-len
  //           "Based on the current personal details of the client. With the Katch & McArdle formula we estimated total calorie expenditure (based on total lean mass)."
  //         )}
  //       </Text>
  //     </View>
  //   </Flex>
  // );

  const shouldViewClientPlanOptionBeAvailable = Boolean(client.intake?.weekly_nutrition_plan?.tuesday);

  const Header = (): JSX.Element => (
    <Flex flexDirection={"row"} justifyContent="flex-start" alignItems={"center"}>
      <HStack>
        <CommonIconButton
          // NOTE: Not a valid error
          // eslint-disable-next-line react/prop-types
          onPress={() => navigation.popToTop()}
          source={Images.BackIcon}
          size={24}
          containerStyle={{ marginHorizontal: Scale(20) }}
          testID={"clientInfoScreen-back-button"}
        />

        <Text style={commonStyles.titleText20Bold}>{formatUserForDisplay(client)}</Text>
      </HStack>

      {shouldViewClientPlanOptionBeAvailable ? (
        <Button onPress={onViewClientPlan} testID={"clientInfoScreen-viewPlan-button"} m="2" size="sm">
          <Text style={[commonStyles.titleGreenText14, { fontWeight: "700" }]}>
            {t("coach_mode_view_client.view_plan_button_text")}
          </Text>
        </Button>
      ) : null}
    </Flex>
  );

  const TabsView = (): JSX.Element => {
    const TabBarView = (
      props: SceneRendererProps & {
        navigationState: NavigationState<{
          key: string;
          title: string;
        }>;
      }
    ): JSX.Element => (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <TabBar
        {...props}
        indicatorStyle={{ backgroundColor: theme.colors.primary["600"] }}
        style={{ backgroundColor: "white" }}
        activeColor={theme.colors.primary["600"]}
        inactiveColor={theme.colors.gray["400"]}
        renderLabel={({ route: tabRoute, color }) => (
          <Text textAlign={"center"} fontWeight={"medium"} fontSize={isDesktop ? 16 : 14}>
            {tabRoute.title}
          </Text>
        )}
      />
    );

    return (
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        renderTabBar={(props) => (
          <>
            {isDesktop ? (
              <Flex flexDirection={"row"} alignItems={"center"} background="white">
                <Header />
                <Flex style={{ width: "50%" }}>
                  {/* NOTE: The Route type has `title: string | undefined` but we always define it */}
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <TabBarView {...props} />
                </Flex>
              </Flex>
            ) : (
              // NOTE: The Route type has `title: string | undefined` but we always define it
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <TabBarView {...props} />
            )}
          </>
        )}
        style={{
          marginTop: VerticalScale(32),
          // backgroundColor: Colors.greyBgColor
          backgroundColor: theme.colors.gray["100"],
        }}
        initialLayout={{ width: layout.width }}
      />
    );
  };

  const SelectViewButton = ({ tab, lastButton = false }: { tab: ClientViewTab; lastButton?: boolean }): JSX.Element => (
    <Button
      onPress={() => {
        setIndex(tab);
      }}
      variant={index === tab ? "solid" : "subtle"}
      mr={lastButton ? "0" : "2"}
      testID={`viewClientTab-${_.camelCase(ClientViewTab[tab])}`}
    >
      {/* This works but I am unsure of how to get the compiler to realise it */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Text>{t(`coach_mode_client_settings.select_view_button.${ClientViewTab[tab]}`)}</Text>
    </Button>
  );

  const ClientInfoWithoutUsingTabs = (): JSX.Element => (
    <Flex flexDirection={"column"} background="white" ml="auto" mr="auto">
      <Center>
        <View style={{ width: "100%", maxWidth: Scale(600) }} mt="2" mb="4">
          <Header />
        </View>

        <Flex direction="row">
          <SelectViewButton tab={ClientViewTab.NUTRITION_PLAN} />
          <SelectViewButton tab={ClientViewTab.PROGRESS} />
          <SelectViewButton tab={ClientViewTab.SETTINGS} lastButton />
        </Flex>
      </Center>

      <>
        {index === ClientViewTab.NUTRITION_PLAN ? <MacroProfileRoute /> : null}
        {index === ClientViewTab.SETTINGS ? <SettingViewMobile /> : null}
        {index === ClientViewTab.PROGRESS ? <ProgressRoute /> : null}
      </>
    </Flex>
  );

  const headerComponent = isDesktop ? (
    <Flex flexDirection={"row"} justifyContent="space-between" alignItems={"center"} background="customRed.400">
      <View
        style={{
          paddingHorizontal: Scale(40),
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor: "white",
        }}
      />
    </Flex>
  ) : (
    <Flex
      flexDirection={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      style={[commonStyles.paddingContainer, { paddingVertical: VerticalScale(10) }]}
    >
      <CommonIconButton
        onPress={() => {
          logger.debug("onPress back button");
          navigation.popToTop();
        }}
        source={Images.BackIcon}
        size={Scale(20)}
        testID={"clientInfoScreen-back-button"}
      />
      {shouldViewClientPlanOptionBeAvailable ? (
        <Button onPress={onViewClientPlan} testID={"clientInfoScreen-viewPlan-button"} m="2" size="sm">
          <Text style={[commonStyles.titleGreenText14, { fontWeight: "700" }]}>
            {t("coach_mode_view_client.view_plan_button_text")}
          </Text>
        </Button>
      ) : null}
    </Flex>
  );

  const tabsComponent = isDesktop ? (
    <ClientInfoWithoutUsingTabs />
  ) : (
    <>
      <Flex
        flexDirection={"row"}
        justifyContent="flex-start"
        alignItems={"center"}
        style={{ ...commonStyles.paddingContainer, ...{ marginTop: VerticalScale(30) } }}
      >
        {/* TODO: Decide if we will implement this */}
        {/* eslint-disable-next-line max-len */}
        {/* <InitialsCircleAvatar label={"LL"} size={Scale(30)} backgroundColor={Colors.blueColor} textColor="white" /> */}
        <Text style={[{ marginLeft: Scale(16) }, commonStyles.titleText20Bold]}>{formatUserForDisplay(client)}</Text>
      </Flex>
      <TabsView />
    </>
  );

  const deactivateClientModal = (
    <AlertDialog
      leastDestructiveRef={deactivateClientDialogRef}
      isOpen={isOpenDeactivateDialog}
      onClose={onCloseDeactivateDialog}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{t("coach_mode_client_settings.client_active_status_modal.title_text")}</AlertDialog.Header>

        <AlertDialog.Footer>
          {client.account_enabled ? (
            <Text fontSize={16} textAlign={"center"}>
              {t("general.dialog_confirmation_question")}{" "}
              {t("coach_mode_client_settings.deactivate_client_confirmation_message")}
            </Text>
          ) : null}

          <Flex flexDirection={"row"} justifyContent={isDesktop ? "flex-end" : "center"} alignItems={"center"} mt="2">
            <Button
              onPress={() => {
                logger.debug("on press Cancel");
                onCloseDeactivateDialog();
              }}
              variant={"subtle"}
            >
              {t("general.cancel")}
            </Button>

            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={async () => {
                logger.debug("Inside onPress for activate dialog");

                await patchUserBackendCall({
                  id: client.id,
                  patchedUserRequest: {
                    account_enabled: !client.account_enabled,
                  },
                });

                onCloseDeactivateDialog();
              }}
              ml="2"
              colorScheme={"danger"}
              testID={"deactivateClient-button"}
              isLoading={isLoadingUpdateUserOnBackend}
            >
              {client.account_enabled
                ? t("coach_mode_client_settings.client_active_status_modal.deactivate_button_text")
                : t("coach_mode_client_settings.client_active_status_modal.activate_button_text")}
            </Button>
          </Flex>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  const commonContainerStyle = {
    backgroundColor: "white",
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
  };

  return (
    <View
      style={[
        isMobilePlatform() ? mobileContainerStyle : commonContainerStyle,
        { paddingTop: isMobilePlatform() ? 50 : 0 },
      ]}
    >
      {headerComponent}
      {tabsComponent}
      {deactivateClientModal}
    </View>
  );
};
export default CoachModeClientInfoScreen;
