import { StyleSheet } from "react-native";

import { Scale, VerticalScale } from "../constants";

const ImageSize = Scale(160);

const styles = StyleSheet.create({
  titleText: {
    fontSize: 24,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: Scale(24),
  },
  descriptionText: {
    // color: Colors.textColor,
    fontSize: 16,
    fontWeight: "500",
    lineHeight: VerticalScale(24),
  },
  bottomContainer: {
    bottom: 40,
  },
  profileAvatarImg: { width: ImageSize, height: ImageSize, borderRadius: 100 },
  editIconContainer: {
    // backgroundColor: Colors.primaryGreenColor,
    borderRadius: 50,
    width: Scale(50),
    height: Scale(50),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
  },
});

export default styles;
