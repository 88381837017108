import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import _ from "lodash";
import { Center, Flex, Icon, IconButton, Input, Spinner, Text, View } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, SafeAreaView } from "react-native";

import FoodSearchResult from "../components/FoodSearchResult";
import { commonStyles, Routes } from "../constants";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { Food, FoodSearchListApiResponse, SuggestedServing } from "../services/backendTypes";
import logger from "../services/logger";
import styles from "./IngredientSearchScreenStyle";

const { useFoodSearchListQuery, usePlannerCalendarDayPartialUpdateMutation } = backendApi;

// TODO: Rename this
type FlatListItem = {
  id: number;
  label: string;
  description: string;
};

// TODO: Remove, unused?
function convertFoodSearchResultsForFlatList(foods: FoodSearchListApiResponse | undefined): FlatListItem[] {
  const getDescriptionFromSuggestedServing = (firstSuggestedServing: SuggestedServing, food: Food): string =>
    // FIXME: Adjust the backend serializer to give back the serving_description
    `${firstSuggestedServing.kcal} kCal per ${firstSuggestedServing.quantity} ${food.name}`;

  const convertFoodToSearchResult = (food: Food): FlatListItem => {
    const firstSuggestedServing: SuggestedServing = _.get(food, "suggested_servings[0]");

    let description = "";
    if (!firstSuggestedServing) {
      logger.warn("No suggested servings found for food", food);
    } else {
      description = getDescriptionFromSuggestedServing(firstSuggestedServing, food);
    }

    if (!food.id) {
      throw new Error("Food id is missing");
    }

    return {
      id: food.id,
      label: food.name,
      description,
    };
  };

  return _.map(foods, convertFoodToSearchResult);
}

type Props = NativeStackScreenProps<RootStackParamList, Routes.AddIngredientScreen>;

const IngredientSearchScreen = ({
  navigation,
  route: {
    params: { onChoose, chooseMealMoment, mealSlotSpecifications },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  // NOTE: We will use the commented code below when we implement the edit meal feature
  // const [postMealToCalendarBody, setPostMealToCalendarBody] =
  //   useState<PlannerCalendarDayPartialUpdateApiArg>(skipToken);

  const onPressBack = (): void => {
    navigation.goBack();
  };

  type FoodFlatListItemProp = {
    item: Food;
    index: number;
  };

  // const onItemPress = (item: IngredientSearchListItemType): void => {
  //   logger.debug("Inside onItemPress with", item);
  //   // navigation.navigate(Routes.AddIngredientScreen as keyof IngredientSearchScreenParamList, item);

  //   // FIXME: Get calendarDay
  //   const postBody: PlannerCalendarDayPartialUpdateApiArg = { id: calendarDay.id };
  //   logger.debug("Updating postBody with", postBody);
  //   setPostMealToCalendarBody(postBody);
  // };

  // logger.trace("Inside IngredientSearchScreen with postMealToCalendarBody", postMealToCalendarBody);
  // usePlannerCalendarDayPartialUpdateMutation(postMealToCalendarBody);

  // TODO: We probably shouldn't trigger an API request if the query is empty
  const {
    data: foods,
    error,
    isLoading,
  } = useFoodSearchListQuery({ query: searchQuery }, { skip: searchQuery.length === 0 });

  if (error) {
    logger.error(error);
  }

  return (
    <SafeAreaView style={commonStyles.container}>
      <Flex direction="row" alignItems="flex-start" mt={4} ml="3">
        <IconButton
          onPress={onPressBack}
          icon={<Icon as={MaterialIcons} name="arrow-back-ios" />}
          size={"md"}
          color={"gray.300"}
          testID="food-search-screen-back-button"
        />
        <Input
          variant="unstyled"
          size="2xl"
          autoFocus
          placeholderTextColor={"gray.300"}
          placeholder={t("planner.add_product.search_prompt")}
          onChangeText={(text: string): void => {
            setSearchQuery(text);
          }}
          value={searchQuery}
          testID="foodSearchInput"
          nativeID="foodSearchInput"
        />

        {/* TODO: Barcode scanner: To be implemented */}
        {/* <IconButton
          onPress={() => alert("not implemented")}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          icon={<Ionicons name="barcode-sharp" size={32} color={theme.colors.primary["600"]} />}
          testID={"addOwnRecipeAddIngredientByBarcode-button"}
          disabled={!values.name}
        /> */}
      </Flex>

      <Flex mt="8" flex={1} px={"4"} nativeID="foodSearchResultsList">
        {!_.isEmpty(foods) ? (
          <FlatList
            data={foods}
            showsVerticalScrollIndicator={false}
            renderItem={({ item: food, index }: FoodFlatListItemProp) => (
              <FoodSearchResult
                food={food}
                index={index}
                onPress={(foodInner) => {
                  const params = { food: foodInner, onChoose, chooseMealMoment, mealSlotSpecifications };

                  navigation.navigate(Routes.AddIngredientScreen, params);
                }}
              />
            )}
            keyboardShouldPersistTaps="handled"
          />
        ) : (
          <View>
            <Center>
              {isLoading ? <Spinner color="primary.600" /> : null}
              {searchQuery.length > 0 && foods && !foods.length && !isLoading ? (
                <Text bold>{t("general.no_search_results")}</Text>
              ) : null}
            </Center>
          </View>
        )}
      </Flex>
    </SafeAreaView>
  );
};

export default IngredientSearchScreen;
