import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import PostOnboardingLoadingInterstitial from "../../components/PostOnboardingLoadingInterstitial";
import { Routes } from "../../constants";
import backendApi from "../../services/backendApi";
import { DietPreferences, onboardingDataSelector, onboardingSlice } from "../../slices/onboardingSlice";
import { diyFormSelector, userSelector } from "../../slices/userSlice";
import { type IngredientDislike, IngredientDislikesEnum } from "../../types";
import { doOrRedoNutritionPreferencesOnBackend } from "./onboardingHelpers";
import SelectionScreen, { Option } from "./SelectionScreen";

interface IngredientDislikesScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const {
  useUsersUserProfileUpdateMutation,
  useUsersUserProfileCreateMutation,
  useUsersNutritionDayPlanCreateMutation,
  useUsersWeeklyNutritionPlanUpdateMutation,
  useUsersMealSlotSpecificationCreateMutation,
  useUsersWeeklyNutritionPlanCreateMutation,
  useFoodIngredientToAvoidListQuery,
  useUsersSetIngredientsToAvoidCreateMutation,
  usePlannerAutoPlanDaysCreateMutation,
} = backendApi;

const IngredientDislikesScreen: React.FC<IngredientDislikesScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updateUserProfileOnBackend, { isLoading: isLoadingUpdateUserProfileOnBackend }] =
    useUsersUserProfileUpdateMutation();
  const [createUserProfileOnBackend, { isLoading: isLoadingCreateUserProfileOnBackend }] =
    useUsersUserProfileCreateMutation();
  const [updateWeeklyNutritionPlanOnBackend, { isLoading: isLoadingUpdateWnpOnBackend }] =
    useUsersWeeklyNutritionPlanUpdateMutation();
  const [createWeeklyNutritionPlanOnBackend, { isLoading: isLoadingCreateWnpOnBackend }] =
    useUsersWeeklyNutritionPlanCreateMutation();
  const [createNutritionDayPlanOnBackend, { isLoading: isLoadingCreatingNdpOnBackend }] =
    useUsersNutritionDayPlanCreateMutation();
  const [createMealSlotSpecificationOnBackend, { isLoading: isLoadingCreateMssOnBackend }] =
    useUsersMealSlotSpecificationCreateMutation();
  const [autoPlanDaysOnBackend, { isLoading: isLoadingAutoPlanDays }] = usePlannerAutoPlanDaysCreateMutation();

  const { data: ingredientToAvoidObjectsFromBackend } = useFoodIngredientToAvoidListQuery({});
  const [setIngredientsToAvoidMutation, { isLoading: isLoadingSetIngredientsToAvoid }] =
    useUsersSetIngredientsToAvoidCreateMutation();

  const isLoading =
    isLoadingUpdateUserProfileOnBackend ||
    isLoadingCreateUserProfileOnBackend ||
    isLoadingUpdateWnpOnBackend ||
    isLoadingCreateWnpOnBackend ||
    isLoadingCreatingNdpOnBackend ||
    isLoadingCreateMssOnBackend ||
    isLoadingSetIngredientsToAvoid ||
    isLoadingAutoPlanDays;

  const onboardingData = useSelector(onboardingDataSelector);
  const user = useSelector(userSelector);

  const dislikeOptions: Option[] = [
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.beef"),
      description: "",
      value: IngredientDislikesEnum.BEEF,
      icon: "🥩",
      testID: `dislike-option-${IngredientDislikesEnum.BEEF}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.eggplant"),
      description: "",
      value: IngredientDislikesEnum.EGGPLANT,
      icon: "🍆",
      testID: `dislike-option-${IngredientDislikesEnum.EGGPLANT}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.honey"),
      description: "",
      value: IngredientDislikesEnum.HONEY,
      icon: "🍯",
      testID: `dislike-option-${IngredientDislikesEnum.HONEY}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.cilantro"),
      description: "",
      value: IngredientDislikesEnum.CILANTRO,
      icon: "🌿",
      testID: `dislike-option-${IngredientDislikesEnum.CILANTRO}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.mayonnaise"),
      description: "",
      value: IngredientDislikesEnum.MAYONNAISE,
      icon: "🍶",
      testID: `dislike-option-${IngredientDislikesEnum.MAYONNAISE}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.potato"),
      description: "",
      value: IngredientDislikesEnum.POTATO,
      icon: "🥔",
      testID: `dislike-option-${IngredientDislikesEnum.POTATO}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.mushroom"),
      description: "",
      value: IngredientDislikesEnum.MUSHROOM,
      icon: "🍄",
      testID: `dislike-option-${IngredientDislikesEnum.MUSHROOM}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.tomato"),
      description: "",
      value: IngredientDislikesEnum.TOMATO,
      icon: "🍅",
      testID: `dislike-option-${IngredientDislikesEnum.TOMATO}`,
    },
    {
      label: t("onboarding.onboarding_10_ingredient_dislikes_screen.brussels_sprouts"),
      description: "",
      value: IngredientDislikesEnum.BRUSSELS_SPROUTS,
      icon: "🍃",
      testID: `dislike-option-${IngredientDislikesEnum.BRUSSELS_SPROUTS}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedDislikes: Yup.array()
      .of(Yup.mixed<IngredientDislike>().oneOf(Object.values(IngredientDislikesEnum)).defined())
      .required(t("onboarding.onboarding_10_ingredient_dislikes_screen.select_dislikes")),
  });
  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = async (values: FormSchema): Promise<void> => {
    if (!user) {
      throw new Error("User is not defined.");
    }

    const updatedDietPreferences: DietPreferences = {
      ...onboardingData.dietPreferences,
      ingredientDislikes: values.selectedDislikes,
    };
    dispatch(onboardingSlice.actions.setDietPreferences(updatedDietPreferences));

    const updatedOnboardingData = {
      ...onboardingData,
      dietPreferences: updatedDietPreferences,
    };
    try {
      await doOrRedoNutritionPreferencesOnBackend(
        user,
        updatedOnboardingData,
        updateUserProfileOnBackend,
        createUserProfileOnBackend,
        updateWeeklyNutritionPlanOnBackend,
        createNutritionDayPlanOnBackend,
        createMealSlotSpecificationOnBackend,
        createWeeklyNutritionPlanOnBackend,
        setIngredientsToAvoidMutation,
        ingredientToAvoidObjectsFromBackend,
        autoPlanDaysOnBackend
      );
    } catch (error) {
      alert("Something went wrong. Please contact support.");
      throw error;
    } finally {
      navigation.navigate(Routes.PostOnboardingScreen);
    }
  };

  return (
    <>
      {isLoading ? (
        <PostOnboardingLoadingInterstitial />
      ) : (
        <Formik
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          initialValues={validationSchema.cast({ selectedDislikes: [] })}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <SelectionScreen
              title={t("onboarding.onboarding_10_ingredient_dislikes_screen.title")}
              subtitle={t("onboarding.onboarding_10_ingredient_dislikes_screen.subtitle")}
              options={dislikeOptions}
              selectedOptions={values.selectedDislikes}
              setSelectedOptions={(selectedOptions) => {
                setFieldValue("selectedDislikes", selectedOptions);
              }}
              onPressNext={handleSubmit}
              onPressBack={() => navigation.goBack()}
              errorMessage={errors.selectedDislikes && touched.selectedDislikes ? errors.selectedDislikes : ""}
              multipleSelection={true}
              submitButtonLoading={isLoading}
            />
          )}
        </Formik>
      )}
    </>
  );
};

export default IngredientDislikesScreen;
