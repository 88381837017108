import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Center, Link } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { Image, Text, View } from "react-native";

import { CommonText } from "../commons";
import CommonInnerText from "../commons/CommonInnerText";
import { Images, Routes, Scale } from "../constants";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import styles from "./MagicLinkSentScreenStyle";

export const MAGIC_LINK_HELP_ARTICLE = "https://help.nutrition-app.com/articles/8863553-trouble-logging-in";

type Props = NativeStackScreenProps<RootStackParamList, Routes.MagicLinkSentScreen>;
const MagicLinkSentScreen = ({
  navigation,
  route: {
    params: { email },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <View style={styles.wrapper}>
      <Center>
        <View style={styles.container}>
          <CommonText title={t("magic_link_sent_screen.message_1")} externalTextStyle={styles.titleStyle} />
          <CommonInnerText title={t("magic_link_sent_screen.message_2")} externalTextStyle={styles.emailStyle} />
          <View style={styles.textStyle}>
            <Text style={{ textAlign: "center", fontSize: Scale(15), marginVertical: Scale(5) }}>{email}</Text>
          </View>
          <Text style={styles.lastTextStyle}>{t("magic_link_sent_screen.message_3")}</Text>

          <Image source={Images.EmailIcon} style={styles.emailIconStyle} />
        </View>
        <Link onPress={() => navigation.goBack()}>{t("magic_link_sent_screen.change_email_address_message_text")}</Link>

        <Link isExternal href={MAGIC_LINK_HELP_ARTICLE} mt={5}>
          {t("login_screen.trouble_logging_in_help")}
        </Link>
      </Center>
    </View>
  );
};
export default MagicLinkSentScreen;
