import { Text } from "native-base";
import React from "react";
import { StyleSheet, View } from "react-native";

import { Images, Scale, width } from "../constants";
import CommonIconButton from "./CommonIconButton";

const styles = StyleSheet.create({
  headerContainer: {
    paddingHorizontal: 24,
    paddingVertical: 24,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 24,
  },
  backIcon: {
    marginRight: 24,
  },
  textStyle: {
    // color: Colors.largeTextColor,
    fontWeight: "bold",
    fontSize: Scale(20),
    width: width - Scale(100),
    marginLeft: Scale(20),
  },
  headerTitle: {
    fontSize: 16,
    // color: Colors.textColor,
    fontWeight: "500",
  },
});

type CommonPageHeaderProps = {
  title?: string;
  showBackButton?: boolean;
  onPressBack?: () => void;
  onPressClose?: () => void;
};

const CommonPageHeader: React.FC<CommonPageHeaderProps> = ({ title, showBackButton, onPressBack, onPressClose }) => (
  <View style={styles.headerContainer}>
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {showBackButton ? <CommonIconButton onPress={onPressBack} source={Images.BackIcon} size={24} /> : null}
      <Text fontWeight={"500"} color={"coolGray.800"}>
        {title}
      </Text>
    </View>
    {onPressClose ? <CommonIconButton onPress={onPressClose} source={Images.CloseIcon} size={24} /> : null}
  </View>
);

export default CommonPageHeader;
