import { useTheme } from "native-base";
import React from "react";
import { Text } from "react-native";
import InputSpinner from "react-native-input-spinner";

import { Scale, VerticalScale } from "../constants";

type Props = {
  onChange: (val: number) => void | Promise<void>;
  width?: number;
  value?: string | number | undefined;
  min?: number | undefined;
  step?: number | undefined;
  append?: string;
  testIdPrefix?: string;
  editable?: boolean;
};
const spinnerBtnWidth = 30;
const CommonNumberInputWithPlusMinusButtons = ({
  width,
  append,
  min = 1,
  step = 1,
  value,
  testIdPrefix,
  onChange,
  editable = true,
  ...props
}: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <InputSpinner
      editable={editable}
      min={min}
      step={step}
      onChange={onChange}
      width={width || Scale(80)}
      height={VerticalScale(35)}
      inputProps={{
        borderWidth: 0,
        testID: `${testIdPrefix || "PREFIX_NOT_PROVIDED"}-input`,
        color: theme.colors.gray["600"],
        fontWeight: "500",
        fontSize: 14,
      }}
      showBorder={false}
      style={{
        borderWidth: 1,
        borderColor: theme.colors.gray["300"],
        borderRadius: 10,
      }}
      leftButtonProps={{
        style: {
          width: spinnerBtnWidth,
        },
        testID: `${testIdPrefix || "PREFIX_NOT_PROVIDED"}-left-button`,
      }}
      rightButtonProps={{
        style: {
          width: spinnerBtnWidth,
          borderRadius: 8,
        },
      }}
      buttonTextColor={theme.colors.gray["300"]}
      colorPress={theme.colors.primary["600"]}
      append={<Text style={{ color: theme.colors.gray["600"], fontWeight: "500", fontSize: 14 }}>{append}</Text>}
      value={value}
      {...props}
    />
  );
};

export default CommonNumberInputWithPlusMinusButtons;
