import React from "react";
import { Modal, ModalProps, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import { Images, Scale, VerticalScale, width } from "../constants";
import CommonIconButton from "./CommonIconButton";
import CommonInnerText from "./CommonInnerText";

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: VerticalScale(50),
  },
  modalView: {
    backgroundColor: "white",
    paddingVertical: VerticalScale(24),
    width: width - Scale(24),
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});

type CommonModalProps = {
  modalRef: React.Ref<Modal>;
  ModalContent: React.ElementType;
  FooterComponent?: React.ElementType;
  modalHeight?: number;
  onRequestClose: () => void;
  modalProps?: ModalProps;
  title?: string;
  externalModalViewStyle?: ViewStyle;
  titleExternalStyle?: TextStyle;
};

const CommonModal = ({
  onRequestClose,
  ModalContent,
  modalHeight,
  modalRef,
  modalProps,
  title,
  externalModalViewStyle,
  titleExternalStyle,
}: CommonModalProps): JSX.Element => (
  <Modal animationType="slide" transparent={true} visible={true} onRequestClose={onRequestClose} {...modalProps}>
    <View style={styles.centeredView}>
      <View style={[styles.modalView, externalModalViewStyle]}>
        {title ? (
          <View style={{ position: "absolute", top: Scale(30), left: Scale(30) }}>
            <CommonInnerText
              title={title}
              externalTextStyle={{ fontSize: Scale(16), fontWeight: "600", ...titleExternalStyle }}
            />
          </View>
        ) : null}

        <View style={{ position: "absolute", top: Scale(30), right: Scale(30) }}>
          <CommonIconButton onPress={onRequestClose} source={Images.CloseIcon} size={24} />
        </View>

        <View
          style={{
            marginTop: VerticalScale(60),
            paddingHorizontal: Scale(24),
            width: "100%",
          }}
        >
          {/* NOTE: This library component has a ts compile error which we cannot fix */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <ModalContent modalRef={modalRef} />
        </View>
      </View>
    </View>
  </Modal>
);

export default CommonModal;
