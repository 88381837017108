import { StyleSheet } from "react-native";

import { Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  headerImg: {
    width: "100%",
    height: "40%",
  },
  titleText: {
    fontSize: 24,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
  },
  greenTitle: {
    fontSize: 24,
    // color: Colors.primaryGreenColor,
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: Scale(24),
  },
  rowContainer: {
    // backgroundColor: Colors.grey,
    flexDirection: "row",
    paddingVertical: 16,
    alignItems: "center",
  },
  roleText: {
    fontSize: 12,
    // color: Colors.textColor
  },
  nameText: {
    fontSize: 16,
    fontWeight: "600",
    // color: Colors.largeTextColor
  },
  descriptionText: {
    // color: Colors.textColor,
    fontSize: 16,
    fontWeight: "500",
    lineHeight: VerticalScale(24),
  },
  bottomContainer: {
    bottom: 40,
  },
  startBtn: {
    // backgroundColor: Colors.primaryGreenColor,
    height: 48,
    borderRadius: 8,
  },
  skipBtn: { backgroundColor: "white", height: 48, borderRadius: 8 },
  startText: { fontSize: 18, fontWeight: "500", color: "white" },
  skipText: { fontSize: 18, fontWeight: "500", color: "black" },
  colorBlack: { color: "black" },
});

export default styles;
