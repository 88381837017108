// TODO: This file has been superseded by nutritionCalculations7.ts and should be deleted
// (after any functions from this file that are still needed have been copied over to nutritionCalculations7.ts)

/*
 * This file is the new codebase-facing service for the legacy nutrition calculations.
 */

import _ from "lodash";

import type {
  ExerciseInstanceListRetrieve as ExerciseInstance,
  MealMomentEnum,
  MealSlotSpecification,
  MealTypeEnum,
  UserProfile,
} from "./backendTypes";
import {
  CardioInput,
  computeEnergyExpenditure,
  computeMacroDistribution,
  computeRecommendedMacros,
  EnergyExpenditure,
  EnergyInput,
  LegacyMealType,
  MacroDistribution,
  Macros,
  MealPreferences,
} from "./legacyNutritionCalculations";

const thermicEffectOfDiet = 20 / 100;

// Helper functions
export const convertLegacyMealTypeToMealType = (legacyMealType: LegacyMealType): MealTypeEnum => {
  switch (legacyMealType) {
    case "breakfast":
      return "BREAKFAST";
    case "lunch":
      return "LUNCH";
    case "snack":
      return "SNACK";
    case "dinner":
      return "DINNER";
    case "pre-bed":
      return "SNACK";
    default:
      throw new Error("Unknown legacy meal type");
  }
};

export const convertMealTypeToLegacyMealType = (mealType: MealMomentEnum): LegacyMealType => {
  switch (mealType) {
    case "BREAKFAST":
      return "breakfast";
    case "MORNING_SNACK":
      return "snack";
    case "LUNCH":
      return "lunch";
    case "AFTERNOON_SNACK":
      return "snack";
    case "DINNER":
      return "dinner";
    case "SNACK":
      return "snack";
    case "LATE_SNACK":
      return "pre-bed";
    default:
      throw new Error("Unknown meal type");
  }
};

const convertLegacyMacrosToOrdering = (legacyMealType: LegacyMealType): number => {
  switch (legacyMealType) {
    case "breakfast":
      return 0;
    case "lunch":
      return 1;
    case "snack":
      return 2;
    case "dinner":
      return 3;
    case "pre-bed":
      return 4;
    default:
      throw new Error(`Unknown legacy meal type: ${String(legacyMealType)}`);
  }
};

const convertMealSlotSpecificationOrderToMealMoment = (mssOrder: number): MealMomentEnum => {
  switch (mssOrder) {
    case 0:
      return "BREAKFAST";
    case 1:
      return "LUNCH";
    case 2:
      return "SNACK";
    case 3:
      return "DINNER";
    case 4:
      return "LATE_SNACK";
    default:
      throw new Error(`Unknown mssOrder: ${String(mssOrder)}`);
  }
};

const createLegacyCardio = (exerciseInstance: ExerciseInstance): CardioInput => {
  const session = {
    activity: exerciseInstance.exercise.exercise_name,
    burnRate: exerciseInstance.exercise.kcal_per_kg_per_min,
    id: exerciseInstance.exercise.id,
  };

  return {
    weeklyFrequency: exerciseInstance.sessions_per_week,
    duration: exerciseInstance.minutes_per_session,
    option: {
      id: exerciseInstance.exercise.id,
      name: exerciseInstance.exercise.exercise_name,
      netEnergyExpenditure: exerciseInstance.exercise.kcal_per_kg_per_min,
      session,
    },
    session,
  };
};

function getCardioInputArray(intake: UserProfile): CardioInput[] {
  return _.map(intake.exercise_instances, createLegacyCardio);
}

export const calculateEnergyExpenditure = (intake: UserProfile): EnergyExpenditure | undefined => {
  if (!intake) throw new Error("No intake provided");

  const { gender, weight, body_fat_percentage: fatPercentage, activity: lifestyle } = intake;

  const cardio = getCardioInputArray(intake);

  const input = {
    gender,
    weight,
    bodyFatPercentage: fatPercentage,
    thermicEffectOfDiet,
    lifestyle,
    cardio,
  };

  return computeEnergyExpenditure(input);
};

export function getEnergyInput(userProfile: UserProfile): EnergyInput {
  return {
    gender: userProfile.gender,
    weight: userProfile.weight, // in kilos.
    bodyFatPercentage: userProfile.body_fat_percentage, // as a percentage (16% => 0.16).

    // Thermic effect of diet (0.1 - 0.25). Higher when lean and when diet's rich
    // in whole foods, unsaturated fats, MCTs, high volume foods, and lots of fiber.
    // For average western diet low in protein, it's 10%.
    thermicEffectOfDiet,

    lifestyle: userProfile.activity,

    cardio: getCardioInputArray(userProfile),
  };
}

/**
 *
 * @param userProfile
 * @param energyBalance A percentage as a float, ie, 0 = 0%, 1 = 100%, 3 = 300%
 * @returns
 */
export function getRecommendedDailyMacronutrientIntake(
  userProfile: UserProfile,
  energyBalance: number
): Macros | undefined {
  const options = {
    energyBalance,
    diet: userProfile.diet,
    optimize: userProfile.sustainability,
  };

  return computeRecommendedMacros(getEnergyInput(userProfile), calculateEnergyExpenditure(userProfile), options);
}

export type PossibleMealMoment = MealTypeEnum | "LATE_SNACK";
export type DesiredMealMoments = {
  [M in PossibleMealMoment]: boolean;
};

export const DEFAULT_MEAL_MOMENT_PREFERENCES: DesiredMealMoments = {
  BREAKFAST: true,
  LUNCH: true,
  SNACK: true,
  DINNER: true,
  LATE_SNACK: true,
};

export function convertPossibleMealMomentToOrdering(mealMoment: PossibleMealMoment): number {
  switch (mealMoment) {
    case "BREAKFAST":
      return 0;
    case "LUNCH":
      return 1;
    case "SNACK":
      return 2;
    case "DINNER":
      return 3;
    case "LATE_SNACK":
      return 4;
    default:
      throw new Error(`Unknown meal moment: ${String(mealMoment)}`);
  }
}

function convertToLegacyMealPreferences(desiredMealMoments: DesiredMealMoments): MealPreferences {
  const { BREAKFAST, LUNCH, SNACK, DINNER, LATE_SNACK } = desiredMealMoments;

  return {
    breakfast: BREAKFAST,
    lunch: LUNCH,
    snack: SNACK,
    dinner: DINNER,
    "pre-bed": LATE_SNACK,
  };
}

export function calculateLegacyMacroDistribution(
  userProfile: UserProfile,
  energyBalance: number,
  desiredMealMoments: DesiredMealMoments
): MacroDistribution {
  const legacyRecommendedMacros = getRecommendedDailyMacronutrientIntake(userProfile, energyBalance);
  if (!legacyRecommendedMacros) throw new Error("This should never happen");

  const legacyPreferences = convertToLegacyMealPreferences(desiredMealMoments);

  const calculatedLegacyMeals = computeMacroDistribution(legacyRecommendedMacros, legacyPreferences);

  return calculatedLegacyMeals;
}

const convertLegacyMealMacrosToMealSlotSpecification = ([key, calculatedMacros]: [
  key: LegacyMealType | "total",
  calculatedMacros: Macros
]): MealSlotSpecification | undefined => {
  if (key === "total") {
    return undefined;
  }
  if (!["breakfast", "lunch", "snack", "dinner", "pre-bed"].includes(key)) {
    throw new Error(`Bad type for key: ${String(key)}`);
  }

  const order = convertLegacyMacrosToOrdering(key);

  const { calories, protein, carbs, fat } = calculatedMacros;
  return {
    kcal: calories,
    protein,
    carbohydrates: carbs,
    fat,

    meal_type: convertLegacyMealTypeToMealType(key),
    order,
    meal_moment: convertMealSlotSpecificationOrderToMealMoment(order),

    // NOTE: These are required by the type but cannot yet be populated
    id: -1,
    nutrition_day_plan: -1,
  };
};

function convertLegacyMacrosToMealSlotSpecifications(legacyMacros: MacroDistribution): MealSlotSpecification[] {
  const mealSlotSpecifications = Object.entries(legacyMacros.meals)
    // NOTE: Object.entries doesn't realise the key's type is narrower than a string
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map(convertLegacyMealMacrosToMealSlotSpecification)
    .filter((mss) => mss && mss.kcal && mss.kcal > 0);

  // NOTE: The compiler doesn't realise that undefined values are not possible
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mealSlotSpecifications;
}

export function calculateNutritionDayPlan(
  userProfile: UserProfile,
  energyBalance: number,
  desiredMealMoments: DesiredMealMoments = DEFAULT_MEAL_MOMENT_PREFERENCES
): MealSlotSpecification[] {
  const calculatedLegacyMeals = calculateLegacyMacroDistribution(userProfile, energyBalance, desiredMealMoments);

  return convertLegacyMacrosToMealSlotSpecifications(calculatedLegacyMeals);
}

/*
 * Functions required in our create nutrition day plan flow
 */

export function getMealTypeFromLegacyMealMoment(legacy: PossibleMealMoment): MealTypeEnum {
  switch (legacy) {
    case "BREAKFAST":
      return "BREAKFAST";
    case "LUNCH":
      return "LUNCH";
    case "DINNER":
      return "DINNER";
    case "SNACK":
    case "LATE_SNACK":
      return "SNACK";
    default:
      throw new Error(`Unknown legacy meal moment: ${String(legacy)}`);
  }
}
