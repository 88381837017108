import { Image, View } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";
import { CheckBox } from "react-native-elements";

import { Images, Scale } from "../constants";

const styles = StyleSheet.create({
  textBoxContainerStyle: {
    padding: Scale(0),
    marginLeft: Scale(0),
  },
  uncheckedBox: {
    height: Scale(28),
    width: Scale(28),
    // backgroundColor: Colors.whiteColor,
    borderWidth: 1,
    borderStyle: "solid",
    // borderColor: Colors.borderColor,
    borderRadius: Scale(4),
  },
  checkedBox: {
    width: Scale(28),
    height: Scale(28),
    // backgroundColor: Colors.primaryGreenColor,
    borderRadius: Scale(4),
  },
});

type comProps = {
  checked: boolean;
  onSelect: () => void;
};
const CheckBoxCom: React.FC<comProps> = (props: comProps) => (
  <CheckBox
    containerStyle={styles.textBoxContainerStyle}
    // TODO: Convert this to a material icon so we can alter its color
    checkedIcon={<Image source={Images.CheckIcon} style={styles.checkedBox} color="primary.600" />}
    uncheckedIcon={<View style={styles.uncheckedBox} bgColor="white" borderColor={"gray.100"} />}
    checked={props.checked}
    onPress={props.onSelect}
  />
);

export default CheckBoxCom;
