import { Scale } from ".";

export const FontSize = {
  h1: Scale(30),
  h2: Scale(28),
  h3: Scale(26),
  h4: Scale(24),
  h5: Scale(22),
  h6: Scale(20),
  title: Scale(18),
  body: Scale(16),
  label: Scale(15),
  small: Scale(14),
  mini: Scale(12),
  micro: Scale(11),
};

export const FontFamily = {
  regular: "DMSans_400Regular",
  medium: "DMSans_500Medium",
  bold: "DMSans_700Bold",
};
