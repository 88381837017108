import { Entypo, Ionicons } from "@expo/vector-icons";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import _ from "lodash";
import { Button, Icon, IIconButtonProps, Image, Link, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScreenWidth } from "react-native-elements/dist/helpers";
import { useSelector } from "react-redux";

import { Scale } from "../constants";
import { IS_NOT_DESKTOP } from "../constants/helperFunction";
import { MAGIC_LINK_HELP_ARTICLE } from "../screens/MagicLinkSentScreen";
import backendApi from "../services/backendApi";
import type { User } from "../services/backendTypes";
import { getBrandSelector, getIsLoadingBrandSelector } from "../slices/brandSlice";
import analyticsInterface, { analyticsJson } from "./analyticsHelpers";
import { isMobilePlatform, openURL } from "./generalHelpers";
import { getHostname, getManagedCustomerPortalSubdomain } from "./userHelpers";

const { isIntercomLoaded, trackEvent } = analyticsInterface;

const SUPPORT_WEBSITE = "https://help.nutrition-app.com/";

const KNOWLEDGE_BASE_URL = "https://kennisbank.weekmeals.co/docs/";

type Props = {
  width?: string;
  ml?: string;
};

const { useUsersStripeCustomerPortalCreateMutation, useUsersBrandRetrieveQuery } = backendApi;

export const KnowledgeBaseLink = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Button
      onPress={() => openURL(KNOWLEDGE_BASE_URL)}
      leftIcon={<Icon as={Ionicons} name="book-outline" />}
      mt="3"
      nativeID="knowledgeBaseButton"
      {...props}
    >
      {t("general.knowledge_base")}
    </Button>
  );
};

const NO_CODE_STRIPE_CUSTOMER_PORTAL_URL = "https://billing.stripe.com/p/login/eVa6sjehu85DeDmdQQ";

type StripeCustomerPortalLinkProps = {
  isDesktop: boolean;
  isUpgradeNowButton?: boolean;
  noCode?: boolean;
};
export const StripeCustomerPortalLink = ({
  isDesktop,
  isUpgradeNowButton,
  noCode = false,
}: StripeCustomerPortalLinkProps): JSX.Element => {
  const { t } = useTranslation();
  const [createStripeCustomerPortal] = useUsersStripeCustomerPortalCreateMutation();

  const onPress = async (): Promise<void> => {
    if (noCode) {
      openURL(NO_CODE_STRIPE_CUSTOMER_PORTAL_URL);
      return;
    }

    let stripeCustomerPortal;
    try {
      stripeCustomerPortal = await createStripeCustomerPortal().unwrap();
    } catch (e) {
      Sentry.captureException(e);

      alert(t("general.generic_error_message"));

      return;
    }

    if (!isMobilePlatform()) {
      window.open(stripeCustomerPortal.portal_url, "_blank");
      openURL(stripeCustomerPortal.portal_url);
    }

    // NOTE: In order to remain in line with the app policies we don't allow billing management in the app
    // await WebBrowser.openBrowserAsync(stripeCustomerPortal.portal_url);
  };

  const optionalProps: IIconButtonProps = isUpgradeNowButton
    ? { width: isDesktop ? "10%" : "40%", ml: isDesktop ? "80%" : "30%", backgroundColor: "warning.400" }
    : {};

  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onPress={onPress}
      leftIcon={<Icon as={Ionicons} name="card-outline" />}
      mt="3"
      nativeID="stripeCustomerPortalButton"
      {...optionalProps}
    >
      {t(isUpgradeNowButton ? "general.upgrade_now_button_text" : "general.manage_billing")}
    </Button>
  );
};

const HUBSPOT_DEMO_CALENDAR_URL = "https://meetings.hubspot.com/martin-foley/weekmeals-office-hours";

type BookACallLinkProps = {
  isDesktop: boolean;
  email?: string;
};
export const BookACallLink = ({ isDesktop, email }: BookACallLinkProps): JSX.Element => {
  const { t } = useTranslation();

  const optionalProps: IIconButtonProps = {
    width: isDesktop ? "10%" : "40%",
    ml: isDesktop ? "80%" : "30%",
    mt: "3",
  };

  const onPressBookACallButton = (): void => {
    trackEvent(analyticsJson.events.click_book_a_demo_button.name);
    openURL(email ? `${HUBSPOT_DEMO_CALENDAR_URL}?email=${encodeURIComponent(email)}` : HUBSPOT_DEMO_CALENDAR_URL);
  };

  return (
    <Button
      onPress={onPressBookACallButton}
      leftIcon={<Icon as={Ionicons} name="call-outline" />}
      nativeID="bookACallButton"
      variant="outline"
      {...optionalProps}
    >
      {t("general.get_a_demo")}
    </Button>
  );
};

const REFERRAL_PROGRAMME_URL = "https://referrals.weekmeals.co";

export const ReferralProgrammeLink = ({ isDesktop }: { isDesktop: boolean }): JSX.Element => {
  const { t } = useTranslation();

  const optionalProps: IIconButtonProps = {
    width: isDesktop ? null : "60%",
    ml: isDesktop ? "70%" : "20%",
    mr: isDesktop ? "10%" : null,
    mt: "3",
  };

  const onPressReferralProgrammeButton = (): void => {
    trackEvent(analyticsJson.events.click_referral_programme_button.name);

    openURL(REFERRAL_PROGRAMME_URL);
  };

  return (
    <Button
      onPress={onPressReferralProgrammeButton}
      leftIcon={<Icon as={Ionicons} name="gift-outline" />}
      nativeID="referralProgrammeButton"
      backgroundColor={"warning.400"}
      {...optionalProps}
    >
      {t("coach_general.refer_a_friend_button")}
    </Button>
  );
};

type ContactSupportLinkProps = {
  user: User | null;
};

export const ContactSupportLink = ({ user }: ContactSupportLinkProps): JSX.Element => {
  const { t } = useTranslation();
  const customSupportSubdomain = getManagedCustomerPortalSubdomain(getHostname());

  // NOTE: If we are not using a custom support subdomain then there is no need to retrieve the url
  const { data: brand } = useUsersBrandRetrieveQuery(
    { subdomain: customSupportSubdomain },
    { skip: !customSupportSubdomain }
  );

  const onPressContactSupportButton = (): void => {
    const organisationHasCustomSupportUrl = brand && brand?.support_url;

    if (!organisationHasCustomSupportUrl) {
      if (isIntercomLoaded()) {
        analyticsInterface.openIntercomMessenger();
        return;
      }
      // If Intercom is not loaded then fallback to the website
      openURL(SUPPORT_WEBSITE);
      return;
    }

    openURL(brand?.support_url);
  };

  return (
    <Button
      onPress={onPressContactSupportButton}
      leftIcon={<Icon as={Ionicons} name="chatbox-outline" />}
      mt="3"
      nativeID="contactSupportButton"
      color={"white"}
    >
      {t("general.get_help")}
    </Button>
  );
};

export const TroubleLoggingInLink = (): JSX.Element => {
  const { t } = useTranslation();
  const customSupportSubdomain = getManagedCustomerPortalSubdomain(getHostname());

  const {
    data: organisation,
    isError,
    error,
  } = useUsersBrandRetrieveQuery({ subdomain: customSupportSubdomain }, { skip: !customSupportSubdomain });
  const getSupportUrl = (): string => {
    if (organisation && organisation?.support_url) {
      return organisation.support_url;
    }

    return MAGIC_LINK_HELP_ARTICLE;
  };

  return (
    <Link isExternal onPress={() => openURL(getSupportUrl())}>
      {t("login_screen.trouble_logging_in_help")}
    </Link>
  );
};

const TROUBLE_INSTALLING_PWA_ARTICLE_URL =
  "https://help.nutrition-app.com/en/articles/9819301-how-to-install-nutrition-app-on-your-mobile-device";

export const TroubleInstallingPWAArticleComponent = (): JSX.Element => {
  const { t } = useTranslation();
  return HelpArticleButton({
    articleUrl: TROUBLE_INSTALLING_PWA_ARTICLE_URL,
    ctaText: t("pwa_install_screen.need_help_installing_text"),
  });
};

export const HelpArticleButton = ({ articleUrl, ctaText }: { articleUrl: string; ctaText?: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button
      onPress={() => openURL(articleUrl)}
      leftIcon={<Icon as={Ionicons} name="help-circle" size="md" color="white" />}
      mt="10"
      size="lg"
      bg="amber.300"
      _hover={{ bg: "amber.500" }}
      _pressed={{ bg: "amber.600" }}
      shadow="2"
      borderRadius="lg"
      height="12"
    >
      <Text color="white" fontWeight="bold" fontSize="md">
        {ctaText || t("general.get_help")}
      </Text>
    </Button>
  );
};

export const AppImage = (): JSX.Element => {
  const brand = useSelector(getBrandSelector);
  const isLoadingBrand = useSelector(getIsLoadingBrandSelector);

  const imageSize = Scale(ScreenWidth * 0.8 * (IS_NOT_DESKTOP ? 0.5 : 0.2));

  if (isLoadingBrand) {
    // We return a placeholder div to prevent layout shift
    return (
      <div
        style={{
          width: imageSize,
          height: imageSize,
        }}
      ></div>
    );
  }

  return (
    <Image
      source={{ uri: brand?.logo }}
      style={{
        width: imageSize,
        height: imageSize,
      }}
      resizeMode="contain"
      marginY={8}
    />
  );
};
