import { StyleSheet } from "react-native";

import { Scale } from "../constants";
import { FontFamily } from "../constants/fonts";

const styles = StyleSheet.create({
  container: { flex: 1, marginBottom: Scale(2) },
  nutrientDiv: {
    width: "100%",
    paddingHorizontal: Scale(20),
    height: Scale(30),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nutrientHead: {
    fontFamily: FontFamily.medium,
    fontSize: 16,
    lineHeight: Scale(28),
    // color: Colors.darkGreyTextColor,
  },
  nutrientTextDiv: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nutrientText1: {
    fontFamily: FontFamily.medium,
    fontSize: 12,
    // color: Colors.largeTextColor,
    lineHeight: Scale(28),
  },
  nutrientText2: {
    fontFamily: FontFamily.medium,
    fontSize: 12,
    // color: Colors.darkGreyTextColor,
    lineHeight: Scale(28),
  },
  progressBarDiv: { paddingLeft: 20, width: Scale(10), marginTop: Scale(5) },
  progressBarContainer: { paddingHorizontal: Scale(20) },
});

export default styles;
