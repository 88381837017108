// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Image, Text, View } from "react-native";

import { CommonButton } from "../commons";
import { Images, Routes } from "../constants";
import styles from "./GetStartedScreenStyle";

type GetStartedScreenParamList = {
  GetStaredScreen: undefined;
  PlanScreen: undefined;
};
type GetStartedScreenProps = {
  navigation: NativeStackNavigationProp<GetStartedScreenParamList, "GetStaredScreen">;
};

const GetStartedScreen = ({ navigation }: GetStartedScreenProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Image source={Images.HeaderIcon} style={styles.headerImg} />
      <View style={styles.mainContainer}>
        <View style={{ flex: 1 }}>
          <View style={{ marginVertical: 32 }}>
            <Text>
              <Text style={styles.title}>{"Hoi Lisa"}, </Text>
              <Text style={styles.greenTitle}>{"let`s get started!"}</Text>
            </Text>
          </View>
          <View style={styles.rowContainer}>
            <Image source={Images.Avatar} style={styles.avatarImg} />
            <View>
              <Text style={styles.roleText}>{"Jouw coach"}</Text>
              <Text style={styles.nameText}>Kevin Matisse</Text>
            </View>
          </View>
          <View style={{ marginVertical: 24 }}>
            <Text style={styles.descriptionText}>
              {"Je coach heeft je doel en voedings- plan al voor je klaargezet– datscheelt! We nemen je erin mee."}
            </Text>
          </View>
        </View>
        <View style={styles.bottomContainer}>
          <CommonButton
            title={"Start"}
            onPress={() => navigation.push(Routes.PlanScreen as keyof GetStartedScreenParamList)}
          />
        </View>
      </View>
    </View>
  );
};

export default GetStartedScreen;
