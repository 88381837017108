import { StyleSheet } from "react-native";

import { Scale, width } from "../constants";

const RegisterStyles = StyleSheet.create({
  titleStyle: {
    marginBottom: Scale(20),
  },
  textBoxContainerExternalStyle: {
    marginBottom: Scale(10),
  },
  strongPasswordStyle: {
    height: Scale(4),
    width: width / 7,
    // backgroundColor: Colors.greyTextColor,
    borderRadius: Scale(2),
    marginRight: Scale(4),
  },
  strongPasswordContainer: {
    flexDirection: "row",
    marginHorizontal: Scale(20),
  },
  lightGrayColor: {
    // backgroundColor: Colors.greyTextColor,
  },
  textBoxContainerStyle: {
    marginRight: 0,
    width: 10,
  },
  checkboxTextStyle: {
    fontSize: Scale(14),
    fontWeight: "500",
  },
  checkboxStyle: {
    height: Scale(20),
    resizeMode: "contain",
    width: Scale(20),
  },
  checkBoxContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: Scale(20),
  },
  externalTextStyle: {
    fontSize: Scale(16),
  },
  container: {
    flex: 2,
    justifyContent: "space-between",
  },
  wrapper: {
    flex: 1,
    // backgroundColor: Colors.whiteColor,
  },
  logginContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  loginTouchableContainer: {
    position: "absolute",
    right: Scale(20),
    width: Scale(100),
  },
  bottomStyle: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: Scale(30),
  },
});

export default RegisterStyles;
