import { Text } from "native-base";
import React from "react";
import { StyleSheet, TextStyle } from "react-native";

import { Scale, width } from "../constants";

const styles = StyleSheet.create({
  textStyle: {
    // color: Colors.largeTextColor,
    fontWeight: "bold",
    fontSize: Scale(20),
    width: width - Scale(100),
    marginLeft: Scale(20),
  },
});

type CommonTextProps = {
  title: string;
  externalTextStyle?: TextStyle;
};

const CommonText: React.FC<CommonTextProps> = ({ title, externalTextStyle }) => (
  <Text style={[styles.textStyle, externalTextStyle]}>{title}</Text>
);

export default CommonText;
