import { StyleSheet } from "react-native";

import { Scale } from "../constants";

export default StyleSheet.create({
  calendar: { flex: 1 },
  dropDown: { position: "absolute", top: Scale(55), right: Scale(108) },
  dropDownImg: { width: Scale(10), height: Scale(5) },
  grid: { position: "absolute", top: Scale(45), right: Scale(24) },
  gridImg: { width: Scale(24), height: Scale(24) },
  dateNumberStyle: {
    marginTop: Scale(3),
    fontSize: 12,
  },
  dayContainerStyle: {
    borderRadius: Scale(8),
    width: Scale(30),
    height: Scale(44),
    marginBottom: Scale(20),
    backgroundColor: "white",
  },
  highlightDateContainerStyle: {
    borderRadius: 8,
  },
  highlightDateNumberStyle: {
    marginTop: Scale(3),
    fontSize: 13,
    color: "white",
  },
  dateNameStyle: {
    //  color: Colors.greyTextColor,
    fontSize: 12,
  },
});
