// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import { body_measurement as bodyMeasurement } from "health-calculator";
import { Gender as HealthCalculatorGender } from "health-calculator/lib/util";
import _ from "lodash";
import moment from "moment";
import type { Dispatch } from "react";

import { formatMomentAsDateForApi } from "../../helpers/apiHelpers";
import { adjustLegacyInputCarbs, MAX_CARBS_FOR_KETO } from "../../helpers/diyFormHelpers";
import { harrisBenedictBMR, mifflinStJoerRMR } from "../../helpers/fitnessCalculationHelpers";
import { apiCallErrorHandler } from "../../helpers/generalHelpers";
import type {
  DaysToUpdateToNutritionDayPlanIdEnum,
  IngredientToAvoid,
  MainGoalEnum as Goal,
  MealMomentEnum as MealMoment,
  MealSlotSpecification,
  PaginatedIngredientToAvoidList,
  User,
  UserProfile,
  UsersUserProfileCreateApiArg,
} from "../../services/backendTypes";
import type { Macros } from "../../services/legacyNutritionCalculations";
import { type LegacyInput, type LegacyMealState, rebalanceSizes } from "../../services/legacyNutritionCalculations7";
import {
  convertToMealSlotSpecifications,
  getDefaultLegacyInput,
  getUpdatedMealSlotSpecificationsFromFormValues,
  updateLegacyInput,
} from "../../services/nutritionCalculations7";
import { type OnboardingState, DEFAULT_MEAL_TIMES, determineWeeklyGoal } from "../../slices/onboardingSlice";
import { type DIYFormInterface, userSlice } from "../../slices/userSlice";
import {
  type WeeklyGoal,
  AutoPlanDaysOnBackendMutation,
  CreateMealSlotSpecificationOnBackend,
  CreateNutritionDayPlanOnBackend,
  CreateUserProfileOnBackend,
  CreateWeeklyNutritionPlanOnBackend,
  GenderEnum,
  GoalEnum,
  ingredientDislikeToBackendIdentifier,
  MacrosPreference,
  MacrosPreferenceEnum,
  MealMomentEnum,
  SetIngredientsToAvoidMutation,
  SustainabilityEnum,
  UpdateUserProfileOnBackend,
  UpdateWeeklyNutritionPlanOnBackend,
  WeeklyGoalEnum,
} from "../../types";

export interface DiyCreateNutritionPlanFormInterface {
  goal: Goal;
  mealMoments: MealMoment[];
  macrosPreference: MacrosPreference;
}

export const diyCreateNutritionPlanFormInitialValues: DiyCreateNutritionPlanFormInterface = {
  goal: GoalEnum.LOSE_WEIGHT,
  mealMoments: [MealMomentEnum.BREAKFAST, MealMomentEnum.LUNCH, MealMomentEnum.AFTERNOON_SNACK, MealMomentEnum.DINNER],
  macrosPreference: MacrosPreferenceEnum.RECOMMENDED,
};

function calculateBodyFatPercentage(onboardingData: OnboardingState): number {
  if (
    !onboardingData.physicalStats.weightInKg ||
    !onboardingData.physicalStats.heightInCm ||
    !onboardingData.biometricData.age
  ) {
    throw new Error("Invalid onboarding data");
  }

  const bmi = bodyMeasurement.bmi(onboardingData.physicalStats.weightInKg, onboardingData.physicalStats.heightInCm);

  const bodyFatPercentage =
    bodyMeasurement.bfp(
      onboardingData.biometricData.gender === GenderEnum.MALE
        ? HealthCalculatorGender.Male
        : HealthCalculatorGender.Female,
      onboardingData.biometricData.age,
      bmi
    ) / 100.0;

  return bodyFatPercentage;
}

export function createUserProfileFromOnboardingData(onboardingData: OnboardingState): UserProfile {
  if (!onboardingData) {
    throw new Error("onboardingData is not set");
  }

  return {
    id: -1,
    weekly_nutrition_plan: null,
    gender: onboardingData.biometricData.gender!,
    activity: onboardingData.activityLevel,
    sustainability: SustainabilityEnum.SUSTAINABLE,
    diet: onboardingData.dietPreferences.dietaryPreference || "OMNIVORE",
    weight: onboardingData.physicalStats.weightInKg!,
    body_fat_percentage: calculateBodyFatPercentage(onboardingData),
    food_intolerances_gluten: onboardingData.dietPreferences.intolerances.includes("GLUTEN"),
    food_intolerances_lactose: onboardingData.dietPreferences.intolerances.includes("LACTOSE"),
    food_intolerances_nut: onboardingData.dietPreferences.intolerances.includes("NUTS"),
    food_intolerances_crustaceans_shellfish:
      onboardingData.dietPreferences.intolerances.includes("CRUSTACEANS_AND_SHELLFISH"),
    exercise_instances: [],
    is_keto_user: onboardingData.dietPreferences.dietType === MacrosPreferenceEnum.KETO,
    main_goal: onboardingData.goal,
  };
}

export function createAnonymousUser(onboardingData: OnboardingState): User {
  const anonymousUser: User = {
    id: -1,
    email: `anonymous${_.uniqueId()}@nutriaiplatform.com`,
    first_name: "",
    last_name: "",
    is_coach: false,
    account_enabled: true,
    payment_status_override: false,
    intake: createUserProfileFromOnboardingData(onboardingData),
    // These fields are not relevant for any nutrition calculations
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customer: undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    organisation: undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    coach: undefined,
  };

  return anonymousUser;
}

export const ALL_DAYS_OF_THE_WEEK: DaysToUpdateToNutritionDayPlanIdEnum[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

/**
 * We calculate the energy deficit required to achieve the desired weight loss
 */
export function calculateEnergyBalanceMatchingGoal(onboardingState: OnboardingState, tdee: number): string {
  const kcalsToLoseOneKg = 7700; // Approximate kcal deficit needed to lose 1 kg of body weight
  let weeklyDeficit = 0;

  if (onboardingState.weeklyGoal) {
    switch (onboardingState.weeklyGoal) {
      case "LOSE_0_25_KG_PER_WEEK":
        weeklyDeficit = 0.25 * kcalsToLoseOneKg;
        break;
      case "LOSE_0_5_KG_PER_WEEK":
        weeklyDeficit = 0.5 * kcalsToLoseOneKg;
        break;
      case "LOSE_0_75_KG_PER_WEEK":
        weeklyDeficit = 0.75 * kcalsToLoseOneKg;
        break;
      case "LOSE_1_KG_PER_WEEK":
        weeklyDeficit = 1 * kcalsToLoseOneKg;
        break;
      default:
        console.error("Unsupported weekly goal ", onboardingState.weeklyGoal);
    }
  }

  const dailyDeficit = weeklyDeficit / 7;
  const energyBalance = 100 - (dailyDeficit / tdee) * 100;

  return String(Math.round(energyBalance));
}

export function createMealSlotSpecificationFromDiyForm(
  user: User,
  onboardingState: OnboardingState
): MealSlotSpecification[] {
  if (!user.intake) {
    throw new Error("user intake is not set - this should never happen");
  }

  // Create nutrition plan
  const defaultLegacyInput = getDefaultLegacyInput(
    user.intake,
    {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    "diy-nutrition-plan"
  );

  let energyBalance;
  if (onboardingState.goal === GoalEnum.RECOMPOSITION) {
    energyBalance = String(100);
  } else if (onboardingState.goal === GoalEnum.LOSE_WEIGHT) {
    energyBalance = String(90);
    if (onboardingState.goal) {
      energyBalance = calculateEnergyBalanceMatchingGoal(
        onboardingState,
        defaultLegacyInput.computedDailyMacros.calories
      );
    }
  } else {
    energyBalance = String(110);
  }

  const legacyInputForUpdateCalculation: LegacyInput = {
    ...defaultLegacyInput,
    energyBalance,
  };
  if (onboardingState.dietPreferences.dietType === "CHOOSE_YOUR_OWN") {
    if (!onboardingState.dietPreferences.userProvidedMacros) {
      throw new Error("User provided macros are not set");
    }

    legacyInputForUpdateCalculation.adjustedDailyMacros = {
      calories: onboardingState.dietPreferences.userProvidedMacros?.calories,
      fat: onboardingState.dietPreferences.userProvidedMacros?.fat,
      carbs: onboardingState.dietPreferences.userProvidedMacros?.carbohydrates,
      protein: onboardingState.dietPreferences.userProvidedMacros?.protein,
      fiber: 0,
    };
  }

  const legacyInputWithEnergyBalance = updateLegacyInput(legacyInputForUpdateCalculation, user.intake, onboardingState);

  // Now add meal moments
  const updatedMeals = legacyInputWithEnergyBalance.meals.map((legacyMealState: LegacyMealState) => ({
    ...legacyMealState,
    enabled: onboardingState.dietPreferences.mealTimes
      ? onboardingState.dietPreferences.mealTimes.includes(legacyMealState.mealMoment)
      : legacyMealState.enabled,
  }));

  const updatedAndRebalancedMeals = { ...legacyInputWithEnergyBalance, meals: rebalanceSizes(updatedMeals) };
  let updatedLegacyInput = updateLegacyInput(updatedAndRebalancedMeals, user.intake, onboardingState);

  // TODO: Should we add this in?
  // if (diyForm.macrosPreference === "LOW_CARB") {
  //   updatedLegacyInput = adjustLegacyInputCarbs(user.intake, updatedLegacyInput, MAX_CARBS);
  // }

  if (onboardingState.dietPreferences.dietType === "KETO") {
    updatedLegacyInput = adjustLegacyInputCarbs(user.intake, updatedLegacyInput, MAX_CARBS_FOR_KETO);
  }

  const mealSlotSpecifications = convertToMealSlotSpecifications(updatedLegacyInput.meals);

  return mealSlotSpecifications;
}

export function createMealSlotSpecificationFromAnonymousUser(onboardingData: OnboardingState): MealSlotSpecification[] {
  const anonymousUser = createAnonymousUser(onboardingData);

  const mss = createMealSlotSpecificationFromDiyForm(anonymousUser, onboardingData);

  return mss;
}

export async function doOrRedoNutritionPreferencesOnBackend(
  user: User,
  onboardingData: OnboardingState,
  updateUserProfileOnBackend: UpdateUserProfileOnBackend,
  createUserProfileOnBackend: CreateUserProfileOnBackend,
  updateWeeklyNutritionPlanOnBackend: UpdateWeeklyNutritionPlanOnBackend,
  createNutritionDayPlanOnBackend: CreateNutritionDayPlanOnBackend,
  createMealSlotSpecificationOnBackend: CreateMealSlotSpecificationOnBackend,
  createWeeklyNutritionPlanOnBackend: CreateWeeklyNutritionPlanOnBackend,
  setIngredientsToAvoidMutation: SetIngredientsToAvoidMutation,
  ingredientToAvoidObjectsFromBackend: PaginatedIngredientToAvoidList | undefined,
  autoPlanDaysOnBackend: AutoPlanDaysOnBackendMutation
): Promise<void> {
  const updatedUserProfile = createUserProfileFromOnboardingData(onboardingData);

  // Update a user's intake on the backend
  const userProfileCreateBody: UsersUserProfileCreateApiArg = {
    userProfileRequest: {
      ...updatedUserProfile,
    },
  };

  const updateOrCreatePromise = user.intake?.id
    ? updateUserProfileOnBackend({ ...userProfileCreateBody, id: user.intake.id })
    : createUserProfileOnBackend({
        userProfileRequest: {
          ...userProfileCreateBody.userProfileRequest,
          user_id: user.id,
        },
      });

  const userProfileUpdated = await updateOrCreatePromise.unwrap().catch(apiCallErrorHandler());

  // Update a user's meal slot specification
  // Create new NutritionDayPlan
  const createdNutritionDayPlan = await createNutritionDayPlanOnBackend({
    nutritionDayPlanRequest: {
      name: "DIY nutrition plan",
    },
  }).unwrap();

  // NOTE: We need to do this because if it is the first time through there is no intake
  const mss = createMealSlotSpecificationFromAnonymousUser(onboardingData);
  if (!mss) {
    throw new Error("Meal slot specification is not set");
  }

  const createPromises = _.map(mss, (mealSlot) =>
    createMealSlotSpecificationOnBackend({
      mealSlotSpecificationRequest: {
        ...mealSlot,
        nutrition_day_plan: createdNutritionDayPlan.id,
      },
    })
  );

  await Promise.all(createPromises);

  const updateExistingWeeklyNutritionPlan = Boolean(user.intake?.weekly_nutrition_plan);
  // NOTE: We do this afterwards so that the meal slot specifications are created before we update the WNP
  // This results in all the data always being up to date when the call is made to fetch new data
  if (updateExistingWeeklyNutritionPlan) {
    if (!user?.intake?.weekly_nutrition_plan?.id) {
      throw new Error("Client intake weekly nutrition plan id is not defined");
    }

    await updateWeeklyNutritionPlanOnBackend({
      id: user?.intake?.weekly_nutrition_plan?.id,
      weeklyNutritionPlanUpdateRequest: {
        nutrition_day_plan_id: createdNutritionDayPlan.id,
        days_to_update_to_nutrition_day_plan_id: ALL_DAYS_OF_THE_WEEK,
      },
    }).unwrap();
  } else {
    if (!userProfileUpdated) {
      throw new Error("User profile is not defined");
    }

    await createWeeklyNutritionPlanOnBackend({
      weeklyNutritionPlanCreateRequest: {
        // Since this is the first nutrition plan we are creating we populate every day
        monday: createdNutritionDayPlan.id,
        tuesday: createdNutritionDayPlan.id,
        wednesday: createdNutritionDayPlan.id,
        thursday: createdNutritionDayPlan.id,
        friday: createdNutritionDayPlan.id,
        saturday: createdNutritionDayPlan.id,
        sunday: createdNutritionDayPlan.id,

        client_intake: userProfileUpdated.id,
      },
    }).unwrap();
  }

  const ingredientDislikesInBackendFormat = onboardingData.dietPreferences.ingredientDislikes.map(
    (ingredient) => ingredientDislikeToBackendIdentifier[ingredient]
  );

  // If the data from the backend is empty then do not do this
  const ingredientToAvoidIds = ingredientToAvoidObjectsFromBackend?.results
    ? ingredientToAvoidObjectsFromBackend.results
        .filter((ingredient: IngredientToAvoid) => ingredientDislikesInBackendFormat.includes(ingredient.name))
        .map((ingredient: IngredientToAvoid) => ingredient.id)
    : [];

  // Set ingredients to avoid
  await setIngredientsToAvoidMutation({
    setIngredientToAvoidRequest: {
      ingredient_to_avoid_ids: ingredientToAvoidIds,
    },
  })
    .unwrap()
    .catch((error) => {
      Sentry.captureException(error);
      console.error("Failed to set ingredients to avoid:", error);
    });

  const todayDateStringForApiRequest = formatMomentAsDateForApi(moment());
  await autoPlanDaysOnBackend({
    autoPlanDaysRequest: {
      // What if the user is re-doing their preferences on Wednesday? Should we still do 7 days?
      num_days: 7,
      start_date_inclusive: todayDateStringForApiRequest,
      delete_existing_plans: true,
    },
  });
}

function calculateTdeeForWeeklyGoal(onboardingState: OnboardingState, weeklyGoal: WeeklyGoal): number {
  const userProfile = createUserProfileFromOnboardingData(onboardingState);

  const defaultLegacyInput = getDefaultLegacyInput(
    userProfile,
    {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    "diy-nutrition-plan"
  );

  const tdee = defaultLegacyInput.computedDailyMacros.calories;

  const energyBalance = calculateEnergyBalanceMatchingGoal(
    { ...onboardingState, weeklyGoal },
    defaultLegacyInput.computedDailyMacros.calories
  );

  const deficitTdee = (tdee * Number(energyBalance)) / 100;

  return deficitTdee;
}

const MIN_DAILY_CALORIES = 1250;
/**
 * Some of these goals are too aggressive if the person is too small and has too little activity.
 * There should never be daily kcals under 1500, no matter what.
 *
 */
export function appropriateWeeklyGoalsForSafety(onboardingData: OnboardingState): WeeklyGoal[] {
  const isGoalSafe = (goal: WeeklyGoal): boolean => {
    const goalTdee = calculateTdeeForWeeklyGoal(onboardingData, goal);
    return goalTdee >= MIN_DAILY_CALORIES;
  };

  return Object.values(WeeklyGoalEnum).filter(isGoalSafe);
}

export function getBalancedMacros(kcal: number, onboardingState: OnboardingState): Macros {
  if (!onboardingState.physicalStats.weightInKg) {
    throw new Error("Weight is not defined");
  }
  if (!onboardingState.biometricData.gender) {
    throw new Error("Gender is not defined");
  }
  if (!onboardingState.physicalStats.heightInCm) {
    throw new Error("Height is not defined");
  }
  if (!onboardingState.biometricData.age) {
    throw new Error("Age is not defined");
  }

  const protein = onboardingState.physicalStats.weightInKg * 1.6;

  const rmr = mifflinStJoerRMR(
    onboardingState.physicalStats.weightInKg,
    onboardingState.physicalStats.heightInCm,
    onboardingState.biometricData.age,
    onboardingState.biometricData.gender === "MALE"
  );
  const bmr = harrisBenedictBMR(
    onboardingState.physicalStats.weightInKg,
    onboardingState.physicalStats.heightInCm,
    onboardingState.biometricData.age,
    onboardingState.biometricData.gender === "MALE"
  );

  let fat;
  if (onboardingState.biometricData.gender === "MALE") {
    fat = (bmr * 0.25) / 9;
  } else {
    fat = (rmr * 0.25) / 9;
  }

  const MINIMUM_FAT = 40;

  if (fat < MINIMUM_FAT) {
    fat = MINIMUM_FAT;
  }

  return {
    protein,
    fat,
    carbs: (kcal - protein * 4 - fat * 9) / 4,
    calories: kcal,
    // NOTE: Fiber is not currently use so we just put a healthy default
    fiber: 40,
  };
}

export function getMealSlotSpecificationsFromDesiredMacros(
  onboardingState: OnboardingState,
  desiredMacros: Macros
): MealSlotSpecification[] {
  const anonymousUser = createAnonymousUser(onboardingState);

  if (!anonymousUser.intake) {
    throw new Error("Could not create anonymous user");
  }

  const defaultLegacyInput = getDefaultLegacyInput(
    anonymousUser.intake,
    {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    "diy-nutrition-plan"
  );

  const updatedLegacyInput = updateLegacyInput(
    {
      ...defaultLegacyInput,
      computedDailyMacros: desiredMacros,
    },
    anonymousUser.intake,
    onboardingState
  );

  const meals = updatedLegacyInput.meals.map((meal) => ({
    ...meal,
    enabled: onboardingState.dietPreferences.mealTimes
      ? onboardingState.dietPreferences.mealTimes.includes(meal.mealMoment)
      : meal.enabled,
  }));

  const values = meals.reduce((acc, meal) => {
    const newAcc = { ...acc };
    newAcc[meal.mealMoment] = {
      protein: desiredMacros.protein * (meal.size / 100),
      carbs: desiredMacros.carbs * (meal.size / 100),
      fat: desiredMacros.fat * (meal.size / 100),
    };
    return newAcc;
  }, {} as { [x: string]: { protein: number; carbs: number; fat: number } });

  const { updatedMealSlotSpecifications } = getUpdatedMealSlotSpecificationsFromFormValues(values, meals);

  return updatedMealSlotSpecifications;
}

export function createUpdatedFormValues(onboardingData: OnboardingState): DIYFormInterface {
  const formValues = diyCreateNutritionPlanFormInitialValues;
  const mss = createMealSlotSpecificationFromAnonymousUser(onboardingData);

  return { formValues, mss };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateMacrosInState(dispatch: Dispatch<any>, onboardingState: OnboardingState): void {
  const updatedFormValues = createUpdatedFormValues(onboardingState);

  dispatch(userSlice.actions.storeDiyFormResults(updatedFormValues));
}

export function createOnboardingState(userProfile: UserProfile): OnboardingState {
  const DEFAULT_HEIGHT = 170;
  const DEFAULT_WEIGHT = 70;
  const DEFAULT_GOAL_WEIGHT = 65;
  const DEFAULT_WEEKLY_GOAL = WeeklyGoalEnum.LOSE_0_25_KG_PER_WEEK;

  const weeklyGoal = userProfile.goal_weekly_change_in_kg
    ? determineWeeklyGoal(userProfile.goal_weekly_change_in_kg)
    : DEFAULT_WEEKLY_GOAL;

  return {
    goal: userProfile.main_goal || "LOSE_WEIGHT",
    activityLevel: userProfile.activity,
    biometricData: {
      age: userProfile.age || 25,
      gender: userProfile.gender,
    },
    physicalStats: {
      heightInCm: userProfile.height_in_cm || DEFAULT_HEIGHT,
      weightInKg: userProfile.weight || DEFAULT_WEIGHT,
      goalWeight: userProfile.goal_weight_in_kg || DEFAULT_GOAL_WEIGHT,
    },
    weeklyGoal,
    dietPreferences: {
      dietType: MacrosPreferenceEnum.RECOMMENDED,
      intolerances: [],
      ingredientDislikes: [],
      mealTimes: DEFAULT_MEAL_TIMES,
    },
  };
}
