import type moment from "moment";
import { useTheme, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import CalendarStrip from "react-native-calendar-strip";
import { useDispatch, useSelector } from "react-redux";

import { Scale } from "../constants";
import { currentDayInPlannerSelector, plannerSlice } from "../slices/plannerSlice";
import baseStyles from "./SlidingCalendarStyle";

const CalenderView = ({
  // TODO: This should be picked up from the state
  leaveRoomForCustomBrandingHeader = false,
  currentDayInPlanner,
}: {
  leaveRoomForCustomBrandingHeader?: boolean;
  currentDayInPlanner: moment.Moment;
}): JSX.Element => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();

  const styles = StyleSheet.create({
    ...baseStyles,
    calendarStripStyle: { padding: Scale(1), height: Scale(115), marginTop: leaveRoomForCustomBrandingHeader ? 15 : 0 },
    calendarHeaderStyle: { color: theme.colors.gray["400"] },
    dateNumberStyle: { ...baseStyles.dateNumberStyle, color: theme.colors.gray["400"] },
    dateNameStyle: { ...baseStyles.dateNameStyle, color: theme.colors.gray["400"] },
    highlightDateNameStyle: { color: "white" },
    highlightDateContainerStyle: {
      ...baseStyles.highlightDateContainerStyle,
      backgroundColor: theme.colors.primary["600"],
    },
    iconStyle: { tintColor: theme.colors.gray["400"] },
  });

  const onDateSelected = (date: moment.Moment): void => {
    if (date) {
      dispatch(plannerSlice.actions.setCurrentDayInPlanner(date.format()));
    }
  };

  return (
    <>
      <CalendarStrip
        numDaysInWeek={7}
        selectedDate={currentDayInPlanner}
        onDateSelected={onDateSelected}
        // NOTE: The below is not in scope for the initial release
        // markedDates={nutritionalInfoOverviewForDates}

        style={styles.calendarStripStyle}
        calendarColor={"white"}
        calendarHeaderStyle={styles.calendarHeaderStyle}
        dateNumberStyle={styles.dateNumberStyle}
        dateNameStyle={styles.dateNameStyle}
        dayContainerStyle={styles.dayContainerStyle}
        highlightDateContainerStyle={styles.highlightDateContainerStyle}
        highlightDateNumberStyle={styles.highlightDateNumberStyle}
        highlightDateNameStyle={styles.highlightDateNameStyle}
        iconLeftStyle={styles.iconStyle}
        iconRightStyle={styles.iconStyle}
        locale={{ name: i18n.language, config: i18n.language }}
      />

      {/* NOTE: This is functionality that has been removed from the initial release */}
      {/* <View style={styles.grid}>
        <CommonIconButton
          onPress={() => {
            navigation.navigate(Routes.ThreeDaysScreen as keyof RootStackParamList);
          }}
          source={Images.GridIcon}
          size={24}
        />
      </View> */}
    </>
  );
};
export default CalenderView;
