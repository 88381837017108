import { StyleSheet } from "react-native";

import { Scale } from "../constants";
import { FontFamily } from "../constants/fonts";

const style = StyleSheet.create({
  addMoreContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: Scale(5),
    paddingVertical: Scale(8),
    borderWidth: StyleSheet.hairlineWidth,
  },
  mainContainer: {
    paddingVertical: Scale(20),
    paddingHorizontal: Scale(15),
    // backgroundColor: Colors.whiteColor,
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: Scale(5),
    marginBottom: Scale(20),
  },
  head: {
    fontSize: Scale(20),
    // color: Colors.largeTextColor,
    lineHeight: Scale(28),
    fontWeight: "600",
  },
  mealBox: {
    marginTop: Scale(20),
    height: Scale(112),
    width: "100%",
    // backgroundColor: Colors.grey,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  mealBoxText: {
    fontSize: Scale(16),
    lineHeight: Scale(24),
    // color: Colors.greyTextColor,
    marginLeft: Scale(13.34),
  },
  text: {
    fontSize: Scale(12),
    // color: Colors.largeTextColor,
    lineHeight: Scale(16),
  },
  nutrientTextDiv: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nutrientText1: {
    fontFamily: FontFamily.medium,
    fontSize: 12,
    // color: Colors.largeTextColor,
    lineHeight: Scale(25),
  },
  nutrientText2: {
    fontFamily: FontFamily.medium,
    fontSize: 12,
    // color: Colors.darkGreyTextColor,
    lineHeight: Scale(25),
  },
  titleDiv: { flex: 1, display: "flex", flexDirection: "row" },
});

export default style;
