import { AntDesign } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import {
  AlertDialog,
  Button,
  Center,
  Flex,
  FormControl,
  IconButton,
  Input,
  ScrollView,
  Text,
  useDisclose,
  useTheme,
  View,
} from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button as NativeButton, SafeAreaView, View as NativeView } from "react-native";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { CommonModal, CommonPageHeader, CommonPureTextInput } from "../commons";
import CommonHeaderDivider from "../commons/CommonHeaderDivider";
import RecipeMacros from "../components/RecipeMacros";
import { commonStyles, height, Images, isDesktopScreen, Routes, Scale, VerticalScale, width } from "../constants";
import {
  formatMealType,
  formatMomentAsBackendFormatDateString,
  formatNumberAsWholeNumber,
  isIos,
} from "../helpers/generalHelpers";
import type { RecipeAddOwnScreenParams, RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type {
  Ingredient,
  IngredientPost,
  IngredientPostRequest,
  PlannerSaveAndPlanRecipeMealCreateApiArg,
  RecipeTagRequest,
  RecipeTemplate,
  RecipeTemplateRequest,
  SuggestedServing,
} from "../services/backendTypes";
import logger from "../services/logger";
import { calendarDaysSelector, currentDayInPlannerSelector } from "../slices/plannerSlice";
import { userSelector, viewAsUserSelector } from "../slices/userSlice";
import type { RecipeItemType, RecipeMacrosItemType } from "../types";
import styles from "./RecipeAddOwnScreenStyle";
import { IngredientComponent, MemoizedIngredientComponent } from "./RecipeDetailScreen";

const {
  useFoodRecipeTemplateCreateMutation,
  useFoodIngredientCreateMutation,
  useFoodRecipeTemplatePartialUpdateMutation,
  useFoodIngredientDestroyMutation,
  useFoodIngredientPartialUpdateMutation,
  useFoodRecipeTemplateDestroyMutation,
  useFoodCreateRecipeCreateMutation,
  usePlannerSaveAndPlanRecipeMealCreateMutation,
} = backendApi;

type Props = NativeStackScreenProps<RootStackParamList, Routes.RecipeAddOwnScreen>;

const RecipeAddOwnScreen = ({
  navigation,
  route: {
    params: { mealSlotSpecification, onSave, initialRecipeMeal, submitMessage },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [allIngredientQuantityInputState, setAllIngredientQuantityInputState] = useState<{
    [ingredientIndex: number]: string;
  }>({});

  const currentDayInPlanner = useSelector(currentDayInPlannerSelector);
  const calendarDaysInStore = useSelector(calendarDaysSelector);
  const currentCalendarDay = calendarDaysInStore[formatMomentAsBackendFormatDateString(currentDayInPlanner)];

  const theme = useTheme();

  const viewAsUser = useSelector(viewAsUserSelector);
  const realUser = useSelector(userSelector);
  const user = viewAsUser || realUser;

  const [newOwnRecipeTemplate, setNewOwnRecipeTemplate] = useState<RecipeTemplate | undefined>(undefined);

  const [createRecipeTemplate] = useFoodRecipeTemplateCreateMutation();
  const [deleteRecipeTemplate, { isLoading: isLoadingDeleteRecipeTemplate }] = useFoodRecipeTemplateDestroyMutation();
  const [updateRecipeTemplate, { isLoading: isLoadingUpdateRecipeTemplate }] =
    useFoodRecipeTemplatePartialUpdateMutation();
  const [updateRecipeTemplateIngredient, { isLoading: isLoadingIngredientUpdate }] =
    useFoodIngredientPartialUpdateMutation();
  const [createNewRecipeTemplateFromExisting, { isLoading: isLoadingCreateRecipeTemplate }] =
    useFoodCreateRecipeCreateMutation();
  const [saveAndPlanRecipeTemplate, { isLoading: isLoadingSaveAndPlanRecipeTemplate }] =
    usePlannerSaveAndPlanRecipeMealCreateMutation();

  const [createIngredient] = useFoodIngredientCreateMutation();
  const [deleteIngredient, { isLoading: isLoadingDeleteIngredient }] = useFoodIngredientDestroyMutation();
  const [updateIngredient, { isLoading: isLoadingUpdateIngredient }] = useFoodIngredientPartialUpdateMutation();
  // const [bulkCreateIngredient, { isLoading: isLoadingBulkUpdateIngredient }] = useFoodBulkIngredientCreateMutation();

  const [exitModalVisible, setExitModalVisible] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclose();

  const cancelRef = React.useRef(null);

  const recipeMacrosItems: RecipeMacrosItemType[] = [
    {
      macroName: "kcal",
      progress: Number(formatNumberAsWholeNumber(newOwnRecipeTemplate?.kcal || 0)),
      total: Number(formatNumberAsWholeNumber(mealSlotSpecification.kcal || 0)),
      unit: "kcal",
    },
    {
      macroName: "protein",
      progress: Number(formatNumberAsWholeNumber(newOwnRecipeTemplate?.protein || 0)),
      total: Number(formatNumberAsWholeNumber(mealSlotSpecification.protein || 0)),
      unit: "g",
    },
  ];

  const handleDeleteRecipeTemplate = async (): Promise<void> => {
    if (initialRecipeMeal && initialRecipeMeal.recipe_template && initialRecipeMeal.recipe_template.id) {
      await updateRecipeTemplate({
        id: initialRecipeMeal.recipe_template.id,
        patchedRecipeTemplateRequest: {
          published: false,
        },
      }).unwrap();
    } else {
      throw new Error("");
    }

    onCloseDeleteDialog();
    navigation.pop(2);
  };

  const deleteRecipeTemplateModal = (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpenDeleteDialog}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Body>
          <Text>{t("general.dialog_confirmation_question")}</Text>
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button
            isLoading={isLoadingDeleteRecipeTemplate}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={handleDeleteRecipeTemplate}
            testID={"confirmDeleteRecipe-button"}
          >
            {t("general.delete")}
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );

  const getNewRecipeTemplateNameFromInitialRecipeMeal = (): string => {
    if (!initialRecipeMeal) {
      throw new Error("No initial recipe meal was provided");
    }

    return initialRecipeMeal?.recipe_template.source_provider === "WEEKMEALS"
      ? t("recipe_detail.user_generated_meal_display_name", { mealName: initialRecipeMeal.recipe_template.name })
      : initialRecipeMeal.recipe_template.name || "";
  };

  const ownRecipeTemplateSchema = Yup.object().shape({
    name: Yup.string().default("").required(t("general.required")),
    alsoPlanAsMeal: Yup.boolean().default(false),
  });

  type OwnRecipeTemplateSchema = Yup.InferType<typeof ownRecipeTemplateSchema>;

  const initialValues = ownRecipeTemplateSchema.cast({
    name: initialRecipeMeal ? getNewRecipeTemplateNameFromInitialRecipeMeal() : "",
  });

  // TODO: To implement
  // const onBarcodeScanButtonPress = (): void => {
  //   logger.debug("TODO: onBarcodeScanButtonPress");
  //   navigation.navigate(Routes.BarcodeScannerScreen);
  // };

  const onCloseButton = (): void => {
    setExitModalVisible(true);
  };

  const onSubmit = async (values: OwnRecipeTemplateSchema): Promise<void> => {
    if (newOwnRecipeTemplate && newOwnRecipeTemplate.id) {
      if (values.alsoPlanAsMeal) {
        if (!mealSlotSpecification) {
          throw new Error("No meal slot specification was provided");
        }
        if (!currentCalendarDay?.id) {
          throw new Error("No calendar day was provided");
        }

        const payload: PlannerSaveAndPlanRecipeMealCreateApiArg = {
          saveAndPlanRecipeTemplateRequest: {
            id: newOwnRecipeTemplate.id,
            name: values.name,
            calendar_day_id: currentCalendarDay.id,
            meal_slot_id: mealSlotSpecification.id,
          },
        };

        if (initialRecipeMeal) {
          // To unpublish the existing (now outdated) RecipeTemplate
          payload.saveAndPlanRecipeTemplateRequest.existing_recipe_template_id = initialRecipeMeal.recipe_template.id;
        }

        await saveAndPlanRecipeTemplate(payload);
      } else {
        const recipeTemplate = await updateRecipeTemplate({
          id: newOwnRecipeTemplate.id,
          patchedRecipeTemplateRequest: {
            published: true,
            name: values.name,
          },
        }).unwrap();

        await onSave(recipeTemplate, true);
      }

      let screensToPop = viewAsUser ? 2 : 3;
      // NOTE: If we are using the "Create new own recipe" (as opposed to the create new own recipe from existing) flow
      // then we need to pop an additional screen
      if (viewAsUser && initialRecipeMeal) {
        screensToPop += 1;
      }

      // NOTE: This is used instead of navigating directly to DiaryViewScreen
      // because doing that caused errors in cypress tests
      navigation.pop(screensToPop);
    } else {
      throw new Error("Error with recipe creation");
    }
  };

  const getOrCreateRecipeTemplate = async ({ name }: { name: string }, publish = false): Promise<RecipeTemplate> => {
    let recipeTemplate;
    if (!newOwnRecipeTemplate) {
      if (!user) {
        throw new Error("User is not set");
      }

      const tags: RecipeTagRequest[] = [
        {
          name: mealSlotSpecification.meal_type,
        },
      ];

      const recipeTemplateRequest: RecipeTemplateRequest = {
        name,
        author: user.id,
        source_provider: "USER_GENERATED",
        published: publish,
        tags,
      };
      recipeTemplate = await createRecipeTemplate({
        recipeTemplateRequest,
      }).unwrap();
    } else {
      recipeTemplate = newOwnRecipeTemplate;
    }
    return recipeTemplate;
  };

  const addIngredientToRecipeTemplate = async (
    quantity: number,
    recipeTemplate: RecipeTemplate,
    suggestedServing: SuggestedServing | undefined
  ): Promise<IngredientPost> => {
    if (!recipeTemplate.id) throw new Error("RecipeTemplate has no id");
    if (!suggestedServing || !suggestedServing.id) throw new Error("SuggestedServing has no id");

    const ingredientPostRequest: IngredientPostRequest = {
      quantity,
      object_id: recipeTemplate.id,
      content_type: "recipetemplate",
      suggested_serving: suggestedServing.id,
    };

    const newIngredient = await createIngredient({ ingredientPostRequest }).unwrap();
    return newIngredient;
  };

  const updateRecipeTemplateMacros = (recipeTemplate: RecipeTemplate): RecipeTemplate => {
    const kcal = recipeTemplate.ingredients.reduce((acc, ingredient) => {
      if (!ingredient.suggested_serving) return 0;

      return acc + ingredient.suggested_serving.kcal * ingredient.quantity;
    }, 0);

    const protein = recipeTemplate.ingredients.reduce((acc, ingredient) => {
      if (!ingredient.suggested_serving) return 0;
      return acc + ingredient.suggested_serving.protein * ingredient.quantity;
    }, 0);

    const updatedRecipeTemplate = {
      ...recipeTemplate,
      kcal,
      protein,
    };

    return updatedRecipeTemplate;
  };

  const addOrEditRecipeTemplateIngredient = (
    recipeTemplate: RecipeTemplate,
    updatedOrCreatedIngredient: Ingredient
  ): RecipeTemplate => {
    const updatedIngredients = _.map(
      _.unionBy(recipeTemplate.ingredients, [updatedOrCreatedIngredient], "id"),
      (currentIngredient) => {
        if (currentIngredient.id === updatedOrCreatedIngredient.id) {
          return updatedOrCreatedIngredient;
        }
        return currentIngredient;
      }
    );

    const updatedRecipeTemplate = {
      ...recipeTemplate,
      ingredients: updatedIngredients,
    };
    return updateRecipeTemplateMacros(updatedRecipeTemplate);
  };

  const deleteRecipeTemplateIngredient = (recipeTemplate: RecipeTemplate, ingredientId: number): RecipeTemplate => {
    const updatedIngredients = _.filter(
      recipeTemplate.ingredients,
      (currentIngredient) => currentIngredient.id !== ingredientId
    );

    const updatedRecipeTemplate = {
      ...recipeTemplate,
      ingredients: updatedIngredients,
    };
    return updateRecipeTemplateMacros(updatedRecipeTemplate);
  };

  const onChooseProduct = async (
    ingredient: Ingredient | undefined,
    suggestedServing: SuggestedServing,
    quantity: number,
    formValues: OwnRecipeTemplateSchema
  ): Promise<void> => {
    const recipeTemplate = await getOrCreateRecipeTemplate(formValues);

    if (!recipeTemplate.id) {
      throw new Error("Recipe template is not set");
    }

    if (!suggestedServing.id) {
      throw new Error("Suggested serving is not set");
    }

    let updatedOrCreatedIngredient: Ingredient;

    if (ingredient && ingredient.id) {
      // Update the ingredient
      updatedOrCreatedIngredient = await updateRecipeTemplateIngredient({
        id: ingredient.id,
        patchedIngredientRequest: {
          quantity,
          suggested_serving: suggestedServing,
        },
      }).unwrap();
    } else {
      updatedOrCreatedIngredient = (await addIngredientToRecipeTemplate(
        quantity,
        recipeTemplate,
        suggestedServing
      )) as unknown as Ingredient;
    }

    const updatedRecipeTemplate = addOrEditRecipeTemplateIngredient(recipeTemplate, updatedOrCreatedIngredient);
    setNewOwnRecipeTemplate(updatedRecipeTemplate);
    navigation.goBack();
  };

  const handleAddProduct = (formValues: OwnRecipeTemplateSchema): void => {
    navigation.push(Routes.AddProductStack, {
      screen: Routes.FoodSearchScreen,
      params: {
        onChoose: async (product, suggestedServing, quantity) =>
          onChooseProduct(undefined, suggestedServing, quantity, formValues),
      },
    });
  };

  const handleEditIngredient = (ingredient: Ingredient, formValues: OwnRecipeTemplateSchema): void => {
    if (!ingredient) {
      throw new Error("Tried to edit a non editable Ingredient or GeneratedRecipeMealIngredient");
    }

    navigation.push(Routes.AddProductStack, {
      screen: Routes.AddIngredientScreen,
      params: {
        ingredient,
        onChoose: async (product, suggestedServing, quantity) => {
          await onChooseProduct(ingredient, suggestedServing, quantity, formValues);
        },
      },
    });
  };

  const onEditIngredientQuantity = async (ingredient: Ingredient, quantity: number): Promise<void> => {
    if (!ingredient.id) {
      throw new Error("Tried to edit a non editable Ingredient");
    }

    if (!newOwnRecipeTemplate || !newOwnRecipeTemplate.id) {
      throw new Error("Tried to edit a non editable recipe");
    }

    let editedRecipeTemplate;
    if (!quantity) {
      await deleteIngredient({ id: ingredient.id });
      editedRecipeTemplate = deleteRecipeTemplateIngredient(newOwnRecipeTemplate, ingredient.id);
    } else {
      const updatedIngredient = await updateIngredient({
        id: ingredient.id,
        patchedIngredientRequest: {
          quantity,
        },
      }).unwrap();
      editedRecipeTemplate = addOrEditRecipeTemplateIngredient(newOwnRecipeTemplate, updatedIngredient);
    }

    setNewOwnRecipeTemplate(editedRecipeTemplate);
  };

  const createRecipeTemplateFromRecipeMeal = async (): Promise<void> => {
    if (!initialRecipeMeal) {
      throw new Error("No initialRecipeMeal was provided");
    }

    if (!user) {
      throw new Error("No user was provided");
    }

    return createNewRecipeTemplateFromExisting({
      createNewRecipeTemplateRequest: {
        ...initialRecipeMeal,
        author: user.id,
        recipe_template: { ...initialRecipeMeal.recipe_template, tags: [], image: undefined },
        // NOTE: The compiler thinks an ingredient could be a number (ie, the id) or a GeneratedRecipeMealIngredient
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ingredients: initialRecipeMeal.ingredients,
      },
    })
      .unwrap()
      .then((newRecipeTemplate) => {
        const newIngredients = newRecipeTemplate.ingredients;

        setNewOwnRecipeTemplate({
          ...newRecipeTemplate,
          ingredients: newIngredients as unknown as Ingredient[],
        });
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (!initialRecipeMeal) return;

    // NOTE: This is an async function but it needs to be used in a useEffect
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await createRecipeTemplateFromRecipeMeal();
    })();
  }, [initialRecipeMeal]);

  const onAbandonRecipeTemplate = async (): Promise<void> => {
    if (newOwnRecipeTemplate && newOwnRecipeTemplate.id) {
      await deleteRecipeTemplate({ id: newOwnRecipeTemplate.id });
    }
    setExitModalVisible(false);
    navigation.goBack();
  };

  const ModalContent = (): JSX.Element => (
    <>
      <Text fontSize="16" textAlign="center">
        {t("recipe_create.stop_editing_recipe_confirmation_text")}
      </Text>
      <NativeView style={styles.modalButtonContainer}>
        <Button
          variant={"subtle"}
          isLoading={isLoadingDeleteRecipeTemplate}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={async () => onAbandonRecipeTemplate()}
          testID={"addOwnRecipeExitConfirm-button"}
        >
          {t("general.dialog_confirmation")}
        </Button>

        <Button
          onPress={() => {
            setExitModalVisible(false);
          }}
          testID={"addOwnRecipeExitCancel-button"}
          mt="2"
        >
          {t("general.back_button_text")}
        </Button>
      </NativeView>
    </>
  );
  const isDesktop = isDesktopScreen();

  const headerComponent = React.useCallback(
    () => (
      <CommonPageHeader
        title={t("recipe_create.screen_heading")}
        onPressBack={() => {
          navigation.goBack();
        }}
        onPressClose={() => {
          onCloseButton();
        }}
      />
    ),
    [t]
  );

  const recipeMacrosComponent = React.useCallback(
    () => <RecipeMacros RecipeMacrosItems={recipeMacrosItems} showUnits={false} />,
    [recipeMacrosItems]
  );

  return (
    <SafeAreaView style={commonStyles.container}>
      <Formik
        // NOTE: I don't know why this error happens
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={ownRecipeTemplateSchema}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, values, setFieldValue, errors }) => (
          <NativeView style={{ flex: 1 }}>
            {headerComponent()}

            <NativeView testID="macros-view" nativeID="RecipeAddOwnMacrosView">
              {recipeMacrosComponent()}
            </NativeView>

            <ScrollView style={commonStyles.mainContainer}>
              <NativeView style={styles.titleContainer}>
                <FormControl isRequired isInvalid={"name" in errors}>
                  <FormControl.Label>{t("recipe_create.name_label")}</FormControl.Label>
                  <Input
                    placeholder={t("recipe_create.name_input_field_placeholder")}
                    onBlur={handleBlur("name")}
                    onChangeText={handleChange("name")}
                    value={String(values.name)}
                    testID="ownRecipeTitleInput"
                    borderRadius="4"
                    height="12"
                    borderWidth={0}
                    fontWeight={values.name ? "bold" : "normal"}
                    py="3"
                    px="2"
                    fontSize="14"
                    borderTopWidth="1"
                    borderBottomWidth="1"
                    borderLeftWidth={"1"}
                    borderRightWidth={"1"}
                    borderStyle={"solid"}
                  />

                  <FormControl.ErrorMessage>{errors.name}</FormControl.ErrorMessage>
                </FormControl>
              </NativeView>

              {/* Delete button */}
              {initialRecipeMeal?.recipe_template.published &&
              initialRecipeMeal.recipe_template.source_provider === "USER_GENERATED" ? (
                <NativeView
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: Scale(5),
                    backgroundColor: isIos() ? theme.colors.primary["600"] : undefined,
                  }}
                >
                  <NativeButton
                    title={t("general.delete")}
                    onPress={() => {
                      onOpenDeleteDialog();
                    }}
                    color={isIos() ? "white" : theme.colors.primary["600"]}
                    testID={"addOwnRecipeDelete-button"}
                  />
                </NativeView>
              ) : null}

              {Boolean(values.name) && (
                <>
                  <View style={styles.addIngredientsContainer}>
                    <Text fontSize={"18"}>{t("recipe_detail.ingredients_tab_title")}</Text>
                    <NativeView style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                      <IconButton
                        onPress={() => handleAddProduct(values)}
                        icon={<AntDesign name="pluscircle" size={32} color={theme.colors.primary["600"]} />}
                        testID={"addOwnRecipeAddIngredient-button"}
                        disabled={!values.name}
                        nativeID="RecipeAddOwnAddIngredientButton"
                      />
                    </NativeView>
                  </View>

                  <>
                    {newOwnRecipeTemplate?.ingredients.map((ingredient, ingredientIndex) => {
                      const quantityInputState = allIngredientQuantityInputState[ingredientIndex] || "";

                      const setQuantityInputState = (newQuantityInputState: string): void => {
                        setAllIngredientQuantityInputState({
                          ...allIngredientQuantityInputState,
                          [ingredientIndex]: newQuantityInputState,
                        });
                      };

                      return (
                        // TODO: Fix MemoizedIngredientComponent and readd
                        <IngredientComponent
                          key={ingredientIndex}
                          {...{
                            ingredientIndex,
                            ingredient,
                            portions: 1,
                            editable: true,
                            onEditIngredientQuantity,
                            t,
                            quantityInputState,
                            setQuantityInputState,
                            onPressIngredientSwapButton: () => handleEditIngredient(ingredient, values),
                            isLoading: isLoadingDeleteIngredient || isLoadingUpdateIngredient,
                          }}
                        />
                      );
                    })}
                  </>
                </>
              )}
            </ScrollView>

            {newOwnRecipeTemplate && newOwnRecipeTemplate.ingredients.length > 0 ? (
              <View style={isDesktop ? commonStyles.bottomFixedContainer : commonStyles.paddingContainer}>
                <Button
                  isLoading={isLoadingUpdateRecipeTemplate || isLoadingSaveAndPlanRecipeTemplate}
                  onPress={() => {
                    setFieldValue("alsoPlanAsMeal", true);
                    handleSubmit();
                  }}
                  testID={"addOwnRecipeSaveAndPlan-button"}
                  nativeID="RecipeAddOwnSaveAndPlanButton"
                  mt="3"
                >
                  {t("recipe_create.save_and_plan_button_text", {
                    meal: formatMealType(mealSlotSpecification.meal_moment, t),
                  })}
                </Button>

                <Button
                  isLoading={isLoadingUpdateRecipeTemplate}
                  onPress={() => handleSubmit()}
                  testID={"addOwnRecipeSave-button"}
                  mt="3"
                >
                  {submitMessage || t("general.save_button_text")}
                </Button>
              </View>
            ) : null}

            <CommonModal
              modalProps={{
                visible: exitModalVisible,
              }}
              ModalContent={ModalContent}
              modalHeight={height - 150}
              modalRef={null}
              onRequestClose={() => setExitModalVisible(false)}
            />
          </NativeView>
        )}
      </Formik>
      {deleteRecipeTemplateModal}
    </SafeAreaView>
  );
};
export default RecipeAddOwnScreen;
