import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  avatarHeader: {
    marginTop: 50,
  },
  headerImg: {
    width: "100%",
    height: "40%",
  },
  planTitle: {
    fontSize: 24,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
  },

  sectionTitle: {
    fontSize: 20,
    // color: Colors.largeTextColor,
    fontWeight: "bold",
  },
  greenTitle: {
    fontSize: 24,
    // color: Colors.primaryGreenColor,
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  itemContainer: {
    marginVertical: 16,
  },
  itemRowContainer: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 16,
  },
  labelText: {
    // color: Colors.sectionTextColor,
    fontSize: 16,
    marginBottom: 6,
  },
  valueText: {
    // color: Colors.textColor,
    fontSize: 16,
  },
  sportImg: {
    marginRight: 12,
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: 24,
  },
  avatarImg: {
    width: 40,
    height: 40,
    marginHorizontal: 24,
  },
  rowContainer: {
    flexDirection: "row",
    paddingVertical: 16,
    alignItems: "center",
  },
  roleText: {
    fontSize: 12,
    // color: Colors.textColor
  },
  nameText: {
    fontSize: 16,
    fontWeight: "600",
    // color: Colors.largeTextColor
  },
  descriptionText: {
    // color: Colors.textColor,
    fontSize: 20,
    fontWeight: "500",
  },
  bottomContainer: {
    paddingHorizontal: 24,
    bottom: 40,
  },
  startBtn: {
    // backgroundColor: Colors.primaryGreenColor,
    height: 48,
    borderRadius: 8,
  },
  startText: { fontSize: 18, fontWeight: "500", color: "white" },
  contentContainer: {
    paddingBottom: 40,
  },
});

export default styles;
