import _ from "lodash";

function isIntercomWebLoaded(): boolean {
  return typeof window !== "undefined" && window.Intercom && _.isFunction(window.Intercom);
}

export function sendUpdateRequestToIntercom(): void {
  if (isIntercomWebLoaded()) {
    window.Intercom("update");
  }
}

export function sendShutdownRequestToIntercom(): void {
  if (isIntercomWebLoaded()) {
    window.Intercom("shutdown");
  }
}
