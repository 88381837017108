import { StyleSheet } from "react-native";

import { height, Scale, width } from "../constants";

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    // backgroundColor: Colors.white,
  },

  titleContainer: {
    flex: 0.39,

    alignItems: "center",
    textAlign: "center",
    justifyContent: "flex-end",
  },
  headingFirst: {
    textAlign: "center",

    // color: Colors.textColor,
    fontStyle: "italic",
    fontWeight: "500",

    width: width / 1.7,
    lineHeight: 32,
    letterSpacing: 1,
  },
  headingSecond: {
    textAlign: "center",

    // color: Colours.primaryGreenColor,
    paddingVertical: Scale(10),
  },
  headingThird: {
    alignSelf: "center",
    textAlign: "center",
    // color: Colors.textColor,
    fontWeight: "700",
  },

  meditationIcon: {
    flex: 0.6,
    height: height * 0.8,
    width: width * 0.8,
    resizeMode: "contain",
    alignSelf: "center",
  },
  buttonContainer: {
    marginBottom: Scale(50),
  },
});

export default styles;
