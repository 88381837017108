import "react-native-gesture-handler";
import "expo-dev-client";
import "./i18n";
import "./intercom-override.css";

import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import {
  DMSans_400Regular as dmSans400Regular,
  DMSans_500Medium as dmSans500Medium,
  DMSans_700Bold as dmSans700Bold,
  useFonts,
} from "@expo-google-fonts/dm-sans";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as SentryReact from "@sentry/react";
import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import Updates from "expo-updates";
import _ from "lodash";
import { Button, Center, NativeBaseProvider, Text, View } from "native-base";
import React, { Dispatch } from "react";
import SSRProvider from "react-bootstrap/SSRProvider";
import { TFunction, useTranslation } from "react-i18next";
import { LogBox, StyleSheet } from "react-native";
import { Theme, ThemeProvider } from "react-native-elements";
import { SafeAreaView } from "react-native-safe-area-context";
import { enableFreeze } from "react-native-screens";
import { Provider, useDispatch, useSelector } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "sentry-expo";

import { PRODUCTION, SENTRY_DSN } from "./app/src/constants";
import { getTheme } from "./app/src/constants/theme";
import { addOpacityToBaseHexColour } from "./app/src/helpers/generalHelpers";
import { logout } from "./app/src/helpers/logout";
import NavigationStack from "./app/src/navigation/NavigationStack";
import logger from "./app/src/services/logger";
import { getBrandSelector } from "./app/src/slices/brandSlice";
import { userSelector } from "./app/src/slices/userSlice";
import { store, USER_LOGOUT_ACTION } from "./store";

enableFreeze(true);

const LOG_MESSAGES_TO_IGNORE = ["SerializableStateInvariantMiddleware", "ImmutableStateInvariantMiddleware"];

if (!PRODUCTION) {
  LogBox.ignoreLogs(LOG_MESSAGES_TO_IGNORE);
}

if (SENTRY_DSN) {
  console.log("Sentry enabled.");

  Sentry.init({
    dsn: SENTRY_DSN,
    enableInExpoDevelopment: true,
    debug: !PRODUCTION,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 0.05,
    beforeSend(event, hint) {
      if (hint) {
        const exception = hint.originalException;
        const errorMessage = exception instanceof Error ? exception.message : exception;
        const MIDDLEWARE_EXCEPTION_NAME = "api/executeQuery/rejected";
        if (errorMessage && _.startsWith(errorMessage, MIDDLEWARE_EXCEPTION_NAME)) {
          // NOTE: We do this to avoid Sentry grouping different errors which are captured in the middleware

          // eslint-disable-next-line no-param-reassign
          event.fingerprint = [errorMessage];
        }
      }

      return event;
    },
  });
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});

function App(): JSX.Element | null {
  const [loaded] = useFonts({
    DMSans_400Regular: dmSans400Regular,
    DMSans_500Medium: dmSans500Medium,
    DMSans_700Bold: dmSans700Bold,
  });
  if (!loaded) {
    return null;
  }

  // TODO: Consider using this: https://www.npmjs.com/package/@bugsnag/plugin-react-native-unhandled-rejection
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function globalPromiseRejectionHandler(this: WindowEventHandlers, p: PromiseRejectionEvent): any {
    console.log("Unhandled Rejection at: Promise", p);
    console.log("promise", JSON.stringify(p, null, 2));

    console.log("Reason: ", this);
  }

  // Here we assign our handler to the corresponding global, window property
  window.onunhandledrejection = globalPromiseRejectionHandler;

  const ErrorBoundaryFallbackComponent = ({
    error,
    resetError,
    t,
    dispatch,
  }: {
    error: Error;
    resetError: () => void;
    t: TFunction;
    dispatch: Dispatch<unknown>;
  }): JSX.Element => (
    <View
      style={{
        flex: 1,
        alignItems: "center",
      }}
    >
      <View mb="4">
        <Center>
          <Text mt="10">{t("general.error_boundary_generic_error")}</Text>
        </Center>
        {error ? <Text>{String(error)}</Text> : null}
      </View>

      <Button onPress={resetError}>{t("general.error_boundary_try_again_button_text")}</Button>
      <Button
        onPress={() => {
          logout(dispatch);
          resetError();
        }}
        mt="2"
      >
        {t("general.error_boundary_reset_app_button")}
      </Button>
    </View>
  );

  const NativeBaseApp = (): JSX.Element => {
    const user = useSelector(userSelector);
    const brand = useSelector(getBrandSelector);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const nativeBaseTheme = getTheme(user, brand);

    const rnElementsTheme: Theme = {
      colors: {
        primary: nativeBaseTheme.colors.primary[500],
        // NOTE: `searchBg` is used as a lighter version of the primary colour when needed in the app
        searchBg: addOpacityToBaseHexColour(nativeBaseTheme.colors.primary[500], 12.5),
      },
    };

    return (
      <NativeBaseProvider theme={nativeBaseTheme}>
        <ThemeProvider theme={rnElementsTheme}>
          <ActionSheetProvider>
            <SentryReact.ErrorBoundary
              fallback={({ error, resetError }) => (
                <ErrorBoundaryFallbackComponent error={error} resetError={resetError} t={t} dispatch={dispatch} />
              )}
            >
              <SafeAreaView style={styles.container}>
                <NavigationStack />
                <StatusBar style="auto" />
              </SafeAreaView>
            </SentryReact.ErrorBoundary>
          </ActionSheetProvider>
        </ThemeProvider>
      </NativeBaseProvider>
    );
  };

  // NOTE: Exposing the store for Cypress tests
  if ("Cypress" in window) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.store = store;
  }

  return (
    <SSRProvider>
      {/* NOTE: Since updating to Expo SDK 48 the type compiler gives an error on this. */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
          <NativeBaseApp />
        </PersistGate>
      </Provider>
    </SSRProvider>
  );
}

export default App;
