import { Button, Text, useTheme } from "native-base";
import React from "react";
import { GestureResponderEvent, TextStyle, TouchableOpacity, ViewStyle } from "react-native";

type CommonButtonProps = {
  title: string;
  externalContainerStyle?: ViewStyle;
  onPress?: (event: GestureResponderEvent) => void;
  disable?: boolean;
  externalTextStyle?: TextStyle;
  testID?: string;
};

const CommonButton: React.FC<CommonButtonProps> = ({
  title,
  onPress,
  disable,
  externalTextStyle,
  externalContainerStyle,
  testID,
}) => {
  const theme = useTheme();

  return (
    <TouchableOpacity>
      <Button
        borderRadius={"8"}
        disabled={disable}
        onPress={onPress}
        marginLeft={5}
        marginRight={5}
        style={externalContainerStyle}
        testID={testID}
        backgroundColor={theme.colors.primary["400"]}
      >
        <Text color={"white"} fontWeight={"700"} fontSize={"lg"} style={externalTextStyle}>
          {title}
        </Text>
      </Button>
    </TouchableOpacity>
  );
};

export default CommonButton;
