import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Center, Heading, Icon, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isDesktopScreen, Routes } from "../constants";
import { isMobilePlatform } from "../helpers/generalHelpers";
import { ContactSupportLink, HelpArticleButton, StripeCustomerPortalLink } from "../helpers/supportHelpers";
import { isUserInPaymentRequiredOrganisation } from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import { userSelector } from "../slices/userSlice";
import styles from "./LoginScreenStyles";

type Props = NativeStackScreenProps<RootStackParamList, Routes.BillingIssueScreen>;

const HELP_ARTICLE_CANT_FIND_SUBSCRIPTION_URL =
  "https://help.nutrition-app.com/articles/9953348-we-can-t-find-your-subscription";

const BillingIssueScreen = ({ route, navigation }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isDesktop = isDesktopScreen();

  const user = useSelector(userSelector);
  const isCoach = user?.is_coach;
  const isWeb = !isMobilePlatform();
  const isPaymentRequired = user ? isUserInPaymentRequiredOrganisation(user) : false;

  const renderContent = (): JSX.Element => {
    if (isCoach) {
      return (
        <View testID="validAccountWarning-coach">
          <Heading>{t("billing_issue_screen.subscription_error")}</Heading>
          {isWeb ? <StripeCustomerPortalLink isDesktop={isDesktop} /> : null}
          <ContactSupportLink user={user} />
        </View>
      );
    }

    // Else, we are a client
    if (isPaymentRequired) {
      return (
        <View testID="paymentRequiredWarning-diyUser">
          <Center>
            <Heading textAlign="center" mx={4}>
              {t("billing_issue_screen.diy_customer_subscription_error")}
            </Heading>
            <Icon as={MaterialIcons} name="error-outline" size="6xl" color="red.500" mb={4} />
            <HelpArticleButton articleUrl={HELP_ARTICLE_CANT_FIND_SUBSCRIPTION_URL} ctaText={t("general.get_help")} />
          </Center>
        </View>
      );
    }

    return (
      <View testID="validAccountWarning-client">
        <Heading>{t("billing_issue_screen.client_subscription_error")}</Heading>
        <ContactSupportLink user={user} />
      </View>
    );
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.loginContainer}>
        <View style={{ ...styles.wrapper, justifyContent: "center" }}>
          <Center>{renderContent()}</Center>
        </View>
      </View>
    </View>
  );
};

export default BillingIssueScreen;
