import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import { BarCodeScanner, PermissionStatus } from "expo-barcode-scanner";
import _ from "lodash";
import { IconButton } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, StyleSheet, Text, View } from "react-native";

import { Routes } from "../constants";
import type { OnChooseProduct, RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { Food, MealSlotSpecification } from "../services/backendTypes";

const { useFoodSearchByBarcodeRetrieveQuery } = backendApi;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    position: "absolute",
    top: 50,
    left: 30,
    right: 0,
    bottom: 0,
    height: 50,
    width: 50,
  },
});

type Props = NativeStackScreenProps<RootStackParamList, Routes.BarcodeScannerScreen>;

export default function BarcodeScanner({
  navigation,
  route: {
    params: { onChoose, chooseMealMoment, mealSlotSpecifications },
  },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [scanned, setScanned] = useState<boolean>(false);

  const [barcode, setBarcode] = useState("");

  // NOTE: For dev purposes use barcodes found here: https://www.buycott.com/brand/40186/jumbo-upc
  // https://www.buycott.com/upc/8711715891228
  const {
    isError,
    isLoading,
    data: food,
    error,
  } = useFoodSearchByBarcodeRetrieveQuery({ barcode }, { skip: barcode === "" });

  if (!isError && !isLoading && food) {
    // console.log("currentData: ", currentData);
    // navigation.navigate(Routes.FoodDetail, { food: data });

    if (_.isEmpty(food)) {
      alert("Could not find a food with that barcode.");
    } else {
      const params: {
        food: Food;
        onChoose: OnChooseProduct;
        chooseMealMoment?: boolean;
        mealSlotSpecifications?: MealSlotSpecification[];
      } = { food, onChoose };

      if (chooseMealMoment) {
        params.chooseMealMoment = chooseMealMoment;
      }

      if (mealSlotSpecifications) {
        params.mealSlotSpecifications = mealSlotSpecifications;
      }

      navigation.navigate(Routes.AddIngredientScreen, params);
    }
  } else if (isError) {
    Sentry.captureException(error);

    alert(`Error: ${JSON.stringify(error)}`);
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === PermissionStatus.GRANTED);
    })();
  }, []);

  const handleBarCodeScanned = ({ data }: { data: string }): void => {
    setScanned(true);
    setBarcode(data);
  };

  if (hasPermission === null) {
    return <Text>{t("system_permissions.request_camera_permissions_text")}</Text>;
  }
  if (hasPermission === false) {
    return <Text>{t("system_permissions.camera_permission_not_granted_text")}</Text>;
  }

  return (
    <View style={styles.container}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />

      <IconButton
        size={"md"}
        variant="solid"
        _icon={{
          as: MaterialIcons,
          name: "arrow-back",
        }}
        style={styles.backButton}
        onPress={() => navigation.goBack()}
      />

      {scanned ? <Button title={"Tap to Scan Again"} onPress={() => setScanned(false)} /> : null}
    </View>
  );
}
