import { useTheme } from "native-base";
import React from "react";
import * as Progress from "react-native-progress";

import { Scale } from "../constants";

export default function ProgressBar({
  value,
  warningMargin = 0.15,
  allowedToGoOver = false,
  width = 40,
}: {
  value: number;
  warningMargin?: number;
  allowedToGoOver?: boolean;
  width?: number;
}): JSX.Element {
  const theme = useTheme();

  const warningValue = 100 * warningMargin;

  const valueLteWarningMarginPercent = value <= 100 - warningValue;
  const valueGteWarningMarginPercent = value >= 100 + warningValue;
  const color =
    valueLteWarningMarginPercent || (allowedToGoOver ? false : valueGteWarningMarginPercent)
      ? theme.colors.amber["200"]
      : theme.colors.primary["600"];

  return (
    <Progress.Bar
      progress={value / 100}
      unfilledColor={theme.colors.gray["100"]}
      color={color}
      borderWidth={0}
      width={Scale(width)}
    />
  );
}
