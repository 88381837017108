import { AntDesign, EvilIcons, MaterialIcons } from "@expo/vector-icons";
import { Field, FieldArray, Formik } from "formik";
import _ from "lodash";
import {
  AlertDialog,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Input,
  Link,
  Radio,
  Row,
  ScrollView,
  Spinner,
  Text,
  useTheme,
  View,
} from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import type { TextStyle } from "react-native";
import RNPickerSelect, { PickerStyle } from "react-native-picker-select";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { isDesktopScreen, KG_TO_LBS, LBS_TO_KG } from "../constants";
import { shouldWeUseImperialForThisUser } from "../helpers/foodHelpers";
import { formatNumberAsDecimal, formatNumberAsWholeNumber } from "../helpers/generalHelpers";
import { getKcalPerSession } from "../helpers/userHelpers";
import type { BasicUserInfoFormSchema } from "../screens/ClientsOverviewScreen";
import backendApi from "../services/backendApi";
import type { ActivityEnum as Activity, DietEnum as Diet, GenderEnum as Gender, User } from "../services/backendTypes";
import logger from "../services/logger";
import { userSelector } from "../slices/userSlice";
import { ActivityEnum, DietEnum, GenderEnum, IntolerancesEnum } from "../types";

const { useUsersExerciseTemplateListQuery } = backendApi;

const HELP_ARTICLE_ON_ACTIVITY_FIELD_URL =
  "https://help.weekmeals.co/en/articles/6715607-how-is-total-daily-calorie-expenditure-calculated";
const HELP_ARTICLE_ON_BODY_FAT_PERCENTAGE =
  "https://help.weekmeals.co/en/articles/7120478-body-fat-percentage-examples";

const DEFAULT_EXERCISE_ID = 1;
const DEFAULT_MINUTES_PER_WEEK = 30;
const DEFAULT_SESSIONS_PER_WEEK = 1;

const PLACEHOLDER_NEW_EXERCISE = {
  exercise: DEFAULT_EXERCISE_ID,
  minutes_per_session: DEFAULT_MINUTES_PER_WEEK,
  sessions_per_week: DEFAULT_SESSIONS_PER_WEEK,
  id: null,
};

const rnPickerSelectInnerComponentStyle: TextStyle = {
  fontSize: 14,
  paddingVertical: 8,
  paddingHorizontal: 10,
  borderWidth: 1,
  borderColor: "lightgray",
  borderRadius: 4,
  backgroundColor: "white",
  color: "black",
  paddingRight: 30, // to ensure the text is never behind the icon
};

export const rnPickerSelectStyle: PickerStyle = {
  inputIOS: rnPickerSelectInnerComponentStyle,
  inputAndroid: rnPickerSelectInnerComponentStyle,
  inputWeb: rnPickerSelectInnerComponentStyle,
};

const exerciseInstanceSchema = Yup.object().shape({
  exercise: Yup.number().positive().required(),
  minutes_per_session: Yup.number().positive().default(DEFAULT_MINUTES_PER_WEEK),
  sessions_per_week: Yup.number().positive().default(DEFAULT_SESSIONS_PER_WEEK),
  id: Yup.number().positive().nullable(),
});

const userProfileFormSchema = Yup.object().shape({
  gender: Yup.mixed<Gender>().oneOf(Object.values(GenderEnum)).required(),
  weight: Yup.number().positive().required(),
  weight_in_lbs: Yup.number().positive().required(),
  body_fat_percentage: Yup.number().min(3).max(60).required(),
  diet: Yup.mixed<Diet>().oneOf(Object.values(DietEnum)).required(),
  // NOTE: This is not currently in scope
  // sustainability: Yup.mixed<Sustainability>().oneOf(Object.values(SustainabilityEnum)).required(),
  activity: Yup.mixed<Activity>().oneOf(Object.values(ActivityEnum)).required(),
  food_intolerances_gluten: Yup.boolean(),
  food_intolerances_lactose: Yup.boolean(),
  food_intolerances_nut: Yup.boolean(),
  food_intolerances_crustaceans_shellfish: Yup.boolean(),
  exercise_instances: Yup.array().of(exerciseInstanceSchema),
});

export type UserProfileFormSchema = Yup.InferType<typeof userProfileFormSchema>;
export type ExerciseInstanceFormSchema = Yup.InferType<typeof exerciseInstanceSchema>;

type Props = {
  basicInfoForm?: BasicUserInfoFormSchema;
  client?: User;
  onSubmitUserProfile: (values: UserProfileFormSchema) => Promise<void>;
  submitButtonText: string;
  showLoading?: boolean;
};

const EditUserProfileComponent = ({
  onSubmitUserProfile,
  client,
  basicInfoForm,
  submitButtonText,
  showLoading = false,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isDesktop = isDesktopScreen();

  const user = useSelector(userSelector);

  const { isLoading: isLoadingGetExerciseTemplates, data: exerciseTemplates } = useUsersExerciseTemplateListQuery({});

  if (!(client || basicInfoForm)) {
    throw new Error("Either client or basicInfoForm must be defined");
  }

  const defaultUserProfileFormValues = {
    gender: GenderEnum.FEMALE,
    diet: DietEnum.OMNIVORE,
    activity: ActivityEnum.SEDENTARY,
    weight: 70,
    weight_in_lbs: 155,
    body_fat_percentage: 20,
  };

  const userProfileFormInitialValues = userProfileFormSchema.cast(
    client?.intake
      ? {
          ...client?.intake,
          weight_in_lbs: formatNumberAsDecimal(client.intake.weight * KG_TO_LBS, 2),
          body_fat_percentage: client?.intake?.body_fat_percentage ? client.intake.body_fat_percentage * 100 : 20,
          exercise_instances: client?.intake?.exercise_instances?.map((exerciseInstance) => ({
            ...exerciseInstance,
            exercise: exerciseInstance.exercise.id,
          })),
        }
      : defaultUserProfileFormValues
  );

  const userProfileForm = (
    <View testID={"editUserProfileForm"}>
      <Formik
        // NOTE: This is because there are no default values. See comment where userProfileFormInitialValues is defined
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues={userProfileFormInitialValues}
        validationSchema={userProfileFormSchema}
        onSubmit={onSubmitUserProfile}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          values,
          errors,
          initialValues,
          dirty,
          isValid,
        }) => (
          <ScrollView persistentScrollbar={true} showsVerticalScrollIndicator={true}>
            <AlertDialog.Body backgroundColor={"white"}>
              {/* Previously entered basicInfo */}
              <View>
                <Row>
                  <Text textAlign={"left"} bold>{`${t("coach_mode_create_new_client.full_name_label_text")}: `}</Text>
                  <Text textAlign={"left"} fontSize={14}>
                    {`${client?.first_name || basicInfoForm?.firstName || ""} ${
                      client?.last_name || basicInfoForm?.lastName || ""
                    }`}
                  </Text>
                </Row>

                <Row>
                  <Text textAlign={"left"} bold>{`${t("coach_mode_create_new_client.email_label_text")}: `}</Text>
                  <Text textAlign={"left"} fontSize={14}>
                    {client?.email || basicInfoForm?.email || ""}
                  </Text>
                </Row>
              </View>
              <Divider />

              <View nativeID="editUserProfileForm">
                {/*  Gender */}
                <FormControl isRequired isInvalid={!_.isEmpty(errors.gender)}>
                  <FormControl.Label>{t("coach_mode_create_new_client.gender_title_text")}</FormControl.Label>
                  <Radio.Group
                    name={"userProfile-gender-radio-group"}
                    value={values.gender}
                    onChange={handleChange("gender")}
                    accessibilityLabel="userProfile-gender-radio-group"
                    nativeID={"editUserProfileFormGenderGroup"}
                  >
                    <Radio value={GenderEnum.MALE} my="1" testID="addNewClient-form-userProfile-gender-male-field">
                      {t(`general.gender.${GenderEnum.MALE}`)}
                    </Radio>
                    <Radio value={GenderEnum.FEMALE} my="1" testID="addNewClient-form-userProfile-gender-female-field">
                      {t(`general.gender.${GenderEnum.FEMALE}`)}
                    </Radio>
                  </Radio.Group>

                  <FormControl.ErrorMessage>{errors.gender}</FormControl.ErrorMessage>
                </FormControl>
                {/* Weight */}
                {user && shouldWeUseImperialForThisUser(user) ? (
                  <FormControl isRequired isInvalid={!_.isEmpty(errors.weight_in_lbs)}>
                    <FormControl.Label>
                      {t("coach_mode_create_new_client.weight_title_text")}
                      <Text>{" (lbs)"}</Text>
                    </FormControl.Label>
                    <Input
                      onBlur={handleBlur("weight_in_lbs")}
                      placeholder={`${t("coach_mode_create_new_client.weight_title_text")} (lbs)`}
                      onChangeText={(lbs) => {
                        const kg = Number(lbs) * LBS_TO_KG;
                        setFieldValue("weight_in_lbs", lbs);
                        setFieldValue("weight", kg);
                      }}
                      value={String(values.weight_in_lbs)}
                      selectTextOnFocus
                      testID="addNewClient-weight-input"
                      nativeID={"editUserProfileFormWeightInput"}
                    />
                    <FormControl.ErrorMessage>{errors.weight}</FormControl.ErrorMessage>
                  </FormControl>
                ) : (
                  <FormControl isRequired isInvalid={!_.isEmpty(errors.weight)}>
                    <FormControl.Label>
                      {t("coach_mode_create_new_client.weight_title_text")}
                      <Text>{" (kg)"}</Text>
                    </FormControl.Label>
                    <Input
                      onBlur={handleBlur("weight")}
                      placeholder={`${t("coach_mode_create_new_client.weight_title_text")} (kg)`}
                      onChangeText={handleChange("weight")}
                      value={String(values.weight)}
                      selectTextOnFocus
                      testID="addNewClient-weight-input"
                      nativeID={"editUserProfileFormWeightInput"}
                    />
                    <FormControl.ErrorMessage>{errors.weight}</FormControl.ErrorMessage>
                  </FormControl>
                )}

                {/* Body fat percentage */}
                <FormControl isRequired isInvalid={!_.isEmpty(errors.body_fat_percentage)}>
                  <FormControl.Label>
                    <Text>{`${t("coach_mode_create_new_client.body_fat_percentage_title_text")} (%)`}</Text>
                  </FormControl.Label>
                  <Link href={HELP_ARTICLE_ON_BODY_FAT_PERCENTAGE} isExternal>
                    <Text underline fontSize="xs" opacity={50}>
                      {t("coach_mode_create_new_client.body_fat_web_examples_link_text")}
                    </Text>
                    <EvilIcons name="external-link" size={18} color="black" opacity={50} />
                  </Link>
                  <Input
                    onBlur={handleBlur("body_fat_percentage")}
                    placeholder={t("coach_mode_create_new_client.body_fat_percentage_title_text")}
                    onChangeText={handleChange("body_fat_percentage")}
                    value={formatNumberAsDecimal(values.body_fat_percentage)}
                    selectTextOnFocus
                    testID="addNewClient-bodyFatPercentage-input"
                    nativeID={"editUserProfileBodyFatPercentageInput"}
                  />
                  <FormControl.ErrorMessage>{errors.body_fat_percentage}</FormControl.ErrorMessage>
                </FormControl>
                {/* Sustainability */}
                {/* NOTE: Not currently in scope */}
                {/* <FormControl isRequired isInvalid={!_.isEmpty(errors.sustainability)}>
              <FormControl.Label>{t("coach_mode_create_new_client.sustainability_title_text")}</FormControl.Label>
              <Select
                selectedValue={String(values.sustainability)}
                mt={1}
                onValueChange={(itemValue) => setFieldValue("sustainability", itemValue)}
                testID={"addNewClient-sustainability-select"}
              >
                {Object.keys(SustainabilityEnum).map((sustainability) => (
                  <Select.Item
                    key={sustainability}
                    label={t(`general.sustainability.${sustainability as SustainabilityEnum}`)}
                    value={sustainability}
                  />
                ))}
              </Select>
              <FormControl.ErrorMessage>{errors.sustainability}</FormControl.ErrorMessage>
            </FormControl> */}
                {/* Activity */}
                <FormControl isRequired isInvalid={!_.isEmpty(errors.activity)}>
                  <FormControl.Label>{t("coach_mode_create_new_client.activity_title_text")}</FormControl.Label>
                  <Link href={HELP_ARTICLE_ON_ACTIVITY_FIELD_URL} isExternal>
                    <Text underline fontSize="xs" opacity={50}>
                      {t("coach_mode_create_new_client.help_article_about_activity_field_text")}
                    </Text>
                    <EvilIcons name="external-link" size={18} color="black" opacity={50} />
                  </Link>
                  <View mt={1} testID={"addNewClient-activity-select"} nativeID={"editUserProfileActivitySelect"}>
                    <RNPickerSelect
                      onValueChange={handleChange("activity")}
                      value={values.activity}
                      items={Object.keys(ActivityEnum).map((activity) => ({
                        label: t(`general.activity.${activity as ActivityEnum}`),
                        value: activity,
                      }))}
                      placeholder={{}}
                      useNativeAndroidPickerStyle={false}
                      style={rnPickerSelectStyle}
                    />
                  </View>
                  <FormControl.ErrorMessage>{errors.activity}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!_.isEmpty(errors.diet)}>
                  <FormControl.Label>{t("coach_mode_create_new_client.diet_title_text")}</FormControl.Label>
                  <View mt={1} testID={"dietType-select"} nativeID={"editUserProfileDietTypeSelect"}>
                    <RNPickerSelect
                      onValueChange={handleChange("diet")}
                      value={values.diet}
                      items={Object.keys(DietEnum).map((diet) => ({
                        label: t(`general.diets.${diet as DietEnum}`),
                        value: diet,
                      }))}
                      placeholder={{}}
                      useNativeAndroidPickerStyle={false}
                      style={rnPickerSelectStyle}
                    />
                  </View>

                  <FormControl.ErrorMessage>{errors.diet}</FormControl.ErrorMessage>
                </FormControl>
                <Text>{t("coach_mode_create_new_client.intolerances_and_allergies_title_text")}</Text>
                <FormControl isInvalid={!_.isEmpty(errors.food_intolerances_gluten)}>
                  <Checkbox
                    key={IntolerancesEnum.GLUTEN}
                    isChecked={values.food_intolerances_gluten}
                    value={String(initialValues.food_intolerances_gluten)}
                    onChange={(itemValue) => setFieldValue("food_intolerances_gluten", itemValue)}
                    testID={"checkbox-foodIntolerancesGluten"}
                    accessibilityLabel={values.food_intolerances_gluten ? "Checked" : "Unchecked"}
                  >
                    {t(`general.intolerances_and_allergies.${IntolerancesEnum.GLUTEN}`)}
                  </Checkbox>
                  <FormControl.ErrorMessage>{errors.food_intolerances_gluten}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isInvalid={!_.isEmpty(errors.food_intolerances_lactose)}>
                  <Checkbox
                    key={IntolerancesEnum.LACTOSE}
                    isChecked={values.food_intolerances_lactose}
                    value={String(initialValues.food_intolerances_lactose)}
                    onChange={(itemValue) => setFieldValue("food_intolerances_lactose", itemValue)}
                    testID={"checkbox-foodIntolerancesLactose"}
                  >
                    {t(`general.intolerances_and_allergies.${IntolerancesEnum.LACTOSE}`)}
                  </Checkbox>
                  <FormControl.ErrorMessage>{errors.food_intolerances_lactose}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isInvalid={!_.isEmpty(errors.food_intolerances_nut)}>
                  <Checkbox
                    key={IntolerancesEnum.NUTS}
                    isChecked={values.food_intolerances_nut}
                    value={String(initialValues.food_intolerances_nut)}
                    onChange={(itemValue) => setFieldValue("food_intolerances_nut", itemValue)}
                    testID={"checkbox-food_intolerances_nut"}
                  >
                    {t(`general.intolerances_and_allergies.${IntolerancesEnum.NUTS}`)}
                  </Checkbox>
                  <FormControl.ErrorMessage>{errors.food_intolerances_nut}</FormControl.ErrorMessage>
                </FormControl>
                <FormControl isInvalid={!_.isEmpty(errors.food_intolerances_crustaceans_and_shellfish)}>
                  <Checkbox
                    key={IntolerancesEnum.CRUSTACEANS_AND_SHELLFISH}
                    isChecked={values.food_intolerances_crustaceans_shellfish}
                    value={String(initialValues.food_intolerances_crustaceans_shellfish)}
                    onChange={(itemValue) => setFieldValue("food_intolerances_crustaceans_shellfish", itemValue)}
                    testID={"checkbox-food_intolerances_crustaceans_shellfish"}
                  >
                    {t(`general.intolerances_and_allergies.${IntolerancesEnum.CRUSTACEANS_AND_SHELLFISH}`)}
                  </Checkbox>
                  <FormControl.ErrorMessage>{errors.food_intolerances_crustaceans_shellfish}</FormControl.ErrorMessage>
                </FormControl>
                {isLoadingGetExerciseTemplates ? (
                  <Spinner />
                ) : (
                  <View mt="4">
                    <FormControl isInvalid={!_.isEmpty(errors.exercise_instances)}>
                      <FormControl.Label nativeID={"editUserProfileExerciseTemplatesLabel"}>
                        {t("edit_user_intake.exercises_label_text")}
                      </FormControl.Label>

                      <FieldArray
                        name="exercise_instances"
                        render={(arrayHelpers) => (
                          <>
                            {values.exercise_instances?.length
                              ? values.exercise_instances.map((exercise, index) => (
                                  <View key={index}>
                                    <View
                                      mt="1"
                                      key={index}
                                      borderColor="black"
                                      borderRadius="sm"
                                      borderWidth={"1"}
                                      p="1"
                                    >
                                      <Field name={`exercise.${index}`}>
                                        {() => (
                                          <>
                                            <View
                                              mt="1"
                                              width={isDesktop ? "50%" : "100%"}
                                              testID={`exercise-${index}-select`}
                                            >
                                              <RNPickerSelect
                                                value={String(exercise.exercise)}
                                                onValueChange={(exerciseId) =>
                                                  arrayHelpers.handleReplace(index, {
                                                    ...exercise,
                                                    exercise: exerciseId,
                                                  })()
                                                }
                                                items={_.sortBy(exerciseTemplates?.results, "exercise_name").map(
                                                  (exerciseTemplate) => ({
                                                    label: exerciseTemplate.exercise_name,
                                                    value: String(exerciseTemplate.id),
                                                    key: exerciseTemplate.id,
                                                  })
                                                )}
                                                placeholder={{}}
                                                useNativeAndroidPickerStyle={false}
                                                style={rnPickerSelectStyle}
                                              />
                                            </View>
                                            <Row>
                                              <Input
                                                // eslint-disable-next-line camelcase
                                                onChangeText={(minutes_per_session) =>
                                                  arrayHelpers.handleReplace(index, {
                                                    ...exercise,
                                                    // eslint-disable-next-line camelcase
                                                    minutes_per_session,
                                                  })()
                                                }
                                                value={String(exercise.minutes_per_session)}
                                                width="50"
                                                mt="1"
                                                testID={`exercise-${index}-minutes_per_session`}
                                              />

                                              <Text ml="1" alignSelf="center">
                                                {t("edit_user_intake.exercise_instance.minutes_per_session_suffix")}
                                              </Text>
                                            </Row>

                                            <Row>
                                              <Input
                                                // eslint-disable-next-line camelcase
                                                onChangeText={(sessions_per_week) =>
                                                  arrayHelpers.handleReplace(index, {
                                                    ...exercise,
                                                    // eslint-disable-next-line camelcase
                                                    sessions_per_week,
                                                  })()
                                                }
                                                value={String(exercise.sessions_per_week)}
                                                width="50"
                                                mt="1"
                                                testID={`exercise-${index}-sessions_per_week`}
                                              />
                                              <Text ml="1" alignSelf="center">
                                                {t("edit_user_intake.exercise_instance.times_per_week_suffix")}
                                              </Text>
                                            </Row>
                                          </>
                                        )}
                                      </Field>
                                      <Flex flexDirection="row-reverse" alignItems={"center"}>
                                        <IconButton
                                          onPress={() => arrayHelpers.remove(index)}
                                          icon={<Icon as={MaterialIcons} color="red.400" name="delete" />}
                                          testID={"deleteSport-button"}
                                        />
                                        <Text>
                                          {t("edit_user_intake.exercise_instance.kcal_per_session_label", {
                                            kcal_per_session: formatNumberAsWholeNumber(
                                              getKcalPerSession(
                                                exercise.exercise,
                                                exercise.minutes_per_session,
                                                client?.intake?.weight || values.weight,
                                                exerciseTemplates?.results
                                              )
                                            ),
                                          })}
                                        </Text>
                                      </Flex>
                                    </View>
                                  </View>
                                ))
                              : null}
                            <View>
                              <Flex flexDirection="row-reverse" alignItems={"center"}>
                                <IconButton
                                  onPress={() => arrayHelpers.push(PLACEHOLDER_NEW_EXERCISE)}
                                  icon={<AntDesign name="pluscircle" size={32} color={theme.colors.primary["600"]} />}
                                  testID={"addSport-button"}
                                />
                              </Flex>
                            </View>
                          </>
                        )}
                      />
                    </FormControl>
                  </View>
                )}
              </View>
            </AlertDialog.Body>

            <AlertDialog.Footer backgroundColor={"white"}>
              <Button
                onPress={() => handleSubmit()}
                isLoading={isSubmitting || showLoading}
                isDisabled={!isValid}
                testID={"addNewClientByEmail-submit-button"}
                nativeID={"addNewClientByEmailSubmitButton"}
              >
                {submitButtonText}
              </Button>
            </AlertDialog.Footer>
          </ScrollView>
        )}
      </Formik>
    </View>
  );

  return userProfileForm;
};

export default EditUserProfileComponent;
