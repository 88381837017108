import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";
import type {
  ActivityEnum as ActivityLevel,
  DietEnum,
  GenderEnum as Gender,
  MainGoalEnum as Goal,
  MealMomentEnum as MealMoment,
  UserProfile,
} from "../services/backendTypes";
import {
  type WeeklyGoal,
  IngredientDislike,
  IntoleranceType,
  MacrosPreference,
  MacrosPreferenceEnum,
  MealMomentEnum,
  WeeklyGoalEnum,
} from "../types";

// Define the interfaces
export interface BiometricData {
  age: number | undefined;
  gender: Gender | undefined;
}

export interface PhysicalStats {
  heightInCm: number | undefined;
  weightInKg: number | undefined;
  goalWeight: number | undefined;
}

export type DietPreferences = {
  dietType: MacrosPreference;
  dietaryPreference?: DietEnum;
  intolerances: Array<IntoleranceType>;
  ingredientDislikes: Array<IngredientDislike>;
  mealTimes: Array<MealMoment>;
  userProvidedMacros?: {
    calories: number;
    protein: number;
    fat: number;
    carbohydrates: number;
  };
};

export interface OnboardingState {
  goal: Goal;
  activityLevel: ActivityLevel;
  biometricData: BiometricData;
  physicalStats: PhysicalStats;
  weeklyGoal: WeeklyGoal;
  dietPreferences: DietPreferences;
}

export const DEFAULT_MEAL_TIMES = [MealMomentEnum.BREAKFAST, MealMomentEnum.LUNCH, MealMomentEnum.DINNER];

export function determineWeeklyGoal(goalWeeklyChangeInKg: number): WeeklyGoal {
  if (!goalWeeklyChangeInKg) return WeeklyGoalEnum.LOSE_0_25_KG_PER_WEEK;

  const weeklyChangeAbs = Math.abs(goalWeeklyChangeInKg);
  if (weeklyChangeAbs <= 0.25) {
    return WeeklyGoalEnum.LOSE_0_25_KG_PER_WEEK;
  }
  if (weeklyChangeAbs <= 0.5) {
    return WeeklyGoalEnum.LOSE_0_5_KG_PER_WEEK;
  }
  return WeeklyGoalEnum.LOSE_1_KG_PER_WEEK;
}

const initialState: OnboardingState = {
  goal: "LOSE_WEIGHT",
  activityLevel: "MILDLY_ACTIVE",
  biometricData: {
    age: undefined,
    gender: undefined,
  },
  physicalStats: {
    heightInCm: undefined,
    weightInKg: undefined,
    goalWeight: undefined,
  },
  weeklyGoal: WeeklyGoalEnum.LOSE_0_25_KG_PER_WEEK,
  dietPreferences: {
    dietType: MacrosPreferenceEnum.RECOMMENDED,
    intolerances: [],
    ingredientDislikes: [],
    mealTimes: DEFAULT_MEAL_TIMES,
  },
};

// Create the slice
export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setGoal(state, action: PayloadAction<Goal>) {
      state.goal = action.payload;
    },
    setActivityLevel(state, action: PayloadAction<ActivityLevel>) {
      state.activityLevel = action.payload;
    },
    setBiometricData(state, action: PayloadAction<BiometricData>) {
      state.biometricData = action.payload;
    },
    setPhysicalStats(state, action: PayloadAction<PhysicalStats>) {
      state.physicalStats = action.payload;
    },
    setWeeklyGoal(state, action: PayloadAction<WeeklyGoal>) {
      state.weeklyGoal = action.payload;
    },
    setDietPreferences(state, action: PayloadAction<DietPreferences>) {
      const updatedDietPreferences = {
        ...action.payload,
        // Ensure that all the arrays do not contain duplicate elements
        intolerances: [...new Set(action.payload.intolerances)],
        ingredientDislikes: [...new Set(action.payload.ingredientDislikes)],
        mealTimes: [...new Set(action.payload.mealTimes)],
      };

      if (action.payload.dietaryPreference) {
        updatedDietPreferences.dietaryPreference = action.payload.dietaryPreference;
      }

      state.dietPreferences = updatedDietPreferences;
    },
    setUserProfileData(state, action: PayloadAction<UserProfile>) {
      const userProfile = action.payload;

      state.goal = userProfile.main_goal || "LOSE_WEIGHT";
      state.activityLevel = userProfile.activity;

      state.biometricData = {
        age: userProfile.age || 25,
        gender: userProfile.gender,
      };

      const DEFAULT_HEIGHT = 170;
      const DEFAULT_WEIGHT = 70;
      const DEFAULT_GOAL_WEIGHT = 65;

      state.physicalStats = {
        heightInCm: userProfile.height_in_cm || DEFAULT_HEIGHT,
        weightInKg: userProfile.weight || DEFAULT_WEIGHT,
        goalWeight: userProfile.goal_weight_in_kg || DEFAULT_GOAL_WEIGHT,
      };

      state.weeklyGoal = determineWeeklyGoal(userProfile.goal_weekly_change_in_kg || -0.25);
    },
    resetOnboardingState(state) {
      // eslint-disable-next-line no-param-reassign, no-unused-vars
      state = initialState;
    },
  },
});

// Export actions
export const { setGoal, setActivityLevel, setBiometricData, setPhysicalStats, setWeeklyGoal, resetOnboardingState } =
  onboardingSlice.actions;

// Selectors
const selectSelf = (state: RootState): OnboardingState => state[onboardingSlice.name];

export const onboardingDataSelector = createSelector(selectSelf, (state: OnboardingState) => state);
