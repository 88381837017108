import _ from "lodash";
import { Text } from "native-base";
import React from "react";
// NOTE: The whole path is required
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-unresolved, import/extensions
import { Metabolism } from "tdee-calculator/src/index.js";

import MacroProgressWidget from "../components/RecipeMacrosItem";
import type { MealSlotSpecification, User } from "../services/backendTypes";
import logger from "../services/logger";
import { ActivityEnum, MacroName, Macros } from "../types";

const KCAL_IN_FAT = 9;
const KCAL_IN_CARBOHYDRATES = 4;
const KCAL_IN_PROTEIN = 4;

type Macronutrient = "fat" | "carbohydrates" | "protein";

const KCAL_IN_MACRO_LOOKUP: { [M in Macronutrient]: number } = {
  fat: KCAL_IN_FAT,
  carbohydrates: KCAL_IN_CARBOHYDRATES,
  protein: KCAL_IN_PROTEIN,
};

export const calculateDailyTotalForMacro = (
  macro: MacroName,
  mealSlotSpecifications: (MealSlotSpecification | undefined)[]
): number => _.sumBy(mealSlotSpecifications, macro);

function calculateMacroPercentageOfDailyTotalEnergy(
  macro: Macronutrient,
  mealSlotSpecifications: MealSlotSpecification[]
): number {
  return (
    (calculateDailyTotalForMacro(macro, mealSlotSpecifications) * KCAL_IN_MACRO_LOOKUP[macro]) /
    calculateDailyTotalForMacro("kcal", mealSlotSpecifications)
  );
}

export function getNutritionDayPlanOverviewComponent(
  mealSlotSpecifications: MealSlotSpecification[],
  showPercentages = false
): JSX.Element {
  if (_.isEmpty(mealSlotSpecifications)) {
    logger.warn("No meal slot specifications provided");

    return <Text>{"Something went wrong"}</Text>;
  }

  const fatPercentage = calculateMacroPercentageOfDailyTotalEnergy("fat", mealSlotSpecifications);

  const carbohydratesPercentage = calculateMacroPercentageOfDailyTotalEnergy("carbohydrates", mealSlotSpecifications);

  const proteinPercentage = calculateMacroPercentageOfDailyTotalEnergy("protein", mealSlotSpecifications);

  type PercentageObject = {
    [m in MacroName]: number | undefined;
  };

  const percentageObject: PercentageObject = {
    fat: fatPercentage,
    carbohydrates: carbohydratesPercentage,
    protein: proteinPercentage,
    kcal: undefined,
  };

  return (
    <>
      {Object.keys(Macros).map((macro: string) => {
        const macroName = macro.toLowerCase() as MacroName;
        return (
          <MacroProgressWidget
            key={macro}
            macroDetail={{
              macroName,
              progress: calculateDailyTotalForMacro(macroName, mealSlotSpecifications),
              total: 0,
              unit: macroName,
            }}
            showLabel
            verticalMode
            excludeDetail={["kcal", "protein", "fat", "carbohydrates"]}
            optionalPercentage={showPercentages ? percentageObject[macroName] : undefined}
          />
        );
      })}
    </>
  );
}

export const convertActivityEnumToActivityForMetabolism = (activity: ActivityEnum): string => {
  switch (activity) {
    case ActivityEnum.SEDENTARY:
      return "Sedentary";
    case ActivityEnum.MILDLY_ACTIVE:
      return "Light Exercise";
    case ActivityEnum.ACTIVE:
      return "Moderate Exercise";
    case ActivityEnum.VERY_ACTIVE:
      return "Heavy Exercise";
    default:
      throw new Error("Unknown activity");
  }
};

const KATCH_MCARDLE_FORMULA = "Katch and McArdle (2001)";
const METRIC = "Metric";
export const calculateDailyEnergyExpenditure = (client: User): Metabolism => {
  if (!client) {
    throw new Error("Client is undefined");
  }

  if (!client.intake) {
    throw new Error("Client does not have an intake");
  }

  // NOTE: This library doesn't have good typing support
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return new Metabolism(
    KATCH_MCARDLE_FORMULA,
    METRIC,
    convertActivityEnumToActivityForMetabolism(client.intake.activity as ActivityEnum),
    {
      bodyfat: client.intake.body_fat_percentage * 100,
      weight: client.intake.weight,
    }
  );
};
