import type { PaginatedUserList } from "./backendTypes";
import { backendApi } from "./backendTypes";

export enum TAGS {
  FAVOURITE = "FAVOURITE",
  DISLIKE = "DISLIKE",
  OWN_MEAL = "OWN_MEAL",
  USER = "USER",
  CLIENTS = "CLIENTS",
  CALENDAR_DAY = "CALENDAR_DAY",
  USER_MEASUREMENT_WEIGHT = "USER_MEASUREMENT_WEIGHT",
  USER_MEASUREMENT_SLEEP = "USER_MEASUREMENT_SLEEP",
  USER_MEASUREMENT_STRESS = "USER_MEASUREMENT_STRESS",
  USER_MEASUREMENT_WAIST_CIRCUMFERENCE = "USER_MEASUREMENT_WAIST_CIRCUMFERENCE",
  GENERATE_MEALS = "GENERATE_MEALS",
}

// Spectacular does not recognise custom paginated response properties
export interface ExtendedPaginatedUserList extends PaginatedUserList {
  total_pages: number;
}

const LIKE_DISLIKE_TAGS = [TAGS.FAVOURITE, TAGS.DISLIKE];

export default backendApi.enhanceEndpoints({
  addTagTypes: Object.values(TAGS),

  endpoints: {
    foodGenerateMealsList: {
      providesTags: [TAGS.FAVOURITE, TAGS.OWN_MEAL, TAGS.GENERATE_MEALS],
    },
    usersClientsList: {
      providesTags: [TAGS.CLIENTS],
    },
    usersPaginatedclientsList: {
      providesTags: [TAGS.CLIENTS],
    },
    plannerCalendarDayList: {
      providesTags: [TAGS.CALENDAR_DAY],
    },
    usersUserfavouriterecipeList: {
      providesTags: [TAGS.FAVOURITE],
    },
    usersUserdislikerecipeList: {
      providesTags: [TAGS.DISLIKE],
    },

    plannerCalendarDayPartialUpdate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },

    usersUserfavouriterecipeCreate: {
      invalidatesTags: LIKE_DISLIKE_TAGS,
    },
    usersUserfavouriterecipeDestroy: {
      invalidatesTags: LIKE_DISLIKE_TAGS,
    },

    usersUserdislikerecipeCreate: {
      invalidatesTags: [TAGS.DISLIKE, TAGS.FAVOURITE],
    },
    usersUserdislikerecipeDestroy: {
      invalidatesTags: [TAGS.DISLIKE, TAGS.FAVOURITE],
    },

    foodRecipeTemplateCreate: {
      invalidatesTags: [TAGS.OWN_MEAL],
    },
    foodRecipeTemplateDestroy: {
      invalidatesTags: [TAGS.OWN_MEAL],
    },

    usersAuthUsersCreate: {
      invalidatesTags: [TAGS.CLIENTS],
    },
    usersUserProfileCreate: {
      invalidatesTags: [TAGS.CLIENTS, TAGS.USER],
    },
    usersAuthUsersMeRetrieve: {
      providesTags: [TAGS.USER],
    },
    usersClientsRetrieve: {
      providesTags: [TAGS.CLIENTS],
    },
    usersOrganisationCreate: {
      invalidatesTags: [TAGS.USER],
    },
    usersOrganisationPartialUpdate: {
      invalidatesTags: [TAGS.USER],
    },
    usersClientsPartialUpdate: {
      invalidatesTags: [TAGS.CLIENTS],
    },
    usersUserProfileUpdate: {
      invalidatesTags: [TAGS.CLIENTS, TAGS.USER, TAGS.GENERATE_MEALS],
    },
    usersExerciseInstanceCreate: {
      invalidatesTags: [TAGS.CLIENTS],
    },
    usersExerciseInstanceUpdate: {
      invalidatesTags: [TAGS.CLIENTS],
    },
    usersExerciseInstancePartialUpdate: {
      invalidatesTags: [TAGS.CLIENTS],
    },
    usersExerciseInstanceDestroy: {
      invalidatesTags: [TAGS.CLIENTS],
    },
    usersWeeklyNutritionPlanCreate: {
      invalidatesTags: [TAGS.CLIENTS, TAGS.USER, TAGS.CALENDAR_DAY],
    },
    usersWeeklyNutritionPlanUpdate: {
      invalidatesTags: [TAGS.CLIENTS, TAGS.USER],
    },
    foodRecipeTemplatePartialUpdate: {
      invalidatesTags: [TAGS.OWN_MEAL],
    },
    plannerCopyMealsToMealMomentCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },
    plannerPlanGeneratedRecipeMealCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },
    plannerSaveAndPlanRecipeMealCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY, TAGS.OWN_MEAL],
    },
    plannerPlanSingleFoodMealCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },

    // useMeasurementsUserWeightListQuery
    measurementsUserWeightList: {
      providesTags: [TAGS.USER_MEASUREMENT_WEIGHT],
    },
    // useMeasurementsUserSleepListQuery
    measurementsUserSleepList: {
      providesTags: [TAGS.USER_MEASUREMENT_SLEEP],
    },
    // useMeasurementsUserStressListQuery
    measurementsUserStressList: {
      providesTags: [TAGS.USER_MEASUREMENT_STRESS],
    },
    // useMeasurementsUserDistanceListQuery
    measurementsUserDistanceList: {
      providesTags: [TAGS.USER_MEASUREMENT_WAIST_CIRCUMFERENCE],
    },

    // useMeasurementsUserWeightCreateMutation
    measurementsUserWeightCreate: {
      invalidatesTags: [TAGS.USER_MEASUREMENT_WEIGHT],
    },
    // useMeasurementsUserSleepCreateMutation
    measurementsUserSleepCreate: {
      invalidatesTags: [TAGS.USER_MEASUREMENT_SLEEP],
    },
    // useMeasurementsUserStressCreateMutation
    measurementsUserStressCreate: {
      invalidatesTags: [TAGS.USER_MEASUREMENT_STRESS],
    },
    // useMeasurementsUserDistanceCreateMutation
    measurementsUserDistanceCreate: {
      invalidatesTags: [TAGS.USER_MEASUREMENT_WAIST_CIRCUMFERENCE],
    },

    measurementsDeleteMeasurementCreate: {
      invalidatesTags: [
        TAGS.USER_MEASUREMENT_SLEEP,
        TAGS.USER_MEASUREMENT_STRESS,
        TAGS.USER_MEASUREMENT_WAIST_CIRCUMFERENCE,
        TAGS.USER_MEASUREMENT_WEIGHT,
      ],
    },

    plannerAutoPlanDayCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },
    plannerAutoPlanDaysCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },
    plannerCopyMealsFromDateCreate: {
      invalidatesTags: [TAGS.CALENDAR_DAY],
    },
  },
});
