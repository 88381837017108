// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";

const GOOGLE_ANALYTICS_INBUILT_EVENTS = {
  SIGN_UP: "sign_up",
};

// eslint-disable-next-line import/prefer-default-export
export function googleAnalyticsSendStartTrialEvent(): void {
  if (!window.gtag) {
    const errorForSentry = new Error("Conversion tracking error - gtag.js is not loaded");
    Sentry.captureException(errorForSentry);
  } else {
    window.gtag("event", GOOGLE_ANALYTICS_INBUILT_EVENTS.SIGN_UP);
  }
}
