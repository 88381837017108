// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, Text, View } from "react-native";

import { CommonButton, CommonDescriptionInput, CommonPageHeader, CommonRadioBox } from "../commons";
import { commonStyles, Routes, Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  description: {
    marginTop: Scale(32),
  },
  inputbox: {
    backgroundColor: "white",
    borderRadius: Scale(8),
    borderWidth: 1,
    borderStyle: "solid",
    // borderColor: Colors.borderColor,
    marginBottom: Scale(20),
    paddingVertical: VerticalScale(12),
    paddingHorizontal: Scale(16),
    marginTop: VerticalScale(8),
    fontSize: 16,
    minHeight: VerticalScale(72),
  },
});

type OptionItem = {
  id: number;
  label: string;
  description?: string;
};

type ParamList = {
  SelectOptionScreen: undefined;
  ProfileStartScreen: undefined;
  AddMainGoalScreen: undefined;
  AddGenderScreen: undefined;
};
type Props = {
  navigation: NativeStackNavigationProp<ParamList, keyof ParamList>;
  pageTitle?: string;
  pageDescription?: string;
  optionsData: OptionItem[];
  buttonText: string;
  onButtonPress: () => void;
  multipleSelectionsAllowed: boolean;
  inputLabel?: string;
  onChangeText: (text: string) => void;
};

const SelectOptionScreen = ({
  navigation,
  optionsData,
  pageTitle,
  pageDescription,
  buttonText,
  onButtonPress,
  inputLabel,
  onChangeText,
  multipleSelectionsAllowed,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  const onPressItem = (index: number): void => {
    if (multipleSelectionsAllowed) {
      let options = [...selectedOptions];
      if (options.includes(index)) {
        options = options.filter((item) => item !== index);
      } else {
        options.push(index);
      }
      setSelectedOptions(options);
    } else {
      setSelectedOptions([index]);
    }
  };

  return (
    <View style={commonStyles.container}>
      <CommonPageHeader
        title={"Complete your profile"}
        onPressBack={() => {
          navigation.goBack();
        }}
        onPressClose={() => {
          navigation.goBack();
        }}
      />

      <ScrollView contentContainerStyle={commonStyles.scrollContentContainer}>
        <View style={commonStyles.mainContainer}>
          <View style={{ marginVertical: VerticalScale(50) }}>
            {pageTitle ? <Text style={commonStyles.titleText24Bold}>{pageTitle}</Text> : null}
            {pageDescription ? (
              <Text style={[commonStyles.descriptionText14, styles.description]}>{pageDescription}</Text>
            ) : null}
          </View>
          <View>
            {optionsData.map((optionData, index) => (
              <CommonRadioBox
                key={index}
                label={optionData.label}
                description={optionData?.description}
                checked={selectedOptions.includes(index)}
                onPress={() => {
                  onPressItem(index);
                }}
                multipleSelectionsAllowed={multipleSelectionsAllowed}
              />
            ))}
          </View>

          {inputLabel ? (
            <CommonDescriptionInput title={inputLabel} onChangeText={(val: string) => onChangeText(val)} />
          ) : null}
        </View>
      </ScrollView>

      {selectedOptions.length > 0 && (
        <View style={commonStyles.bottomContainer}>
          <CommonButton title={buttonText} onPress={onButtonPress} />
        </View>
      )}
    </View>
  );
};

export default SelectOptionScreen;
