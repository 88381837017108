import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import { Formik } from "formik";
import _ from "lodash";
import { Box, Button, Center, FormControl, Icon, Input, KeyboardAvoidingView, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Images, IS_MOBILE_PLATFORM, Routes } from "../constants";
import { AppImage, TroubleLoggingInLink } from "../helpers/supportHelpers";
import { getHostname, getManagedCustomerPortalSubdomain } from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { ClientAuthenticationMethodEnum } from "../services/backendTypes";
import logger from "../services/logger";
import { userSlice } from "../slices/userSlice";
import styles from "./LoginScreenStyles";

const { useUsersCreateMagicLinkCreateMutation } = backendApi;
const { useUsersCreateLoginSignupPortalCreateMutation } = backendApi;

const magicLinkFormSchema = Yup.object().shape({
  // TODO: This should be refactored to allow use of translated keys
  email: Yup.string().trim().email("Invalid email").required("Required"),
});

export interface MagicLinkFormValues {
  email: string;
}

const APP_STORE_REVIEW_EMAIL = "apple-review@weekmeals.co";
const APP_STORE_REVIEW_ACCOUNT_AUTH_TOKEN = "ee796cdd54495c94762346d5a64eca88f93bf65b";

const hostname = getHostname();
const subdomainWeAreCurrentlyOn = getManagedCustomerPortalSubdomain(hostname);

// TODO: This should be a field on the organisation as opposed to hardcoding
const shouldWeShowSameEmailAsCheckoutMessage = subdomainWeAreCurrentlyOn === "killerbody";

type Props = NativeStackScreenProps<RootStackParamList, Routes.LoginScreen>;
const LoginScreen = ({ navigation, route }: Props): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [sendMagicLinkToEmailPost, { isLoading, error, isError }] = useUsersCreateMagicLinkCreateMutation();
  // Note: This is doing getOrCreate on user and the sending a magic link (as normal)
  const [sendLoginSignupPost] = useUsersCreateLoginSignupPortalCreateMutation();

  const submitForm = async (values: MagicLinkFormValues): Promise<void> => {
    // NOTE: This is required because app store review cannot log in to the email account
    if (values.email === APP_STORE_REVIEW_EMAIL) {
      dispatch(userSlice.actions.storeAuthToken(APP_STORE_REVIEW_ACCOUNT_AUTH_TOKEN));
    }

    // NOTE: This needs to be a "valid" url to pass validation on the backend
    const redirectUrlString = Platform.OS === "web" ? "/" : "login.me";

    // NOTE: This is for debugging the compiled app
    // alert(`redirectUrlString: ${redirectUrlString}`);

    const redirectUrl = Linking.createURL(redirectUrlString);
    // NOTE: This is for debugging the compiled app
    // alert(`redirectUrl: ${redirectUrl}`);

    // NOTE: This is for debugging the compiled app
    // alert(`magicLinkToEmailPostBody: ${JSON.stringify(magicLinkToEmailPostBody, null, 2)}`);

    let authenticationMethod: ClientAuthenticationMethodEnum = "MAGIC_LINK";

    try {
      if (subdomainWeAreCurrentlyOn && !IS_MOBILE_PLATFORM) {
        // Note: This is doing getOrCreate on user and the sending a magic link (as normal)
        const response = await sendLoginSignupPost({
          loginSignupPortalRequest: {
            email: values.email,
            redirect_url: redirectUrl,
            organisation_subdomain: subdomainWeAreCurrentlyOn,
          },
        }).unwrap();

        authenticationMethod = response?.authentication_method;
      } else {
        await sendMagicLinkToEmailPost({
          magicLinkCreateRequest: {
            email: values.email,
            redirect_url: redirectUrl,
          },
        }).unwrap();
      }
    } catch (e) {
      alert(t("login_screen.failure_recovery"));
      return;
    }

    // Only navigate to the sent screen if there has been no error
    switch (authenticationMethod) {
      case "OTP":
        navigation.navigate(Routes.EnterOTPScreen, { email: values.email });
        break;
      default:
        navigation.navigate(Routes.MagicLinkSentScreen, { email: values.email });
    }
  };

  const initialValues: MagicLinkFormValues = { email: "" };

  const messageAboutUsingTheSameEmailAsCheckout = (
    <Box bg="blue.50" p="4" borderRadius="md" mb="4">
      <VStack space={2} alignItems="center">
        <Icon as={MaterialIcons} name="info-outline" size="sm" color="amber.500" />
        <Text fontSize="sm" color="amber.500" textAlign="center">
          <Text fontWeight="bold">{t("login_screen.same_email_as_checkout_message")}</Text>
        </Text>
      </VStack>
    </Box>
  );

  return (
    <View style={styles.wrapper}>
      <KeyboardAvoidingView behavior="position">
        <Formik initialValues={initialValues} validationSchema={magicLinkFormSchema} onSubmit={submitForm}>
          {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, dirty, isValid }) => (
            <Box safeArea flex={1} p="2" py="8" w="90%" mx="auto">
              <VStack space={3} mt="5">
                <Box alignItems="center">
                  <AppImage />

                  <Text fontSize="lg" mt="8" fontWeight="medium" textAlign="center">
                    {t("login_screen.heading")}
                  </Text>
                </Box>

                {shouldWeShowSameEmailAsCheckoutMessage ? messageAboutUsingTheSameEmailAsCheckout : null}

                <View>
                  <FormControl isRequired isInvalid={!_.isEmpty(errors.email)}>
                    <Input
                      onChangeText={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      placeholder={t("login_screen.login_form.email_placeholder")}
                      testID={"magicLinkEmail-input"}
                      mt="6"
                      py="3.5"
                      autoCapitalize="none"
                      autoComplete="email"
                      keyboardType="email-address"
                      textContentType="emailAddress"
                    />
                    <Center>
                      <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>
                    </Center>
                  </FormControl>

                  <Button
                    isLoading={isSubmitting}
                    isDisabled={!dirty || !isValid}
                    onPress={() => handleSubmit()}
                    testID="magicLinkButton"
                    mt="2"
                  >
                    {t("login_screen.send_magic_link_button_text")}
                  </Button>
                </View>

                <Center mt={10}>
                  <TroubleLoggingInLink />
                </Center>
              </VStack>
            </Box>
          )}
        </Formik>
      </KeyboardAvoidingView>
    </View>
  );
};
export default LoginScreen;
