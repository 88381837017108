import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as Localization from "expo-localization";

import type { RootState } from "../../../store";
import { BACKEND_API_BASE_URL } from "../constants";
import { authTokenSelector, localeSelector } from "../slices/userSlice";

const DEFAULT_LANGUAGE = "en";

// eslint-disable-next-line import/prefer-default-export
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const token = authTokenSelector(state);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const userLocaleFromStore = localeSelector(state);

      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }

      // NOTE: This can cause issues if trying to find out the user's locale using Accept-Language
      // because we are only using the first part of the locale (e.g. "en" instead of "en-US")
      const userLocale = userLocaleFromStore || Localization.locale.split("-")[0] || DEFAULT_LANGUAGE;

      headers.set("Accept-Language", userLocale);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
