import { MaterialIcons } from "@expo/vector-icons";
import { Icon, Image, Input } from "native-base";
import React from "react";
import { ImageSourcePropType, TouchableOpacity } from "react-native";

type Props = {
  placeholder: string;
  leftIcon?: JSX.Element;
  onChangeText: (text: string) => void;
  value?: string;
  onClearText?: () => void;
  backgroundColour?: string;
  width?: string;
  testID?: string;
};

const CommonSearchInput = ({
  placeholder,
  onChangeText,
  onClearText,
  value,
  leftIcon,
  backgroundColour,
  width,
  testID,
}: Props): JSX.Element => {
  const colour = "primary.600";
  return (
    <Input
      placeholder={placeholder}
      width={width || "100%"}
      borderRadius="4"
      height="12"
      value={value}
      borderWidth={0}
      fontWeight={"normal"}
      py="3"
      px="1"
      fontSize="14"
      InputLeftElement={leftIcon || undefined}
      InputRightElement={
        onClearText ? (
          <TouchableOpacity onPress={onClearText}>
            <Icon as={MaterialIcons} m="2" ml="3" color={colour} name="cancel" />
          </TouchableOpacity>
        ) : (
          <></>
        )
      }
      borderColor={colour}
      placeholderTextColor={colour}
      borderTopWidth="1"
      borderBottomWidth="1"
      borderLeftWidth={"1"}
      borderRightWidth={"1"}
      borderStyle={"solid"}
      onChangeText={onChangeText}
      background={backgroundColour}
      testID={testID}
    />
  );
};

export default CommonSearchInput;
