import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import {
  AlertDialog,
  Box,
  Button,
  Fab,
  Flex,
  FormControl,
  Icon,
  Input,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  useDisclose,
  useTheme,
  View,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Image, SafeAreaView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import EditUserProfileComponent, { UserProfileFormSchema } from "../components/EditUserProfileComponent";
import { commonStyles, Images, isDesktopScreen, Routes, Scale, VerticalScale, width } from "../constants";
import { formatUserForDisplay, isMobilePlatform } from "../helpers/generalHelpers";
import { BookACallLink, ReferralProgrammeLink, StripeCustomerPortalLink } from "../helpers/supportHelpers";
import { doesCustomerHaveAnyTrialingSubscriptions, getOrganisation } from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import type { ExtendedPaginatedUserList } from "../services/backendApi";
import backendApi from "../services/backendApi";
import type { SlimClient, User, UsersAuthUsersCreateApiArg } from "../services/backendTypes";
import logger from "../services/logger";
import { clientsSelector, userSelector, userSlice, viewAsUserSelector } from "../slices/userSlice";
import { ClientsFilterState, SustainabilityEnum } from "../types";
import styles from "./ClientsOverviewScreenStyle";

const { useUsersAuthUsersCreateMutation, useUsersPaginatedclientsListQuery, useUsersExerciseInstanceCreateMutation } =
  backendApi;

/**
 * NOTE: Because we use tabs a re-poll leads to the content being re-drawn.
 * This can cause problems such as:
 *  - losing all data when creating a new recipe (in viewAs mode)
 *  - resetting the progress view
 *  - resetting the scoll and any modal content when viewing/editing a user's nutrition plan overview
 *
 * We should investigate (and fix) the underlying problem
 */
const CLIENTS_LIST_POLLING_INTERVAL = 60 * 1000;

// TODO: Internationalise the errors
const basicUserInfoFormSchema = Yup.object().shape({
  email: Yup.string().email().required("invalid_email_error").default(""),
  firstName: Yup.string().trim().required().nullable(false).default(""),
  lastName: Yup.string().trim().required().nullable(false).default(""),
});

const basicUserInfoFormInitialValues = basicUserInfoFormSchema.cast({});
export type BasicUserInfoFormSchema = Yup.InferType<typeof basicUserInfoFormSchema>;

type Props = NativeStackScreenProps<RootStackParamList, Routes.CoachModeViewClientsScreen>;
const CoachModeViewClientsScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const [page, setPage] = useState<number>(1);

  const user = useSelector(userSelector);

  const {
    isOpen: isOpenAddNewClientDialog,
    onOpen: onOpenAddNewClientDialog,
    onClose: onCloseAddNewClientDialog,
  } = useDisclose();

  const deactivateClientDialogRef = useRef(null);

  const isDesktop = isDesktopScreen();

  const viewAsUser = useSelector(viewAsUserSelector);

  const [searchText, setSearchText] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [clientsFilter, setClientsFilter] = React.useState<ClientsFilterState>(ClientsFilterState.ACTIVE_CLIENTS);
  const filterActiveClientsOnly = clientsFilter === ClientsFilterState.ACTIVE_CLIENTS;

  const { data: clientsListResponse, isFetching: isFetchingClientsList } = useUsersPaginatedclientsListQuery(
    {
      page,
      search: searchTerm,
      accountEnabled: clientsFilter === ClientsFilterState.ACTIVE_CLIENTS,
    },
    {
      pollingInterval: CLIENTS_LIST_POLLING_INTERVAL,
      // NOTE: The content of the tabs is re-drawn whenever this query is returned.
      // We do this to ensure not to do a poll-related update when we are in viewAs mode
      skip: Boolean(viewAsUser),
    }
  );
  const [createUserBackendCall, { isLoading }] = useUsersAuthUsersCreateMutation();
  const [createExerciseInstanceBackendCall, { isLoading: isLoadingCreateExerciseInstance }] =
    useUsersExerciseInstanceCreateMutation();

  useEffect(() => {
    if (clientsListResponse && clientsListResponse.results) {
      dispatch(userSlice.actions.storeClients(clientsListResponse.results));
    }
  }, [clientsListResponse]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchTerm(searchText);
      setPage(1);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const clients = useSelector(clientsSelector);

  const renderClientFlatlistItem = ({ item: client, index }: { item: SlimClient; index: number }): JSX.Element => (
    <Flex
      flexDirection="row"
      alignItems={"center"}
      nativeID={`clientListItem-${index}`}
      style={[
        styles.clientViewContainer,
        isDesktop && {
          marginHorizontal: Scale(9),
          borderRadius: 8,
        },
      ]}
    >
      <Pressable
        style={{
          paddingVertical: VerticalScale(15),
          paddingHorizontal: Scale(8),
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderTopRightRadius: index === 0 ? 8 : 0,
          borderTopLeftRadius: index === 0 ? 8 : 0,
          borderBottomRightRadius: index === clients.length - 1 ? 8 : 0,
          borderBottomLeftRadius: index === clients.length - 1 ? 8 : 0,
        }}
        borderColor={"gray.100"}
        borderWidth={isDesktop ? 0 : "0.5"}
        onPress={() => {
          logger.trace("Pressed client: ", client);
          navigation.navigate(Routes.CoachModeClientInfoScreen, { clientId: client.id });
        }}
        testID={`client-card-${client.email}`}
      >
        <Icon as={MaterialIcons} m="2" ml="3" size="6" color={"primary.600"} name="person" />

        {/* TODO: This should be `${first_name} ${last_name}` after we update the API to return these fields. */}
        <Text style={commonStyles.descriptionText16Bold}>
          {isDesktop
            ? _.truncate(formatUserForDisplay(client), {
                length: 20,
                separator: " ",
              })
            : formatUserForDisplay(client)}
        </Text>
      </Pressable>
    </Flex>
  );

  const clientsListComponent = (
    // NOTE: Implementing the below style will cause the flatlist not to render correctly.
    // <View
    //   style={{
    //     // flex: 1,
    //     marginTop: VerticalScale(37),
    //   }}
    // >
    // <View
    //   style={[
    //     styles.routeViewContainer,
    //     !isDesktop && {
    //       borderWidth: 1,
    //       borderStyle: "solid",
    //       borderColor: Colors.borderColor,
    //     },
    //   ]}
    // >

    <div>
      <FlatList
        key={isDesktop ? "flastList_desktop" : "flatList_mobile"}
        showsVerticalScrollIndicator={false}
        data={clients}
        renderItem={renderClientFlatlistItem}
        keyExtractor={(item) => String(item.id)}
        numColumns={isDesktop ? 3 : 1}
        ItemSeparatorComponent={() => <View style={{ height: Scale(isDesktop ? 15 : 0) }}></View>}
        contentContainerStyle={{
          marginHorizontal: isDesktop ? "20%" : "5%",
          marginVertical: 10,
        }}
        columnWrapperStyle={
          isDesktop
            ? {
                width: "33.333%",
              }
            : null
        }
      />
    </div>
  );
  // TODO: Get feedback from the team on how this should be implemented
  // const getNeedsActionRoute = (): JSX.Element => getAllClientsRoute();
  // // TODO: Likewise
  // const getArchiveRoute = (): JSX.Element => <View></View>;

  const headerComponent = (
    <Flex
      flexDirection={"column"}
      justifyContent="space-between"
      alignItems={"center"}
      style={commonStyles.paddingContainer}
      nativeID={"clientsHeaderComponent"}
    >
      <Text bold fontSize={"6xl"}>
        {t("bottom_tabs.clients_tab_name")}
      </Text>
    </Flex>
  );

  const organisation = user ? getOrganisation(user) : undefined;

  // TODO: Put this into a Component
  const desktopOnlyNavbar = (
    <Flex
      testID={"customBrandingDesktop-header"}
      flexDirection={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      background="primary.600"
    >
      <View
        style={{
          paddingHorizontal: Scale(40),
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor: "white",
        }}
      >
        <Image
          source={organisation?.logo ? { uri: organisation.logo } : Images.WeekmealsLogo}
          style={{ width: Scale(160), height: VerticalScale(41) }}
          resizeMode="contain"
        />
      </View>
    </Flex>
  );

  const activeClientsLabel = t(`clients_overview.status_filter.${ClientsFilterState.ACTIVE_CLIENTS}`);
  const deactivatedClientsLabel = t(`clients_overview.status_filter.${ClientsFilterState.DEACTIVATED_CLIENTS}`);
  const activeClientsTabIndex = 0;

  const activeInactiveTabsComponent = (
    <Box flexDirection="row" justifyContent={"center"} my={2}>
      {[ClientsFilterState.ACTIVE_CLIENTS, ClientsFilterState.DEACTIVATED_CLIENTS].map(
        (filterState: ClientsFilterState, i: number) => {
          const renderingActiveClientsTab = i === activeClientsTabIndex;

          const shouldHighlightThisTab = renderingActiveClientsTab ? filterActiveClientsOnly : !filterActiveClientsOnly;

          const color = shouldHighlightThisTab ? "blue.200" : "gray.200";
          const borderColor = shouldHighlightThisTab ? "primary.600" : "coolGray.200";

          return (
            <Box
              key={i}
              borderBottomWidth="3"
              borderColor={borderColor}
              width={isDesktop ? "15%" : "50%"}
              alignItems="center"
              p="3"
            >
              <Pressable
                testID={`clientsFilter-${filterState}-radioButton`}
                onPress={() => {
                  setClientsFilter(
                    filterActiveClientsOnly ? ClientsFilterState.DEACTIVATED_CLIENTS : ClientsFilterState.ACTIVE_CLIENTS
                  );
                }}
              >
                <Text bold={shouldHighlightThisTab} color={color}>{`${
                  renderingActiveClientsTab ? activeClientsLabel : deactivatedClientsLabel
                }`}</Text>
                {isFetchingClientsList ? <Spinner /> : null}
              </Pressable>
            </Box>
          );
        }
      )}
    </Box>
  );
  const [validatedBasicInfoFormValues, setValidatedBasicInfoFormValues] = useState<
    BasicUserInfoFormSchema | undefined
  >();
  const onSubmitBasicUserInfo = (values: BasicUserInfoFormSchema): void => setValidatedBasicInfoFormValues(values);

  const basicInfoForm = (
    <Formik
      initialValues={basicUserInfoFormInitialValues}
      validationSchema={basicUserInfoFormSchema}
      onSubmit={onSubmitBasicUserInfo}
    >
      {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, dirty, isValid }) => (
        <>
          <AlertDialog.Body backgroundColor={"white"}>
            <View>
              <FormControl isRequired isInvalid={!_.isEmpty(errors.firstName)}>
                <FormControl.Label>{t("coach_mode_create_new_client.first_name_input_label_text")}</FormControl.Label>
                <Input
                  onBlur={handleBlur("firstName")}
                  placeholder={t("coach_mode_create_new_client.first_name_input_label_text")}
                  onChangeText={handleChange("firstName")}
                  value={String(values.firstName)}
                  testID="addNewClient-firstName-input"
                />
                <FormControl.ErrorMessage>{errors.lastName}</FormControl.ErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!_.isEmpty(errors.lastName)}>
                <FormControl.Label>{t("coach_mode_create_new_client.last_name_input_label_text")}</FormControl.Label>
                <Input
                  onBlur={handleBlur("lastName")}
                  placeholder={t("coach_mode_create_new_client.last_name_input_label_text")}
                  onChangeText={handleChange("lastName")}
                  value={String(values.lastName)}
                  testID="addNewClient-lastName-input"
                />
                <FormControl.ErrorMessage>{errors.lastName}</FormControl.ErrorMessage>
              </FormControl>

              {/* Email address */}
              <FormControl isRequired isInvalid={!_.isEmpty(errors.email)}>
                <FormControl.Label>{t("add_new_client.email_input_label")}</FormControl.Label>
                <Input
                  onBlur={handleBlur("email")}
                  placeholder={t("general.email_address")}
                  onChangeText={handleChange("email")}
                  value={String(values.email)}
                  keyboardType="email-address"
                  testID="addNewClientByEmail-input"
                />
                <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>
              </FormControl>
            </View>
          </AlertDialog.Body>
          <AlertDialog.Footer backgroundColor={"white"}>
            <Button
              onPress={() => handleSubmit()}
              isLoading={isSubmitting}
              isDisabled={!dirty || !isValid}
              testID={"addNewClientByEmail-submitBasicInfo-button"}
            >
              {t("add_new_client.add_new_client_button_text")}
            </Button>
          </AlertDialog.Footer>
        </>
      )}
    </Formik>
  );

  const onSubmitUserProfile = async (values: UserProfileFormSchema): Promise<void> => {
    onCloseAddNewClientDialog();

    if (
      !validatedBasicInfoFormValues?.email ||
      !validatedBasicInfoFormValues?.firstName ||
      !validatedBasicInfoFormValues?.lastName
    ) {
      throw new Error("validated basic info form values were not set");
    }

    setValidatedBasicInfoFormValues(undefined);

    const createUserRequestPostBody: UsersAuthUsersCreateApiArg = {
      customUserCreateRequest: {
        email: validatedBasicInfoFormValues.email,
        first_name: validatedBasicInfoFormValues.firstName,
        last_name: validatedBasicInfoFormValues.lastName,
        intake: {
          weight: values.weight,
          body_fat_percentage: values.body_fat_percentage / 100,
          activity: values.activity,
          diet: values.diet,
          gender: values.gender,
          // NOTE: Not currently in scope
          sustainability: SustainabilityEnum.SUSTAINABLE,
          food_intolerances_gluten: values.foodIntolerancesGluten,
          food_intolerances_lactose: values.foodIntolerancesLactose,
          food_intolerances_nut: values.foodIntolerancesNut,
          food_intolerances_crustaceans_shellfish: values.foodIntolerancesCrustaceansShellfish,
        },
      },
    };
    await createUserBackendCall(createUserRequestPostBody)
      .unwrap()
      .then((createdUser) => {
        const exerciseCreationPromises =
          values.exercise_instances && !_.isEmpty(values.exercise_instances)
            ? values.exercise_instances.map((exercise) =>
                createExerciseInstanceBackendCall({
                  exerciseInstanceCreateUpdateRequest: {
                    ...exercise,
                    intake: createdUser.intake.id,
                  },
                })
              )
            : [];

        return Promise.all(exerciseCreationPromises);
      })
      .catch((error) => {
        if (error?.status === 400) {
          alert(_.values(error?.data));
        }
      });

    // TODO: Navigate to client profile page
    setPage(1);
  };

  const addNewClientDialog = (
    <AlertDialog
      leastDestructiveRef={deactivateClientDialogRef}
      isOpen={isOpenAddNewClientDialog}
      onClose={onCloseAddNewClientDialog}
      size={"xl"}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header backgroundColor={"white"}>
          {t("add_new_client.add_new_client_dialog_header")}
        </AlertDialog.Header>

        {!validatedBasicInfoFormValues ? (
          basicInfoForm
        ) : (
          <ScrollView showsVerticalScrollIndicator={false}>
            <EditUserProfileComponent
              onSubmitUserProfile={onSubmitUserProfile}
              basicInfoForm={validatedBasicInfoFormValues}
              showLoading={isLoading || isLoadingCreateExerciseInstance}
              submitButtonText={t("coach_mode_create_new_client.create_new_client_button_text")}
            />
          </ScrollView>
        )}
      </AlertDialog.Content>
    </AlertDialog>
  );

  const pressedCreateNewClientButton = (): void => {
    onOpenAddNewClientDialog();
  };

  const floatingAddNewClientButton = (
    <Fab
      placement="bottom-right"
      renderInPortal={false}
      onPress={pressedCreateNewClientButton}
      icon={<Icon as={MaterialIcons} size="6xl" name="person-add" />}
      testID={"openAddNewClientDialog-floatingButton"}
    />
  );

  const clientsSearchAndAddNewClientButton = (
    <div>
      <View
        mx={isDesktop ? "20%" : "5%"}
        flexDirection="row"
        justifyContent={isDesktop ? "left" : "center"}
        my={4}
        px={isDesktop ? 2 : 0}
      >
        <Input
          placeholder={t("clients_overview.search_for_clients_text")}
          value={searchText}
          onChangeText={setSearchText}
          width={isDesktop ? "40%" : "50%"}
          testID={"clientsSearch-input"}
          borderRadius="4"
          py="3"
          px="1"
          fontSize="14"
          InputLeftElement={<Icon as={MaterialIcons} m="2" ml="3" size="6" color={"gray.400"} name="search" />}
        />
        {isDesktop ? (
          <Button
            ml={"2"}
            onPress={pressedCreateNewClientButton}
            testID={"openAddNewClientDialog-button"}
            nativeID={"addClientButton"}
          >
            {t("clients_overview.add_client_button_text")}
          </Button>
        ) : null}
      </View>
      <Text mx={isDesktop ? "20%" : "5%"} fontSize="md" color="gray.400" px={isDesktop ? 2 : 0} my={2}>
        {t("clients_overview.number_of_results", { count: clientsListResponse?.count || 0 })}
      </Text>
    </div>
  );

  const isCustomerCurrentlyTrialing = user ? doesCustomerHaveAnyTrialingSubscriptions(user) : false;

  const upgradeNowButton =
    isCustomerCurrentlyTrialing && !isMobilePlatform() ? (
      <StripeCustomerPortalLink isDesktop={isDesktop} isUpgradeNowButton />
    ) : null;

  const totalPages = (clientsListResponse as ExtendedPaginatedUserList)?.total_pages || 1;
  const paginationComponent = (
    <Flex flexDirection="row" justifyContent="center" alignItems="center">
      <Pressable
        onPress={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
        disabled={page === 1}
      >
        <Icon as={Ionicons} name="chevron-back" size="5" color={page === 1 ? "gray.400" : "gray.600"} />
      </Pressable>
      <Text mx={2} fontSize="md">
        {t("general.page")} {page} {t("general.of")} {totalPages}
      </Text>
      <Pressable
        onPress={() => {
          if (page < totalPages) {
            setPage(page + 1);
          }
        }}
        disabled={page === totalPages}
      >
        <Icon as={Ionicons} name="chevron-forward" size="5" color={page === totalPages ? "gray.400" : "gray.600"} />
      </Pressable>
    </Flex>
  );

  return (
    <SafeAreaView style={[commonStyles.container, { backgroundColor: theme.colors.coolGray["100"] }]}>
      {isDesktop ? desktopOnlyNavbar : null}
      <Flex backgroundColor="coolGray.100" flex={1}>
        {headerComponent}

        {upgradeNowButton}
        {/* {!isMobilePlatform() ? <ReferralProgrammeLink isDesktop={isDesktop} /> : null} */}
        {isCustomerCurrentlyTrialing ? <BookACallLink isDesktop={isDesktop} email={user?.email} /> : null}

        {activeInactiveTabsComponent}

        {clientsSearchAndAddNewClientButton}
        {clientsListComponent}
        {totalPages > 1 ? paginationComponent : null}

        {!isDesktop ? floatingAddNewClientButton : null}

        {addNewClientDialog}
      </Flex>
    </SafeAreaView>
  );
};
export default CoachModeViewClientsScreen;

// NOTE: This is here for future reference
// const AddClientInExcessOfCurrentSeats = (): JSX.Element => (
//   <View>
//     <Text fontSize="16" color="gray.100" fontWeight="500" textAlign="center">
//       {t("Adding this client will increase your tier from max. 10 clients to max. 15 clients.")}
//     </Text>
//     <Text fontSize="16" color="gray.100" fontWeight="500" textAlign="center">
//       {t("With your current plan this increases your monthly invoice by € 13,50")}
//     </Text>

//     <View style={styles.modalButtonContainer}>
//       <CommonButton
//         externalContainerStyle={{}}
//         title={t("Add this client")}
//         onPress={() => {
//           setModalVisible(false);
//         }}
//       />
//       <CommonButton
//         externalContainerStyle={{ marginTop: VerticalScale(10) }}
//         title={t("Take me back")}
//         onPress={() => {
//           setModalVisible(false);
//         }}
//         lightMode
//       />
//     </View>
//   </View>
// );
