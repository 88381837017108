import { useTheme } from "native-base";
import React from "react";
import { StyleProp, Text, TextStyle, View, ViewStyle } from "react-native";

import ProgressBar from "../commons/ProgressBar";
import { formatNumberAsWholeNumber } from "../helpers/generalHelpers";
import styles from "./MacroTargetInfoStyle";

type MacroTargetInfoProps = {
  title: string;
  style?: StyleProp<ViewStyle> | StyleProp<TextStyle>;
  targetValue?: number;
  actualValue?: number;
  showProgressBar?: boolean;
  showTarget?: boolean;
};

const MacroTargetInfo = ({
  title,
  style,
  targetValue,
  actualValue,
  showProgressBar = true,
  showTarget = true,
}: MacroTargetInfoProps): JSX.Element => {
  const theme = useTheme();

  return (
    <View style={styles.container}>
      <View style={styles.nutrientDiv}>
        <Text style={[styles.nutrientHead, style, { color: theme.colors.gray[400] }]}>{title}</Text>
        <View style={styles.nutrientTextDiv}>
          <Text style={{ ...styles.nutrientText1, color: "black" }} testID={`${title}-actual-value`}>
            {formatNumberAsWholeNumber(actualValue || 0)}
          </Text>
          {showTarget ? (
            <Text style={{ ...styles.nutrientText2, color: theme.colors.gray[400] }} testID={`${title}-target-value`}>
              /{formatNumberAsWholeNumber(targetValue || 0)}
            </Text>
          ) : null}
        </View>
      </View>

      {showProgressBar && showTarget ? (
        <View style={styles.progressBarContainer}>
          <ProgressBar value={targetValue ? ((actualValue || 0) / targetValue) * 100 : 0} width={150} />
        </View>
      ) : null}
    </View>
  );
};
export default MacroTargetInfo;
