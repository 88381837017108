import { StyleSheet } from "react-native";

import { Scale, VerticalScale, width } from "../constants";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: VerticalScale(17),
  },
  titleInputContainer: {
    width: VerticalScale(47),
    height: VerticalScale(47),
    padding: 5,
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 8,
    // borderColor: Colors.borderColor,
  },
  titleInputWrapper: {
    flex: 1,
    marginLeft: Scale(6),
  },
  addIngredientsContainer: {
    marginTop: VerticalScale(47),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  recipeImg: {
    width: Scale(35),
    height: Scale(35),
    borderRadius: 8,
  },
  modalButtonContainer: {
    marginTop: VerticalScale(48),
  },
});

export default styles;
