/* eslint-disable import/no-cycle */
import * as Application from "expo-application";
import { Locale } from "locale-enum";
import { Platform } from "react-native";

import logger from "../services/logger";
import { faces, homeCarouselData, homeData, timeLineForProfile, walkThroughList } from "./data";
import * as constants from "./data";
import { height, isDesktopScreen, Scale, StatusBarHeight, VerticalScale, width } from "./helperFunction";
import Images from "./images";
import Routes from "./routeNames";
import ScreenIndexes from "./ScreenIndexes";
import commonStyles from "./styles";

// TODO: When we implement translations we need to decide what to do
const PHRASE_DISTRIBUTION_ID = null;
const PHRASE_ENVIRONMENT_ID = null;

const DEFAULT_PRODUCTION_BACKEND_API_BASE_URL = "https://weekmeals-django-production.herokuapp.com";

// NOTE: We cannot use destructuring because we use a rewriting plugin to make the env vars code work
// eslint-disable-next-line prefer-destructuring
const BACKEND_API_BASE_URL = process.env.BACKEND_API_BASE_URL || DEFAULT_PRODUCTION_BACKEND_API_BASE_URL;
// const BACKEND_API_BASE_URL = "https://weekmeals-django-production.herokuapp.com";
// eslint-disable-next-line prefer-destructuring
const SENTRY_DSN = process.env.SENTRY_DSN;
// eslint-disable-next-line prefer-destructuring
const SEGMENT_WRITE_KEY_FOR_WEB_SNIPPET = process.env.SEGMENT_WRITE_KEY_FOR_WEB_SNIPPET || "";
// eslint-disable-next-line prefer-destructuring
const SEGMENT_API_KEY_FOR_NATIVE_LIBRARY = process.env.SEGMENT_API_KEY_FOR_NATIVE_LIBRARY || "";

if (!BACKEND_API_BASE_URL) {
  // NOTE: Our jest env does not appear to set process.env.BACKEND_API_BASE_URL correctly on CI
  // so we add this guard to prevent the unit tests from crashing
  if (process.env.NODE_ENV !== "test") {
    throw Error("No process.env.BACKEND_API_BASE_URL found: this should not happen.");
  }
}

if (process.env.NODE_ENV !== "test") {
  logger.info("BACKEND_API_BASE_URL:", BACKEND_API_BASE_URL);
}

const ENVIRONMENT = process.env.ENVIRONMENT || "production";

const PRODUCTION = ENVIRONMENT === "production";

const SUBDOMAIN_OVERRIDE = PRODUCTION && process.env.SUBDOMAIN_OVERRIDE ? process.env.SUBDOMAIN_OVERRIDE : "";

const DEFAULT_LANGUAGE = Locale.nl;

const IS_MOBILE_PLATFORM = Platform.OS !== "web";

const BUILD_NUMBER = IS_MOBILE_PLATFORM ? Application.nativeBuildVersion : "web";

const KG_TO_LBS = 2.20462;
const LBS_TO_KG = 0.453592;

export {
  BACKEND_API_BASE_URL,
  BUILD_NUMBER,
  commonStyles,
  constants,
  DEFAULT_LANGUAGE,
  ENVIRONMENT,
  faces,
  height,
  homeCarouselData,
  homeData,
  Images,
  IS_MOBILE_PLATFORM,
  isDesktopScreen,
  KG_TO_LBS,
  LBS_TO_KG,
  PHRASE_DISTRIBUTION_ID,
  PHRASE_ENVIRONMENT_ID,
  PRODUCTION,
  Routes,
  Scale,
  ScreenIndexes,
  SEGMENT_API_KEY_FOR_NATIVE_LIBRARY,
  SEGMENT_WRITE_KEY_FOR_WEB_SNIPPET,
  SENTRY_DSN,
  StatusBarHeight,
  SUBDOMAIN_OVERRIDE,
  timeLineForProfile,
  VerticalScale,
  walkThroughList,
  width,
};
