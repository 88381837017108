import type { Dispatch } from "react";

import { USER_LOGOUT_ACTION } from "../../../store";
import analyticsInterface from "./analyticsHelpers";
import { sendShutdownRequestToIntercom } from "./intercomHelpers";

const { resetAnalytics } = analyticsInterface;

// eslint-disable-next-line import/prefer-default-export
export function logout(dispatch: Dispatch<unknown>): void {
  resetAnalytics();
  sendShutdownRequestToIntercom();

  dispatch({ type: USER_LOGOUT_ACTION });
}
