import { Flex } from "native-base";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Image, ImageSourcePropType, Text, View } from "react-native";

import { Images } from "../constants";
import type { NutritionType } from "../types";
import styles from "./NutritionPlanRowItemStyle";

type MacronutrientItemProps = {
  label: string;
  value: string;
};

const MacronutrientItem = ({ label, value }: MacronutrientItemProps): JSX.Element => (
  <View style={styles.itemContainer}>
    <Text style={styles.caloriesLabelText}>{label}</Text>
    <Text style={styles.caloriesValueText}>{value}</Text>
  </View>
);

type NutritionPlanRowItemProps = {
  imgSrc: ImageSourcePropType;
  label: string;
  nutrition: NutritionType;
};

const NutritionPlanRowItem: React.FC<NutritionPlanRowItemProps> = ({ imgSrc, label, nutrition }) => {
  const { t } = useTranslation();
  return (
    <Flex direction="row" justifyContent={"flex-start"} mt="6">
      <View style={styles.imgContainer}>
        <Image source={imgSrc} />
      </View>
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <Flex direction="row" justifyContent={"space-between"} mt="3">
          <Text style={styles.labelText}>{label}</Text>
          <Image source={Images.PencilIcon} />
        </Flex>
        <Flex direction="row" justifyContent={"space-between"} mt="3">
          <MacronutrientItem label={t("general.calories")} value={nutrition.calories} />
          <MacronutrientItem label={t("general.fat")} value={nutrition.fat} />
          <MacronutrientItem label={t("general.protein")} value={nutrition.protein} />
          <MacronutrientItem label={t("general.carbohydrates")} value={nutrition.carbohydrates} />
        </Flex>
      </View>
    </Flex>
  );
};

export default NutritionPlanRowItem;
