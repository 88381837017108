/* eslint-disable no-use-before-define */
import React from "react";
import { Image, Pressable, StyleSheet, Text, TextStyle, View } from "react-native";

import { commonStyles, Images, Scale } from "../constants";

type Props = {
  label: string;
  description?: string;
  onPress?: () => void;
  checked?: boolean;
  multipleSelectionsAllowed: boolean;
};

const CommonRadioBox = ({ label, description, onPress, checked, multipleSelectionsAllowed }: Props): JSX.Element => (
  <Pressable style={[styles.containerBase, checked && styles.checkedContainer]} onPress={onPress}>
    <View
      style={[
        styles.checkboxBase,
        checked && styles.checkboxChecked,
        multipleSelectionsAllowed && styles.multiCheckbox,
      ]}
    >
      {checked ? <Image source={Images.CheckWhite} style={styles.checkedIcon} /> : null}
    </View>
    <View style={{ flex: 1 }}>
      <Text style={commonStyles.titleText16Bold}>{label}</Text>
      {Boolean(description) && (
        <Text style={[commonStyles.bodyText14, styles.descriptionText]} numberOfLines={2}>
          {description}
        </Text>
      )}
    </View>
  </Pressable>
);

export default CommonRadioBox;

const styles = StyleSheet.create({
  containerBase: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // backgroundColor: Colors.grey,
    padding: Scale(16),
    marginBottom: Scale(8),
    borderRadius: 4,
    borderWidth: 2,
    borderColor: "transparent",
  },
  checkedContainer: {
    backgroundColor: "white",
    // borderColor: Colors.primaryGreenColor,
    borderWidth: 2,
  },
  checkboxBase: {
    width: Scale(28),
    height: Scale(28),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    borderWidth: 2,
    // borderColor: Colors.borderColor,
    backgroundColor: "white",
    marginRight: Scale(16),
  },
  checkboxChecked: {
    borderWidth: 2,
    // borderColor: Colors.primaryGreenColor,
    // backgroundColor: Colors.primaryGreenColor,
  },
  multiCheckbox: {
    borderRadius: 4,
  },
  checkedIcon: {
    width: Scale(20),
    height: Scale(20),
  },
  descriptionText: {
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
