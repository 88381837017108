import { StyleSheet } from "react-native";

import { Scale, width } from "../constants/helperFunction";

const styles = StyleSheet.create({
  checkedSmallCircle: {
    height: Scale(6),
    width: Scale(6),
    // backgroundColor: "primary.300",
    borderRadius: Scale(5),
    // textDecorationLine: "line-through",
    marginHorizontal: Scale(10),
  },
  unCheckedSmallCircle: {
    height: Scale(6),
    width: Scale(6),
    // backgroundColor: Colors.textColor,
    borderRadius: Scale(5),

    marginHorizontal: Scale(10),
  },
  checkboxStyle: {
    height: Scale(26),
    resizeMode: "contain",
    width: Scale(26),
  },
  flexContainer: {
    flexDirection: "row",

    marginBottom: Scale(15),
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageStyle: {
    height: Scale(70),
    width: Scale(70),
    resizeMode: "contain",
    marginRight: Scale(10),
  },
  singleProductMealWrapper: {
    flexDirection: "row",
    marginBottom: Scale(10),
    justifyContent: "space-between",
  },
  recipeImage: { width: 80, height: 80, borderRadius: 5, marginRight: 10 },
  recipeMealPlannerItem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "center",
  },
  macroOverviewTargetContainer: { flexDirection: "row", marginTop: 8 },
  proteinForTheDayText: { fontSize: 14 },
});

export default styles;
