import { StyleSheet } from "react-native";

import { Scale, width } from "../constants";

const styles = StyleSheet.create({
  textWrapperContainer: { flexDirection: "row", justifyContent: "center", alignItems: "center" },
  bottomTextComponent: {
    flexDirection: "row",
    // backgroundColor: Colors.whiteColor,
    position: "absolute",
    zIndex: 1,
    right: Scale(30),
    bottom: Scale(50),
    justifyContent: "center",
    alignItems: "center",
    height: Scale(35),
    paddingHorizontal: Scale(5),
    borderRadius: Scale(4),
  },
  dotsWrapperStyle: {
    marginVertical: Scale(20),
    width: Scale(8),
    borderRadius: Scale(5),
    position: "absolute",
    left: Scale(22),
  },
  badgeStyle: {
    backgroundColor: "#CCEFDC",
  },

  smallComponent: { width: Scale(60) },
  swiperTopTimerTextStyle: {
    marginLeft: Scale(7),
    fontSize: Scale(15),
    width: Scale(30),
  },
  timerStyle: {
    height: Scale(18),
    width: Scale(18),
    resizeMode: "contain",
    // tintColor: Colors.largeTextColor,
  },
  topSwiperTimerComponent: {
    flexDirection: "row",
    // backgroundColor: Colors.whiteColor,
    position: "absolute",
    zIndex: 1,
    right: Scale(30),
    top: Scale(30),
    justifyContent: "center",
    alignItems: "center",
    height: Scale(35),
    paddingHorizontal: Scale(5),
    borderRadius: Scale(4),
  },
  dotStyle: {
    width: Scale(8),
    height: Scale(8),
    backgroundColor: "#ccefdc",
    borderRadius: 4,
    marginHorizontal: Scale(3),
  },
  paginationStyle: {
    justifyContent: "flex-start",
    marginHorizontal: Scale(20),

    position: "absolute",
    bottom: Scale(10),
  },
  activeDotStyle: {
    width: Scale(8),
    height: Scale(8),
    // backgroundColor: Colors.primaryGreenColor,
    borderRadius: Scale(5),
  },
  swiperContainer: {
    height: Scale(348),
  },
  imageStyle: {
    height: Scale(250),
    width: Scale(350),
    resizeMode: "contain",
    marginVertical: Scale(20),
  },
  textStyleForSwiper: {
    marginHorizontal: Scale(20),
    fontWeight: "500",
    // color: Colors.swiperColor,
  },
  topContainer: {
    // backgroundColor: Colors.lightGreen,
    paddingVertical: Scale(20),
  },
  textInputStyle: {
    // borderColor: Colors.primaryGreenColor,
    borderWidth: Scale(1),
  },
  externalTextInputStyle: {
    // color: Colors.primaryGreenColor,
  },
  wrapper: {
    // backgroundColor: Colors.whiteColor,
    flex: 1,
  },
  listWrapper: {
    marginTop: Scale(20),
  },
  titleContainer: {
    flexDirection: "row",

    justifyContent: "space-between",
    marginHorizontal: Scale(20),
    marginBottom: Scale(20),
  },
  leftTextStyle: {
    marginLeft: 0,
    marginRight: Scale(15),

    fontSize: Scale(19),
    fontWeight: "700",
    // color: colors.largeTextColor,
  },
  middleTextStyle: {
    borderWidth: StyleSheet.hairlineWidth,
    // borderColor: Colors.largeTextColor,
    fontSize: Scale(12),
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: Scale(5),
    paddingTop: Scale(2),
    paddingHorizontal: Scale(5),
    // color: Colors.largeTextColor,
  },
  rightTextStyle: {
    marginLeft: 0,
    marginRight: 0,
    // color: Colors.primaryGreenColor,
    fontSize: Scale(16),
    fontWeight: "600",
    width: Scale(160),

    textAlign: "right",
  },
  topStyle: {
    marginTop: Scale(40),
  },
  firstTitleContainer: {
    flexDirection: "row",

    width: width / 2,
  },
  scrollViewStyle: {
    paddingHorizontal: Scale(10),
  },
});

export default styles;
