import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

const styles = StyleSheet.create({
  itemContainer: {
    marginVertical: 16,
  },
  labelText: {
    // color: Colors.sectionTextColor,
    fontSize: 16,
    marginBottom: 6,
  },
  valueText: {
    // color: Colors.textColor,
    fontSize: 16,
  },
});

type PlanRowItemProps = {
  label: string;
  value: string;
};

const PlanRowItem = ({ label, value }: PlanRowItemProps): JSX.Element => (
  <View style={styles.itemContainer}>
    <Text style={styles.labelText}>{label}</Text>
    <Text style={styles.valueText}>{value}</Text>
  </View>
);

export default PlanRowItem;
