import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { onboardingDataSelector, setActivityLevel } from "../../slices/onboardingSlice";
import { ActivityEnum as Activity } from "../../types";
import type { Option } from "./SelectionScreen";
import SelectionScreen from "./SelectionScreen";

interface ActivityLevelScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const ActivityLevelScreen: React.FC<ActivityLevelScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onboardingData = useSelector(onboardingDataSelector);

  const activityLevels: Option[] = [
    {
      label: t("onboarding.onboarding_3_activity_level_screen.not_very_active"),
      description: t("onboarding.onboarding_3_activity_level_screen.spend_most_day_sitting"),
      value: Activity.SEDENTARY,
      icon: "🪑", // Chair
      testID: `activity-option-${Activity.SEDENTARY}`,
    },
    {
      label: t("onboarding.onboarding_3_activity_level_screen.lightly_active"),
      description: t("onboarding.onboarding_3_activity_level_screen.spend_good_part_day_feet"),
      value: Activity.MILDLY_ACTIVE,
      icon: "🚶", // Person walking
      testID: `activity-option-${Activity.MILDLY_ACTIVE}`,
    },
    {
      label: t("onboarding.onboarding_3_activity_level_screen.active"),
      description: t("onboarding.onboarding_3_activity_level_screen.most_part_day_feet"),
      value: Activity.ACTIVE,
      icon: "🏃", // Person running
      testID: `activity-option-${Activity.ACTIVE}`,
    },
    {
      label: t("onboarding.onboarding_3_activity_level_screen.very_active"),
      description: t("onboarding.onboarding_3_activity_level_screen.most_part_day_heavy_work"),
      value: Activity.VERY_ACTIVE,
      icon: "🏋️", // Person lifting weights
      testID: `activity-option-${Activity.VERY_ACTIVE}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedActivity: Yup.mixed<Activity>().required(
      t("onboarding.onboarding_3_activity_level_screen.select_at_least_one_activity")
    ),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    dispatch(setActivityLevel(values.selectedActivity as Activity));
    navigation.navigate(Routes.BiometricDataScreen);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({
        selectedActivity: onboardingData.activityLevel || Activity.MILDLY_ACTIVE,
      })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => (
        <SelectionScreen
          title={t("onboarding.onboarding_3_activity_level_screen.activity_level")}
          subtitle={""}
          options={activityLevels}
          multipleSelection={false}
          selectedOptions={[values.selectedActivity]}
          setSelectedOptions={(options: string[]) => {
            setFieldValue("selectedActivity", options[0]);
          }}
          onPressNext={handleSubmit}
          onPressBack={() => navigation.goBack()}
          errorMessage={errors.selectedActivity && touched.selectedActivity ? errors.selectedActivity : ""}
        />
      )}
    </Formik>
  );
};

export default ActivityLevelScreen;
