// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import SelectOptionScreen from "../components/SelectOptionScreen";
import { commonStyles, Routes } from "../constants";
import logLevel from "../services/logger";

type ParamList = {
  AddMotivationGoalScreen: undefined;
  ProfileStartScreen: undefined;
  AddCardioSportScreen: undefined;
  RecipeSearchScreen: undefined;
};
type Props = {
  navigation: NativeStackNavigationProp<ParamList, "AddMotivationGoalScreen">;
};

const optionsData = [
  {
    id: 0,
    label: "I want to feel more confident",
  },
  {
    id: 1,
    label: "I want to be healthier",
  },
  {
    id: 2,
    label: "I want to avoid pain and / or injuries",
  },
  {
    id: 3,
    label: "I want to feel stronger",
  },
  {
    id: 4,
    label: "I want to look good",
  },
  {
    id: 5,
    label: "I want to be better for my loved ones",
  },
];

const AddMotivationGoalScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [inputText, setInputText] = useState("");
  return (
    <View style={commonStyles.container}>
      <SelectOptionScreen
        pageTitle={"What is the reason you want to achieve this goal?"}
        pageDescription={"Multiple answers possible"}
        optionsData={optionsData}
        buttonText={"Next"}
        onButtonPress={() => {
          logLevel.debug("onPress button");
          navigation.push(Routes.RecipeSearchScreen as keyof ParamList);
        }}
        multipleSelectionsAllowed={true}
        // FIXME:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        navigation={navigation}
        inputLabel={"Other, namely:"}
        onChangeText={(text: string) => setInputText(text)}
      />
    </View>
  );
};

export default AddMotivationGoalScreen;
