import logLevel from "loglevel";
// import remote from "loglevel-plugin-remote";

// import { PRODUCTION } from "../constants";

// const customJSON = (log: {
//   message: string;
//   level: { label: string };
//   stacktrace: StackTrace;
// }) => ({
//   msg: log.message,
//   level: log.level.label,
//   stacktrace: log.stacktrace,
// });

// TODO: Configure with remote logging when we have decided
// remote.apply(logLevel, { format: customJSON, url: REMOTE_LOGGER_URL });

logLevel.setLevel("debug");

// if (!PRODUCTION) {
//   logLevel.enableAll();
// }

if (process.env.NODE_ENV !== "test") {
  console.log(`Log level: ${logLevel.getLevel()}`);
}

export default logLevel;
