/* eslint-disable import/prefer-default-export */

import type { UserProfile } from "../services/backendTypes";
import type { LegacyInput } from "../services/legacyNutritionCalculations7";
import { updateLegacyInput } from "../services/nutritionCalculations7";

const MAX_CARBS = 100;
/**
 * Discussed and agreed with Oscar Helm.
 */
export const MAX_CARBS_FOR_KETO = 40;

export function adjustLegacyInputCarbs(
  userProfile: UserProfile,
  legacyInput: LegacyInput,
  maxCarbs: number
): LegacyInput {
  const { fat, carbs, protein, calories } = legacyInput.computedDailyMacros;

  const extraCarbs = Math.max(carbs - maxCarbs, 0);

  if (extraCarbs === 0) {
    return legacyInput;
  }

  const updatedFat = fat + Math.round((extraCarbs * 4) / 9);
  const updatedCarbs = carbs - extraCarbs;

  const adjustedLegacyInput = updateLegacyInput(
    {
      ...legacyInput,
      adjustedDailyMacros: {
        fiber: 0,
        protein,
        fat: updatedFat,
        carbs: updatedCarbs,
        calories,
      },
    },
    userProfile
  );

  return adjustedLegacyInput;
}
