import { StyleSheet } from "react-native";

import { Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  questionMarkImg: {
    width: Scale(21),
    height: Scale(21),
  },
  recipeMacrosContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  externalContainer: {
    backgroundColor: "white",
    // borderColor: Colors.disableButton,
    borderWidth: 1,
    marginTop: VerticalScale(10),
    marginLeft: 0,
    marginRight: 0,
  },
  deactiveButton: {
    backgroundColor: "transparent",
    // borderColor: Colors.greyTextColor,
    borderWidth: 1,
    marginTop: VerticalScale(10),
    marginLeft: 0,
    marginRight: 0,
  },
  routeViewContainer: {
    marginVertical: VerticalScale(24),
    marginHorizontal: Scale(16),
    borderRadius: 8,
  },
  marginTop24: {
    marginTop: VerticalScale(24),
  },
  marginVertical24: {
    marginVertical: VerticalScale(24),
  },
  settingIcon: {
    width: Scale(24),
    height: Scale(24),
    marginTop: VerticalScale(24),
  },
  modalContainer: {
    width: "50%",
  },
});

export default styles;
