// eslint-disable-next-line import/no-cycle
import type { MealSlotSpecification } from "../services/backendTypes"; // temporary test data and will be removed shortly
import type { MacroName } from "../types";
import Images from "./images";

const walkThroughList = [
  {
    id: 1,
    title: "Welkom!",
    description: "WeekMeals helpt je op een leuke manier gezonder te leven en te eten. Swipe om verder te gaan.",
    image: Images.WeekmealsLogo,
  },
  {
    id: 2,
    title: "Recepten op maat",
    description: "Recepten in de app worden automatisch aangepast op jouw voedingsplan– lekker & makkelijk.",
    image: Images.OnBoardingIcon1,
    icon: Images.ForkKnifeWhiteIcon,
    // bgColor: Colors.bgEmerald,
  },
  {
    id: 3,
    title: "Gebaseerd op wetenschap",
    description: "De recepten en het voedingsadvies van WeekMeals zijn gebasseerd op wetenschappelijk onderzoek.",
    image: Images.OnBoardingIcon2,
    icon: Images.MicroscopeWhiteIcon,
    // bgColor: Colors.bgPurple,
  },
  {
    id: 4,
    title: "Weekplanner",
    description: "Het inplannen van je week verhoogt jouw kans op succes",
    image: Images.OnBoardingIcon3,
    icon: Images.CalendarCheckWhiteIcon,
    // bgColor: Colors.bgBlizzardBlue,
  },
  {
    id: 5,
    title: "Boodschappenlijstjes",
    description: "Maak met je geplande maaltijden in no-time een boodschappenlijstje. Nooit meer peinzen bij de super!",
    image: Images.OnBoardingIcon4,
    icon: Images.ChecklistWhiteIcon,
    // bgColor: Colors.bgTangerine,
    button: true,
  },
];

export const CATEGORY_POPULAR = "POPULAR";
export const CATEGORY_FAVOURITE = "FAVOURITE";
export const CATEGORY_OWN_RECIPE = "OWN_RECIPE";

export enum RecipeCategory {
  POPULAR = "POPULAR",
  FAVOURITE = "FAVOURITE",
  OWN_RECIPE = "OWN_RECIPE",
}

export enum SourceProvider {
  WEEKMEALS = "WEEKMEALS",
  USER_GENERATED = "USER_GENERATED",
}

// TODO: we need to figure out a way to generate the enums from the API
export enum RecipeTag {
  BREAD = "BREAD",
  BURGER = "BURGER",
  COUSCOUS_AND_QUINOA = "COUSCOUS_AND_QUINOA",
  DAIRY = "DAIRY",
  DIPS_AND_SPREADS = "DIPS_AND_SPREADS",
  KETO = "KETO",
  PANCAKES_AND_OMELETTES = "PANCAKES_AND_OMELETTES",
  PASTA = "PASTA",
  POTATO = "POTATO",
  RICE_AND_NOODLES = "RICE_AND_NOODLES",
  SALAD = "SALAD",
  SNACKS_AND_BAKES = "SNACKS_AND_BAKES",
  SOUP = "SOUP",
  VEGAN = "VEGAN",
  WRAP = "WRAP",
}

export const MACRO_NAMES: MacroName[] = ["kcal", "protein", "carbohydrates", "fat"];

export enum MealType {
  BREAKFAST = "BREAKFAST",
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  SNACK = "SNACK",
  ALL = "ALL",
}

const homeData = [
  {
    id: 1,
    image: Images.HomeFoodImage1,
    title: "Magere kwark met stukjes fruit",
    time: "5m",
  },
  {
    id: 2,
    image: Images.HomeFoodImage1,
    title: "Stracciatella kwark",
    time: "5m",
  },
  {
    id: 3,
    image: Images.HomeFoodImage1,
    title: "Magere kwark met stukjes fruit",
    time: "15m",
  },
];
const homeCarouselData = [
  {
    id: 1,
    image: Images.HomeFoodImage1,
    title: "Caesar salade met cherry tomaatjes1",
    time: "15m",
  },
  {
    id: 2,
    image: Images.HomeFoodImage1,
    title: "Caesar salade met cherry tomaatjes2",
    time: "15m",
  },
  {
    id: 3,
    image: Images.HomeFoodImage1,
    title: "Caesar salade met cherry tomaatjes3",
    time: "15m",
  },
];

const DiaryItem = [
  {
    id: 1,
    mealType: "Breakfast",
    mealList: [
      // {
      //   id: 1,
      //   type: "Organic corn-flakes with milk",
      //   icon: Images.Diary1,
      //   checked: false,
      //   item: "placeholder",
      //   amount: "placeholder",
      // },
      // {
      //   id: 2,
      //   type: "Quark with strawberries",
      //   icon: Images.Diary1,
      //   checked: false,
      //   item: "placeholder",
      //   amount: "placeholder",
      // },
      // { id: 3, item: "Candy Bar", amount: "150g", checked: false, type: "placeholder" },
      // { id: 4, item: "Banana", amount: "1 stuk", checked: false, type: "placeholder" },
      // { id: 1, type: "Organic corn-flakes with milk", icon: Images.Diary1, checked: false },
      // { id: 2, type: "Quark with strawberries", icon: Images.Diary1, checked: false },
      // { id: 3, item: "Candy Bar", amount: "150g", checked: false },
      // { id: 4, item: "Banana", amount: "1 stuk", checked: false },
    ],
  },
  { id: 2, mealType: "Lunch" },
  { id: 3, mealType: "Snack" },
  { id: 4, mealType: "Dinner" },
  { id: 5, mealType: "Pre-bed" },
];

const Ingredients = [
  {
    id: 1,
    name: "Bio. cornflakes",
    kcal: 88,
    protein: 4,
  },
  {
    id: 2,
    name: "Honing",
    kcal: 131,
    protein: 1,
  },
  {
    id: 3,
    name: "Vanille stokje",
    kcal: 9,
    protein: 0,
  },
  {
    id: 4,
    name: "Biologische melk [longer name]",
    kcal: 101,
    protein: 4,
  },
];

const RecipeMacrosItems = [
  {
    id: 1,
    label: "Calories",
    progress: 0,
    total: 716,
    unit: "kCal",
  },
  {
    id: 2,
    label: "Protein",
    progress: 0,
    total: 18,
    unit: "g",
  },
];

const faces = [
  { id: 1, icon: Images.AngryIcon, selected: false },
  { id: 2, icon: Images.SadIcon, selected: false },
  { id: 3, icon: Images.OkIcon, selected: false },
  { id: 4, icon: Images.SatisfiedIcon, selected: false },
  { id: 5, icon: Images.HappyIcon, selected: false },
];

const timeLineForProfile = [
  { id: 1, time: "5 Feb - 12 Feb", absolute: "-0.8 kg", wtLost: "-0.7%" },
  { id: 2, time: "13 Feb - 19 Feb", absolute: "-0.8 kg", wtLost: "-0.5%" },
  { id: 3, time: "20 Feb - 26 Feb", absolute: "-0.8 kg", wtLost: "-1.1%" },
  { id: 4, time: "20 Feb - 26 Feb", absolute: "-0.8 kg", wtLost: "-1.1%" },
];

// eslint-disable-next-line import/prefer-default-export
export {
  DiaryItem,
  faces,
  homeCarouselData,
  homeData,
  Ingredients,
  RecipeMacrosItems,
  timeLineForProfile,
  walkThroughList,
};
