import { CheckIcon, Select } from "native-base";
import React from "react";

type SelectOptionType = {
  label: string;
  value: string;
};

type Props = {
  selectedValue: string;
  optionsList: SelectOptionType[];
  placeholder: string;
  onValueChange: (item: string) => void;
  width?: number | string;
  testID?: string;
};

const CommonSelect = ({
  selectedValue,
  width,
  onValueChange,
  optionsList,
  placeholder,
  testID,
}: Props): JSX.Element => (
  <Select
    selectedValue={selectedValue}
    width={width || "100"}
    height={"10"}
    accessibilityLabel={placeholder}
    placeholder={placeholder}
    borderRadius={"10"}
    _selectedItem={{
      endIcon: <CheckIcon size="5" />,
    }}
    mt={1}
    mr={"2"}
    onValueChange={(itemValue) => onValueChange(itemValue)}
    testID={testID}
  >
    {optionsList.map((option: SelectOptionType, index: number) => (
      <Select.Item key={index} label={option.label} value={option.value} />
    ))}
  </Select>
);

export default CommonSelect;
