/* eslint-disable max-len */
import { Entypo, Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import {
  Actionsheet,
  Button,
  Center,
  Flex,
  FormControl,
  Icon,
  IconButton,
  Input,
  Pressable,
  ScrollView,
  Text,
  theme,
  useDisclose,
  View,
} from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch } from "react-redux";

import CommonHeaderDivider from "../../commons/CommonHeaderDivider";
import { commonStyles, Routes } from "../../constants";
import { useKeyboardBottomInset } from "../../constants/helperFunction";
import { getNutritionDayPlanOverviewComponent } from "../../helpers/coachHelpers";
import {
  formatNumberAsPercentage,
  formatNumberAsWholeNumber,
  IS_MOBILE_WEB,
  isMobilePlatform,
} from "../../helpers/generalHelpers";
import type { RootStackParamList } from "../../navigation/NavigationStackParams";
import type { MealSlotSpecification } from "../../services/backendTypes";
import type { LegacyInput } from "../../services/legacyNutritionCalculations7";
import logger from "../../services/logger";
import { calculateEnergyExpenditure } from "../../services/nutritionCalculations";
import { convertToMealSlotSpecifications, updateLegacyInput } from "../../services/nutritionCalculations7";
import { userSlice } from "../../slices/userSlice";
import styles from "../MacroProfilesEditDayScreenStyle";
import { TargetMacronutrientsComponent, TargetMacrosFormSchema, targetMacrosFormSchema } from "./helperComponents";
import {
  DEFAULT_ENERGY_BALANCE,
  energyBalanceChoices,
  EnergyBalanceFormSchema,
  energyBalanceFormSchema,
  EnergyBalanceOption,
  getClientNutritionPlan,
  OTHER_ENERGY_BALANCE,
} from "./helperFunctions";

function getEnergyBalanceOptionFromEnergyBalanceValue(energyBalanceValue: string): EnergyBalanceOption {
  switch (energyBalanceValue) {
    case "80":
      return "80";
    case "90":
      return "90";
    case "100":
      return "100";
    case "105":
      return "105";
    case "110":
      return "110";
    default:
      return OTHER_ENERGY_BALANCE;
  }
}

type Props = NativeStackScreenProps<RootStackParamList, Routes.EnergyBalanceTab1>;
const EnergyBalanceTab1 = ({
  navigation,
  route: {
    params: { client, nutritionPlanId },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const clientNutritionPlan = getClientNutritionPlan(client, nutritionPlanId);

  // NOTE: We do this up here to avoid rendering a different number of hooks
  const [inputState, setInputState] = useState<{
    optionSelected: EnergyBalanceOption;
    legacyInput: LegacyInput;
  }>({
    legacyInput: clientNutritionPlan,
    optionSelected: getEnergyBalanceOptionFromEnergyBalanceValue(
      clientNutritionPlan?.energyBalance || String(DEFAULT_ENERGY_BALANCE)
    ),
  });
  const [haveTargetMacrosBeenProvided, setHaveTargetMacrosBeenProvided] = useState(false);

  const {
    isOpen: isOpenTargetMacrosActionsheet,
    onClose: onCloseTargetMacrosActionsheet,
    onOpen: onOpenTargetMacrosActionsheet,
  } = useDisclose();

  // NOTE: This function has hooks inside it
  const bottomInset = useKeyboardBottomInset();

  if (!client) {
    throw new Error("Client not found");
  }

  if (!client.intake) {
    throw new Error("Client does not have an intake");
  }

  const energyExpenditure = calculateEnergyExpenditure(client.intake);
  if (!energyExpenditure) {
    throw new Error("No energy expenditure calculated");
  }

  const tdee = energyExpenditure.daily.total;

  // TODO: Put this into a helpers file
  const getEnergyBalanceChoiceString = (percentage: number): string =>
    `${formatNumberAsWholeNumber(tdee * percentage)} ${t("general.kcal")}`;

  // NOTE: This is handled with another button: we should probably combine them
  const onSubmitEnergyBalanceForm = (submittedValues: EnergyBalanceFormSchema): void => {
    if (!client.intake) {
      throw new Error("Client does not have an intake");
    }

    const updatedEnergyBalance = String(submittedValues.energyBalance);

    const hasEnergyBalanceBeenUpdated = updatedEnergyBalance !== clientNutritionPlan.energyBalance;
    const isFormDirty = hasEnergyBalanceBeenUpdated || haveTargetMacrosBeenProvided;

    if (isFormDirty) {
      const updatedLegacyInput = updateLegacyInput(
        {
          ...inputState.legacyInput,
          energyBalance: updatedEnergyBalance,
        },
        client.intake
      );

      dispatch(
        userSlice.actions.storeClientNutritionPlan({
          clientId: client.id,
          plan: updatedLegacyInput,
        })
      );
    }

    navigation.push(Routes.ChooseMealMomentsTab2, { client, nutritionPlanId: inputState.legacyInput.id });
  };

  const energyBalanceFormInitialValues = energyBalanceFormSchema.cast({
    energyBalance: inputState.legacyInput
      ? parseInt(inputState.legacyInput?.energyBalance, 10) || DEFAULT_ENERGY_BALANCE
      : DEFAULT_ENERGY_BALANCE,
  });

  const onBack = (): void => {
    navigation.goBack();
  };
  const commonContainerStyle = {
    flex: 1,
    backgroundColor: "white",
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
  };
  // eslint-disable-next-line no-nested-ternary
  const flexWidth = isMobilePlatform() ? "100%" : IS_MOBILE_WEB ? "100%" : "50%";
  return (
    <SafeAreaView style={isMobilePlatform() ? mobileContainerStyle : commonContainerStyle}>
      <Flex flex={1} pt="4" bgColor="white" width={flexWidth} marginX="auto">
        <Flex flexDirection={"row-reverse"} my={2}>
          <IconButton
            // mr={4}
            onPress={onBack}
            _icon={{
              as: Entypo,
              name: "cross",
              size: "2xl",
              color: "gray.500",
            }}
          />
        </Flex>
        <Center mb="2">
          <Text>
            <Text fontSize={"16"} fontWeight={"medium"} color={theme.colors.gray["500"]}>{`${t(
              "general.daily_energy_calculation.daily_energy_expenditure"
            )}: `}</Text>
            <Text fontSize={"16"} fontWeight={"bold"}>{`${formatNumberAsWholeNumber(tdee)} `}</Text>
            <Text fontSize={"16"} color={theme.colors.gray["500"]}>{` ${t("general.kcal")}`}</Text>
          </Text>
        </Center>

        <CommonHeaderDivider />
        <Formik
          initialValues={energyBalanceFormInitialValues}
          validationSchema={energyBalanceFormSchema}
          onSubmit={onSubmitEnergyBalanceForm}
        >
          {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, isValid, dirty }) => {
            if (!client.intake) {
              throw new Error("Client does not have an intake");
            }

            function onUserUpdateEnergyBalance(updatedValue: string, usingOtherInput = false): void {
              if (!client) {
                throw new Error("Client not found");
              }

              if (!client.intake) {
                throw new Error("Client does not have an intake");
              }

              handleChange("energyBalance")(updatedValue);

              const updatedLegacyInput = updateLegacyInput(
                {
                  // NOTE: This is deliberately not using inputState.legacyInput because any of those tweaks should be removed by adjusting the energy balance
                  ...clientNutritionPlan,

                  // When the user selects a new energy balance it overwrites any target macros that were previously provided
                  adjustedDailyMacros: undefined,
                  energyBalance: String(updatedValue),
                },
                client.intake
              );

              setInputState({
                legacyInput: updatedLegacyInput,
                optionSelected: usingOtherInput
                  ? OTHER_ENERGY_BALANCE
                  : getEnergyBalanceOptionFromEnergyBalanceValue(updatedValue),
              });
            }

            const isOtherChecked = inputState.optionSelected === OTHER_ENERGY_BALANCE;

            // TODO: Perhaps this should update clientNutritionPlan7Edit instead
            const legacyInputUpdated = {
              ...inputState.legacyInput,
              energyBalance: String(values.energyBalance),
            };

            let calculatedMealSlotSpecifications: MealSlotSpecification[] = [];
            if (inputState.legacyInput) {
              const updatedLegacyInput = updateLegacyInput(legacyInputUpdated, client.intake);
              calculatedMealSlotSpecifications = convertToMealSlotSpecifications(updatedLegacyInput.meals);
            }

            const setTargetMacros = (formValues: TargetMacrosFormSchema): void => {
              if (!client.intake) {
                throw new Error("Client does not have an intake");
              }

              onCloseTargetMacrosActionsheet();

              // NOTE: The schema is for a number but the formValues are strings because they come from the form
              const protein = parseInt(String(formValues.protein), 10);
              const carbs = parseInt(String(formValues.carbs), 10);
              const fat = parseInt(String(formValues.fat), 10);

              const adjustedLegacyInput = updateLegacyInput(
                {
                  ...inputState.legacyInput,
                  adjustedDailyMacros: {
                    fiber: 0,
                    protein,
                    fat,
                    carbs,
                    calories: Math.round(carbs * 4 + protein * 4 + fat * 9),
                  },
                },
                client.intake
              );

              setHaveTargetMacrosBeenProvided(true);
              setInputState({
                ...inputState,
                legacyInput: adjustedLegacyInput,
              });
            };

            const macrosTargetComponent = (
              <Actionsheet isOpen={isOpenTargetMacrosActionsheet} onClose={onCloseTargetMacrosActionsheet}>
                <Actionsheet.Content bgColor="white" bottom={bottomInset}>
                  <TargetMacronutrientsComponent
                    // NOTE: I don't know why this causes a type error
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    initialValues={targetMacrosFormSchema.cast({
                      fat: inputState.legacyInput?.computedDailyMacros.fat || 0,
                      protein: inputState.legacyInput?.computedDailyMacros.protein || 0,
                      carbs: inputState.legacyInput?.computedDailyMacros.carbs || 0,
                    })}
                    onSubmit={setTargetMacros}
                    t={t}
                  />
                </Actionsheet.Content>
              </Actionsheet>
            );

            return (
              <>
                {macrosTargetComponent}
                <View>
                  <ScrollView contentContainerStyle={commonStyles.scrollContentContainer}>
                    <View style={commonStyles.mainContainer}>
                      <>
                        <View nativeID="energyBalanceForm">
                          <FormControl isRequired isInvalid={!_.isEmpty(errors.energyBalance)}>
                            {energyBalanceChoices.map((energyBalanceDelta: EnergyBalanceOption): JSX.Element => {
                              // FIXME: This does not work very nicely on back
                              // const isChecked = energyBalanceOptionChecked === energyBalanceDelta;
                              // NOTE: We do this because values.energyBalance is actually a string!
                              // const isChecked =
                              //   !isOtherChecked && String(values.energyBalance) === String(energyBalanceDelta);
                              const isChecked = inputState.optionSelected === energyBalanceDelta;

                              return (
                                <Flex
                                  key={energyBalanceDelta}
                                  flexDirection={"row"}
                                  justifyContent="space-between"
                                  alignItems={"center"}
                                  borderBottomWidth={0.5}
                                  borderBottomColor={theme.colors.gray["300"]}
                                  py={2}
                                >
                                  <Flex flexDirection={"row"} justifyContent="space-between" alignItems={"center"}>
                                    <Pressable
                                      onPress={() => {
                                        onUserUpdateEnergyBalance(String(energyBalanceDelta));
                                      }}
                                      testID={`energyBalanceOption-${energyBalanceDelta}`}
                                      nativeID={`energyBalanceOption-${energyBalanceDelta}`}
                                    >
                                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                      {/* @ts-ignore */}
                                      <MaterialCommunityIcons
                                        name={isChecked ? "checkbox-marked-circle" : "checkbox-blank-circle-outline"}
                                        size={36}
                                        color={isChecked ? theme.colors.primary["600"] : theme.colors.gray["700"]}
                                      />
                                    </Pressable>
                                    <Text ml="2" color={theme.colors.gray["600"]} fontWeight="medium">
                                      {formatNumberAsPercentage(parseFloat(energyBalanceDelta) / 100)}
                                    </Text>
                                  </Flex>
                                  <Text color={theme.colors.gray["500"]} fontWeight="normal">
                                    {getEnergyBalanceChoiceString(parseFloat(energyBalanceDelta) / 100)}
                                  </Text>
                                </Flex>
                              );
                            })}

                            <View>
                              <Flex
                                key={OTHER_ENERGY_BALANCE}
                                flexDirection={"row"}
                                justifyContent="space-between"
                                alignItems={"center"}
                                py={2}
                              >
                                <Flex flexDirection={"row"} justifyContent="space-between" alignItems={"center"}>
                                  <Pressable
                                    onPress={() => onUserUpdateEnergyBalance(String(DEFAULT_ENERGY_BALANCE), true)}
                                  >
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <MaterialCommunityIcons
                                      name={isOtherChecked ? "checkbox-marked-circle" : "checkbox-blank-circle-outline"}
                                      size={36}
                                      color={isOtherChecked ? theme.colors.primary["600"] : theme.colors.gray["700"]}
                                    />
                                  </Pressable>
                                  <Text ml="2">{t("nutrition_day_plan.edit_energy_balance.other_input_label")}</Text>
                                </Flex>
                                <Input
                                  onFocus={() => onUserUpdateEnergyBalance(String(DEFAULT_ENERGY_BALANCE), true)}
                                  maxWidth="25%"
                                  borderColor={theme.colors.gray["700"]}
                                  borderWidth="1"
                                  // TODO: Create a helper function the formats energyBalance for display
                                  value={isOtherChecked ? String(values.energyBalance) : ""}
                                  onChangeText={(updatedValue) => onUserUpdateEnergyBalance(updatedValue, true)}
                                  InputRightElement={
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    <Icon as={<Feather name="percent" />} size={4} mr="1" color="gray.300" />
                                  }
                                  testID="energyBalance-other-input"
                                />
                              </Flex>
                            </View>
                            <FormControl.ErrorMessage>{errors.energyBalance}</FormControl.ErrorMessage>
                          </FormControl>
                        </View>
                        <View mt="5">
                          <View style={styles.recipeMacrosContainer} mt="2">
                            {getNutritionDayPlanOverviewComponent(calculatedMealSlotSpecifications, true)}
                          </View>
                        </View>
                        <Flex my={2.5}>
                          <Button
                            onPress={onOpenTargetMacrosActionsheet}
                            variant="outline"
                            testID="adjustMacronutrients-button"
                            nativeID="adjustMacronutrientsButton"
                          >
                            {t("nutrition_day_plan.adjust_macronutrients_button_text")}
                          </Button>
                        </Flex>
                      </>
                      <Button
                        mt="1.5"
                        // NOTE: `energyBalance` is actually a string
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        isDisabled={!isValid || values.energyBalance === ""}
                        onPress={() => handleSubmit()}
                        testID={"energyBalanceTab-nextButton"}
                        nativeID={"energyBalanceTabNextButton"}
                      >
                        {t("general.next_button_text")}
                      </Button>
                    </View>
                  </ScrollView>
                </View>
              </>
            );
          }}
        </Formik>
      </Flex>
    </SafeAreaView>
  );
};

export default EnergyBalanceTab1;
