import { Entypo, MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import _ from "lodash";
import { Button, Flex, Icon, IconButton, Switch, Text, useTheme, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch } from "react-redux";

import { commonStyles, isDesktopScreen, Routes } from "../../constants";
import { IS_MOBILE_WEB, isMobilePlatform } from "../../helpers/generalHelpers";
import type { RootStackParamList } from "../../navigation/NavigationStackParams";
import { LegacyMealState, rebalanceSizes } from "../../services/legacyNutritionCalculations7";
import { updateLegacyInput } from "../../services/nutritionCalculations7";
import { userSlice } from "../../slices/userSlice";
import { EDIT_NUTRITION_PLAN_TABS_BACK_BUTTON_TESTID } from "./helperComponents";
import { getClientNutritionPlan } from "./helperFunctions";

type Props = NativeStackScreenProps<RootStackParamList, Routes.ChooseMealMomentsTab2>;
const ChooseMealMomentsTab2 = ({
  navigation,
  route: {
    params: { client, nutritionPlanId },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = isDesktopScreen();

  const clientNutritionPlan7Edit = getClientNutritionPlan(client, nutritionPlanId);

  if (!client.intake) {
    throw new Error("Client does not have an intake");
  }

  const nextButtonEnabled = _.some(clientNutritionPlan7Edit.meals, "enabled");

  function toggleMealMoment(legacyMealStateToToggle: LegacyMealState): void {
    if (!client.intake) {
      throw new Error("Client does not have an intake");
    }

    const updatedMealState = { ...legacyMealStateToToggle, enabled: !legacyMealStateToToggle.enabled };

    const updatedMeals = clientNutritionPlan7Edit.meals.map((legacyMealState) =>
      legacyMealState.mealMoment === legacyMealStateToToggle.mealMoment ? updatedMealState : legacyMealState
    );

    const legacyInputAfterMealMomentToggled = { ...clientNutritionPlan7Edit, meals: rebalanceSizes(updatedMeals) };

    const updatedLegacyInput = updateLegacyInput(legacyInputAfterMealMomentToggled, client.intake);

    dispatch(
      userSlice.actions.storeClientNutritionPlan({
        clientId: client.id,
        plan: updatedLegacyInput,
      })
    );
  }

  const onNext = (): void => {
    navigation.push(Routes.EditMealMomentsTab3, { client, nutritionPlanId: clientNutritionPlan7Edit.id });
  };

  const onBack = (): void => navigation.goBack();

  const isNotLastListItem = (index: number): boolean =>
    index !== Object.keys(clientNutritionPlan7Edit.meals).length - 1;

  const mealMoments = clientNutritionPlan7Edit.meals.map((legacyMealState: LegacyMealState, i) => (
    <Flex key={i} borderBottomWidth={isNotLastListItem(i) ? 0.5 : 0} borderBottomColor={theme.colors.gray["300"]}>
      <Flex flexDirection="row" alignItems={"center"} key={i} py={2.5}>
        <Switch
          isChecked={legacyMealState.enabled}
          onToggle={() => toggleMealMoment(legacyMealState)}
          offTrackColor="coolGray.100"
          testID={`mealMomentSwitch-${legacyMealState.mealMoment}`}
          nativeID={`mealMomentSwitch-${legacyMealState.mealMoment}`}
        />
        <Text color={theme.colors.gray["600"]} fontWeight="700" mx={2}>
          {t(`general.meal_types.${legacyMealState.mealMoment}`)}
        </Text>
      </Flex>
    </Flex>
  ));

  const nextButton = (
    <Button
      isDisabled={!nextButtonEnabled}
      onPress={onNext}
      testID={"chooseMealMomentsTab-nextButton"}
      nativeID={"chooseMealMomentsTabNextButton"}
    >
      {t("general.next_button_text")}
    </Button>
  );

  const quitEditingNutritionPlan = (): void => {
    navigation.push(Routes.CoachModeClientInfoScreen, { clientId: client.id });
  };

  const commonContainerStyle = {
    flex: 1,
    backgroundColor: "white",
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
  };

  const webWidth = IS_MOBILE_WEB ? "100%" : "50%";
  const flexWidth = isMobilePlatform() ? "100%" : webWidth;
  return (
    <SafeAreaView style={isMobilePlatform() ? mobileContainerStyle : commonContainerStyle}>
      <Flex flex={1} pt="4" bgColor="white" width={flexWidth} marginX="auto">
        <Flex style={commonStyles.paddingContainer}>
          <Flex flexDirection={"row-reverse"} my={2}>
            <IconButton
              onPress={quitEditingNutritionPlan}
              _icon={{
                as: Entypo,
                name: "cross",
                size: "2xl",
                color: "gray.500",
              }}
            />
          </Flex>
          <Flex flexDirection={"row"} my={2}>
            <IconButton
              mr={0}
              pr={0}
              onPress={onBack}
              icon={<Icon as={MaterialIcons} name="arrow-back-ios" color={"gray.500"} />}
              testID={EDIT_NUTRITION_PLAN_TABS_BACK_BUTTON_TESTID}
            />
          </Flex>

          <View nativeID={"chooseMealMomentsTab"}>{mealMoments}</View>

          {!isDesktop && nextButton}
        </Flex>

        {isDesktop ? <View>{nextButton}</View> : null}
      </Flex>
    </SafeAreaView>
  );
};

export default ChooseMealMomentsTab2;
