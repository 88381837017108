import React from "react";
import { Modalize, ModalizeProps } from "react-native-modalize";

type CommonModalizeProps = {
  modalizeRef: React.Ref<Modalize>;
  ModalContent: React.ElementType;
  FooterComponent?: JSX.Element;
  modalHeight?: number;
  modalizeProps?: ModalizeProps;
};

const CommonModalize = ({
  modalizeRef,
  ModalContent,
  FooterComponent,
  modalHeight,
  modalizeProps,
}: CommonModalizeProps): JSX.Element => (
  <Modalize
    ref={modalizeRef}
    modalHeight={modalHeight}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    FooterComponent={() => FooterComponent && <FooterComponent modalizeRef={modalizeRef} />}
    {...modalizeProps}
  >
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <ModalContent modalizeRef={modalizeRef} />
  </Modalize>
);

export default CommonModalize;
