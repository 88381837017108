import type { NativeStackNavigationProp, NativeStackScreenProps } from "@react-navigation/native-stack";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import * as Linking from "expo-linking";
import { ErrorMessage, Formik } from "formik";
import { getUserLocales } from "get-user-locale";
import { Box, Button, Center, Divider, Heading, Icon, Image, Link, Select, Text, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { KeyboardAvoidingView, SafeAreaView } from "react-native";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import analyticsJsonRaw from "../../../../analytics.json";
import { CommonInnerText, CommonTextInput } from "../../commons";
import { Images, isDesktopScreen, Routes, Scale } from "../../constants";
import analyticsInterface from "../../helpers/analyticsHelpers";
import { facebookPixelSendStartTrialEvent } from "../../helpers/facebookPixelHelpers";
import { googleAnalyticsSendStartTrialEvent } from "../../helpers/googleAnalyticsHelpers";
import type { RootStackParamList } from "../../navigation/NavigationStackParams";
import backendApi from "../../services/backendApi";
import type { AnalyticsEventConstants } from "../../types";
import type { MagicLinkFormValues } from "../LoginScreen";
import styles from "../LoginScreenStyles";
import { CHECKOUT_QUERY_PARAM_NAME, CheckoutParamStates } from "./constants";

// NOTE: This is removed (temporarily) from the app and so we keep it here commented
// const { useCheckoutCheckoutSessionCreateMutation } = backendApi;
const { trackEvent } = analyticsInterface;

const analyticsJson: AnalyticsEventConstants = analyticsJsonRaw;

const magicLinkFormSchema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Required"),
});
const initialValues: MagicLinkFormValues = { email: "" };

export function handleCheckoutSuccess(redirectUrl: string): void {
  facebookPixelSendStartTrialEvent();
  googleAnalyticsSendStartTrialEvent();

  window.open(redirectUrl, "_self");
}

type Props = NativeStackScreenProps<RootStackParamList, Routes.CheckoutScreen>;

const EmailInput = ({ navigation, route: { params } }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isDesktop = isDesktopScreen();

  // const [checkoutCheckoutSessionCreateMutation] = useCheckoutCheckoutSessionCreateMutation();

  const priceId = params?.priceId || undefined;

  const successUrl = Linking.createURL("/", {
    queryParams: {
      [CHECKOUT_QUERY_PARAM_NAME]: CheckoutParamStates.CHECKOUT_SUCCESS,
    },
  });

  const cancelUrl = Linking.createURL("/", {
    queryParams: {
      [CHECKOUT_QUERY_PARAM_NAME]: CheckoutParamStates.CHECKOUT,
    },
  });

  const submitForm = (values: MagicLinkFormValues): void => {
    trackEvent(analyticsJson.events.click_create_coach_button.name);

    // TODO: This takes quite a few seconds and so perhaps we should add a better loading spinner

    const userLocales = getUserLocales();

    try {
      // const response = await checkoutCheckoutSessionCreateMutation({
      //   streamlinedCheckoutRequest: {
      //     email: values.email,
      //     price_id: priceId,
      //     user_locales: userLocales,
      //   },
      // }).unwrap();
      // const redirectUrl = response?.url;
      // if (redirectUrl) {
      //   handleCheckoutSuccess(redirectUrl);
      // } else {
      //   Sentry.captureException("No redirectUrl was passed");
      // }
    } catch (error) {
      Sentry.captureException(error);

      alert(t("general.generic_error_message"));
    }
  };

  const onClickAlreadyHaveAccount = (): void => {
    navigation.navigate(Routes.LoginScreenOnCheckout);
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.loginContainer}></View>
      <Formik initialValues={initialValues} validationSchema={magicLinkFormSchema} onSubmit={submitForm}>
        {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, dirty, isValid }) => (
          // TODO: Add UI for a loading spinner (I think this should be app-wide)
          <View style={styles.container}>
            <Center>
              <KeyboardAvoidingView behavior="position">
                <Image
                  source={Images.WeekmealsLogo}
                  alt={"App logo"}
                  style={{
                    // Center
                    alignSelf: "center",
                    height: Scale(200),
                    width: Scale(200),
                    resizeMode: "contain",
                  }}
                />
                <Center>
                  <Heading>{t("checkout.main.create_account")}</Heading>
                </Center>

                <View style={styles.wrapper}>
                  <Center>
                    <Text>{t("checkout.main.enter_information")}</Text>
                  </Center>
                  <View width={Scale(200)}>
                    <CommonTextInput
                      onChangeText={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      placeholder={t("checkout.main.email_placeholder")}
                      onSubmitEditing={handleSubmit}
                      testID={"magicLinkEmail-input"}
                      textBoxContainerExternalStyle={{ width: isDesktop ? Scale(600) : "100%" }}
                    />
                    <Center>
                      <Text>
                        {/* NOTE: This library component has a ts compile error which we cannot fix */}
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <ErrorMessage name="email" />
                      </Text>
                    </Center>
                    <Center>
                      <Button
                        isLoading={isSubmitting}
                        isDisabled={!dirty || !isValid}
                        onPress={() => handleSubmit()}
                        testID="magicLinkButton"
                      >
                        {t("checkout.main.create_account")}
                      </Button>
                    </Center>
                  </View>
                  <Center mt={10}>
                    <Link onPress={onClickAlreadyHaveAccount}>
                      <Text underline fontSize="xs" opacity={50}>
                        {t("checkout.main.already_have_account")}
                      </Text>
                    </Link>
                  </Center>
                </View>
              </KeyboardAvoidingView>
            </Center>
          </View>
        )}
      </Formik>
    </View>
  );
};

const CheckoutScreen = ({ route, navigation }: Props): JSX.Element => (
  <EmailInput navigation={navigation} route={route} />
);

export default CheckoutScreen;
