import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { Button, CheckBox, Icon, Input, useTheme } from "react-native-elements";
import * as Yup from "yup";

import { Routes } from "../../constants";

interface RegistrationScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const RegistrationScreen: React.FC<RegistrationScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const primaryColor = theme.colors?.primary || "#4CAF50";

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("onboarding.registration_screen.enter_first_name")),
    lastName: Yup.string().required(t("onboarding.registration_screen.enter_last_name")),
    email: Yup.string()
      .email(t("onboarding.registration_screen.invalid_email"))
      .required(t("onboarding.registration_screen.enter_email")),
    // FIXME: Link to actual terms
    termsAccepted: Yup.boolean().oneOf([true], t("onboarding.registration_screen.accept_terms")),
  });

  return (
    <Formik
      initialValues={{ firstName: "", lastName: "", email: "", termsAccepted: false }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // FIXME: Add logic to actually update the user
        navigation.navigate(Routes.NutritionalPlanScreen);
      }}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => (
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Icon name="arrow-back" size={24} onPress={() => navigation.goBack()} tvParallaxProperties={undefined} />
          </View>
          <ScrollView contentContainerStyle={styles.content}>
            <Icon
              name="robot"
              type="material-community"
              size={64}
              color={primaryColor}
              tvParallaxProperties={undefined}
            />
            <Text style={styles.title}>{t("onboarding.registration_screen.register_to_get_results")}</Text>
            <Input
              placeholder={t("onboarding.registration_screen.first_name")}
              placeholderTextColor="#999"
              value={values.firstName}
              onChangeText={(text) => setFieldValue("firstName", text)}
              containerStyle={styles.inputContainer}
              inputStyle={styles.input}
              errorMessage={errors.firstName && touched.firstName ? errors.firstName : ""}
              autoCompleteType={"off"}
            />
            <Input
              placeholder={t("onboarding.registration_screen.last_name")}
              placeholderTextColor="#999"
              value={values.lastName}
              onChangeText={(text) => setFieldValue("lastName", text)}
              containerStyle={styles.inputContainer}
              inputStyle={styles.input}
              errorMessage={errors.lastName && touched.lastName ? errors.lastName : ""}
              autoCompleteType={"off"}
            />
            <Input
              placeholder={t("onboarding.registration_screen.email")}
              placeholderTextColor="#999"
              value={values.email}
              onChangeText={(text) => setFieldValue("email", text)}
              keyboardType="email-address"
              containerStyle={styles.inputContainer}
              inputStyle={styles.input}
              errorMessage={errors.email && touched.email ? errors.email : ""}
              autoCompleteType={"off"}
            />
            <CheckBox
              title={
                <Text>
                  {t("onboarding.registration_screen.accept_terms_prefix")}
                  {/* TODO: Do we need to add a space here? */}
                  <Text style={{ color: primaryColor, textDecorationLine: "underline" }} onPress={() => {}}>
                    {t("onboarding.registration_screen.terms_and_conditions")}
                  </Text>
                </Text>
              }
              checked={values.termsAccepted}
              onPress={() => setFieldValue("termsAccepted", !values.termsAccepted)}
              containerStyle={styles.checkboxContainer}
            />
            {errors.termsAccepted && touched.termsAccepted ? (
              <Text style={styles.errorText}>{errors.termsAccepted}</Text>
            ) : null}
            <Button
              title={t("onboarding.registration_screen.view_results")}
              buttonStyle={[styles.nextButton, { backgroundColor: primaryColor }]}
              onPress={() => handleSubmit()}
            />
          </ScrollView>
        </SafeAreaView>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  content: {
    alignItems: "center",
    padding: 16,
    paddingBottom: 32,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
    textAlign: "center",
    marginBottom: 24,
  },
  inputContainer: {
    width: "100%",
    marginBottom: 16,
  },
  input: {
    color: "#333",
  },
  checkboxContainer: {
    backgroundColor: "#fff",
    borderWidth: 0,
    marginBottom: 24,
    width: "100%",
  },
  errorText: {
    color: "red",
    marginBottom: 16,
    textAlign: "center",
  },
  nextButton: {
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 8,
    width: "80%",
    alignSelf: "center",
  },
});

export default RegistrationScreen;
