import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Box, Button, Center, Divider, Heading, Icon, Image, Select, Text, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch } from "react-redux";

import { CommonInnerText } from "../../commons";
import { Images, Routes, Scale } from "../../constants";
import type { RootStackParamList } from "../../navigation/NavigationStackParams";
import backendApi from "../../services/backendApi";
import styles from "../LoginScreenStyles";

type Props = NativeStackScreenProps<RootStackParamList, Routes.CheckoutFailureScreen>;

const CheckoutFailureScreen = ({ navigation }: Props): JSX.Element => {
  const { t, i18n } = useTranslation();

  return (
    <SafeAreaView>
      <View style={styles.wrapper}>
        <View style={styles.loginContainer}></View>
        <View style={styles.container}>
          <Center>
            <Image
              source={Images.DefaultRecipeImage}
              alt={"App logo"}
              style={{
                // Center
                alignSelf: "center",
                height: Scale(200),
                width: Scale(200),
                resizeMode: "contain",
              }}
            />
            <Center>
              <Heading>{t("checkout.failure.something_went_wrong")}</Heading>
            </Center>

            <View style={styles.wrapper}>
              <View>
                <Center>
                  <Text>{t("checkout.failure.recovery_message")}</Text>
                </Center>
              </View>
            </View>
          </Center>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default CheckoutFailureScreen;
