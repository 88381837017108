import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";

import type { RootState } from "../../../store";
import type { CalendarDay, GroceryListListRetrieve as GroceryList } from "../services/backendTypes";
// import logger from "../services/logger";

export type CalendarDayByDate = { [date: string]: CalendarDay };

export interface PlannerState {
  calendarDays: {
    byDate: CalendarDayByDate;
  };
  groceryLists: GroceryList[];
  currentDayInPlanner: string;
}

// TODO: Consider using createEntityAdapter (https://redux-toolkit.js.org/api/createEntityAdapter)
const initialState: PlannerState = {
  calendarDays: { byDate: {} },
  groceryLists: [],
  currentDayInPlanner: moment().format(),
};

export const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    storeCalendarDay(state, action: { payload: CalendarDay }) {
      const calendarDay = action.payload;

      // eslint-disable-next-line no-param-reassign
      state.calendarDays.byDate[calendarDay.date] = calendarDay;
    },
    resetCalendarDays(state) {
      // eslint-disable-next-line no-param-reassign
      state.calendarDays.byDate = {};
    },
    setGroceryLists(state, action: { payload: GroceryList[] }) {
      // eslint-disable-next-line no-param-reassign
      state.groceryLists = action.payload;
    },
    setCurrentDayInPlanner(state, action: { payload: string }) {
      // eslint-disable-next-line no-param-reassign
      state.currentDayInPlanner = action.payload;
    },
  },
});

const selectSelf = (state: RootState): PlannerState => state[plannerSlice.name];

export const calendarDaysSelector = createSelector(
  selectSelf,
  (state: PlannerState): CalendarDayByDate => state?.calendarDays?.byDate ?? {}
);

export const groceryListSelector = createSelector(
  selectSelf,
  (state: PlannerState): GroceryList[] => state?.groceryLists ?? []
);

export const currentDayInPlannerSelector = createSelector(
  selectSelf,
  (state: PlannerState): moment.Moment => moment(state?.currentDayInPlanner)
);
