import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import { Button, Flex, Icon, IconButton, ScrollView, Text, useTheme, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { CommonNumberInputWithPlusMinusButtons } from "../../commons";
import { commonStyles, Routes, Scale, VerticalScale } from "../../constants";
import { getNutritionDayPlanOverviewComponent } from "../../helpers/coachHelpers";
import {
  formatMealType,
  formatNumberAsWholeNumber,
  IS_ANDROID_WEB,
  IS_MOBILE_WEB,
  isMobilePlatform,
} from "../../helpers/generalHelpers";
import type { RootStackParamList } from "../../navigation/NavigationStackParams";
import backendApi from "../../services/backendApi";
import type {
  DaysToUpdateToNutritionDayPlanIdEnum,
  MealMomentEnum,
  NutritionDayPlan,
} from "../../services/backendTypes";
import type { LegacyMealState } from "../../services/legacyNutritionCalculations7";
import logger from "../../services/logger";
import {
  getLegacyInputFromMealSlotSpecifications,
  getUpdatedMealSlotSpecificationsFromFormValues,
} from "../../services/nutritionCalculations7";
import { NUTRITION_PLAN_WITHOUT_ID, userSlice } from "../../slices/userSlice";
import type { MacroName } from "../../types";
import { getClientNutritionPlan } from "./helperFunctions";

const {
  useUsersNutritionDayPlanCreateMutation,
  useUsersWeeklyNutritionPlanUpdateMutation,
  useUsersMealSlotSpecificationCreateMutation,
  useUsersWeeklyNutritionPlanCreateMutation,
} = backendApi;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getMealStateSchema = () =>
  Yup.object().shape({
    protein: Yup.number().moreThan(-1).required(),
    carbs: Yup.number().moreThan(-1).required(),
    fat: Yup.number().moreThan(-1).required(),
  });

const mealStateSchema = getMealStateSchema();
type MealStateFormSchema = Yup.InferType<typeof mealStateSchema>;

type HandleChange = {
  (e: React.ChangeEvent<unknown>): void;
  <T = string | React.ChangeEvent<unknown>>(field: T): T extends React.ChangeEvent<unknown>
    ? void
    : (e: string | React.ChangeEvent<unknown>) => void;
};

type Props = NativeStackScreenProps<RootStackParamList, Routes.FineTuningTab4>;
const FineTuningTab4 = ({
  navigation,
  route: {
    params: { client, nutritionPlanId },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [updateWeeklyNutritionPlanOnBackend, { isLoading: isLoadingUpdateWnpOnBackend }] =
    useUsersWeeklyNutritionPlanUpdateMutation();
  const [createWeeklyNutritionPlanOnBackend, { isLoading: isLoadingCreateWnpOnBackend }] =
    useUsersWeeklyNutritionPlanCreateMutation();
  const [createNutritionDayPlanOnBackend, { isLoading: isLoadingCreatingNdpOnBackend }] =
    useUsersNutritionDayPlanCreateMutation();
  const [createMealSlotSpecificationOnBackend, { isLoading: isLoadingCreateMssOnBackend }] =
    useUsersMealSlotSpecificationCreateMutation();

  const clientNutritionPlan7Edit = getClientNutritionPlan(client, nutritionPlanId);

  const convertMacroNameToLegacy = (macroName: MacroName): string => {
    switch (macroName) {
      case "protein":
      case "fat":
        return macroName;
      case "carbohydrates":
        return "carbs";
      default:
        logger.warn("Unknown macro name: ", macroName);
        return "";
    }
  };

  const MacroComponent = ({
    value,
    macroName,
    mealMoment,
    handleChange,
  }: {
    value: string;
    mealMoment: MealMomentEnum;
    macroName: MacroName;
    handleChange: HandleChange;
  }): JSX.Element => (
    <>
      <Text style={commonStyles.descriptionText16}>{t(`general.${macroName}`)} </Text>

      <CommonNumberInputWithPlusMinusButtons
        // Not editable on Android web due to bug
        editable={!IS_ANDROID_WEB}
        width={Scale(100)}
        min={0}
        value={value}
        onChange={(updatedValue) => {
          handleChange(`${mealMoment}.${convertMacroNameToLegacy(macroName)}`)(String(updatedValue));
        }}
        testIdPrefix={`${mealMoment}-${macroName}`}
      />
    </>
  );

  const MealMomentDetailsComponent = ({
    values,
    mealMoment,
    handleChange,
  }: {
    values: MealStateFormSchema;
    mealMoment: MealMomentEnum;
    handleChange: HandleChange;
  }): JSX.Element => (
    <>
      <Flex
        flexDirection="row"
        style={{ marginVertical: VerticalScale(4) }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <MacroComponent
          value={String(values ? values.protein : 0)}
          macroName="protein"
          mealMoment={mealMoment}
          handleChange={handleChange}
        />
      </Flex>
      <Flex
        flexDirection="row"
        style={{ marginVertical: VerticalScale(4) }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <MacroComponent
          value={String(values ? values.fat : 0)}
          macroName="fat"
          mealMoment={mealMoment}
          handleChange={handleChange}
        />
      </Flex>
      <Flex
        flexDirection="row"
        style={{ marginVertical: VerticalScale(4) }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <MacroComponent
          value={String(values ? values.carbs : 0)}
          macroName="carbohydrates"
          mealMoment={mealMoment}
          handleChange={handleChange}
        />
      </Flex>
    </>
  );

  const MealMoment = ({
    legacyMealState,
    values,
    handleChange,
  }: {
    legacyMealState: LegacyMealState;
    values: MealStateFormSchema;
    handleChange: HandleChange;
  }): JSX.Element => (
    <Flex flex={1} borderBottomWidth={0.5} borderBottomColor={theme.colors.gray["300"]}>
      <Flex
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ paddingVertical: VerticalScale(16) }}
      >
        <Flex flexDirection="row" alignItems={"center"}>
          <Text color={theme.colors.gray["600"]} fontWeight="700">
            {formatMealType(legacyMealState.mealMoment, t)}{" "}
          </Text>
        </Flex>
        <Text mr="6" color={"gray.600"} fontSize={14}>
          {`${formatNumberAsWholeNumber(legacyMealState.macros ? legacyMealState.macros.calories : 0)} kCal`}
        </Text>
      </Flex>
      <MealMomentDetailsComponent values={values} mealMoment={legacyMealState.mealMoment} handleChange={handleChange} />
    </Flex>
  );

  const enabledMeals = _.filter(
    clientNutritionPlan7Edit.meals,
    (legacyMealState: LegacyMealState) => legacyMealState.enabled
  );

  const mealStateArrayShape = _.fromPairs(
    Object.values(enabledMeals).map((legacyMealState: LegacyMealState) => [
      legacyMealState.mealMoment,
      getMealStateSchema(),
    ])
  );

  const mealMomentsFormSchema = Yup.object().shape(mealStateArrayShape);
  type MealMomentsFormSchema = Yup.InferType<typeof mealMomentsFormSchema>;

  async function onSubmit(values: MealMomentsFormSchema): Promise<void> {
    if (!client.intake) {
      throw new Error("Client intake is not defined");
    }
    // TODO: This should probably be in formik submit code so we can tie it up with the button's submitting status

    const { updatedMealSlotSpecifications: mealSlotSpecifications } = getUpdatedMealSlotSpecificationsFromFormValues(
      values,
      clientNutritionPlan7Edit.meals
    );

    // TODO: Abstract this logic into a function
    const updatedNutritionDayPlan: NutritionDayPlan = {
      id: clientNutritionPlan7Edit.id || NUTRITION_PLAN_WITHOUT_ID,
      name: clientNutritionPlan7Edit.name,
      meal_slot_specifications: mealSlotSpecifications,
    };

    const updatedLegacyInput = getLegacyInputFromMealSlotSpecifications(
      updatedNutritionDayPlan,
      client.intake,
      clientNutritionPlan7Edit.days
    );
    // NOTE: We do this to ensure it is recreated with the new values when next used
    dispatch(userSlice.actions.storeClientNutritionPlan({ clientId: client.id, plan: updatedLegacyInput }));

    // Create new NutritionDayPlan
    const createdNutritionDayPlan = await createNutritionDayPlanOnBackend({
      nutritionDayPlanRequest: {
        name: clientNutritionPlan7Edit.name,
      },
    }).unwrap();

    if (_.isEmpty(mealSlotSpecifications)) {
      throw new Error("No meal slots to update or create");
    }

    const createPromises = _.map(mealSlotSpecifications, (mealSlot) =>
      createMealSlotSpecificationOnBackend({
        mealSlotSpecificationRequest: {
          ...mealSlot,
          nutrition_day_plan: createdNutritionDayPlan.id,
        },
      })
    );

    await Promise.all(createPromises);

    const updateExistingWeeklyNutritionPlan = Boolean(client.intake.weekly_nutrition_plan);
    // NOTE: We do this afterwards so that the meal slot specifications are created before we update the WNP
    // This results in all the data always being up to date when the call is made to fetch new data
    if (updateExistingWeeklyNutritionPlan) {
      // TODO: Abstract into same function as code in client overview screen and re-use
      const daysToUpdate: DaysToUpdateToNutritionDayPlanIdEnum[] = _.compact([
        updatedLegacyInput.days.monday ? "monday" : undefined,
        updatedLegacyInput.days.tuesday ? "tuesday" : undefined,
        updatedLegacyInput.days.wednesday ? "wednesday" : undefined,
        updatedLegacyInput.days.thursday ? "thursday" : undefined,
        updatedLegacyInput.days.friday ? "friday" : undefined,
        updatedLegacyInput.days.saturday ? "saturday" : undefined,
        updatedLegacyInput.days.sunday ? "sunday" : undefined,
      ]);

      if (!client?.intake?.weekly_nutrition_plan?.id) {
        throw new Error("Client intake weekly nutrition plan id is not defined");
      }

      await updateWeeklyNutritionPlanOnBackend({
        id: client?.intake?.weekly_nutrition_plan?.id,
        weeklyNutritionPlanUpdateRequest: {
          nutrition_day_plan_id: createdNutritionDayPlan.id,
          days_to_update_to_nutrition_day_plan_id: daysToUpdate,
        },
      }).unwrap();
    } else {
      await createWeeklyNutritionPlanOnBackend({
        weeklyNutritionPlanCreateRequest: {
          // Since this is the first nutrition plan we are creating we populate every day
          monday: createdNutritionDayPlan.id,
          tuesday: createdNutritionDayPlan.id,
          wednesday: createdNutritionDayPlan.id,
          thursday: createdNutritionDayPlan.id,
          friday: createdNutritionDayPlan.id,
          saturday: createdNutritionDayPlan.id,
          sunday: createdNutritionDayPlan.id,

          client_intake: client.intake.id,
        },
      }).unwrap();
    }

    navigation.push(Routes.CoachModeClientInfoScreen, { clientId: client.id });
  }

  const mealMomentsInitialValues = _.fromPairs(
    Object.values(enabledMeals).map((legacyMealState: LegacyMealState) => [
      legacyMealState.mealMoment,
      legacyMealState.macros || {
        fat: 0,
        carbohydrates: 0,
        protein: 0,
      },
    ])
  );

  const mealMomentsComponent = (
    <Formik
      // NOTE: Yup & Formik don't always play nicely with typescript
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={mealMomentsFormSchema.cast(mealMomentsInitialValues)}
      validationSchema={mealMomentsFormSchema}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        values,
        errors,
        initialValues,
        dirty,
        isValid,
      }) => (
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={[commonStyles.scrollContentContainer, { marginBottom: VerticalScale(10) }]}
          style={{ marginBottom: 40 }}
        >
          {/* Overview of nutrition day plan for the coach's reference */}
          {/* NOTE: Because we load all tabs at the start sometimes this will not be populated */}
          {!_.isEmpty(
            getUpdatedMealSlotSpecificationsFromFormValues(values, clientNutritionPlan7Edit.meals)
              .updatedMealSlotSpecifications
          ) ? (
            <Flex flex={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
              {getNutritionDayPlanOverviewComponent(
                getUpdatedMealSlotSpecificationsFromFormValues(values, clientNutritionPlan7Edit.meals)
                  .updatedMealSlotSpecifications,
                true
              )}
            </Flex>
          ) : null}

          <>
            <View nativeID={"fineTuningTab"}>
              {_.map(
                getUpdatedMealSlotSpecificationsFromFormValues(values, clientNutritionPlan7Edit.meals)
                  .updatedLegacyMealStateArray,
                (legacyMealState: LegacyMealState) => (
                  // eslint-disable-next-line react/prop-types
                  <Flex flexDirection="row" alignItems={"center"} key={legacyMealState.mealMoment}>
                    {/* eslint-disable-next-line react/prop-types */}
                    {legacyMealState.enabled ? (
                      <MealMoment
                        legacyMealState={legacyMealState}
                        // NOTE: Yup & Formik don't always play nicely with typescript
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        values={values[legacyMealState.mealMoment]}
                        handleChange={handleChange}
                      />
                    ) : null}
                  </Flex>
                )
              )}
            </View>

            <Button
              disabled={isSubmitting || !isValid}
              isLoading={
                isLoadingCreateMssOnBackend ||
                isLoadingCreateWnpOnBackend ||
                isLoadingUpdateWnpOnBackend ||
                isLoadingCreatingNdpOnBackend ||
                isSubmitting
              }
              onPress={() => handleSubmit()}
              testID={"fineTuningTab-saveButton"}
              nativeID={"fineTuningTabSaveButton"}
            >
              {t("general.save_button_text")}
            </Button>
          </>
        </ScrollView>
      )}
    </Formik>
  );

  const onBack = (): void => navigation.goBack();

  const commonContainerStyle = {
    backgroundColor: "white",
    flex: 1,
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
  };

  const webWidth = IS_MOBILE_WEB ? "100%" : "50%";
  const flexWidth = isMobilePlatform() ? "100%" : webWidth;
  return (
    <SafeAreaView style={isMobilePlatform() ? mobileContainerStyle : commonContainerStyle}>
      <ScrollView>
        <Flex flex={1} pt="4" bgColor="white" width={flexWidth} marginX="auto">
          <Flex style={[commonStyles.container, commonStyles.paddingContainer]}>
            <Flex flexDirection={"row"} my={2}>
              <IconButton
                mr={0}
                pr={0}
                onPress={onBack}
                icon={<Icon as={MaterialIcons} name="arrow-back-ios" color={"gray.500"} />}
              />
            </Flex>
            <Flex flex={1}>{mealMomentsComponent}</Flex>
          </Flex>
        </Flex>
      </ScrollView>
    </SafeAreaView>
  );
};

export default FineTuningTab4;
