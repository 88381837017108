import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { onboardingDataSelector, setWeeklyGoal } from "../../slices/onboardingSlice";
import { WeeklyGoal, WeeklyGoalEnum } from "../../types";
import { appropriateWeeklyGoalsForSafety, updateMacrosInState } from "./onboardingHelpers";
import SelectionScreen, { Option } from "./SelectionScreen";

// FIXME: This screen is skipped if the user does not choose fat loss
// TODO: (we need to make sure the logic here is ALWAYS done)
interface WeeklyGoalScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const WeeklyGoalScreen: React.FC<WeeklyGoalScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onboardingData = useSelector(onboardingDataSelector);

  const goals: Option[] = [
    {
      label: t("onboarding.onboarding_6_weekly_goal_screen.lose_0_25_kg_per_week"),
      description: t("onboarding.onboarding_6_weekly_goal_screen.lose_0_25_kg_per_week_description"),
      value: WeeklyGoalEnum.LOSE_0_25_KG_PER_WEEK,
      testID: `weekly-goal-option-${WeeklyGoalEnum.LOSE_0_25_KG_PER_WEEK}`,
    },
    {
      label: t("onboarding.onboarding_6_weekly_goal_screen.lose_0_5_kg_per_week"),
      description: t("onboarding.onboarding_6_weekly_goal_screen.lose_0_5_kg_per_week_description"),
      value: WeeklyGoalEnum.LOSE_0_5_KG_PER_WEEK,
      testID: `weekly-goal-option-${WeeklyGoalEnum.LOSE_0_5_KG_PER_WEEK}`,
    },
    {
      label: t("onboarding.onboarding_6_weekly_goal_screen.lose_0_75_kg_per_week"),
      description: t("onboarding.onboarding_6_weekly_goal_screen.lose_0_75_kg_per_week_description"),
      value: WeeklyGoalEnum.LOSE_0_75_KG_PER_WEEK,
      testID: `weekly-goal-option-${WeeklyGoalEnum.LOSE_0_75_KG_PER_WEEK}`,
    },
    {
      label: t("onboarding.onboarding_6_weekly_goal_screen.lose_1_kg_per_week"),
      description: t("onboarding.onboarding_6_weekly_goal_screen.lose_1_kg_per_week_description"),
      value: WeeklyGoalEnum.LOSE_1_KG_PER_WEEK,
      testID: `weekly-goal-option-${WeeklyGoalEnum.LOSE_1_KG_PER_WEEK}`,
    },
  ].filter((goal) => appropriateWeeklyGoalsForSafety(onboardingData).includes(goal.value));

  const validationSchema = Yup.object().shape({
    selectedGoal: Yup.mixed<WeeklyGoal>()
      .oneOf(Object.values(WeeklyGoalEnum), t("onboarding.onboarding_6_weekly_goal_screen.select_valid_weekly_goal"))
      .required(t("onboarding.onboarding_6_weekly_goal_screen.select_weekly_goal")),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    updateMacrosInState(dispatch, onboardingData);

    // TODO: This should go to Routes.Onboarding_RegistrationScreen
    // but we are not implementing that for now
    navigation.navigate(Routes.NutritionalPlanScreen);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({ selectedGoal: onboardingData.weeklyGoal || "" })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => (
        <SelectionScreen
          title={t("onboarding.onboarding_6_weekly_goal_screen.weekly_goal")}
          subtitle={t("onboarding.onboarding_6_weekly_goal_screen.weekly_goal_subtitle")}
          options={goals}
          selectedOptions={[values.selectedGoal]}
          multipleSelection={false}
          setSelectedOptions={(options: string[]) => {
            const goal = options[0] as WeeklyGoal;
            setFieldValue("selectedGoal", goal);

            // NOTE: We do this here to ensure the state is updated before the calculations in onSubmit
            dispatch(setWeeklyGoal(goal));
          }}
          onPressNext={handleSubmit}
          onPressBack={() => navigation.goBack()}
          errorMessage={errors.selectedGoal && touched.selectedGoal ? errors.selectedGoal : ""}
        />
      )}
    </Formik>
  );
};

export default WeeklyGoalScreen;
