import { Formik } from "formik";
import _ from "lodash";
import { Button, Divider, Flex, FormControl, Input, Switch, Text } from "native-base";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { VerticalScale } from "../../constants";
import type { DesiredMealMoments, PossibleMealMoment } from "../../services/nutritionCalculations";

export const PossibleMealMomentComponent = ({
  mealMoment,
  setMealMomentPreferences,
}: {
  mealMoment: PossibleMealMoment;
  setMealMomentPreferences: React.Dispatch<React.SetStateAction<DesiredMealMoments>>;
}): JSX.Element => {
  const { t } = useTranslation();

  function onChangeSwitch(): void {
    setMealMomentPreferences((prevState) => {
      const newState = { ...prevState };
      newState[mealMoment] = !newState[mealMoment];
      return newState;
    });
  }

  return (
    <>
      <Flex
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ paddingVertical: VerticalScale(16) }}
      >
        <Flex flexDirection="row" alignItems={"center"}>
          <Text>{t(`general.meal_types.${mealMoment}`)}</Text>
          <Switch isChecked={false} onToggle={onChangeSwitch} />
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};

// type MacroItemEditRowProps = {
//   category: string;
//   onChangeText?: () => void;
//   pertentage?: number;
//   diff: number;
//   description?: string;
//   amount?: number;
//   rowIndex?: number;
// };
// const MacroItemEditRow = ({
//   category,
//   onChangeText,
//   pertentage,
//   diff,
//   description,
//   amount,
//   rowIndex,
// }: MacroItemEditRowProps): JSX.Element => (
//   <>
//     <Flex
//       flexDirection={"row"}
//       justifyContent="space-between"
//       alignItems={"center"}
//       style={{ paddingVertical: rowIndex === 0 ? VerticalScale(10) : VerticalScale(3) }}
//     >
//       <Flex style={{ flex: 3 }}>
//         <Text>{t(category)}</Text>
//       </Flex>
//       <Flex style={{ flex: 1 }}>
//         {amount ? (
//           <Text fontSize={"16"} fontWeight={"900"} textAlign="center">
//             {amount}
//           </Text>
//         ) : (
//           <CommonTextInput
//             title={""}
//             textBoxContainerExternalStyle={styles.textInputStyle}
//             textWrapperExternalStyle={styles.externalTextInputStyle}
//             onChangeText={(val: string) => logger.debug("onProteinChange", val)}
//             placeholder={""}
//           />
//         )}
//       </Flex>
//       <Flex
//         flexDirection={"row"}
//         justifyContent="space-between"
//         alignItems={"center"}
//         style={{ paddingLeft: 20, flex: 1 }}
//       >
//         <Text>{pertentage ? `${pertentage}%` : ""}</Text>
//         <Text color={"customRed.700"}>{diff}</Text>
//       </Flex>
//     </Flex>
//     {description ? (
//       <Text style={[commonStyles.descriptionText16, { marginBottom: VerticalScale(5) }]}>{t(description)}</Text>
//     ) : null}
//     <Divider />
//   </>
// );

export const targetMacrosFormSchema = Yup.object().shape({
  protein: Yup.number().positive().required(),
  carbs: Yup.number().positive().required(),
  fat: Yup.number().positive().required(),
});
export type TargetMacrosFormSchema = Yup.InferType<typeof targetMacrosFormSchema>;

export const TargetMacronutrientsComponent = ({
  onSubmit,
  t,
  initialValues,
}: {
  initialValues: TargetMacrosFormSchema;
  t: TFunction;
  onSubmit: (formValues: TargetMacrosFormSchema) => void;
}): JSX.Element => (
  <Formik initialValues={initialValues} validationSchema={targetMacrosFormSchema} onSubmit={onSubmit}>
    {({ handleChange, handleSubmit, values, errors, isValid }) => (
      <>
        <Flex>
          {/* TODO: Use MacroItemEditRow to provide coach with extra info (eg, the diff from the calculated macro) */}
          {/* <MacroItemEditRow
            category={"Calories"}
            amount={adjustedDailyMacros.kcal}
            diff={calculatedDailyMacros.kcal - adjustedDailyMacros.kcal}
            // rowIndex={0}
            description={"1.2 g/kg body weight"}
          /> */}

          <FormControl isInvalid={!_.isEmpty(errors.protein)}>
            <FormControl.Label>{t("general.protein")}</FormControl.Label>
            <Input
              onChangeText={handleChange("protein")}
              value={String(values.protein)}
              minWidth={100}
              testID="targetMacrosProtein-input"
            />
            <FormControl.ErrorMessage>{errors.protein}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!_.isEmpty(errors.fat)}>
            <FormControl.Label>{t("general.fat")}</FormControl.Label>
            <Input
              onChangeText={handleChange("fat")}
              value={String(values.fat)}
              minWidth={100}
              testID="targetMacrosFat-input"
            />
            <FormControl.ErrorMessage>{errors.fat}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!_.isEmpty(errors.carbs)}>
            <FormControl.Label>{t("general.carbohydrates")}</FormControl.Label>
            <Input
              onChangeText={handleChange("carbs")}
              value={String(values.carbs)}
              minWidth={100}
              testID="targetMacrosCarbohydrate-input"
            />
            <FormControl.ErrorMessage>{errors.carbs}</FormControl.ErrorMessage>
          </FormControl>
        </Flex>

        <Button
          isDisabled={!isValid}
          onPress={() => handleSubmit()}
          mt="2"
          testID="targetMacros-submit"
          nativeID="targetMacrosSubmit"
        >
          {t("general.save_button_text")}
        </Button>
      </>
    )}
  </Formik>
);

export const EDIT_NUTRITION_PLAN_TABS_BACK_BUTTON_TESTID = "editNutritionPlanTabs-backButton";
