import React from "react";
import { StyleSheet, Text, TextInput, TextStyle, View } from "react-native";

import { commonStyles, Scale, VerticalScale } from "../constants";

const styles = StyleSheet.create({
  textStyle: {
    // color: Colors.largeTextColor,
    fontSize: Scale(15),
  },
  inputbox: {
    backgroundColor: "white",
    borderRadius: Scale(8),
    borderWidth: 1,
    borderStyle: "solid",
    // borderColor: Colors.borderColor,
    marginBottom: Scale(20),
    paddingVertical: VerticalScale(12),
    paddingHorizontal: Scale(16),
    marginTop: VerticalScale(8),
    fontSize: 16,
    minHeight: VerticalScale(72),
  },
});

type CommonDescriptionInputProps = {
  title: string;
  externalTextStyle?: TextStyle;
  onChangeText: (text: string) => void;
};

const CommonDescriptionInput: React.FC<CommonDescriptionInputProps> = ({ title, externalTextStyle, onChangeText }) => (
  <View>
    <Text style={[commonStyles.titleText16Bold, externalTextStyle]}>{title}</Text>
    <TextInput style={styles.inputbox} multiline numberOfLines={2} onChangeText={(val: string) => onChangeText(val)} />
  </View>
);

export default CommonDescriptionInput;
