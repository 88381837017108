import React from "react";
import { Image, StyleSheet, View } from "react-native";

import { CommonButton, CommonText } from "../commons";
import { Images, Scale } from "../constants";

const styles = StyleSheet.create({
  bottomContainer: {
    flex: 1 / 4,
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 2,
  },
  wrapper: {
    flex: 1,
    // backgroundColor: Colors.whiteColor,
  },
  icon: {
    height: Scale(100),
    width: Scale(100),
    marginBottom: Scale(25),
  },

  titleStyle: {
    textAlign: "center",
  },
});

type Props = {
  // FIXME: This is to be fixed on another branch
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation?: any;
};

const EmailConfirmed: React.FC<Props> = ({ navigation }) => {
  const handleNavigation = (): void => {
    // navigation.navigate("Welcome");
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <Image source={Images.EmailSentIcon} style={styles.icon} />

        <CommonText
          title="Gelukt! Je e-mailadres is
          nu bevestigd"
          externalTextStyle={styles.titleStyle}
        />
      </View>
      <View style={styles.bottomContainer}>
        <CommonButton title="Verder" onPress={handleNavigation} />
      </View>
    </View>
  );
};

export default EmailConfirmed;
