import { StyleSheet } from "react-native";

import { height, Scale, width } from "../constants";

const styles = StyleSheet.create({
  paginationStyle: {
    position: "absolute",
    bottom: Scale(120),
  },
  externalTextStyle: {
    fontSize: Scale(16),
    width: Scale(100),
    textAlign: "right",
  },
  loginTouchableContainer: {
    position: "absolute",
    right: Scale(20),
  },
  loginContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  walkThroughListContainer: {
    flex: 1,
    alignItems: "center",
  },
  buttonContainer: {
    position: "absolute",
    bottom: Scale(30),
  },
  activeDotStyle: {
    width: Scale(8),
    height: Scale(8),
    // backgroundColor: Colors.primaryGreenColor,
    borderRadius: Scale(5),
  },
  dotStyle: {
    width: Scale(8),
    height: Scale(8),
    // backgroundColor: Colors.greyTextColor,
    borderRadius: 4,
    marginHorizontal: Scale(3),
  },
  swipeImages: {
    height: width / 2,
    width: width / 4,
    resizeMode: "contain",
    marginBottom: Scale(20),
  },
  swiperAlign: {
    flex: 1,
    justifyContent: "center",
    // backgroundColor: Colors.whiteColor,
  },
  titleText: {
    textAlign: "center",
  },
  discriptionText: {
    textAlign: "center",
    // color: Colors.textColor,
    marginTop: Scale(20),
    width: width - width / 3,
    fontSize: Scale(14),
  },
  badgeStyle: {
    // backgroundColor: Colors.greyTextColor,
  },
  dotsWrapperStyle: {
    marginTop: Scale(150),
  },
  imageBackground: {
    position: "absolute",
    left: width / 4,
    top: height / 4,
    width: width / 2,
    height: width / 2,
    borderRadius: width / 2,
  },
});

export default styles;
