function calculateWeightAtGivenBMI(heightInCm: number, bmi: number): number {
  return (heightInCm / 100) ** 2 * bmi;
}

export function calculateMinimumHealthyWeight(heightInCm: number): number {
  const MINIMUM_HEALTHY_BMI = 18.5;
  return calculateWeightAtGivenBMI(heightInCm, MINIMUM_HEALTHY_BMI);
}

/**
 * https://en.wikipedia.org/wiki/Harris%E2%80%93Benedict_equation
 *
 * @param weight
 * @param height
 * @param age
 * @param isMale
 * @returns
 */
export function harrisBenedictBMR(weight: number, height: number, age: number, isMale: boolean): number {
  if (isMale) {
    return 66 + 13.7 * weight + 5 * height - 6.8 * age;
  }

  return 655 + 9.6 * weight + 1.8 * height - 4.6 * age;
}

/**
 * https://en.wikipedia.org/wiki/Basal_metabolic_rate#BMR_estimation_formulas
 * https://reference.medscape.com/calculator/846/mifflin-st-jeor-equation
 *
 * @param weight
 * @param height
 * @param age
 * @param isMale
 * @returns
 */
export function mifflinStJoerRMR(weight: number, height: number, age: number, isMale: boolean): number {
  if (isMale) {
    return 10 * weight + 6.25 * height - 5 * age + 5;
  }
  return 10 * weight + 6.25 * height - 5 * age - 161;
}
