import React from "react";
import { Image, ImageSourcePropType, TouchableOpacity, ViewStyle } from "react-native";

type CommonIconButtonProps = {
  onPress?: () => void;
  size?: number;
  source: ImageSourcePropType;
  containerStyle?: ViewStyle;
  testID?: string;
};

const CommonIconButton: React.FC<CommonIconButtonProps> = ({ containerStyle, onPress, source, size, testID }) => (
  <TouchableOpacity onPress={onPress} style={containerStyle} testID={testID}>
    <Image source={source} style={{ width: size ?? 16, height: size ?? 16 }}></Image>
  </TouchableOpacity>
);

export default CommonIconButton;
