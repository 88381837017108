import React from "react";
import {
  Image,
  ImageSourcePropType,
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import { Images, Scale, width } from "../constants";

const styles = StyleSheet.create({
  iconContainer: {
    position: "absolute",
    right: Scale(8),
    justifyContent: "center",
    alignSelf: "center",
  },
  eyeIconStyle: {
    height: Scale(22),
    width: Scale(18),
    resizeMode: "contain",
  },
  textBoxContainer: {
    borderWidth: StyleSheet.hairlineWidth,
    paddingHorizontal: Scale(10),
    paddingVertical: Scale(10),
    width: width - Scale(40),
    alignSelf: "center",
    marginTop: Scale(10),
    borderRadius: Scale(5),
    marginBottom: Scale(30),
    flexDirection: "row",
  },
  inputContainer: { width: "100%" },
  wrapper: {
    marginHorizontal: Scale(20),
  },
  searchIconStyle: {
    height: Scale(18),
    width: Scale(18),
    resizeMode: "contain",
    marginRight: Scale(4),
  },
});

type CommonTextInputProps = {
  title?: string;
  externalTextStyle?: TextStyle;
  password?: boolean;
  placeholder: string;
  value?: string | number;
  textWrapperExternalStyle?: TextStyle;
  onChangeText: (text: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  textBoxContainerExternalStyle?: TextStyle;
  showEyeIcon?: boolean;
  textInputProps?: TextInputProps;
  placeholderTextColor?: string;
  searchIcon?: ImageSourcePropType;
  inputContainerStyle?: ViewStyle;
  onSubmitEditing?: () => void;
  testID?: string;
};

const CommonTextInput = ({
  title,
  placeholder,
  onChangeText,
  onBlur,
  password,
  textBoxContainerExternalStyle,
  textWrapperExternalStyle,
  showEyeIcon,
  textInputProps,
  placeholderTextColor,
  searchIcon,
  externalTextStyle,
  inputContainerStyle,
  onSubmitEditing,
  testID,
}: CommonTextInputProps): JSX.Element => (
  <View style={[styles.wrapper, textWrapperExternalStyle]}>
    <Text style={externalTextStyle}>{title}</Text>
    <View style={[styles.textBoxContainer, textBoxContainerExternalStyle]}>
      {searchIcon ? <Image source={searchIcon} style={styles.searchIconStyle} /> : null}
      <TextInput
        secureTextEntry={password}
        placeholder={placeholder}
        style={[styles.inputContainer, inputContainerStyle]}
        onChangeText={onChangeText}
        onBlur={onBlur}
        {...textInputProps}
        placeholderTextColor={placeholderTextColor}
        testID={testID}
        onSubmitEditing={onSubmitEditing}
      />
      {showEyeIcon ? (
        <TouchableOpacity style={styles.iconContainer}>
          <Image source={Images.EyeIcon} style={styles.eyeIconStyle} />
        </TouchableOpacity>
      ) : null}
    </View>
  </View>
);

export default CommonTextInput;
