import { Entypo, MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import _ from "lodash";
import { Button, Column, Flex, Icon, IconButton, Text, useTheme, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView } from "react-native";
import { useDispatch } from "react-redux";

import { CommonNumberInputWithPlusMinusButtons } from "../../commons";
import { commonStyles, isDesktopScreen, Routes, Scale } from "../../constants";
import { formatNumberAsPercentage, formatNumberAsWholeNumber } from "../../helpers/generalHelpers";
import type { RootStackParamList } from "../../navigation/NavigationStackParams";
import type { LegacyMealState } from "../../services/legacyNutritionCalculations7";
import logger from "../../services/logger";
import { updateLegacyInput } from "../../services/nutritionCalculations7";
import { userSlice } from "../../slices/userSlice";
import { getClientNutritionPlan } from "./helperFunctions";

type Props = NativeStackScreenProps<RootStackParamList, Routes.EditMealMomentsTab3>;
const EditMealMomentsTab3 = ({
  navigation,
  route: {
    params: { client, nutritionPlanId },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = isDesktopScreen();
  const isMobileScreen = !isDesktop;

  const clientNutritionPlan7Edit = getClientNutritionPlan(client, nutritionPlanId);

  if (!client.intake) {
    throw new Error("Client does not have an intake");
  }

  const enabledMeals = _.filter(clientNutritionPlan7Edit.meals, "enabled");

  const isNotLastListItem = (index: number): boolean => index !== enabledMeals.length - 1;

  const createMealMomentComponent = (legacyMealState: LegacyMealState, i: number): JSX.Element => {
    const kcalForMealMoment = `${legacyMealState.macros?.calories || 0} ${t("general.kcal")}`;
    const proteinForMealMoment = `${legacyMealState.macros?.protein || 0}g ${t("general.protein")}`;

    const kcalAndProteinDescription = `${kcalForMealMoment}, ${proteinForMealMoment}`;

    const onChangeLegacyMealStateSize = (updatedValue: number): void => {
      if (!client.intake) {
        throw new Error("Client does not have an intake");
      }

      // TODO: Do we need to do any validation?
      const updatedMealState = { ...legacyMealState, size: updatedValue };

      const updatedMeals = clientNutritionPlan7Edit.meals.map((legacyMealStateBeingIterated) =>
        legacyMealStateBeingIterated.mealMoment === legacyMealState.mealMoment
          ? updatedMealState
          : legacyMealStateBeingIterated
      );

      const legacyInputAfterMealMomentSizeUpdated = { ...clientNutritionPlan7Edit, meals: updatedMeals };

      dispatch(
        userSlice.actions.storeClientNutritionPlan({
          clientId: client.id,
          plan: updateLegacyInput(legacyInputAfterMealMomentSizeUpdated, client.intake),
        })
      );
    };

    return (
      <Flex key={i} borderBottomWidth={isNotLastListItem(i) ? 0.5 : 0} borderBottomColor={theme.colors.gray["300"]}>
        <Flex
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          key={legacyMealState.mealMoment}
          py={2.5}
          nativeID={`EditMealMoments-${legacyMealState.mealMoment}`}
        >
          <Column>
            <Text color={theme.colors.gray["600"]} fontWeight="700">
              {t(`general.meal_types.${legacyMealState.mealMoment}`)}
            </Text>
            <Text color="gray.300" testID={`${legacyMealState.mealMoment}-description`}>
              {kcalAndProteinDescription}
            </Text>
          </Column>

          <CommonNumberInputWithPlusMinusButtons
            // Note directly editable, only the increment/decrement buttons are allowed
            editable={false}
            min={0}
            step={5}
            value={formatNumberAsWholeNumber(legacyMealState.size)}
            append="%"
            onChange={onChangeLegacyMealStateSize}
            width={isDesktop ? 100 : Scale(120)}
            testIdPrefix={`${legacyMealState.mealMoment}-size`}
          />
        </Flex>
      </Flex>
    );
  };
  const mealMomentsComponent = enabledMeals.map(createMealMomentComponent);

  const totalEnergyBalanceOfChosenSizes = _.sumBy(clientNutritionPlan7Edit.meals, "size");
  const totalEnergyBalanceOfChosenSizesPercentageForDisplay = formatNumberAsPercentage(
    totalEnergyBalanceOfChosenSizes / 100
  );

  const shouldNextButtonBeDisabled =
    totalEnergyBalanceOfChosenSizes === 0 || _.some(clientNutritionPlan7Edit.meals, { enabled: true, size: 0 });

  const totalComponent = (
    <Flex flexDirection={"row"} justifyContent="flex-end">
      {/* FIXME: Use a proper translation */}
      <Text color={theme.colors.gray["600"]} fontWeight="700">
        {"Total:"}{" "}
      </Text>
      <Text
        color={totalEnergyBalanceOfChosenSizesPercentageForDisplay === "100%" ? "gray.600" : "danger.400"}
        fontWeight="700"
      >
        {totalEnergyBalanceOfChosenSizesPercentageForDisplay}
      </Text>
    </Flex>
  );

  const onNext = (): void => {
    navigation.push(Routes.FineTuningTab4, { client, nutritionPlanId: clientNutritionPlan7Edit.id });
  };

  const onBack = (): void => navigation.goBack();

  const nextButton = (
    <Button
      isDisabled={shouldNextButtonBeDisabled}
      onPress={onNext}
      testID={"editMealMoments-nextButton"}
      nativeID="EditMealMomentsTabNextButton"
    >
      {t("general.next_button_text")}
    </Button>
  );

  const quitEditingNutritionPlan = (): void => {
    navigation.push(Routes.CoachModeClientInfoScreen, { clientId: client.id });
  };

  const commonContainerStyle = {
    backgroundColor: "white",
  };

  const mobileContainerStyle = {
    ...commonContainerStyle,
    flex: 1,
  };

  return (
    <SafeAreaView style={isMobileScreen ? mobileContainerStyle : commonContainerStyle}>
      <Flex flex={1} pt="4" bgColor="white" width={isMobileScreen ? "100%" : "50%"} marginX="auto">
        <Flex style={commonStyles.paddingContainer}>
          <Flex flexDirection={"row-reverse"} my={2}>
            <IconButton
              onPress={quitEditingNutritionPlan}
              _icon={{
                as: Entypo,
                name: "cross",
                size: "2xl",
                color: "gray.500",
              }}
            />
          </Flex>
          <Flex flexDirection={"row"} my={2}>
            <IconButton
              mr={0}
              pr={0}
              onPress={onBack}
              icon={<Icon as={MaterialIcons} name="arrow-back-ios" color={"gray.500"} />}
            />
          </Flex>
          <View nativeID="EditMealMomentsTab">
            {totalComponent}
            {mealMomentsComponent}
          </View>
          {!isDesktop && nextButton}
        </Flex>

        {isDesktop ? <View>{nextButton}</View> : null}
      </Flex>
    </SafeAreaView>
  );
};

export default EditMealMomentsTab3;
