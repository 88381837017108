/*
 * Functions required in our create nutrition day plan flow
 */

import { body_measurement as bodyMeasurement } from "health-calculator";
import { Gender as HealthCalculatorGender } from "health-calculator/lib/util";
import _ from "lodash";

import { harrisBenedictBMR, mifflinStJoerRMR } from "../helpers/fitnessCalculationHelpers";
import { formatNumberAsWholeNumber } from "../helpers/generalHelpers";
import type { OnboardingState } from "../slices/onboardingSlice";
import { NUTRITION_PLAN_WITHOUT_ID } from "../slices/userSlice";
import { ActivityEnum } from "../types";
import type { MealMomentEnum, MealSlotSpecification, NutritionDayPlan, UserProfile } from "./backendTypes";
import { computeCalories, computeEnergyExpenditure, EnergyExpenditure, Macros } from "./legacyNutritionCalculations";
import {
  DaysForNutritionPlan,
  emptyMacros,
  getDefaultMeals,
  LegacyBodyStats,
  LegacyFoodPreferences,
  LegacyInput,
  LegacyMealState,
  updateDailyMacros,
} from "./legacyNutritionCalculations7";
import {
  calculateEnergyExpenditure,
  convertLegacyMealTypeToMealType,
  convertMealTypeToLegacyMealType,
  getEnergyInput,
  getRecommendedDailyMacronutrientIntake,
} from "./nutritionCalculations";

export const DEFAULT_NUTRITION_PLAN_NAME = "Standard";

/*
 * Translation layer functions
 */

function convertEnergyBalanceFloatToString(energyBalance: number): string {
  return String(energyBalance * 100);
}

function convertToLegacyInput(
  userProfile: UserProfile,
  daysForNutritionPlan: DaysForNutritionPlan,
  name: string
): LegacyInput {
  const energyBalanceFloat = 1;

  const computedDailyMacros = getRecommendedDailyMacronutrientIntake(userProfile, energyBalanceFloat);

  if (!computedDailyMacros) {
    throw new Error("Could not calculate daily macros");
  }

  return {
    id: NUTRITION_PLAN_WITHOUT_ID,
    name,
    computedDailyMacros,
    energyBalance: convertEnergyBalanceFloatToString(energyBalanceFloat),
    meals: getDefaultMeals(),
    days: daysForNutritionPlan,
  };
}

function calculateEnergyExpenditureWithoutBodyFat(onboardingState: OnboardingState): EnergyExpenditure {
  if (!onboardingState.biometricData.age) {
    throw new Error("Cannot calculate energy expenditure without age");
  }
  if (!onboardingState.physicalStats.weightInKg) {
    throw new Error("Cannot calculate energy expenditure without weight");
  }
  if (!onboardingState.physicalStats.heightInCm) {
    throw new Error("Cannot calculate energy expenditure without height");
  }

  const bmrHarrisBenedict = harrisBenedictBMR(
    onboardingState.physicalStats.weightInKg,
    onboardingState.physicalStats.heightInCm,
    onboardingState.biometricData.age,
    onboardingState.biometricData.gender === "MALE"
  );

  const rmrMifflinStJour = mifflinStJoerRMR(
    onboardingState.physicalStats.weightInKg,
    onboardingState.physicalStats.heightInCm,
    onboardingState.biometricData.age,
    onboardingState.biometricData.gender === "MALE"
  );

  const bmi = bodyMeasurement.bmi(onboardingState.physicalStats.weightInKg, onboardingState.physicalStats.heightInCm);
  const bodyFatPercentage =
    bodyMeasurement.bfp(
      onboardingState.biometricData.gender === "MALE" ? HealthCalculatorGender.Male : HealthCalculatorGender.Female,
      onboardingState.biometricData.age,
      bmi
    ) / 100.0;

  const leanBodyMassInKg =
    onboardingState.physicalStats.weightInKg - onboardingState.physicalStats.weightInKg * bodyFatPercentage;

  /**
   * Katch-McArdle multipliers
   * https://www.nasm.org/resources/calorie-calculator
   */
  let physicalActivityLevel = 1.2;

  switch (onboardingState.activityLevel) {
    case ActivityEnum.SEDENTARY:
      physicalActivityLevel = 1.2;
      break;
    case ActivityEnum.MILDLY_ACTIVE:
      physicalActivityLevel = 1.375;
      break;
    case ActivityEnum.ACTIVE:
      physicalActivityLevel = 1.55;
      break;
    case ActivityEnum.VERY_ACTIVE:
      physicalActivityLevel = 1.725;
      break;
    default:
      break;
  }

  const activityCalories = rmrMifflinStJour * (physicalActivityLevel - 1);

  const total = rmrMifflinStJour + activityCalories;

  return {
    leanBodyMass: leanBodyMassInKg,
    daily: {
      bmr: bmrHarrisBenedict,
      ree: rmrMifflinStJour,
      lifestyle: activityCalories,
      cardio: 0,
      weightlifting: 0,
      foodDigestion: 0,
      total,
    },
  };
}

function convertToLegacyBodyStats(userProfile: UserProfile, onboardingState?: OnboardingState): LegacyBodyStats {
  let legacyEnergyExpenditure = computeEnergyExpenditure(getEnergyInput(userProfile));

  const doNotUseBodyFatToCalculate = Boolean(onboardingState);

  if (doNotUseBodyFatToCalculate) {
    if (!onboardingState) {
      throw new Error("Cannot calculate energy expenditure without onboarding state");
    }

    legacyEnergyExpenditure = calculateEnergyExpenditureWithoutBodyFat(onboardingState);
  }

  if (!legacyEnergyExpenditure) {
    throw new Error("Could not calculate energy expenditure");
  }

  return {
    gender: userProfile.gender,
    weight: userProfile.weight,
    bodyFatPerc: userProfile.body_fat_percentage,
    leanBodyMass: legacyEnergyExpenditure?.leanBodyMass,
    tdee: legacyEnergyExpenditure?.daily.total,
    bmr: legacyEnergyExpenditure?.daily.bmr,
    ree: legacyEnergyExpenditure?.daily.ree,
  };
}

function convertToLegacyFoodPreferences(userProfile: UserProfile): LegacyFoodPreferences {
  return {
    diet: userProfile.diet,
    optimize: userProfile.sustainability,
  };
}

export function getDefaultLegacyInput(
  userProfile: UserProfile,
  daysForNutritionPlan: DaysForNutritionPlan,
  name: string
): LegacyInput {
  const legacyInput = convertToLegacyInput(userProfile, daysForNutritionPlan, name);

  const legacyBodyStats = convertToLegacyBodyStats(userProfile);
  const legacyFoodPreferences = convertToLegacyFoodPreferences(userProfile);

  return updateDailyMacros(legacyInput, legacyBodyStats, legacyFoodPreferences);
}

export function updateLegacyInput(
  legacyInput: LegacyInput,
  userProfile: UserProfile,
  onboardingState?: OnboardingState
): LegacyInput {
  const legacyBodyStats = convertToLegacyBodyStats(userProfile, onboardingState);
  const legacyFoodPreferences = convertToLegacyFoodPreferences(userProfile);

  return updateDailyMacros(legacyInput, legacyBodyStats, legacyFoodPreferences);
}

function createMealSlotSpecificationFromLegacyMealState(
  legacyMealState: LegacyMealState,
  index: number
): MealSlotSpecification | undefined {
  const { legacyMealType, macros, mealMoment, enabled } = legacyMealState;

  if (!macros) return undefined;

  const { calories, protein, carbs, fat } = macros;
  return {
    kcal: calories,
    protein,
    carbohydrates: carbs,
    fat,

    meal_type: convertLegacyMealTypeToMealType(legacyMealType),
    meal_moment: mealMoment,
    order: index,

    // NOTE: These are required by the type but cannot yet be populated
    id: -1,
    nutrition_day_plan: -1,
  };
}

/*
 * Functions required in our create nutrition day plan flow
 */

export function convertToMealSlotSpecifications(meals: LegacyMealState[]): MealSlotSpecification[] {
  let rawMealSlotSpecificationsWithoutOrder = meals
    .filter((meal) => meal.enabled)
    .map((mss, index) => createMealSlotSpecificationFromLegacyMealState(mss, index));
  rawMealSlotSpecificationsWithoutOrder = rawMealSlotSpecificationsWithoutOrder.filter(Boolean);

  // Add order field to each meal slot specification
  const mealSlotSpecifications = rawMealSlotSpecificationsWithoutOrder.map((mealSlotSpecification, index) => ({
    ...mealSlotSpecification,
    order: index,
  }));

  // NOTE: The compiler doesn't realise that there cannot be any undefined values in the array
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mealSlotSpecifications;
}

export function getLegacyInputFromMealSlotSpecifications(
  nutritionDayPlan: NutritionDayPlan,
  intake: UserProfile,
  daysForNutritionPlan: DaysForNutritionPlan
): LegacyInput {
  const mealSlotSpecifications = nutritionDayPlan.meal_slot_specifications;
  const energyExpenditure = calculateEnergyExpenditure(intake);

  if (!energyExpenditure) {
    return getDefaultLegacyInput(intake, daysForNutritionPlan, nutritionDayPlan.name || DEFAULT_NUTRITION_PLAN_NAME);
  }

  const totalEnergy = _.sumBy(mealSlotSpecifications, "kcal");
  const legacyDailyMacros = {
    calories: totalEnergy,
    protein: _.sumBy(mealSlotSpecifications, "protein"),
    carbs: _.sumBy(mealSlotSpecifications, "carbohydrates"),
    fat: _.sumBy(mealSlotSpecifications, "fat"),
    fiber: 0,
  };

  const energyBalanceString = formatNumberAsWholeNumber((totalEnergy / energyExpenditure.daily.total) * 100);

  const MEAL_MOMENTS: MealMomentEnum[] = [
    "BREAKFAST",
    "MORNING_SNACK",
    "LUNCH",
    "AFTERNOON_SNACK",
    "DINNER",
    "SNACK",
    "LATE_SNACK",
  ];

  const createLegacyMealState = (mealMoment: MealMomentEnum): LegacyMealState => {
    const mealSlotSpecification = _.find(mealSlotSpecifications, { meal_moment: mealMoment });

    if (!mealSlotSpecification) {
      return {
        legacyMealType: convertMealTypeToLegacyMealType(mealMoment),
        mealMoment,
        size: 0,
        enabled: false,
        macros: emptyMacros(),
      };
    }

    const { kcal: calories, protein, carbohydrates: carbs, fat } = mealSlotSpecification;
    return {
      macros: {
        calories: calories || 0,
        protein: protein || 0,
        carbs: carbs || 0,
        fat: fat || 0,
        fiber: 0,
      },
      mealMoment,
      enabled: true,
      size: ((calories || 0) / totalEnergy) * 100,
      legacyMealType: convertMealTypeToLegacyMealType(mealMoment),
    };
  };
  const legacyMeals = MEAL_MOMENTS.map(createLegacyMealState);

  return {
    id: NUTRITION_PLAN_WITHOUT_ID,
    name: nutritionDayPlan.name || DEFAULT_NUTRITION_PLAN_NAME,
    computedDailyMacros: legacyDailyMacros,
    // NOTE: We have to populate `adjustedDailyMacros` to ensure any coach-inputted difference is preserved
    adjustedDailyMacros: legacyDailyMacros,
    energyBalance: energyBalanceString,
    meals: legacyMeals,
    days: daysForNutritionPlan,
  };
}

export function getLegacyInputFromNutritionPlan(
  intake: UserProfile,
  nutritionDayPlan: NutritionDayPlan,
  daysForNutritionPlan: DaysForNutritionPlan
): LegacyInput {
  if (!intake.weekly_nutrition_plan) {
    return getDefaultLegacyInput(intake, daysForNutritionPlan, nutritionDayPlan.name || DEFAULT_NUTRITION_PLAN_NAME);
  }

  return getLegacyInputFromMealSlotSpecifications(nutritionDayPlan, intake, daysForNutritionPlan);
}

export const getUpdatedMealSlotSpecificationsFromFormValues = (
  values: {
    [x: string]: {
      protein: number;
      carbs: number;
      fat: number;
    };
  },
  meals: LegacyMealState[]
): { updatedLegacyMealStateArray: LegacyMealState[]; updatedMealSlotSpecifications: MealSlotSpecification[] } => {
  const updateLegacyMealState = (legacyMealState: LegacyMealState): LegacyMealState => {
    const updatedMacros = values[legacyMealState.mealMoment];
    if (!updatedMacros) {
      return legacyMealState;
    }

    return {
      ...legacyMealState,
      macros: legacyMealState.macros
        ? {
            fat: updatedMacros.fat ? parseFloat(String(updatedMacros.fat)) : 0,
            protein: updatedMacros.protein ? parseFloat(String(updatedMacros.protein)) : 0,
            carbs: updatedMacros.carbs ? parseFloat(String(updatedMacros.carbs)) : 0,
            fiber: 0,

            // NOTE: Yup & Formik don't always play nicely with typescript
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            calories: computeCalories(updatedMacros) || 0,
          }
        : undefined,
    };
  };

  const updatedMeals = _.map(meals, updateLegacyMealState).filter((legacyMealState) => legacyMealState.enabled);
  const updatedMealSlotSpecifications = convertToMealSlotSpecifications(updatedMeals);

  return { updatedLegacyMealStateArray: updatedMeals, updatedMealSlotSpecifications };
};
