import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useTheme } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import type { ImageSourcePropType, Platform, StyleProp, ViewStyle } from "react-native";
import { FAB, Portal, Provider } from "react-native-paper";
import { useSelector } from "react-redux";

import { Routes, Scale } from "../constants";
import Images from "../constants/images";
import { isMobilePlatform } from "../helpers/generalHelpers";
import type { OnChooseProduct, RootStackParamList } from "../navigation/NavigationStackParams";
import type { MealSlotSpecification } from "../services/backendTypes";
import logger from "../services/logger";
import { viewAsUserSelector } from "../slices/userSlice";

type FABGroupAction = {
  icon: ImageSourcePropType;
  label?: string;
  color?: string;
  labelTextColor?: string;
  accessibilityLabel?: string;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  small?: boolean;
  onPress: () => void;
  testID?: string;
};

type Props = {
  navigation: NativeStackNavigationProp<RootStackParamList, Routes.DiaryViewScreen>;
  // FIXME: This should be renamed as it has the same name as another function
  addSingleFoodMealToPlanner: OnChooseProduct;
  displayQuickAddModal: () => void;
  mealSlotSpecifications?: MealSlotSpecification[];
};

const getQuickOptionsAction = (
  label: string,
  image: ImageSourcePropType,
  onClick: () => void,
  testID: string
): FABGroupAction => ({
  icon: image,
  label,
  onPress: onClick,
  small: false,
  labelStyle: { backgroundColor: "transparent", marginHorizontal: 0 },
  labelTextColor: "white",
  style: {
    width: Scale(45),
    height: Scale(45),
    alignItems: "center",
    justifyContent: "center",
  },
  testID,
});

// TODO: Rename this to PlannerQuickOptions
const FloatingButton = ({
  addSingleFoodMealToPlanner,
  displayQuickAddModal,
  navigation,
  mealSlotSpecifications,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const theme = useTheme();

  const viewAsUser = useSelector(viewAsUserSelector);

  const onChoose: OnChooseProduct = async (product, suggestedServing, quantity, mealSlotSpecificationId) => {
    setOpen(false);
    await addSingleFoodMealToPlanner(product, suggestedServing, quantity, mealSlotSpecificationId);

    if (viewAsUser) {
      navigation.pop(1);
    } else {
      navigation.popToTop();
    }
  };

  const foodChooseScreenParams = {
    // TODO: This onChoose is passed down many levels: is there a better architecture?
    onChoose,
    chooseMealMoment: true,
    mealSlotSpecifications,
  };

  const handleAddProduct = (): void => {
    navigation.push(Routes.AddProductStack, {
      screen: Routes.FoodSearchScreen,
      params: foodChooseScreenParams,
    });
  };

  const handleScanProduct = (): void => {
    navigation.push(Routes.AddProductByBarcodeStack, {
      screen: Routes.BarcodeScannerScreen,
      params: foodChooseScreenParams,
    });
  };

  const actions = [
    getQuickOptionsAction(
      t("planner.quick_options.quick_add_title"),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Images.ForkKnifeWhiteIcon,
      displayQuickAddModal,
      "addQuickMeal-button"
    ),
    getQuickOptionsAction(
      t("planner.quick_options.add_product_title"),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Images.AddProductIcon,
      handleAddProduct,
      "addSingleFoodMeal-button"
    ),
  ];
  if (isMobilePlatform()) {
    actions.push(
      getQuickOptionsAction(
        t("planner.quick_options.scan_barcode_title"),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        Images.ScanProductIcon,
        handleScanProduct,
        "addSingleFoodMealByScanningBarcode-button"
      )
    );
  }

  return (
    <Provider>
      <Portal>
        <FAB.Group
          fabStyle={{
            backgroundColor: theme.colors.primary["600"],
            width: Scale(48),
            height: Scale(48),
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          icon={open ? Images.CrossIcon : Images.PlusIcon}
          color={"white"}
          actions={actions}
          visible={true}
          onStateChange={() => {
            setOpen(!open);
          }}
          testID="quickAction-button"
        />
      </Portal>
    </Provider>
  );
};

export default FloatingButton;
