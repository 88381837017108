import { MaterialIcons } from "@expo/vector-icons";
import _ from "lodash";
import moment, { Moment } from "moment";
import { Icon, IconButton, Text, View } from "native-base";
import React from "react";
import type { TFunction } from "react-i18next";
import type { AbstractChartConfig } from "react-native-chart-kit/dist/AbstractChart";
import { Row as TableRow, Table } from "react-native-table-component";

import type { UserDistance, UserSleep, UserStress, UserWeight } from "../services/backendTypes";
import type { UserDistancesByDate, UserSleepsByDate, UserStressByDate, UserWeightsByDate } from "../slices/userSlice";
import { MeasurementType, Stress } from "../types";
import { formatMomentAsBackendFormatDateString, formatNumberAsDecimal } from "./generalHelpers";
import { getLatestMeasurementForDate } from "./userHelpers";

type UserMeasurementsByDate = UserWeightsByDate | UserSleepsByDate | UserStressByDate | UserDistancesByDate;
type UserMeasurement = UserWeight | UserSleep | UserStress | UserDistance;
type UserMeasurementArray = UserWeight[] | UserSleep[] | UserStress[] | UserDistance[];

type PaddedDataPointsArray = (number | null | undefined)[];

const DATE_FORMAT_FOR_DATE_IN_SHORT_PERIOD = "D MMM";
const DATE_FORMAT_FOR_DATE_IN_LONG_PERIOD = "MMM YY";
/**
 * Return an array of 4 dates (format "D MMM") that are evenly spaced out across the chart
 *
 * @returns {[string, string][]}
 */
export const createChartLabelsForLineChart = (numDaysInChart: number): string[] => {
  const fourQuartileIndices = [0, Math.floor(numDaysInChart / 4), Math.floor(numDaysInChart / 2), numDaysInChart];

  return fourQuartileIndices.map((index) => {
    const date = moment().subtract(numDaysInChart - index, "days");

    return date.format(
      numDaysInChart > 60 ? DATE_FORMAT_FOR_DATE_IN_LONG_PERIOD : DATE_FORMAT_FOR_DATE_IN_SHORT_PERIOD
    );
  });
};

export const createChartLabelsForBarChart = (numDaysInChart: number): string[] => {
  if (numDaysInChart < 8) {
    return _.range(numDaysInChart).map((index) => {
      const date = moment().subtract(numDaysInChart - index, "days");

      return date.format(DATE_FORMAT_FOR_DATE_IN_SHORT_PERIOD);
    });
  }
  return createChartLabelsForLineChart(numDaysInChart);
};

export const PROGRESS_CHART_CONFIG: AbstractChartConfig = {
  backgroundGradientFrom: "white",
  backgroundGradientTo: "white",
  useShadowColorFromDataset: true, // optional
  propsForBackgroundLines: {
    strokeDasharray: "", // solid background lines with no dashes
    stroke: "#EDF4F7",
    strokeWidth: "1",
  },
  propsForLabels: {
    fontFamily: "HelveticaNeue-Light",
    fontSize: 10,
  },
};

export const BAR_CHART_CONFIG: AbstractChartConfig = {
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};

export enum TimePeriod {
  LAST_WEEK = "LAST_WEEK",
  LAST_2_WEEKS = "LAST_2_WEEKS",
  LAST_MONTH = "LAST_MONTH",
  LAST_2_MONTHS = "LAST_2_MONTHS",
  SINCE_BEGINNING = "SINCE_BEGINNING",
}

const NUM_DAYS_FOR_TIME_PERIOD: {
  [key in TimePeriod]: number;
} = {
  [TimePeriod.LAST_WEEK]: 7,
  [TimePeriod.LAST_2_WEEKS]: 14,
  [TimePeriod.LAST_MONTH]: 30,
  [TimePeriod.LAST_2_MONTHS]: 60,
  [TimePeriod.SINCE_BEGINNING]: 0,
};

function getSortedDates(userMeasurements: UserMeasurementsByDate): string[] {
  return _.sortBy(Object.keys(userMeasurements), (date) => -moment(date));
}

export const getNumDaysInChart = (selectedTimePeriod: TimePeriod, userMeasurements: UserMeasurementsByDate): number => {
  if (selectedTimePeriod === TimePeriod.SINCE_BEGINNING && userMeasurements) {
    const sortedDates = getSortedDates(userMeasurements);
    const furthestBackDateWeHaveDataFor = sortedDates[sortedDates.length - 1];

    return Math.abs(moment(furthestBackDateWeHaveDataFor).diff(moment(), "days")) + 1;
  }

  return NUM_DAYS_FOR_TIME_PERIOD[selectedTimePeriod];
};

const getStartAndEndDate = (
  selectedTimePeriod: TimePeriod,
  userMeasurements: UserMeasurementsByDate
): {
  startDate: Moment;
  endDate: Moment;
} => {
  const numDaysInChart = getNumDaysInChart(selectedTimePeriod, userMeasurements);

  return {
    startDate: moment().subtract(numDaysInChart, "days"),
    endDate: moment(),
  };
};

function isMeasurementInRange<T extends UserMeasurementArray>(
  userWeightMeasurementArray: T,
  date: string,
  startDate: moment.Moment,
  endDate: moment.Moment
): boolean {
  return moment(date).isBetween(startDate, endDate, null, "[]");
}

function filterUserMeasurementsForTimePeriod<T extends UserMeasurementsByDate>(
  userWeightMeasurements: T,
  startDate: moment.Moment,
  endDate: moment.Moment
): T {
  const isMeasurementInRangeCurried = (userMeasurementsArray: UserMeasurementArray, date: string): boolean =>
    isMeasurementInRange(userMeasurementsArray, date, startDate, endDate);

  return _.pickBy(userWeightMeasurements, isMeasurementInRangeCurried) as unknown as T;
}

export function getUserMeasurementsForTimePeriod<T extends UserMeasurementsByDate>(
  selectedTimePeriod: TimePeriod,
  userMeasurements: T
): T {
  const { startDate, endDate } = getStartAndEndDate(selectedTimePeriod, userMeasurements);

  return filterUserMeasurementsForTimePeriod(userMeasurements, startDate, endDate);
}

export const getDataPointsForChart = (
  userMeasurements: UserMeasurementsByDate,
  numDaysInChart: number
): (number | null)[] => {
  const getDataPointForHistoricDay = (numDaysInThePast: number): number | null => {
    const formattedDate = formatMomentAsBackendFormatDateString(moment().subtract(numDaysInThePast, "days"));

    return getLatestMeasurementForDate(userMeasurements, formattedDate) || null;
  };

  // For each day in the past 60, get the latest measurement for that day
  return _.range(numDaysInChart).map(getDataPointForHistoricDay).reverse();
};

export const getDataPointsForChartWithNullsPadded = (
  userMeasurements: UserMeasurementsByDate,
  numDaysInChart: number
): PaddedDataPointsArray => {
  const dataPointsForChart = getDataPointsForChart(userMeasurements, numDaysInChart);

  const findIndexOfNextNonNullDataPoint = (startingIndex: number): number =>
    _.findIndex(dataPointsForChart, (point) => point !== null, startingIndex);

  const findIndexOfPreviousNonNullDataPoint = (startingIndex: number): number =>
    _.findLastIndex(dataPointsForChart, (point) => point !== null, startingIndex);

  // pad null values with next non-null value - we do this for continuity on the chart
  const dataPointsForChartWithNullsPadded = dataPointsForChart.map((dataPoint, index) => {
    if (dataPoint === null) {
      const nextNonNullDataPointIndex = findIndexOfNextNonNullDataPoint(index);
      // Interpolate to the next non null value
      if (nextNonNullDataPointIndex !== -1) {
        return dataPointsForChart[nextNonNullDataPointIndex];
      }

      // If there is no next non null value, interpolate to the previous non null value
      const lastNonNullDataPointIndex = findIndexOfPreviousNonNullDataPoint(index);
      if (lastNonNullDataPointIndex !== -1) {
        return dataPointsForChart[lastNonNullDataPointIndex];
      }

      return 0;
    }
    return dataPoint;
  });

  return dataPointsForChartWithNullsPadded;
};

export const getDataPointIndicesWithNoData = (dataPointsForChart: PaddedDataPointsArray): number[] => {
  const getIndexIfDataPointIsNull = (value: number, index: number): number | undefined =>
    dataPointsForChart[index] === null ? index : undefined;
  const indicesOfNullDataPoints = _.range(dataPointsForChart.length).map(getIndexIfDataPointIsNull);

  return _.compact(indicesOfNullDataPoints);
};

export function getYAxisMin(dataPointsForChartWithNullsPadded: PaddedDataPointsArray): number {
  return Math.round(_.min(dataPointsForChartWithNullsPadded) || 50) - 1;
}

export function getYAxisMax(dataPointsForChartWithNullsPadded: PaddedDataPointsArray): number {
  return Math.round(_.max(dataPointsForChartWithNullsPadded) || 140) + 1;
}

export function getAverageChangeInMeasurementPerWeek<T extends UserMeasurementsByDate>(
  userMeasurementsForTimePeriod: T
): {
  averageWeeklyDelta: number;
  averageWeeklyDeltaAsPercentage: number;
} {
  const sortedDates = getSortedDates(userMeasurementsForTimePeriod);

  const emptyReturnObject = {
    averageWeeklyDelta: 0,
    averageWeeklyDeltaAsPercentage: 0,
  };

  if (sortedDates.length < 2) {
    return emptyReturnObject;
  }

  const firstDate = sortedDates[sortedDates.length - 1];
  const latestDate = sortedDates[0];

  if (firstDate === latestDate || !firstDate || !latestDate) {
    return emptyReturnObject;
  }

  const firstMeasurement = getLatestMeasurementForDate(userMeasurementsForTimePeriod, firstDate);
  const latestMeasurement = getLatestMeasurementForDate(userMeasurementsForTimePeriod, latestDate);

  if (!firstMeasurement || !latestMeasurement) {
    return emptyReturnObject;
  }

  const numWeeks = moment(latestDate).diff(moment(firstDate), "days") / 7;

  const averageWeeklyDelta = (latestMeasurement - firstMeasurement) / numWeeks;
  const averageWeeklyDeltaAsPercentage = averageWeeklyDelta / latestMeasurement;

  return {
    averageWeeklyDelta,
    averageWeeklyDeltaAsPercentage,
  };
}

function formatMeasurementForDisplay(
  userMeasurement: UserMeasurement,
  measurementType: MeasurementType,
  t: TFunction
): string {
  switch (measurementType) {
    case MeasurementType.WEIGHT:
      if (typeof userMeasurement.value === "number") {
        return userMeasurement.value ? `${formatNumberAsDecimal(userMeasurement.value, 2)}` : "";
      }
      return userMeasurement.value ? `${userMeasurement.value}` : "";
    case MeasurementType.SLEEP:
      return userMeasurement.value ? `${userMeasurement.value} h` : "";
    case MeasurementType.STRESS:
      if (!Number.isNaN(userMeasurement.value)) {
        return userMeasurement.value ? t(`my_progress.STRESS.${userMeasurement.value as Stress}`) : "";
      }
      throw new Error("Invalid stress type");
    case MeasurementType.WAIST_CIRCUMFERENCE:
      return userMeasurement.value ? `${userMeasurement.value} cm` : "";
    default:
      return "";
  }
}

function formatDateForDisplay(date: string): string {
  return moment(date).format("D MMM YY");
}

export function createTableDisplayingMeasurements({
  userMeasurements,
  measurementType,
  backgroundColor,
  deleteMeasurementForDate,
  t,
  isDesktop,
}: {
  userMeasurements: UserMeasurementsByDate;
  measurementType: MeasurementType;
  backgroundColor: string;
  deleteMeasurementForDate: (id: number, measurementType: MeasurementType) => Promise<void>;
  t: TFunction;
  isDesktop: boolean;
}): JSX.Element {
  const createTableRow = (measurementArray: UserMeasurementArray, date: string): [string, JSX.Element, JSX.Element] => {
    const measurement = measurementArray?.[0];

    if (!measurement) {
      return ["", <></>, <></>];
    }

    const formattedDate = formatDateForDisplay(date);

    return [
      formattedDate,
      <Text key={date} testID={`measurementValue-${date}-${measurementType}`}>
        {formatMeasurementForDisplay(measurement, measurementType, t)}
      </Text>,
      <IconButton
        key={date}
        icon={<Icon as={MaterialIcons} color={"red.400"} name="delete" />}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={() => deleteMeasurementForDate(measurement.id, measurementType)}
        testID={`deleteProgressMeasurement-${measurementType}-${measurement.id}-button`}
      />,
    ];
  };

  const unsortedTableData = _.map(userMeasurements, createTableRow);
  const tableData = _.sortBy(unsortedTableData, ([dateString]) => -moment(dateString).unix());

  return (
    // NOTE: This padding is to leave room for the floating action button
    <View mt="8" pr={isDesktop ? 0 : 10}>
      <Table>
        {tableData.map((rowData, index) => (
          <TableRow key={index} data={rowData} style={index % 2 ? { backgroundColor } : {}} textStyle={{ margin: 4 }} />
        ))}
      </Table>
    </View>
  );
}
