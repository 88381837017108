// NOTE: This file is not used

import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import * as ImagePicker from "expo-image-picker";
import { Flex } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Text, TouchableOpacity, View } from "react-native";

import { CommonButton, CommonIcon, CommonPageHeader } from "../commons";
import { height, Images, Routes, Scale, VerticalScale, width } from "../constants";
import styles from "./ProfilePictureScreenStyle";

const ImageSize = Scale(160);

type ParamList = {
  ProfilePictureScreen: undefined;
  ProfileStartScreen: undefined;
  PersonalDetailsScreen: undefined;
};
type Props = {
  navigation: NativeStackNavigationProp<ParamList, "ProfilePictureScreen">;
};

const ProfilePictureScreen = ({ navigation }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [image, setImage] = useState("");

  const pickImage = async (): Promise<void> => {
    const imagePickerOptions: ImagePicker.ImagePickerOptions = {
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    };
    const result = await ImagePicker.launchImageLibraryAsync(imagePickerOptions);

    // if (!result.cancelled) {
    //   NOTE: The signature of this library function is now different in an updated version
    //   setImage(result.uri);
    // }
  };

  const ImageUploadView = (): JSX.Element => (
    <Flex
      direction="row"
      justifyContent={"center"}
      alignItems="center"
      style={{
        position: "absolute",
        left: width / 2 - ImageSize / 2,
        top: height / 2 - ImageSize / 2,
      }}
    >
      {/* {image && <Image source={{ uri: image }} style={styles.profileAvatarImg} />}
      <TouchableOpacity style={image ? styles.editIconContainer : null} onPress={pickImage}>
        {image ? (
          <CommonIcon source={Images.PencilIconWhite} size={24} />
        ) : (
          <Image source={Images.ProfileAvatar} style={styles.profileAvatarImg} />
        )}
      </TouchableOpacity> */}
    </Flex>
  );

  const onSkipBtn = (): void => {
    navigation.navigate(Routes.PersonalDetailsScreen as keyof ParamList);
  };
  return (
    <View style={styles.container}>
      <CommonPageHeader
        title={"Maak je profiel compleet"}
        onPressBack={() => {
          navigation.goBack();
        }}
        onPressClose={() => {
          navigation.navigate(Routes.ProfileStartScreen as keyof ParamList);
        }}
      />
      <View style={styles.mainContainer}>
        <View style={{ flex: 1 }}>
          <View style={{ marginVertical: VerticalScale(50) }}>
            <Text style={styles.titleText}>{"Voeg een profielfoto toe"}</Text>
          </View>
        </View>
        <View style={styles.bottomContainer}>
          {/* <CommonButton title={"Foto uploaden"} onPress={pickImage} /> */}
          <CommonButton
            title={"Skip"}
            externalTextStyle={{ color: "#000" }}
            externalContainerStyle={{ backgroundColor: "#FFF" }}
            onPress={() => navigation.navigate(Routes.BottomNavigationTab as keyof ParamList)}
          />
        </View>
      </View>
      <ImageUploadView />
    </View>
  );
};

export default ProfilePictureScreen;
