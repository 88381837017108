import { MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  Fab,
  Flex,
  FormControl,
  Icon,
  Input,
  Modal,
  Row,
  ScrollView,
  Select,
  Text,
  useDisclose,
  useTheme,
  View,
} from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dimensions, SafeAreaView } from "react-native";
import { Calendar } from "react-native-calendars";
import { BarChart, LineChart } from "react-native-chart-kit";
import type { ChartData } from "react-native-chart-kit/dist/HelperTypes";
import type { LineChartData } from "react-native-chart-kit/dist/line-chart/LineChart";
import { Row as TableRow, Table } from "react-native-table-component";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import CustomBrandingMobileScreenHeader from "../components/CustomBrandingMobileScreenHeader";
import type { MarkedDatesType } from "../components/GroceryListDatePicker";
import { commonStyles, isDesktopScreen, KG_TO_LBS, LBS_TO_KG, Routes } from "../constants";
import { FontFamily } from "../constants/fonts";
import { formatMomentAsDateForApi } from "../helpers/apiHelpers";
import {
  BAR_CHART_CONFIG,
  createChartLabelsForBarChart,
  createChartLabelsForLineChart,
  createTableDisplayingMeasurements,
  getAverageChangeInMeasurementPerWeek,
  getDataPointIndicesWithNoData,
  getDataPointsForChart,
  getDataPointsForChartWithNullsPadded,
  getNumDaysInChart,
  getUserMeasurementsForTimePeriod,
  getYAxisMax,
  getYAxisMin,
  PROGRESS_CHART_CONFIG,
  TimePeriod,
} from "../helpers/chartHelpers";
import { shouldWeUseImperialForThisUser } from "../helpers/foodHelpers";
import {
  formatMomentAsBackendFormatDateString,
  formatNumberAsDecimal,
  formatNumberAsPercentage,
  formatNumberToDecimalPlaces,
  isIosWeb,
  isMacOriOSWeb,
  transformEuropeanStyleNumber,
} from "../helpers/generalHelpers";
import { getLatestMeasurementForDate } from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { UserWeight, ValueEnum as StressValues } from "../services/backendTypes";
import {
  userSelector,
  userSleepMeasurementsSelector,
  userSlice,
  userStressMeasurementsSelector,
  userWaistCircumferenceMeasurementsSelector,
  userWeightMeasurementsSelector,
} from "../slices/userSlice";
import { MeasurementType, Stress } from "../types";
import styles from "./MyProfileScreenStyles";

const screenWidth = Dimensions.get("window").width;
const {
  useMeasurementsUserWeightCreateMutation,
  useMeasurementsUserWeightListQuery,
  useMeasurementsUserSleepCreateMutation,
  useMeasurementsUserSleepListQuery,
  useMeasurementsUserStressCreateMutation,
  useMeasurementsUserStressListQuery,
  useMeasurementsUserDistanceListQuery,
  useMeasurementsUserDistanceCreateMutation,
  useMeasurementsDeleteMeasurementCreateMutation,
} = backendApi;

const MEASUREMENT_POLLING_INTERVAL = 1000 * 60;

type Props = NativeStackScreenProps<RootStackParamList, Routes.MyProgressScreen>;
const MyProgressScreen = ({ route: { params } }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const isDesktop = isDesktopScreen();

  const dispatch = useDispatch();
  const userNotViewAs = useSelector(userSelector);
  const viewAsUser = params?.viewAsUser;
  const user = viewAsUser || userNotViewAs;

  const userUsesImperialMeasurements = user ? shouldWeUseImperialForThisUser(user) : false;

  const userWeightMeasurementsInKg = useSelector(userWeightMeasurementsSelector);
  const userWeightMeasurements = userUsesImperialMeasurements
    ? _.mapValues(userWeightMeasurementsInKg, (measurements): UserWeight[] =>
        measurements.map((measurement) => ({
          ...measurement,
          value: measurement.value * KG_TO_LBS,
        }))
      )
    : userWeightMeasurementsInKg;

  const userSleepMeasurements = useSelector(userSleepMeasurementsSelector);
  const userStressMeasurements = useSelector(userStressMeasurementsSelector);
  const userWaistCircumferenceMeasurements = useSelector(userWaistCircumferenceMeasurementsSelector);

  const {
    isOpen: isOpenAddUserMeasurementsModal,
    onOpen: onOpenAddUserMeasurementsModal,
    onClose: onCloseAddUserMeasurementsModal,
  } = useDisclose();

  // NOTE: We have a bug on Windows which I am unable to replicate.
  // The bug causes the screen to 'jitter' horizontally.
  // Some of the dropdown options do not work and so we default to the one that gives the coach the most information.
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState<TimePeriod>(TimePeriod.SINCE_BEGINNING);
  const [selectedMeasurementType, setSelectedMeasurementType] = React.useState<MeasurementType>(MeasurementType.WEIGHT);

  const [inputNewWeightMeasurementOnBackend] = useMeasurementsUserWeightCreateMutation();
  const [inputNewSleepMeasurementOnBackend] = useMeasurementsUserSleepCreateMutation();
  const [inputNewMoodMeasurementOnBackend] = useMeasurementsUserStressCreateMutation();
  const [inputNewUserDistanceMeasurementOnBackend] = useMeasurementsUserDistanceCreateMutation();
  const [deleteMeasurementOnBackend] = useMeasurementsDeleteMeasurementCreateMutation();
  const { data: userWeightMeasurementsResponse, refetch: refetchUserWeightMeasurements } =
    useMeasurementsUserWeightListQuery(
      { user: user?.id },
      { skip: !user, pollingInterval: MEASUREMENT_POLLING_INTERVAL }
    );
  const { data: userSleepMeasurementsResponse, refetch: refetchUserSleepMeasurements } =
    useMeasurementsUserSleepListQuery(
      { user: user?.id },
      { skip: !user, pollingInterval: MEASUREMENT_POLLING_INTERVAL }
    );
  const { data: userStressMeasurementsResponse, refetch: refetchUserStressMeasurements } =
    useMeasurementsUserStressListQuery(
      { user: user?.id },
      { skip: !user, pollingInterval: MEASUREMENT_POLLING_INTERVAL }
    );
  const { data: userDistanceMeasurementsResponse, refetch: refetchUserDistanceMeasurements } =
    useMeasurementsUserDistanceListQuery(
      { user: user?.id },
      { skip: !user, pollingInterval: MEASUREMENT_POLLING_INTERVAL }
    );

  // useEffects
  useEffect(() => {
    if (userWeightMeasurementsResponse && userWeightMeasurementsResponse.results) {
      dispatch(userSlice.actions.storeUserWeightMeasurements(userWeightMeasurementsResponse.results));
    }
  }, [userWeightMeasurementsResponse]);

  useEffect(() => {
    if (userSleepMeasurementsResponse && userSleepMeasurementsResponse.results) {
      dispatch(userSlice.actions.storeUserSleepMeasurements(userSleepMeasurementsResponse.results));
    }
  }, [userSleepMeasurementsResponse]);

  useEffect(() => {
    if (userStressMeasurementsResponse?.results) {
      dispatch(userSlice.actions.storeUserStressMeasurements(userStressMeasurementsResponse.results));
    }
  }, [userStressMeasurementsResponse]);

  useEffect(() => {
    if (userDistanceMeasurementsResponse?.results) {
      dispatch(userSlice.actions.storeUserDistanceMeasurements(userDistanceMeasurementsResponse.results));
    }
  }, [userDistanceMeasurementsResponse]);

  async function onDeleteProgressMeasurementForDate(
    measurementId: number,
    measurementType: MeasurementType
  ): Promise<void> {
    if (!user) {
      throw new Error("User must be populated");
    }

    // NOTE: We allow users to submit multiple measurements for a single day and just display the latest one.
    // As a result, if a user has 2 measurements for the same day and they do a deletion the old one will just come back
    // We should probably allow only 1 measurement for a single day
    await deleteMeasurementOnBackend({
      deleteMeasurementRequest: {
        measurement_id: measurementId,
        measurement_type: measurementType,
        user_id: user.id,
      },
    });

    alert(t("my_progress.delete_successful_message"));
  }

  const dateOfLatestWeightMeasurement = _.chain(userWeightMeasurements).keys().orderBy("desc").head().value();
  const latestWeightWeightMeasurement =
    getLatestMeasurementForDate(userWeightMeasurements, dateOfLatestWeightMeasurement) || 0;

  // Form config
  const formValidationSchema = Yup.object().shape({
    weight: Yup.number()
      // TODO This should be done properly
      // .transform(transformEuropeanStyleNumber)
      .positive()
      .min(1)
      .max(300)
      .typeError(t("general.form_errors.number_only")),
    weight_in_lbs: Yup.number().positive().min(1).max(650),
    stress: Yup.mixed<StressValues>().oneOf(Object.values(Stress)),
    sleep: Yup.number()
      // .transform(transformEuropeanStyleNumber)
      .positive()
      .max(16),
    waistCircumference: Yup.number()
      // .transform(transformEuropeanStyleNumber)
      .positive()
      .max(300),
    // TODO: Translate error
    date: Yup.date().required().max(moment().endOf("day").toDate(), "Cannot be in future"),
  });
  type UserMeasurementsSchema = Yup.InferType<typeof formValidationSchema>;

  const initialValues = formValidationSchema.cast({
    date: new Date(),
    // NOTE: There are no sensible defaults for sleep and stress unlike weight
  });

  const onSubmit = async (values: UserMeasurementsSchema): Promise<void> => {
    if (!user) {
      throw new Error("User is not set");
    }

    // NOTE: This is only the correct date in the user's current timezone
    // (it should not be strictly necessary as the client will interpret the ISO8601 date correctly)
    const createdAt = moment(values.date).toISOString();

    const requests = [];
    if (values.weight) {
      requests.push(
        inputNewWeightMeasurementOnBackend({
          userWeightRequest: {
            user: user.id,
            value: values.weight,
            created_at: createdAt,
          },
        })
      );
    }

    if (values.stress) {
      requests.push(
        inputNewMoodMeasurementOnBackend({
          userStressRequest: {
            user: user.id,
            value: values.stress,
            created_at: createdAt,
          },
        })
      );
    }

    if (values.sleep) {
      requests.push(
        inputNewSleepMeasurementOnBackend({
          userSleepRequest: {
            user: user.id,
            value: values.sleep,
            created_at: createdAt,
          },
        })
      );
    }

    if (values.waistCircumference) {
      requests.push(
        inputNewUserDistanceMeasurementOnBackend({
          userDistanceRequest: {
            user: user.id,
            body_area: "WAIST",
            value: values.waistCircumference,
            created_at: createdAt,
          },
        })
      );
    }

    // NOTE: The compiler wrongly thinks the heterogeneous array is a problem
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await Promise.all(requests);

    onCloseAddUserMeasurementsModal();
  };

  const getMarkedDatesFromDate = (date: Date): MarkedDatesType => {
    const markedDates: MarkedDatesType = {};
    if (date) {
      markedDates[formatMomentAsBackendFormatDateString(moment(date))] = {
        selected: true,
      };
    }

    return markedDates;
  };

  const addMeasurementsModal = (
    <Modal isOpen={isOpenAddUserMeasurementsModal} onClose={onCloseAddUserMeasurementsModal} size="xl">
      <Modal.Content bgColor={"white"}>
        <Modal.CloseButton />
        <Modal.Header bgColor={"white"} fontSize="4xl" fontWeight="bold">
          {t("my_progress.add_measurement_modal.title")}
        </Modal.Header>
        <Modal.Body>
          {/* `initialValues` is fine but the compiler complains for some reason. */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit}>
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              setFieldValue,
              errors,
              dirty,
              isValid,
            }) => (
              <View>
                <Calendar
                  onDayPress={(day) => handleChange("date")(day.dateString)}
                  markedDates={getMarkedDatesFromDate(values.date)}
                  theme={{
                    textDayFontFamily: FontFamily.medium,
                    textMonthFontFamily: FontFamily.bold,
                    textDayHeaderFontFamily: FontFamily.medium,
                    arrowColor: colors.primary["600"],
                  }}
                  renderArrow={(direction) => (
                    <MaterialIcons
                      name={direction === "left" ? "chevron-left" : "chevron-right"}
                      size={24}
                      color={colors.primary["600"]}
                    />
                  )}
                  firstDay={1}
                  testID="progress-datepicker"
                />
                {user && shouldWeUseImperialForThisUser(user) ? (
                  <FormControl isInvalid={!_.isEmpty(errors.weight)}>
                    <FormControl.Label>{t("my_progress.add_measurement_modal.weight_label")}</FormControl.Label>
                    <Input
                      onBlur={handleBlur("weight")}
                      returnKeyType="done"
                      placeholder={"lbs"}
                      onChangeText={(lbs) => {
                        const kg = Number(lbs) * LBS_TO_KG;
                        setFieldValue("weight_in_lbs", lbs);
                        setFieldValue("weight", kg);
                      }}
                      value={values.weight ? String(values.weight_in_lbs) : ""}
                      testID="userMeasurements-weight-input"
                      keyboardType={isIosWeb() ? "decimal-pad" : "numeric"}
                    />
                    <FormControl.ErrorMessage>{errors.weight}</FormControl.ErrorMessage>
                  </FormControl>
                ) : (
                  <FormControl isInvalid={!_.isEmpty(errors.weight)}>
                    <FormControl.Label>{t("my_progress.add_measurement_modal.weight_label")}</FormControl.Label>
                    <Input
                      onBlur={handleBlur("weight")}
                      returnKeyType="done"
                      placeholder={"kg"}
                      onChangeText={(weight) => setFieldValue("weight", weight.replace(",", "."))}
                      value={values.weight ? String(values.weight) : ""}
                      testID="userMeasurements-weight-input"
                      keyboardType={isIosWeb() ? "decimal-pad" : "numeric"}
                    />
                    <FormControl.ErrorMessage>{errors.weight}</FormControl.ErrorMessage>
                  </FormControl>
                )}

                <FormControl isInvalid={!_.isEmpty(errors.sleep)}>
                  <FormControl.Label>{t("my_progress.add_measurement_modal.sleep_label")}</FormControl.Label>
                  <Input
                    onBlur={handleBlur("sleep")}
                    placeholder={t("my_progress.add_measurement_modal.sleep_input_placeholder")}
                    onChangeText={(sleep) => setFieldValue("sleep", sleep.replace(",", "."))}
                    value={values.sleep ? String(values.sleep) : ""}
                    testID="userMeasurements-sleep-input"
                  />
                  <FormControl.ErrorMessage>{errors.sleep}</FormControl.ErrorMessage>
                </FormControl>

                <FormControl.Label>{t("my_progress.add_measurement_modal.stress_label")}</FormControl.Label>
                <FormControl isRequired isInvalid={!_.isEmpty(errors.stress)}>
                  <Select
                    selectedValue={String(values.stress)}
                    onValueChange={(itemValue) => setFieldValue("stress", itemValue)}
                    // https://github.com/GeekyAnts/NativeBase/issues/5111
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    selection={isMacOriOSWeb() ? 1 : null}
                    testID="userMeasurements-stress-input"
                  >
                    {Object.keys(Stress).map((stressEnum) => (
                      <Select.Item
                        key={stressEnum}
                        label={t(`my_progress.STRESS.${stressEnum as Stress}`)}
                        value={stressEnum}
                      />
                    ))}
                  </Select>
                  <FormControl.ErrorMessage>{errors.stress}</FormControl.ErrorMessage>
                </FormControl>

                <FormControl isInvalid={!_.isEmpty(errors.waistCircumference)}>
                  <FormControl.Label>
                    {t("my_progress.add_measurement_modal.waist_circumference_label")}
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("waistCircumference")}
                    placeholder={t("my_progress.add_measurement_modal.waist_circumference_placeholder")}
                    onChangeText={(waistCircumference) =>
                      setFieldValue("waistCircumference", waistCircumference.replace(",", "."))
                    }
                    value={values.waistCircumference ? String(values.waistCircumference) : ""}
                    testID="userMeasurements-waistCircumference-input"
                  />
                  <FormControl.ErrorMessage>{errors.waistCircumference}</FormControl.ErrorMessage>
                </FormControl>

                <View style={styles.bottomContainer}>
                  <Button
                    onPress={() => handleSubmit()}
                    isLoading={isSubmitting}
                    isDisabled={!dirty || !isValid}
                    testID={"userMeasurements-submit-button"}
                  >
                    {t("general.submit")}
                  </Button>
                </View>
              </View>
            )}
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );

  const floatingActionButton = (
    <Fab
      placement="bottom-right"
      size="md"
      renderInPortal={false}
      onPress={onOpenAddUserMeasurementsModal}
      icon={<Icon as={MaterialIcons} size="5" name="add" tintColor={"white"} />}
      testID={"myProfilePreferences-addMeasurements-button"}
    />
  );

  const chartConfig = {
    ...PROGRESS_CHART_CONFIG,
    color: (opacity = 1) => colors.primary["600"],
    labelColor: (opacity = 1) => "black",
  };

  // Max history length is 60 days, min history length is 7 days
  const numDaysInChart = getNumDaysInChart(selectedTimePeriod, userWeightMeasurements);
  const userWeightsForTimePeriod = getUserMeasurementsForTimePeriod(selectedTimePeriod, userWeightMeasurements);

  const dataPointsForWeightChartWithNullsPadded = getDataPointsForChartWithNullsPadded(
    userWeightMeasurements,
    numDaysInChart
  );

  const xAxisLabelsForLineChart = createChartLabelsForLineChart(numDaysInChart);
  const xAxisLabelsForBarChart = createChartLabelsForBarChart(numDaysInChart);

  const yAxisMinWeightChart = getYAxisMin(dataPointsForWeightChartWithNullsPadded);
  const yAxisMaxWeightChart = getYAxisMax(dataPointsForWeightChartWithNullsPadded);
  const dataForWeightChart: LineChartData = {
    datasets: [
      {
        data: _.map(dataPointsForWeightChartWithNullsPadded, (v) => v || 0),
      },
      {
        // NOTE: There is no way to control the max and min y-axis range so we use a hidden dummy dataset as a hack
        key: "dummy-range-padding",
        data: [yAxisMinWeightChart, yAxisMaxWeightChart],
        color: () => "transparent",
        withDots: false,
      },
    ],
    // NOTE: We deliberately do not show labels because they are not shown if there is no corresponding datapoint and
    // this results in the label for the left hand side date of the X-axis not showing.
    labels: [],
    legend: [`${t("my_progress.weight_chart.legend")} ${userUsesImperialMeasurements ? "(lbs)" : "(kg)"}`],
  };

  const dataPointsForWaistCircumferenceChartWithNullsPadded = getDataPointsForChartWithNullsPadded(
    userWaistCircumferenceMeasurements,
    numDaysInChart
  );
  const yAxisMinWaistCircumferenceChart = getYAxisMin(dataPointsForWaistCircumferenceChartWithNullsPadded);
  const yAxisMaxWaistCircumferenceChart = getYAxisMax(dataPointsForWaistCircumferenceChartWithNullsPadded);
  const dataForWaistCircumferenceChart: LineChartData = {
    datasets: [
      {
        data: _.map(dataPointsForWaistCircumferenceChartWithNullsPadded, (v) => v || 0),
      },
      {
        // NOTE: There is no way to control the max and min y-axis range so we use a hidden dummy dataset as a hack
        key: "dummy-range-padding",
        data: [yAxisMinWaistCircumferenceChart, yAxisMaxWaistCircumferenceChart],
        color: () => "transparent",
        withDots: false,
      },
    ],
    labels: [],
    legend: [t("my_progress.waist_circumference_chart.legend")],
  };

  const dataPointsForSleepChart = getDataPointsForChart(userSleepMeasurements, numDaysInChart);
  const dataForSleepChart: ChartData = {
    datasets: [
      {
        data: _.map(dataPointsForSleepChart, (v) => v || 0),
      },
    ],
    labels: xAxisLabelsForBarChart,
  };

  const dataPointsForStressChart = getDataPointsForChart(userStressMeasurements, numDaysInChart);
  const dataForStressChart: ChartData = {
    datasets: [
      {
        data: _.map(dataPointsForStressChart, (v) => v || 0),
      },
    ],
    labels: xAxisLabelsForBarChart,
  };

  // Extra information accompanying weight chart
  const { averageWeeklyDeltaAsPercentage, averageWeeklyDelta } =
    getAverageChangeInMeasurementPerWeek(userWeightsForTimePeriod);

  const firstWeightValue = _.head(dataPointsForWeightChartWithNullsPadded);
  const latestWeightValue = _.last(dataPointsForWeightChartWithNullsPadded);

  const tableOfWeightMeasurementsComponent = createTableDisplayingMeasurements({
    userMeasurements: userWeightsForTimePeriod,
    measurementType: MeasurementType.WEIGHT,
    backgroundColor: colors.primary["200"],
    deleteMeasurementForDate: onDeleteProgressMeasurementForDate,
    t,
    isDesktop,
  });

  const weightChartInfoComponent = (
    <View mx="2">
      {firstWeightValue && latestWeightValue ? (
        <>
          <Text testID="weight-change-label">
            {t("my_progress.weight_delta_label", {
              weight_change: formatNumberAsDecimal(latestWeightValue - firstWeightValue),
              unit: userUsesImperialMeasurements ? "lbs" : "kg",
            })}
          </Text>
        </>
      ) : null}
      <>
        <Text testID="average-weight-change-label">
          {t("my_progress.average_per_week_label", {
            absolute_change: formatNumberAsDecimal(averageWeeklyDelta),
            percentage_change: formatNumberAsPercentage(averageWeeklyDeltaAsPercentage),
            unit: userUsesImperialMeasurements ? "lbs" : "kg",
          })}
        </Text>
      </>
      {tableOfWeightMeasurementsComponent}
    </View>
  );

  const sleepValuesTable = createTableDisplayingMeasurements({
    userMeasurements: userSleepMeasurements,
    measurementType: MeasurementType.SLEEP,
    backgroundColor: colors.primary["200"],
    deleteMeasurementForDate: onDeleteProgressMeasurementForDate,
    t,
    isDesktop,
  });

  const sleepChart = _.some(dataForSleepChart?.datasets?.[0]?.data) ? (
    // NOTE: The `mt` is to make up for the lack of legend in the chart
    <View mx="5" mt="12">
      <BarChart
        data={dataForSleepChart}
        width={screenWidth - screenWidth * 0.1}
        height={300}
        chartConfig={{
          ...chartConfig,
          ...BAR_CHART_CONFIG,
          data: dataForSleepChart.datasets,
          formatYLabel: (value) => `${formatNumberAsDecimal(parseInt(value, 10), 1)}`,
        }}
        yAxisSuffix={t("my_progress.sleep_chart.suffix")}
        yAxisLabel=""
        fromZero={true}
        // NOTE: This is required to have a fixed y-axis range
        // (otherwise the y-axis range is determined by the data)
        fromNumber={10}
      />
      {sleepValuesTable}
    </View>
  ) : (
    <Center>
      <Text mt="6">{t("my_progress.no_data_for_period_label")}</Text>
      {/* Add empty space to ensure the FAB has empty space to be shown in */}
      <Box mt="32" />
    </Center>
  );

  const stressValuesTable = createTableDisplayingMeasurements({
    userMeasurements: userStressMeasurements,
    measurementType: MeasurementType.STRESS,
    backgroundColor: colors.primary["200"],
    deleteMeasurementForDate: onDeleteProgressMeasurementForDate,
    t,
    isDesktop,
  });

  const stressChart = _.some(dataForStressChart?.datasets?.[0]?.data) ? (
    <View mx="5" mt="12">
      <BarChart
        data={dataForStressChart}
        width={screenWidth - screenWidth * 0.1}
        height={300}
        segments={5}
        chartConfig={{
          ...chartConfig,
          ...BAR_CHART_CONFIG,
          data: dataForStressChart.datasets,
          formatYLabel(yLabel) {
            const stressNumber = parseInt(yLabel, 10);

            if (stressNumber === 0) {
              return t("my_progress.STRESS.NOT_PROVIDED");
            }

            const stress = Object.values(Stress)[stressNumber - 1];

            if (!stress) {
              throw new Error(`Invalid stress number: ${stressNumber}`);
            }

            return t(`my_progress.STRESS.${stress}`);
          },
        }}
        yAxisLabel=""
        yAxisSuffix=""
        fromZero={true}
        // NOTE: This is required to have a fixed y-axis range
        // (otherwise the y-axis range is determined by the data)
        fromNumber={5}
      />
      <View mt="8">{stressValuesTable}</View>
    </View>
  ) : (
    <Center>
      <Text mt="6">{t("my_progress.no_data_for_period_label")}</Text>
      {/* Add empty space */}
      <Box mt="32" />
    </Center>
  );

  const dataPointIndicesWithNoDataForWeightChart = getDataPointIndicesWithNoData(
    getDataPointsForChart(userWeightMeasurements, numDaysInChart)
  );

  const doesWeightChartContainAnyData = _.some(dataPointsForWeightChartWithNullsPadded);
  const weightChart = doesWeightChartContainAnyData ? (
    <>
      <LineChart
        segments={5}
        data={dataForWeightChart}
        width={screenWidth}
        height={300}
        chartConfig={chartConfig}
        bezier
        withDots={true}
        hidePointsAtIndex={dataPointIndicesWithNoDataForWeightChart}
        withVerticalLines={false}
        formatYLabel={(value: string) => formatNumberToDecimalPlaces(Number(value), 1)}
      />
      <Flex mt="-10" mx="10" direction="row" justifyContent={"space-between"}>
        {xAxisLabelsForLineChart.map((label, index) => (
          <Text key={index}>{label}</Text>
        ))}
      </Flex>

      <View mt="8">{weightChartInfoComponent}</View>
    </>
  ) : (
    <Center>
      <Text mt="6">{t("my_progress.no_data_for_period_label")}</Text>
      {/* Add empty space */}
      <Box mt="32" />
    </Center>
  );

  const dataPointIndicesWithNoDataForWaistCircumferenceChart = getDataPointIndicesWithNoData(
    getDataPointsForChart(userWaistCircumferenceMeasurements, numDaysInChart)
  );

  const waistCircumferencesValuesTable = createTableDisplayingMeasurements({
    userMeasurements: userWaistCircumferenceMeasurements,
    measurementType: MeasurementType.WAIST_CIRCUMFERENCE,
    backgroundColor: colors.primary["200"],
    deleteMeasurementForDate: onDeleteProgressMeasurementForDate,
    t,
    isDesktop,
  });

  const waistCircumferenceChart = _.some(dataForWaistCircumferenceChart?.datasets?.[0]?.data) ? (
    <>
      <LineChart
        segments={5}
        data={dataForWaistCircumferenceChart}
        width={screenWidth}
        height={300}
        chartConfig={chartConfig}
        bezier
        withDots={true}
        hidePointsAtIndex={dataPointIndicesWithNoDataForWaistCircumferenceChart}
        withVerticalLines={false}
        formatYLabel={(value: string) => formatNumberToDecimalPlaces(Number(value), 1)}
      />
      <Flex mt="-10" mx="10" direction="row" justifyContent={"space-between"}>
        {xAxisLabelsForLineChart.map((label, index) => (
          <Text key={index}>{label}</Text>
        ))}
      </Flex>
      {waistCircumferencesValuesTable}
    </>
  ) : (
    <Center>
      <Text mt="6">{t("my_progress.no_data_for_period_label")}</Text>
      {/* Add empty space */}
      <Box mt="32" />
    </Center>
  );

  const getRelevantChart = (measurementType: MeasurementType): JSX.Element => {
    switch (measurementType) {
      case MeasurementType.WEIGHT:
        return weightChart;
      case MeasurementType.SLEEP:
        return sleepChart;
      case MeasurementType.STRESS:
        return stressChart;
      case MeasurementType.WAIST_CIRCUMFERENCE:
        return waistCircumferenceChart;
      default:
        return <></>;
    }
  };

  const selectedChart = getRelevantChart(selectedMeasurementType);

  const chartComponent = (
    <>
      <Center>
        <Row>
          <Select
            selectedValue={selectedTimePeriod}
            onValueChange={(itemValue) => setSelectedTimePeriod(itemValue as TimePeriod)}
            width={isDesktop ? "150" : "32"}
            ml="3"
            testID="time-period-select"
          >
            {Object.values(TimePeriod).map((option, index) => (
              <Select.Item key={index} label={t(`my_progress.time_periods.${option}`)} value={option} />
            ))}
          </Select>

          <Select
            selectedValue={selectedMeasurementType}
            onValueChange={(itemValue) => setSelectedMeasurementType(itemValue as MeasurementType)}
            width={isDesktop ? "150" : "32"}
            ml="3"
            testID="measurement-type-select"
          >
            {Object.values(MeasurementType).map((option, index) => (
              <Select.Item
                key={index}
                label={t(`my_progress.measurement_type.${option as MeasurementType}`)}
                value={option}
              />
            ))}
          </Select>
        </Row>
      </Center>

      {selectedChart}
    </>
  );

  return (
    <SafeAreaView style={commonStyles.container}>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.container} testID="myProgressScreen">
        {!isDesktop && !viewAsUser ? <CustomBrandingMobileScreenHeader /> : null}
        {viewAsUser ? null : (
          <View style={{ padding: 20 }}>
            <Text style={styles.headerStyle}>{t("my_progress.screen_title")}</Text>
          </View>
        )}
        {chartComponent}
        {addMeasurementsModal}
      </ScrollView>
      {floatingActionButton}
    </SafeAreaView>
  );
};

export default MyProgressScreen;
