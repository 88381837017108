import _ from "lodash";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import type { MealSlotSpecification, MealTypeEnum, User } from "../../services/backendTypes";
import type { LegacyInput } from "../../services/legacyNutritionCalculations7";
import { clientNutritionPlansByIdSelector, clientsSelector, NUTRITION_PLAN_WITHOUT_ID } from "../../slices/userSlice";

/**
 * 0 = 0%
 */
export const DEFAULT_ENERGY_BALANCE = parseInt("100", 10);
type OTHER_ENERGY_BALANCE_TYPE = "-12361781";
export const OTHER_ENERGY_BALANCE: OTHER_ENERGY_BALANCE_TYPE = "-12361781";
export type EnergyBalanceOption = "80" | "90" | "100" | "105" | "110" | OTHER_ENERGY_BALANCE_TYPE;
export const energyBalanceChoices: EnergyBalanceOption[] = ["80", "90", "100", "105", "110"];

export const energyBalanceFormSchema = Yup.object().shape({
  // TODO: For some reason "" (incorrectly) passes form validation
  energyBalance: Yup.number().min(10).max(500).required().default(DEFAULT_ENERGY_BALANCE),
  protein: Yup.number().min(0),
  fat: Yup.number().min(0),
  carbohydrates: Yup.number().min(0),
});
export type EnergyBalanceFormSchema = Yup.InferType<typeof energyBalanceFormSchema>;

export const getDefaultMealType = (order: number): MealTypeEnum => {
  if (order === 0) {
    return "BREAKFAST";
  }
  if (order === 2) {
    return "LUNCH";
  }
  if (order === 4) {
    return "DINNER";
  }

  return "SNACK";
};

export function getClientNutritionPlan(client: User, id: number = NUTRITION_PLAN_WITHOUT_ID): LegacyInput {
  // TODO: This should be removed to avoid potential errors but it is working at the moment
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clientNutritionPlansByClientId = useSelector(clientNutritionPlansByIdSelector);

  if (!client) {
    throw new Error("Client not found");
  }

  const clientNutritionPlans = _.get(clientNutritionPlansByClientId, client.id);

  const clientNutritionPlan = _.find(clientNutritionPlans, { id });

  if (!clientNutritionPlan) {
    throw new Error(`Client nutrition plan "${id}" not found`);
  }

  return clientNutritionPlan;
}

export function calculateKcalForMealSlot(mealSlotSpecification: MealSlotSpecification): number {
  const { protein, fat, carbohydrates } = mealSlotSpecification;
  return (protein || 0) * 4 + (fat || 0) * 9 + (carbohydrates || 0) * 4;
}
