import { StyleSheet } from "react-native";

import { Scale, VerticalScale, width } from "../constants";

const styles = StyleSheet.create({
  checkedContainer: {
    backgroundColor: "white",
    // borderColor: Colors.primaryGreenColor,
    borderWidth: 2,
  },
  checkboxBase: {
    width: Scale(28),
    height: Scale(28),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    borderWidth: 2,
    // borderColor: Colors.borderColor,
    backgroundColor: "white",
    marginRight: Scale(16),
  },
  checkboxChecked: {
    borderWidth: 2,
    // borderColor: Colors.primaryGreenColor,
    // backgroundColor: Colors.primaryGreenColor,
  },
  multiCheckbox: {
    borderRadius: 4,
  },
  checkedIcon: {
    width: Scale(20),
    height: Scale(20),
  },
  descriptionText: {
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  recipeMacrosContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textInputStyle: {
    width: "100%",
    marginBottom: 10,
    marginTop: 0,
  },
  externalTextInputStyle: {
    width: "100%",
    maxWidth: 200,
    marginHorizontal: 0,
    marginVertical: 0,
    paddingVertical: 0,
  },
  externalContainer: {
    backgroundColor: "white",
    // borderColor: Colors.disableButton,
    borderWidth: 1,
    marginTop: VerticalScale(5),
  },
});

export default styles;
