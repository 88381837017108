import React from "react";
import { StyleSheet, TextInput, View } from "react-native";

import { Scale, width } from "../constants";

const styles = StyleSheet.create({
  textBoxContainer: {
    borderWidth: StyleSheet.hairlineWidth,
    paddingHorizontal: Scale(10),
    paddingVertical: Scale(10),
    width: width / 5,
    marginRight: Scale(10),
    alignSelf: "center",
    marginTop: Scale(10),
    borderRadius: Scale(5),
    marginBottom: Scale(30),
    flexDirection: "row",
  },
  wrapper: {},
  inputContainer: { width: "100%" },
});

type CommonDateTextInputProps = {
  value: string;
  placeholder: string;
  maxLength: number;
  onChangeText: (text: string) => void;
};

const CommonDateTextInput = ({
  value,
  placeholder,
  maxLength,
  onChangeText,
}: CommonDateTextInputProps): JSX.Element => (
  <View style={{ ...styles.textBoxContainer }}>
    <TextInput
      placeholder={placeholder}
      style={styles.inputContainer}
      maxLength={maxLength}
      keyboardType="number-pad"
      value={value}
      returnKeyType="done"
      onChangeText={onChangeText}
    />
  </View>
);

export default CommonDateTextInput;
